import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

/**
 * Check if this control value is a string representation of a date ISO.
 * @see https://github.com/rsaenen/ngx-custom-validators
 * @return ValidatorFn
 */
export const dateISO: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const v: string = control.value;
  return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12]\d|3[01])$/.test(v) ? null : { dateISO: true };
};
