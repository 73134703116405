import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormControlStatus, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Entity } from '@app/core/model/entities/entity';
import { Equipment, IRelatedEquipment, RelatedEquipment } from '@app/core/model/entities/equipments/equipment';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue, simplifyStringForSearch } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, mergeWith, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'equipments-chips-field-builder',
  templateUrl: './equipments-chips-field-builder.component.html',
  styleUrls: ['../chips-field-builder.component.scss']
})
export class EquipmentsChipsFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public noAsset = true;
  public noEquipment = true;
  public focusable = false;

  public Permission = PermissionEnum;
  public chips: RelatedEquipment[] = [];
  public equipments: Observable<RelatedEquipment[]>;

  declare public form: FormGroup<{ field: FormControl<string> }>;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('inputField') public inputField: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') public suggestions: MatAutocomplete;
  @ViewChild('chipList') public chipList: MatChipGrid;

  private assetChange$ = new Subject<string>();

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              private equipmentsService: EquipmentsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  /**
   * Create the form, add the chips and hook to the asset value change to update the equipment list.
   */
  public ngOnInit(): void {
    const initialEquipments = this.getFieldValue();
    this.chips.push(...initialEquipments);
    this.form = new FormGroup({
      field: new FormControl('', this.computeValidators({values: this.chips}))
    });

    // Propagate invalid status to the mat-chip-list
    this.form.controls.field.statusChanges.subscribe(
      (status: FormControlStatus) => {
        if (this.chipList !== void 0) {
          this.chipList.errorState = status === 'INVALID';
        }
      });

    // Load Equipments
    if (this.getFieldGroupInfo(['asset', 'valueChanges'])) {
      // If the field group contains an Asset field whose value can change, load Equipments whenever a new Asset is selected
      this.getFieldGroupInfo(['asset', 'valueChanges'])
        .pipe(
          takeUntil(this.destroy$),
          tap((assetFieldGroup: { asset: RelatedAsset }) => {
            const value = assetFieldGroup?.asset?.id === this.entity['assetId']
              ? this.getFieldValue()
              : [];
            this.setFieldValue(value);
            this.chips = value;

          }),
          switchMap(({asset}: { asset: RelatedAsset }) => {
            if (asset) {
              this.noAsset = false;
              return this.equipmentsService.loadEquipments(asset.id);
            }
            this.noAsset = true;
            return of([]);
          })
        )
        .subscribe((equipments: Equipment[]) => {
          this.noEquipment = equipments.length <= 0;
          this.focusable = !this.noEquipment;
          this.fieldConfig.field.fieldValues = equipments;
          this.assetChange$.next('');
        });
    } else {
      // Otherwise, load the entity's related Asset's Equipments only once, if applicable.
      const assetId = this.entity['assetId'];
      if (assetId) {
        this.noAsset = false;
        this.equipmentsService.loadEquipments(assetId)
          .pipe(first())
          .subscribe((equipments) => {
            this.noEquipment = equipments.length <= 0;
            this.focusable = !this.noEquipment;
            this.fieldConfig.field.fieldValues = equipments;
          });
      } else {
        // FIXME when building the field for the first time, the entity is the Asset or Work instead of the expected related entity (Lease, Check...)
        this.noAsset = true;
        this.noEquipment = true;
        this.fieldConfig.field.fieldValues = [];
      }
    }

    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();

    this.equipments = this.form.controls.field.valueChanges.pipe(
      startWith(''),
      mergeWith(this.assetChange$.asObservable()),
      filter(value => value !== void 0),
      map(value => this.filter(value).map(equipment => equipment.toRelatedEquipment())),
    );
  }

  /**
   * Set up hooks
   */
  public ngAfterViewInit(): void {
    this.setupHooks();
  }

  /**
   * Get the field value to display in read mode.
   * @return any
   */
  public getFieldValue(): RelatedEquipment[] {
    return getValue(this.entity, this.fieldConfig.fieldPath)
      ?.map((equipment: IRelatedEquipment) => new RelatedEquipment(equipment)) ?? [];
  }

  /**
   * Compute the field initial value.
   */
  protected calcFieldInitValue(): void {
    this.fieldInitValue = this.fieldConfig ? this.getFieldValue().map(equipment => equipment.id) : [];
  }

  /**
   * Filer use by the autocomplete input.
   * @param value the input value
   * @private
   */
  private filter(value: Equipment | string): Equipment[] {
    return this.fieldConfig.field?.fieldValues
      .filter((equipment: Equipment) => simplifyStringForSearch(equipment.toString()).includes(
        simplifyStringForSearch(value.toString())) && !this.chips.map(
        equipment => equipment.toString()).includes(equipment.toString()));
  }

  /**
   * Remove a chip from the list.
   * @param chip the chip to be removed.
   */
  public remove(chip: any): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    this.setFieldValue(this.chips.slice().map(equipment => equipment.id));
  }

  /**
   * Add a chip when the user select an entry in the suggestion panel.
   * @param event the selection event.
   */
  public select(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.inputField.nativeElement.value = '';
    this.add(value);
  }

  /**
   * Reset the form and the chips to they initial state, then switch to read mode.
   */
  public cancel(): void {
    this.chips = this.getFieldValue().slice();
    this.form.get('field').setValue('');
    this.setFieldValue((this.getFieldInitialValue() || []).slice());
    this.getNextState();
  }

  /**
   * Add a chip to the list.
   * @param value The chip to add.
   * @private
   */
  private add(value: any): void {
    // Add the new chip
    this.chips.push(value);
    this.form.get('field').setValue('');
    this.setFieldValue(this.chips.slice().map(equipment => equipment.id));
  }

  /**
   * Set focus on the element
   */
  public focus(): void {
    if (this.focusable) {
      setTimeout(() => this.inputField.nativeElement.focus());
    }
  }

  /**
   * Navigate to Equipment sheet
   * @param equipmentId
   */
  public async navigateToEquipmentSheet(equipmentId: string): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.EQUIPMENT,
      equipmentId
    );
    await this.equipmentsService.navigateToEquipmentSheet(equipmentId);
  }
}
