<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div [dir]="dir" id="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in application-header.scss -->
  <!-- ============================================================== -->
  <menubar></menubar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav [disableClose]="mobileQuery.matches" fixedInViewport
                 [opened]="!this.data.hideSidenav && (sidenavService.isSidenavOpen | async)"
                 [mode]="mobileQuery.matches ? 'side' : 'over'"
                 class="fx-layout-col fx-justify-start fx-align-stretch dark-sidebar pl-xs "
                 id="snav">
      <div class="page-sidebar fx-layout-col fx-justify-start fx-align-stretch">
        <router-outlet name="sidebar"></router-outlet>
        <span class="fx-flex"></span>
        <div class="version">
          <span>{{ version }}</span>
        </div>
      </div>

    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="main-content fx-layout-col">
      <spinner class="page-container">
        <div class="page-titles-container fx-flex-no-grow">
          <div class="page-titles">
            <banner></banner>
          </div>
        </div>
        <div class="page-content-container fx-flex-grow">
          <div class="page-content fx-fill">
            <router-outlet></router-outlet>
          </div>
        </div>
      </spinner>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
