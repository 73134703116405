import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';
import dayjs from 'dayjs';

export const maxNow = (control: AbstractControl): ValidationErrors | null => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const inputYear = dayjs(control.value);
  const thisYear = dayjs();
  return (inputYear.isSame(thisYear) || inputYear.isBefore(thisYear)) ? null : { maxNow: true };
};
