<div class="document-viewer-container" *ngIf="previewUrl && this.mimeType; else loading">
  <div *ngIf="mimeType==='application/pdf'">
    <object [data]="previewUrl"
            [type]="mimeType"
            [ngClass]="cssCLass"></object>
  </div>
  <div *ngIf="mimeType!=='application/pdf'">
    <img [src]="previewUrl"
         [ngClass]="cssCLass"
         alt="documentPreview"/>
    <span
      *ngIf="!mimeType.includes('image') || unsupportedImageType.includes(this.mimeType)"> {{ 'LABEL.UNVAILABLE_PREVIEW' | translate }} </span>
  </div>
</div>
<ng-template #loading>
  <spinner></spinner>
</ng-template>
