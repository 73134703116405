import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';

@Injectable()
export class SpinnerService {
  private overlayRef: OverlayRef = null;

  constructor(private overlay: Overlay) {
  }

  public showSpinner(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay.position()
          .global()
          .centerHorizontally()
          .centerVertically('-10%'),
        width: 40,
        height: 40,
        hasBackdrop: true,
        scrollStrategy: this.overlay.scrollStrategies.block()
      });
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);
    this.overlayRef.attach(spinnerOverlayPortal);
  }

  public hideSpinner(): void {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
