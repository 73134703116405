import { Injectable } from '@angular/core';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum, EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { User } from '@app/core/model/client/user';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AnalyticsService {

  private currentPageName: string;

  constructor(private angulartics2: Angulartics2,
              private translate: TranslateService,
              private appManager: AppManager) {
    // Desactivate the automatic tracking on url
    this.angulartics2.settings.pageTracking.autoTrackVirtualPages = false;
  }

  // Set general information about one session
  public setSessionInformation(user: User) {
    this.angulartics2.setUserProperties.next(
      {
        clients: user.clients.map((client) => {
          return client.id;
        }),
        role: user.userRole.id,
        isTb: user.userRole.isTb,
        id: user.id
      });
    this.angulartics2.setUsername.next(user.id);
  }

  // Track current page and send page title to mixpanel
  public trackDisplayPageEvent(currentPageName: string) {
    this.currentPageName = currentPageName;
    // TODO : determine the current module with the current page
    this.trackEvent(new AnalyticsEvent(ActionEnum.READ)
    );
  }

  // Track navigation events from action initialization
  public trackNavigationEvent(eventOrigin: string, navigateTo: string, entityType?: string, entityId?: string) {
    const analyticsEvent = new AnalyticsEvent(ActionEnum.NAVIGATE)
      .addProperties({
        [AnalyticsKeyEnum.EVENT_ORIGIN]: eventOrigin,
        [AnalyticsKeyEnum.NAVIGATE_TO]: this.translate.instant(navigateTo)
      });
    if (entityType) {
      analyticsEvent.addProperties({ [AnalyticsKeyEnum.ENTITY_TYPE]: entityType });
    }
    if (entityId) {
      analyticsEvent.addProperties({ [AnalyticsKeyEnum.ENTITY_ID]: entityId });
    }
    this.trackEvent(analyticsEvent);
  }

  // Track actions in grids
  public trackGridActionEvent(gridActionType: string, entityType: string) {
    const analyticsEvent = new AnalyticsEvent(ActionEnum.GRID)
      .addProperties({
        [AnalyticsKeyEnum.GRID_ACTION_TYPE]: gridActionType,
        [AnalyticsKeyEnum.ENTITY_TYPE]: entityType
      });
    this.trackEvent(analyticsEvent);
  }

  // Track actions in inline fields
  public trackInlineActionEvent(field: string, inlineAction: ActionEnum, eventOrigin: EventOriginEnum) {
    const analyticsEvent = new AnalyticsEvent(ActionEnum.UPDATE)
      .addProperties({
        [AnalyticsKeyEnum.FIELD]: field,
        [AnalyticsKeyEnum.INLINE_ACTION]: inlineAction,
        [AnalyticsKeyEnum.EVENT_ORIGIN]: eventOrigin
      });
    if (this.appManager.currentEntity && this.appManager.currentEntity.entityType) {
      analyticsEvent.addProperties({
        [AnalyticsKeyEnum.ENTITY_TYPE]: this.appManager.currentEntity.entityType,
        [AnalyticsKeyEnum.ENTITY_ID]: this.appManager.currentEntity.id
      });
    }
    this.trackEvent(analyticsEvent);
  }

  // Add page information to analyticsEvent then send it to mixpanel
  public trackEvent(analyticsEvent: AnalyticsEvent): void {
    if (environment.production === true) {
      this.angulartics2.eventTrack.next(
        analyticsEvent.addProperties({ [AnalyticsKeyEnum.CURRENT_PAGE]: this.currentPageName })
          .buildEventTrack()
      );
    }
  }
}
