import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { Space } from '@app/core/model/entities/asset/space';
import {
  OrganizationSpaceCreateModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-create-modal/organization-space-create-modal.component';

@Component({
  templateUrl: './space-create-modal.component.html'
})
export class SpaceCreateModalComponent extends OrganizationSpaceCreateModalComponent {

  constructor(appConfig: AppConfig,
              fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) data: { parentSpace: Space }) {
    super(appConfig, fb, data);
  }
}
