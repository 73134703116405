import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'restricted-text-button',
  templateUrl: './restricted-text-button.component.html',
  styleUrls: ['./restricted-text-button.component.scss'],

})
export class RestrictedTextButtonComponent {

  /*
  Boolean expression for set class style
  mat-raised-button or mat-stroked-button
 */
  @Input() public isRaisedButton: boolean = false;

  /*
  Optional colour parameter
   */
  @Input() public colour: ThemePalette = undefined;
  /*
  To link with the underlying button's click event
   */
  @Output() public onClick = new EventEmitter<any>();

  /*
  Array of permission codes found in permissions.enum.ts
  to display the button
   */
  @Input() public permissions: any[] = [];
  /*
  Boolean expression of preconditions to enabling the button
   */
  @Input() public preconditions: boolean = true;

  /*
  Boolean expression of margins to add to button
   */
  @Input() public withMargins: boolean = true;

  /*
  An id, use in e2e test for select the button
   */
  @Input() public id: string = '';

  public internalButtonClick(event: MouseEvent): void {
    if (this.preconditions) {
      this.onClick.emit(event);
    }
  }

  /**
   * add handler on native JS click event
   * stop propagation and force usage of onCLick custom event
   */
  @HostListener('click', ['$event'])
  public handleNativeClick(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }

}
