import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Space } from '@app/core/model/entities/asset/space';
import { Document } from '@app/core/model/entities/document/document';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import { getValue, hasProperty, setValue } from '@app/shared/extra/utils';
import { Expose, Type } from 'class-transformer';

export class Equipment extends Entity {
  public entityType = EntityTypeEnum.EQUIPMENT;
  public assetId: string;
  public identifier: number;
  public organizationId: string;
  public dataDate: string;

  @Expose({name: 'spaceIdsList'})
  public spaceIds: Space[];

  @Type(() => Document) public documents: Document[] = [];
  @Type(() => Document) public pictures: Document[] = [];

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this.identifier} - ${this.name}`;
  }

  /**
   * Transforms this Equipment to a RelatedEquipment.
   */
  public toRelatedEquipment(): RelatedEquipment {
    return new RelatedEquipment({
      id: this.id,
      name: this.name,
      identifier: this.identifier,
      displayName: this.computedProperties?.['displayName']
    });
  }

  // Properties to be renamed
  protected static inputMapping = {
    asset: {input: ['computedProperties', 'asset', 'id'], output: 'assetId'},
    spaces: {input: ['computedProperties', 'spaces'], output: 'spaceIds'}
  };

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    // Transform input, mapping keys as defined in the inputMapping above
    for (const inputMappingElement in Equipment.inputMapping) {
      if (hasProperty(obj, Equipment.inputMapping[inputMappingElement].input)) {
        const source = getValue(obj, Equipment.inputMapping[inputMappingElement].input);
        setValue(obj, [Equipment.inputMapping[inputMappingElement].output], source);
      }
    }

    // Clean up by removing older keys
    for (const inputMappingElement in Equipment.inputMapping) {
      delete obj[Equipment.inputMapping[inputMappingElement].input.first()];
    }
  }
}

export type IRelatedEquipment = IRelatedEntity;

export class RelatedEquipment {
  public id: string;
  public displayName: string;

  constructor(relatedEquipment: IRelatedEquipment) {
    this.id = relatedEquipment.id;
    this.displayName = relatedEquipment.displayName
      ?? `${relatedEquipment.identifier ?? '?'} - ${relatedEquipment.name ?? '?'}`;
  }

  @Expose()
  public toString(): string {
    return this.displayName;
  }
}

export interface EquipmentInput {
  name?: string;
  state?: string;
  spaceIds?: string[];
  dataDate?: string;
}
