<div class="field-builder-container">
  @if (currentMode === FieldMode.SAVING) {
    <div class="field-custom-container">
      <div class="saving-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EMPTY) {
    <div class="field-custom-container">
      <div class="empty-field-container">
        <div
          [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
          class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <div class="empty-value">
              @if (accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition) {
                <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
              } @else {
                {{ appConfig.EMPTY_FIELD_VALUE }}
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.READ) {
    <div class="field-custom-container">
      @for (field of fields; track field) {
        <div class="read-field-container">
          <div
            [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
            class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
            <div class="field-label fx-flex-100-33-percent">
              {{ 'LABEL.' + field.label | uppercase | translate }}
              @if (getFieldConfig(field.label).tooltip) {
                <span [matTooltipPosition]="'below'"
                  [matTooltip]="getFieldConfig(field.label).tooltip" #tooltip="matTooltip"
                  (click)="onClickTooltip(tooltip)">
                  <i class="mdi mdi-help-circle tooltip-icon"></i>
                </span>
              }
            </div>
            <div class="field-container fx-flex-100-66-percent fx-layout-col">
              <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" [tabindex]="0"
                [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
                @if (getFieldConfig(field.label).hasPictograms && field.value) {
                  <div>
                    <img class='pictogram'
                      [src]="appConfig.PICTOGRAM_FOLDER_PATH + getFieldConfig(field.label).pictogram(field.value)"
                      [alt]="field.value">
                    @if (getFieldConfig(field.label).isPictogramWithText) {
                      <span>{{ field.value }}</span>
                    }
                  </div>
                } @else {
                  <span>{{ field.value }}</span>
                }
                @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
                  <span class="overlay-icon">
                    @if (preconditionsForEdition) {
                      <i class="mdi mdi-pencil selectable"></i>
                    } @else {
                      <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
                    }
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  }

  @if (currentMode === FieldMode.EDIT) {
    <div class="field-custom-container">
      <div class="edit-field-container">
        <div class="field-container fx-layout-col" tabindex="0" #gridContainer>
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <ag-grid-angular (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions"
            [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
            class="ag-theme-material clickable-rows"
            id="technical-category-datagrid"
            rowSelection="single"
            style="height: 500px; width: 100%;">
          </ag-grid-angular>
          <div class="button-container fx-layout-row fx-justify-end fx-align-center">
            <button id="cancel-button"
              (click)="onClickCancel()"
              (keyup.escape)="onEscapeCancel($event)"
              mat-stroked-button>
              {{ 'BUTTON.CANCEL' | translate }}
            </button>
            <button id="save-button"
              (click)="onClickSave()"
              [disabled]="false"
              color="accent"
              mat-raised-button>
              {{ 'BUTTON.SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</div>
