import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ASYNC_PRECONDITIONS_INJECTION,
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldGroup
} from '@app/core/model/other/field-config';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FieldAnchorDirective } from '@app/shared/components/fields/field-anchor.directive';
import { getFieldBuilder } from '@app/shared/components/fields/field-builder-selector';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'single-field-builder',
  templateUrl: './single-field-builder.component.html'
})
export class SingleFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  // Static parameter in Angular 8
  @ViewChild(FieldAnchorDirective, {static: true}) public fieldHost: FieldAnchorDirective;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );
  }

  public ngOnInit(): void {
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isSingleField: true,
      isDisplayed: new BehaviorSubject<boolean>(true),
      value: {},
      initialValue: {},
      hook: {}
    });
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    const fieldGroupContainerRef = this.fieldHost.viewContainerRef;
    fieldGroupContainerRef.clear();

    // Subscribe to any change in state of the single
    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      this.currentMode = newMode;
    });

    this.fieldGroup.fieldConfigs.forEach((fieldConfig, index) => {
      // get the field type to create
      const fieldComponent = getFieldBuilder(fieldConfig);

      // Create the corresponding component if it is recognized
      if (fieldComponent !== null) {
        const fieldRef = fieldGroupContainerRef.createComponent(fieldComponent.fieldBuilder, {
          index: index,
          injector: Injector.create({
            parent: fieldGroupContainerRef.injector,
            providers: [
              {provide: FIELD_CONFIG_INJECTION, useValue: fieldConfig},
              {provide: FIELD_ENTITY_INJECTION, useValue: this.entity},
              {
                provide: FIELD_PRECONDITIONS_INJECTION,
                useValue: this.preconditionsForEdition && this.validationService.fieldIsEditable(
                  fieldConfig,
                  this.entity
                )
              },
              {
                provide: FIELD_ASYNC_PRECONDITIONS_INJECTION,
                useValue: this.validationService.getAsyncPreconditionsToEdit(fieldConfig.field?.validators, this.entity)
              },
              {
                provide: FIELD_EXTRA_DATA, useValue: {
                  isSingleField: true,
                  fieldGroupCode: this.fieldGroup.code,
                  propertiesPath: this.data.propertiesPath,
                  focusable: fieldComponent.focusable
                }
              }
            ]
          })
        });
        fieldRef.changeDetectorRef.detectChanges();

        this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
          if (newMode === FieldStateMode.EDIT) fieldRef.instance.focus();
        });

      } else {
        console.warn('Le type de champ "' + fieldConfig.field?.fieldType + '" n\'est pas reconnu');
      }

    });
  }
}
