export enum DocumentTypeEnum {
  LOGO = 'LOGO',
  ASSET_PICTURE = 'ASSET_PICTURE',
  ASSET_DOCUMENT = 'ASSET_DOCUMENT',
  CHECK_DOCUMENT = 'CHECK_DOCUMENT',
  LEASE_DOCUMENT = 'LEASE_DOCUMENT',
  CONTRACT_DOCUMENT = 'CONTRACT_DOCUMENT',
  SPACE_DOCUMENT = 'SPACE_DOCUMENT',
  BMA = 'BMA',
  PROJECT = 'PROJECT',
  ORGANIZATION_DOCUMENT = 'ORGANIZATION_DOCUMENT',
  WORK_PICTURE = 'WORK_PICTURE',
  WORK_DOCUMENT = 'WORK_DOCUMENT',
  EQUIPMENT_PICTURE = 'EQUIPMENT_PICTURE',
  EQUIPMENT_DOCUMENT = 'EQUIPMENT_DOCUMENT',
  IMPORT = 'IMPORT',
  PROJECT_DOCUMENT = 'PROJECT_DOCUMENT'
}
