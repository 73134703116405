import { Injectable } from '@angular/core';
import { Event } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivationEndService {

  private routeData$: ReplaySubject<Event> = new ReplaySubject<Event>();
  private optionalParamsSubject$: Subject<Record<string, Record<string, string>>> = new Subject<Record<string, Record<string, string>>>();
  private refreshHeaderSubject$: Subject<void> = new Subject<void>();
  private breadCrumbLoadedSubject$: Subject<void> = new Subject<void>();

  public pushRouteEvent(event): void {
    this.routeData$.next(event);
  }

  public getRouteData(): Observable<Event> {
    return this.routeData$.asObservable();
  }

  public pushOptionalParams(optionalParams: Record<string, Record<string, string>>): void {
    this.optionalParamsSubject$.next(optionalParams);
  }

  public getBreadCrumbSubject(): Subject<Record<string, Record<string, string>>> {
    return this.optionalParamsSubject$;
  }

  public getRefreshHeaderSubject(): Subject<void> {
    return this.refreshHeaderSubject$;
  }

  public getBreadCrumbLoadedSubject(): Subject<void> {
    return this.breadCrumbLoadedSubject$;
  }
}
