import { inject } from '@angular/core';
import { thenReturn } from '@app/shared/extra/utils';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';

/**
 * Clear current Organization, current entities, reset translation language.
 * @return Observable emitting true.
 */
export default function cleanupGuard(): Observable<boolean> {
  const appManager = inject(AppManager);
  appManager.currentOrganization = undefined;
  appManager.emptyCurrentEntityStack();
  appManager.entityObservable$.next(false);
  return appManager.resetLang()
    .pipe(thenReturn(true));
}
