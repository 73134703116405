<div id="dialog-container" class="fx-layout-col fx-fill fx-gap-20-lt-md fx-center-lt-md" [cdkTrapFocus]="true">
  <mat-dialog-content class="fx-flex-auto fx-layout-col fx-justify-space-around fx-align-center">
    <i class="mdi lg" [ngClass]="iconName" [style.color]="colour"></i>
    <div class="fx-layout-col fx-justify-start fx-align-center">
      <h3 id="title" class="modal-title">{{ title }}</h3>
      <div id="message" class="modal-message">{{ message }}</div>
    </div>
    <div></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose id="dialog-cancel-button">{{ cancelText }}</button>
    <button mat-raised-button cdkFocusInitial matDialogClose="yes" color="accent" id="dialog-ok-button">{{ okText }}
    </button>
  </mat-dialog-actions>
</div>
