import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SpinnerService } from '@app/shared/popup/spinner.service';

@Component({
  selector: 'spinner',
  template: `
    <div class="preloader" *ngIf="isSpinnerVisible; else content">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnDestroy {
  public isSpinnerVisible = true;
  @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private spinnerService: SpinnerService) {
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.isSpinnerVisible = false;
        }
      },
      error: () => {
        this.isSpinnerVisible = false;
      }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
