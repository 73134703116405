import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';

export abstract class AbstractBean {

  public id: any;
  public entityType: EntityTypeEnum;
  public version: number;
  public name: string;
  public selectedLanguage: number;

}
