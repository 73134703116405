<ng-container *ngIf="checkTypeCode !== 'dpe'; else dpeTemplate">
  <div *ngIf="checkResult" class="dpe-cell fx-layout-row fx-justify-start fx-align-center">
    <div class="label-container">
      <div class="label-content"
           [ngClass]="checkResult | checkResultColour: {checkType: params.checkTypes[checkTypeCode]}"></div>
    </div>
    <div class="result-text">
      {{ checkResult }}
    </div>
  </div>
  <span *ngIf="!checkResult"> {{ emptyValue }}</span>
</ng-container>
<ng-template #dpeTemplate>
  <div class="dpe-cell fx-layout-row fx-justify-start fx-align-center fx-gap-5">
    <ng-container *ngIf="climateLabel">
      <div [ngClass]="'climate-color-' + climateLabel.toLowerCase()"
           class="label-content dpe-label-content not-hoverable">
        {{ climateLabel }}
      </div>
    </ng-container>
    <ng-container *ngIf="powerLabel">
      <div [ngClass]="'power-color-' + powerLabel.toLowerCase()"
           class="label-content dpe-label-content not-hoverable">
        {{ powerLabel }}
      </div>
    </ng-container>
    <span *ngIf="!climateLabel || !powerLabel"> {{ emptyValue }}</span>
  </div>
</ng-template>
