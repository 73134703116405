import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'restricted-icon-button',
  templateUrl: './restricted-icon-button.component.html',
  styleUrls: ['./restricted-icon-button.component.scss']
})
export class RestrictedIconButtonComponent {
  /*
  Optional colour parameter
   */
  @Input() public colour: ThemePalette = undefined;

  /*
  Optional colour parameter
   */
  @Input() public withWhiteBackground = false;
  /*
  To link with the underlying button's click event
   */
  @Output() public onClick = new EventEmitter<any>();

  /*
  Array of permission codes found in permissions.enum.ts
  to display the button
   */
  @Input() public permissions: any[] = [];
  /*
  Boolean expression of preconditions to enable the button
   */
  @Input({ transform: booleanAttribute }) public preconditions: boolean = true;

  /*
  Boolean expression of preconditions to disable the button
   */
  @Input() public disabled: boolean = false;

  /*
  Boolean expression of margins to add to button
   */
  @Input() public withMargins: boolean = false;

  @Input() public class: string = '';

  /*
  An id, use in e2e test for select the button
   */
  @Input() public id: string = '';

  // If set to true, the icon color is changed to the accent color
  @Input() public highlighted: boolean = false;

  // Spin the icon
  @Input() public spin = false;

  @ViewChild('iconButton') public iconButton: ElementRef;

  public internalButtonClick(event: MouseEvent): void {
    if (this.preconditions) {
      this.onClick.emit(event);
    }
  }

  /**
   * add handler on native JS click event
   * stop propagation and force usage of onCLick custom event
   */
  @HostListener('click', ['$event'])
  public handleNativeClick(e: MouseEvent): void {
      e.preventDefault();
      e.stopPropagation();
  }

}
