import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';

/**
 * Observes the visibility of a focused input element and emits an event when the visibility changes.
 */
@Directive({
  selector: 'input[appVisibilityObserver]'
})
export class VisibilityObserverDirective implements OnDestroy {
  /**
   * Emits a boolean value indicating whether the element is visible or not.
   */
  @Output() public visibilityChange = new EventEmitter<boolean>();
  private observer: IntersectionObserver;
  private isFocused = false;
  private isFirstObservation = true;

  /**
   * Initializes the intersection observer.
   * @param element A reference to the element that this directive is applied to.
   */
  constructor(private element: ElementRef) {
    this.observer = new IntersectionObserver(([entry]) => {
      // Do not emit on the first observation because the focused input is obviously visible.
      if (this.isFocused && !this.isFirstObservation) {
        this.visibilityChange.emit(entry.isIntersecting);
      }
      this.isFirstObservation = false;
    });
  }

  /**
   * Called when the element receives focus. It starts observing the element for visibility changes.
   */
  @HostListener('focus')
  public onFocus(): void {
    this.isFocused = true;
    this.isFirstObservation = true;
    this.observer.observe(this.element.nativeElement);
  }

  /**
   * Called when the element loses focus. It stops observing the element for visibility changes.
   */
  @HostListener('blur')
  public onBlur(): void {
    this.isFocused = false;
    this.observer.unobserve(this.element.nativeElement);
  }

  /**
   * Called when the directive is destroyed. It disconnects the intersection observer.
   */
  public ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
