<div class="ag-large-text-input ag-labeled ag-label-align-left ag-text-area ag-input-field">
  <div class="ag-wrapper ag-input-wrapper ag-text-area-input-wrapper">
    <textarea #inputTextArea [formControl]="formControl" matInput
              class="ag-input-field-input ag-text-area-input fullsize-textarea" cols="60" rows="10"></textarea>
  </div>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
  <div>
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>

