import { Directive, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AccordionDirective } from '@app/shared/directives/accordion.directive';


@Directive({
  selector: '[appAccordionLink]'
})
export class AccordionLinkDirective implements OnInit, OnDestroy {

  @Input() public group: any;
  protected _opened: boolean;
  protected nav: AccordionDirective;

  constructor(@Inject(AccordionDirective) nav: AccordionDirective) {
    this.nav = nav;
  }

  @HostBinding('class.opened')
  @Input()
  get opened(): boolean {
    return this._opened;
  }

  set opened(value: boolean) {
    this._opened = value;
  }

  ngOnInit(): any {
    this.nav.addLink(this);

  }

  ngOnDestroy(): any {
    this.nav.removeGroup(this);
  }

  toggle(): any {
    this.opened = !this.opened;
  }
}
