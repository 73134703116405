import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable } from 'rxjs';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { ICellEditorParams } from 'ag-grid-enterprise';

@Component({
  selector: 'text-cell-edit',
  templateUrl: './text-cell-edit.component.html',
  styleUrls: ['./text-cell-edit.component.scss']
})
export class TextCellEditComponent extends AbstractCellEditor implements OnDestroy {

  public suffix?: string;
  public suggestedOptions?: string[];
  public filteredSuggestions: Observable<string[]>;

  @ViewChild(MatInput, {static: false}) public input: MatInput;
  @ViewChild('auto', {static: false}) public autocomplete: MatAutocomplete;

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {

    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
    this.suffix = this.params.suffix;
    this.suggestedOptions = this.params.suggestedOptions;

    if (this.suggestedOptions) {
      this.filteredSuggestions = this.control.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filter(value))
        );
    }
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    if (!this.fullRowEdit) {
      this.input.focus();
    }
  }

  /**
   * Gets called when focus should be put into the editor.
   */
  public focusIn(): void {
    this.input.focus();
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.suggestedOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  public optionSelected(): void {
    this.params.stopEditing();
  }

  public onFocusOut(): void {
    if (this.autocomplete.isOpen) {
      //Perform the save only if no options was selected
      merge(this.autocomplete.optionSelected.asObservable().pipe(map(() => true)), this.autocomplete.closed.asObservable())
        .pipe(first(), takeUntil(this.destroy$))
        .subscribe(res => {
          if (!res) this.params.stopEditing();
        });
    }
  }

  /**
   *  Remove the error class on the cell GUI and stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
