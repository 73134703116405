import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { EventTrack } from 'angulartics2';

type PropertyFields = {
  [propertyKey in AnalyticsKeyEnum]?: string;
};

export class AnalyticsEvent {
  readonly action: string;
  private properties: PropertyFields;

  constructor(action: string, entityType?: string) {
    this.action = action;
    this.properties = entityType ? { [AnalyticsKeyEnum.ENTITY_TYPE]: entityType } : {};
  }

  public addProperties(optionalProperties: PropertyFields): this {
    this.properties = { ...this.properties, ...optionalProperties };
    return this;
  }

  public buildEventTrack(): EventTrack {
    return {
      action: this.action,
      properties: this.properties
    };
  }
}
