import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { AcceptInvitationComponent } from '@app/features/fullscreens/accept-invitation/accept-invitation.component';
import { RedirectComponent } from '@app/features/fullscreens/redirect/redirect.component';

import { SharedModule } from '@app/shared/shared.module';

const components = [
  RedirectComponent,
  AcceptInvitationComponent
];

@NgModule({
  imports: [
    SharedModule,
    NgOptimizedImage
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class FullscreenRoutingModule {
}
