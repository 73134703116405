<div class="fx-layout-row fx-justify-start fx-align-start fx-fill">
  <mat-sidenav-container class="fx-flex">
    <mat-sidenav #chartsSidePanel cdkTrapFocus class="fx-layout-col fx-justify-start fx-align-stretch"
      mode="side" position="end" autoFocus="true">
      <dashboard-tool-panel [preferenceIdKey]=preferenceIdKey class="fx-fill"></dashboard-tool-panel>
    </mat-sidenav>
    <mat-sidenav-content class="fx-fill fx-layout-col">
      @for (chartDivId of chartDivIds; track chartDivId) {
        <div #chartsContainer>
          <div class="chart" id="{{chartDivId}}"></div>
        </div>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
  @if (!isToolPanelOpen) {
    <button id='dashboard-tool-panel-button' (click)="openDashboardToolPanel()">
      <div class="button-text">
        <i class="mdi mdi-cog-outline"></i>
        {{"LABEL.DASHBOARD" | translate }}
      </div>
    </button>
  }
</div>
