import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { CarouselConfig, Image } from '@tbmaestro/ngx-picture-carousel';

@Component({
  selector: 'picture-widget',
  templateUrl: './picture-widget.component.html',
  styleUrls: ['./picture-widget.component.scss']
})
export class PictureWidgetComponent implements OnChanges {

  // Picture widget configurations
  @Input() public images: Image[] = [];
  @Input() public showArrows: boolean = false;

  public config: CarouselConfig = {
    background: 'transparent',
    hideThumbnails: true,
    hideDots: true,
    hideArrows: !this.showArrows,
    buttonsConfig: [],
    width: '100%',
    height: '400px'
  };

  constructor(public accessManager: AccessManager,
              public appConfig: AppConfig,
              public translate: TranslateService) {
  }

  /**
   * Listen for changes on images or gallery config and update carousel accordingly
   * @param changes The changed property.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const newImages: SimpleChange = changes.images;
    if (newImages.currentValue) {
      this.images = newImages.currentValue;
    }

    const showArrows: SimpleChange = changes.showArrows;
    if (showArrows.currentValue) {
      this.config = {...this.config, hideArrows: !showArrows.currentValue}
    }
  }

  public hasPicture(): boolean {
    return this.images.length > 0;
  }
}
