import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApolloCache } from '@apollo/client';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Client } from '@app/core/model/client/client';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Entity } from '@app/core/model/entities/entity';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import {
  ClientCreateModalComponent
} from '@app/features/main/views/management/modals/client-create-modal/client-create-modal.component';
import { OkCancelModalDialogComponent } from '@app/shared/popup/ok-cancel/ok-cancel.modal-dialog';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class ClientsListService  {

  public readonly clientGraphqlFragment: any;

  public createClientsSubject = new Subject<any>();
  public deleteClientsSubject = new Subject<any>();
  private destroy$ = new Subject<void>();

  constructor(private generalService: GeneralService,
              private spinnerService: SpinnerService,
              private popupManager: PopupManager,
              public translate: TranslateService,
              private analyticsService: AnalyticsService,
              private activationEndService: ActivationEndService,
              private snackbarManager: SnackbarManager,
              private appManager: AppManager) {
    // This fragment corresponds to all the info necessary for a client to be displayed in the client grid
    this.clientGraphqlFragment = gql`
      fragment ClientInfoForGrid on Client {
        id
        isTb
        name
        organizationId
        subscriptions {
          id
          nbUser
          startDate
          endDate
          contractId
          activeSubscriptionModules {
            id
            startDate
            endDate
            module {
              id
              code
              order
              isConfigurable
            }
            nbLicence
            userLicences {
              id
            }
          }
        }
      }
    `;
  }

  public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<Entity[]> {
    this.activationEndService.getRefreshHeaderSubject().next();
    return this.getClients();
  }

  /**
   * Fetches the list of clients of a given organization
   */
  public getClients(): Observable<Client[]> {
      const COMBINED_QUERY = gql`
          query ClientList($organizationId: String!) {
              clients(organizationId: $organizationId, tb: ALL) {
                  ...ClientInfoForGrid
              }
          }
          ${this.clientGraphqlFragment}
      `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
        .pipe(
            first(),
            map(response => {
              return plainToInstance(Client, response.data['clients'] as Client[]);
            })
        );
  }

  /**
   * Opens a dialog to create a new client
   */
  public createClient(): void {
    const dialogRef = this.popupManager.showGenericPopup(ClientCreateModalComponent, PopupSize.MEDIUM, {});
    dialogRef.afterClosed().pipe(
      tap((dialogResponse) => {
        const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.CLIENT);
        if (dialogResponse === 'yes') {
          analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
        } else {
          analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
        }
        this.analyticsService.trackEvent(analyticsEvent);
      }),
      filter(dialogResponse => dialogResponse === 'yes'),
      switchMap(() => {
        this.spinnerService.showSpinner();
        // get new client info on dialog close
        const dataObject = dialogRef.componentInstance.getGeneratedObject();
        const organizationId = dataObject.organizationId;
        const client = dataObject.client;
        // send to backend
        const COMBINED_MUTATION = gql`
          mutation CreateClient($organizationId: String!, $client: CreateClientInput!) {
            createClient(organizationId: $organizationId, client: $client) {
              ...ClientInfoForGrid
            }
          }
          ${this.clientGraphqlFragment}
        `;
        const MUTATION_VAR = {
          organizationId: organizationId,
          client: client
        };
        return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR).pipe(takeUntil(this.destroy$),
          tap({
            next: (response) => {
              this.spinnerService.hideSpinner();
              if (response.data['createClient']) {
                const newClient = plainToInstance(Client, response.data['createClient'] as Client);
                this.createClientsSubject.next(newClient);
              }
            },
            error: () => {
              this.spinnerService.hideSpinner();
            }
          }),
          mergeMap(() => {
            return this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CLIENT_CREATED')).onAction();
          }));
      })
    ).subscribe();
  }

  /**
   * Opens a dialog to delete a list of clients
   * Note: Evicts the clients from the cache to clear data inconsistencies
   * @param clients the list of clients to delete
   */
  public deleteClients(clients: Client[]): void {
    const clientIds = clients.map((clientRow: Client) => clientRow.id);

    this.displayDeleteClientModal().afterClosed().pipe(
      tap((dialogResponse) => {
        const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.CLIENT);
        if (dialogResponse === 'yes') {
          analyticsEvent.addProperties({
            [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
            [AnalyticsKeyEnum.ENTITY_ID]: clientIds.toString()
          });
        } else {
          analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
        }
        this.analyticsService.trackEvent(analyticsEvent);
      }),
      filter(dialogResponse => dialogResponse === 'yes'),
      switchMap(() => {
        const hasSelectedTbClient = clients.some((clientRow: Client) => clientRow.isTb);
        if (!hasSelectedTbClient) {

          this.spinnerService.showSpinner();
          const COMBINED_MUTATION = gql`
            mutation DeleteClients($clientIds: [Int!]!) {
              deleteClients(ids: $clientIds)
            }
          `;
          const MUTATION_VAR = {
            clientIds: clientIds
          };
          const updateCache =  (cache: ApolloCache<any>): void => {
            // Remove deleted clients in apollo cache
            clients.forEach(client => {
              cache.evict({id: cache.identify({id: client.id, __typename: 'Client'})});
            });
          };

          return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR, updateCache).pipe(takeUntil(this.destroy$),
            tap({
              next: (response) => {
                this.spinnerService.hideSpinner();
                if (response.data['deleteClients']) {
                  this.deleteClientsSubject.next(clientIds);
                }
              },
              error: () => {
                this.spinnerService.hideSpinner();
              }
            }),
            mergeMap(() => {
              return this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CLIENT_DELETED')).onAction();
            }));
        } else {
          this.popupManager.showOkPopup({
            dialogTitle: this.translate.instant('TITLE.UNAUTHORIZED_DELETE'),
            dialogMessage: this.translate.instant('MESSAGE.UNAUTHORIZED_DELETE_TB_CLIENT'),
            type: 'error'
          });
          return EMPTY;
        }
      })
    ).subscribe(/*OnNext Action Snackbar*/);
  }

  private displayDeleteClientModal(): MatDialogRef<OkCancelModalDialogComponent, any> {
    return this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant('TITLE.DELETE_CLIENT'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_CLIENT'),
      okText: this.translate.instant('BUTTON.DELETE_CLIENT'),
      type: 'warning'
    });
  }

  public getCreateClientsObservable() {
    return this.createClientsSubject.asObservable();
  }

  public getDeleteClientsObservable() {
    return this.deleteClientsSubject.asObservable();
  }
}
