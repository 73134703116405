<div class="field-builder-container">

  @if (currentMode === FieldMode.SAVING) {
    <div class="field-custom-container">
      <div class="saving-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EMPTY) {
    <div class="field-custom-container">
      <div class="empty-field-container">
        <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
             class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span
                [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip"
                #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <div class="empty-value">
              @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
                <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                                [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
              } @else {
                {{ appConfig.EMPTY_FIELD_VALUE }}
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.READ) {
    <div class="field-custom-container">
      <div class="read-field-container">
        <div [ngClass]="{'read-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
             class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span
                [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip"
                #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                 [ngClass]="{
               'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition,
               'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)
              }"
                 tabindex="0">
            <span>
              {{
                form.get('marketValue').value
                  | fieldFormatType: fieldGroup.fieldConfigs[0]?.customOptions?.formatType : appManager.currentOrganization.currency
                  | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}
              }}
            </span>
              <span>
              {{ fieldGroup.fieldConfigs[0]?.suffixType | translate }}
              </span>
              @if (form.get('marketValueDate').value) {
                <span>
                  {{ formatDate(form.get('marketValueDate').value) }}
                </span>
              }
              @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
                <span class="overlay-icon">
                  @if (preconditionsForEdition) {
                    <i class="mdi mdi-pencil selectable"></i>
                  } @else {
                    <i [matTooltipPosition]="'below'"
                       [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate"
                       class="mdi mdi-pencil-off tooltip-icon"></i>
                  }
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EDIT) {
    <div class="field-custom-container">
      <div class="edit-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <!-- label -->
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span
                [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip"
                #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <!-- fields -->
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <form autocomplete="off" [formGroup]="form" class="fx-layout-col fx-gap-10">
              <mat-form-field appearance="outline">
                <mat-label>{{ fieldGroup.fieldConfigs[0].label }}</mat-label>
                <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                       formControlName="marketValue" matInput inputFieldBuilderAutoFocus
                       type="text">
                @for (validator of getErroredValidators('marketValue'); track validator) {
                  <mat-error>
                    {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                  </mat-error>
                }
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ fieldGroup.fieldConfigs[1].label }}</mat-label>
                <input (focus)="dateField.open()"
                       formControlName="marketValueDate"
                       [matDatepicker]="dateField"
                       matInput
                       type="text"/>
                <mat-datepicker-toggle [for]="dateField" matIconSuffix></mat-datepicker-toggle>
                <mat-datepicker #dateField color="accent"></mat-datepicker>
                @for (validator of getErroredValidators('marketValueDate'); track validator) {
                  <mat-error>
                    {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                  </mat-error>
                }
              </mat-form-field>
            </form>
            <div class="button-container fx-layout-row fx-justify-end fx-align-center">
              <!-- cancel + save -->
              <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)"
                      mat-stroked-button>{{ 'BUTTON.CANCEL' | translate }}
              </button>
              <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                      color="accent" mat-raised-button>{{ 'BUTTON.SAVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
