import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { FormControl, UntypedFormArray, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

/***
 * Check if a boolean control is true, required for the permissions.
 */
export const requiredTruePermissions = (control: FormControl<boolean>, permissions: PermissionEnum[]): ValidatorFn => {
  return (controlArray: UntypedFormArray): { [key: string]: boolean } => {
    if (isPresent(Validators.required(controlArray))) {
      return null;
    }
    const selectedPermissions = controlArray.controls.filter((permissionControl) => {
      return permissions.includes(permissionControl.value.name) && permissionControl.value.selected
    });
    // Validation condition
    return !control.value && selectedPermissions.length > 0 ? {requiredTruePermissions: true} : null;
  };
};
