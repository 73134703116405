import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'radiobutton-field-builder',
  templateUrl: './radiobutton-field-builder.component.html',
  styleUrls: ['./radiobutton-field-builder.component.scss']
})
export class RadiobuttonFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;
  //Display undefined option if the field is not required
  public optional: boolean;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
    this.optional = !fieldConfig.field.validators.find(validator => validator.type === ValidatorType.REQUIRED);
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        this.fieldInitValue,
        this.computeValidators()
      )
    });
    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.setFieldValue(value);

      // When toggle button value changes, tell anything hooked to the valuechange to update accordingly
      if (this.getFieldGroupInfo(['requiredTrue'])
        && this.getFieldGroupInfo(['requiredTrue', this.fieldConfig.fieldPath.join('.')])) {
        this.getFieldGroupInfo(['requiredTrue', this.fieldConfig.fieldPath.join('.')]).next(value);
      }
    });
  }

  public formatValue(value: boolean): string {
    if (value === true) {
      return 'LABEL.YES';
    } else if (value === false) {
      return 'LABEL.NO';
    } else {
      return this.appConfig.EMPTY_FIELD_VALUE;
    }
  }

  public cancel(): void {
    this.form.get('field').setValue(this.getFieldInitialValue());
    super.cancel();
  }
}
