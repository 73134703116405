import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import {
  AbstractMultipleAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'multiple-autocomplete-cell-text-cell-edit',
  templateUrl: './abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component.html',
  styleUrls: ['./abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component.scss']
})
export class MultipleAutocompleteCellEditComponent extends AbstractMultipleAutocompleteCellEditComponent {

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  public getFieldValue(value: string): string {
    return value;
  }

  public getValue(): string[] {
    const inputValue = this.control.value as string;
    if (inputValue && inputValue.length > 0) {
      this.newInput(inputValue);
    }
    // If input is empty, save the field
    return this.chips.length > 0 ? this.chips : [];
  }

  public newInput(value: string): void {
    if (!this.control.valid) return;
    if ((value || '').trim()) {
      // Add the new chip
      this.chips.push(value);
      this.setFilterSuggestions();
    }
    this.adjustColumnWidth();
    this.control.setValue('');
  }

  /**
   * This onEnter event handler is responsible for the adding of a new chip extracted from the value of the chip
   * @param event
   */
  public onEnter(event: KeyboardEvent): void {
    this.newInput(this.control.value);
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  protected filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.suggestedOptions.filter(suggestedOption => {
      return suggestedOption.toLowerCase().includes(filterValue) && !this.chips.includes(suggestedOption);
    });
  }

  public sortSuggestedOption(): void {
    this.suggestedOptions.sort();
  }

  public setFilterSuggestions(): void {
    this.filteredSuggestions = this.control.valueChanges
      .pipe(
        startWith(''),
        map((value) => {
          return this.filter(value);
        })
      );
  }
}
