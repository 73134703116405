import { Injectable } from '@angular/core';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { Asset } from '@app/core/model/entities/asset/asset';
import { switchMap } from 'rxjs/operators';
import {
  AssetCreateModalComponent
} from '@app/features/main/views/assets/modals/asset-create-modal/asset-create-modal.component';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@app/shared/popup/spinner.service';

@Injectable()
export class AssetModalService {

  constructor(private popupManager: PopupManager,
              private assetsService: AssetsService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Open the dialog to create an Asset.
   * @return Created Asset.
   * @throws Any encountered error.
   */
  public openCreateAssetDialog(): Observable<Asset> {
    const dialogRef = this.popupManager.showGenericPopup(AssetCreateModalComponent, PopupSize.MEDIUM_SMALL, {});
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.ASSET);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(analyticsEvent);

            const generatedAsset = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.assetsService.createAsset(generatedAsset)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open the dialog to confirm the deletion of an Asset.
   * @param assets Asset to delete.
   * @return True if the operation was successful or false if the action was canceled by the User.
   */
  public openDeleteAssetsDialog(assets: Asset[]): Observable<boolean> {
    return this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(assets.length === 1 ? 'TITLE.DELETE_ASSET' : 'TITLE.DELETE_ASSETS'),
      dialogMessage: this.translate.instant(assets.length === 1 ? 'MESSAGE.DELETE_ASSET' : 'MESSAGE.DELETE_ASSETS'),
      okText: this.translate.instant('BUTTON.DELETE'),
      type: 'warning'
    })
      .afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.ASSET);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: assets.map(asset => asset.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.assetsService.deleteAssets(assets)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of(false);
          }
        })
      );
  }
}
