import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { isValueTaken } from '@app/shared/validators/is-value-taken/validator';
import { Store } from '@ngrx/store';
import { GeneralService } from '@services/general.service';
import { ErrorManager } from '@services/managers/error.manager';
import { Observable } from 'rxjs';

const IS_VALUE_TAKEN_VALIDATOR: any = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => IsValueTakenValidator),
  multi: true
};

@Directive({
  selector: '[isValueTaken][formControlName],[isValueTaken][formControl],[isValueTaken][ngModel]',
  providers: [IS_VALUE_TAKEN_VALIDATOR]
})
export class IsValueTakenValidator implements AsyncValidator, OnChanges {
  @Input() public isValueTaken: string;
  private onChange: () => void;

  constructor(private generalService: GeneralService,
              private errorManager: ErrorManager,
              private store: Store<any>) {
  }

  public get unicityQuery(): string {
    return 'query UnicityQuery($stringToTest: String!) { ' + this.isValueTaken + '(value: $stringToTest) }';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'UNIQUE') {
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  public validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return isValueTaken(this.generalService, null, '', '', {}, {store: this.store, fieldName: ''})(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
