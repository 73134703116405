import { inject, Injectable } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { Space } from '@app/core/model/entities/asset/space';
import { Dimensions } from '@app/shared/extra/column-dimensions.enum';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { ExcelType } from '@app/shared/extra/excel-type.enum';
import { ExtendedColDef } from '@app/shared/grid/column-builder';
import { TranslateService } from '@ngx-translate/core';
import {
  GridOptions,
  HeaderCheckboxSelectionCallbackParams,
  ProcessCellForExportParams,
  ValueFormatterFunc,
  ValueFormatterParams
} from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import dayjs from 'dayjs';

@Injectable()
export class GridOptionsService {

  // Injection
  private appConfig = inject(AppConfig);
  private translate: TranslateService = inject(TranslateService);

  /**
   *  Used to determine if the selection checkbox should be displayed
   */
  private showCheckBoxForFirstColumn(params: HeaderCheckboxSelectionCallbackParams): boolean {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    // Display it if it's the first column and if the datagrid is currently in edit mode
    return displayedColumns[0] === params.column && !(params.colDef as ExtendedColDef)?.editModeEnabled;
  };

  public get staticGridOptions(): GridOptions {
    return {
      suppressPropertyNamesCheck: true,
      columnDefs: <ColDef[]>[],
      defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        resizable: true,
        filterParams: {
          buttons: ['clear']
        },
        // Mandatory to group undefined value
        keyCreator: (params): string => {
          return params.value === void 0 || params.value === null ? this.appConfig.GRID_EMPTY_VALUE : params.value;
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        enableRowGroup: true,
        minWidth: Dimensions.SM,
        // Add a checkbox to the first column of the grid
        headerCheckboxSelection: this.showCheckBoxForFirstColumn.bind(this),
        checkboxSelection: this.showCheckBoxForFirstColumn.bind(this),
        headerCheckboxSelectionFilteredOnly: true
      },
      //FIXME AG-3427 [Excel Export] Add an option to export the formatted cell value (currently you have to apply your
      // valueFormatter for the column to the exported cell value in processCellCallback to format it)
      defaultExcelExportParams: {
        processCellCallback: (params): string => {
          // Use valueFormatter if available to export cells data
          const valueFormatter = params.column.getColDef().valueFormatter as ValueFormatterFunc;
          const value = params.value;

          // Call valueFormatter if the value is not empty and not a number
          // dayjs object type can be a convert to a number
          if (valueFormatter && value !== void 0 && (isNaN(value) || value instanceof dayjs)) {
            return valueFormatter(params as unknown as ValueFormatterParams);
          } else {
            return value;
          }
        },
      },
      rowData: <any[]>[],
      sideBar: undefined,
      animateRows: true,
      autoSizePadding: 10,
      suppressMovableColumns: true,
      suppressAggFuncInHeader: true,
      rowHeight: 48,
      headerHeight: 48,
      excelStyles: [
        {
          id: ExcelType.TEXT,
          dataType: ExcelType.TEXT
        },
        {
          id: ExcelType.DATE,
          dataType: ExcelType.DATE,
          numberFormat: {
            format: this.appConfig.DATE_FORMAT.toLowerCase(),
          }
        },
        {
          id: ExcelType.NUMERICAL,
          dataType: ExcelType.NUMERICAL
        },
        {
          id: 'integer',
          dataType: ExcelType.NUMERICAL,
          numberFormat: {
            format: '#',
          }
        },
        {
          id: 'percent',
          dataType: ExcelType.NUMERICAL,
          numberFormat: {
            format: '0.00%',
          }
        },
        {
          id: 'precise-number',
          dataType: ExcelType.NUMERICAL,
          numberFormat: {
            format: '#.######',
          }
        },
        {
          id: 'numeric',
          dataType: ExcelType.NUMERICAL,
          numberFormat: {
            format: '#.#',
          }
        },
        {
          id: 'duration',
          dataType: ExcelType.TEXT
        },
        {
          id: 'engineering',
          dataType: ExcelType.TEXT
        },
        {
          id: 'scientific',
          dataType: ExcelType.TEXT
        }
      ],

      getRowId: (params): string => params.data.id.toString(),

      // copying
      processCellForClipboard: (params: ProcessCellForExportParams): any => {
        const colDef = params.column.getColDef() as ExtendedColDef;
        // Format copy value if implemented, return value otherwise (default behavior)
        return colDef.onCopy ? colDef.onCopy(params) : params.value;
      },
      // pasting
      suppressLastEmptyLineOnPaste: true,
      processCellFromClipboard: (params: ProcessCellForExportParams): any => {
        const colDef = params.column.getColDef() as ExtendedColDef;
        // Process paste asynchronously if implemented, return value otherwise (default behavior)
        return colDef.onPaste ? colDef.onPaste(params) : params.value;
      }
    };
  }

  public get masterDetailGridOptions(): GridOptions {
    return {
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      suppressContextMenu: true,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: false
      },
    };
  }

  public get staticSelectSpacesGridOptions(): GridOptions {
    return {
      suppressPropertyNamesCheck: true,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filterParams: {
          buttons: ['clear']
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab']
      },
      treeData: true,
      groupDefaultExpanded: 1,
      getDataPath: (data: Space): string[] => {
        if (data.parentPath?.length > 0) {
          return data.parentPath.concat(data.id);
        } else {
          return [data.id];
        }
      },
      autoGroupColumnDef: {
        headerName: this.translate.instant('LABEL.SPACE_NAME'),
        valueGetter: (params): any => (params.node.group) ? '' : params.data.toString(),
        comparator: (nameA, nameB): number => {
          return nameA.localeCompare(nameB);
        },
        checkboxSelection: true,
        colId: 'ag-Grid-AutoColumn',
        cellClass: ColumnType.TEXT,
        width: Dimensions.XL
      },
      suppressContextMenu: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      suppressDragLeaveHidesColumns: true,
      animateRows: true,
      context: this,
      suppressMovableColumns: true,
      getRowId: (params) => params.data.id
    };
  }
}
