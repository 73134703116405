<div [hidden]="!(getFieldGroupInfo(['isDisplayed']) | async)" class="field-builder-container">

  <div *ngIf="currentMode === FieldMode.SAVING" class="field-single-container">
    <div class="saving-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EMPTY" class="field-single-container">
    <div class="empty-field-container">
      <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
           class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
          <i class="mdi mdi-help-circle tooltip-icon"></i>
        </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div class="empty-value">
            <ng-container *ngIf="!fieldGroup.customOptions?.disabled; else disabled">
              <ng-container *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)  && preconditionsForEdition; else noPermissions">
                <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
              </ng-container>
              <ng-template #noPermissions>
                {{ appConfig.EMPTY_FIELD_VALUE }}
              </ng-template>
            </ng-container>
            <ng-template #disabled>
              <span>{{ fieldGroup.emptyLabel }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'field-single-container': currentMode === FieldMode.EDIT || currentMode === FieldMode.READ}">
    <div [ngClass]="{'edit-field-container': currentMode === FieldMode.EDIT}">
      <ng-template fieldAnchor>
      </ng-template>
    </div>
  </div>
</div>
