import { Injectable } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { CellValueChangedEvent, GridApi, IRowNode } from 'ag-grid-community';

@Injectable()
export class UndoRedoService {
  private undos: Partial<CellValueChangedEvent>[] = [];
  private redos: Partial<CellValueChangedEvent>[] = [];

  constructor(private appConfig: AppConfig) {}

  /**
   * Reset the service data
   */
  public reset(): void {
    this.undos = [];
    this.redos = [];
  }

  /**
   * Return a boolean that indicates if the user can undo a change
   * Can be used to display an 'undo' button
   * @returns {boolean}
   */
  public canUndo(): boolean {
    return this.undos.length > 0;
  }

  /**
   * Return a boolean that indicates if the user can redo a change
   * Can be used to display an 'redo' button
   * @returns {boolean}
   */
  public canRedo(): boolean {
    return this.redos.length > 0;
  }

  /**
   * Add an action at the end of the 'undos' array
   * @param event An object that represents the cell change event
   */
  public addAction(event: CellValueChangedEvent): void {
    if (event.source === 'undo-redo') return; // If an undo-redo action brought it here, ignore
    if (this.undos.length >= this.appConfig.UNDO_REDO_LIMIT) this.undos.shift(); // Remove the oldest action if the limit is reached
    this.redos = [];
    this.undos.push({
      node: event.node,
      column: event.column,
      oldValue: event.oldValue ?? '',
      newValue: event.newValue ?? ''
    });
  }

  /**
   * Capture keyboard events and then trigger undo or redo action
   * @param gridApi The grid being edited
   * @param keyboardEvent The keyboard event captured
   */
  public keyboardEventHandler(gridApi: GridApi, keyboardEvent: KeyboardEvent): void {
    const actionKey = (keyboardEvent.ctrlKey || keyboardEvent.metaKey);
    const shiftKey = keyboardEvent.shiftKey;
    const charKey = keyboardEvent.key.toLowerCase();

    if (actionKey && !shiftKey && charKey === 'z') {
      keyboardEvent.preventDefault();
      keyboardEvent.stopPropagation();
      this.undo(gridApi);
    } else if (actionKey && shiftKey && charKey === 'z') {
      keyboardEvent.preventDefault();
      keyboardEvent.stopPropagation();
      this.redo(gridApi);
    }
  }

  /**
   * Edit the current grid to roll back the last user change
   * @param api The API object of the grid to update
   */
  public undo(api: GridApi): void {
    const actionToUndo = this.undos.pop();
    if (!actionToUndo) return;
    this.redos.push(actionToUndo);
    const row = this.getRowToUpdate(api, actionToUndo);
    row.setDataValue(actionToUndo.column, actionToUndo.oldValue, 'undo-redo');
  }

  /**
   * Edit the current grid to roll back the last undo
   * @param api The API object of the grid to update
   */
  public redo(api: GridApi): void {
    const actionToRedo = this.redos.pop();
    if (!actionToRedo) return;
    this.undos.push(actionToRedo);
    const row = this.getRowToUpdate(api, actionToRedo);
    row.setDataValue(actionToRedo.column, actionToRedo.newValue, 'undo-redo');
  }

  /**
   * Get the row of the cell to update, and ensure that
   * the user have the focus on the cell being updated
   * @param api The API object of the grid to update
   * @param action The action that have been performed
   * @returns {IRowNode} The row object of the cell to update
   */
  private getRowToUpdate(api: GridApi, action: Partial<CellValueChangedEvent>): IRowNode {
    const currentRow = api.getRowNode(action.node.id);
    const rowIndex = currentRow.rowIndex;

    // checks if the row has been filtered out
    if (currentRow.rowTop != null) {
      api.ensureIndexVisible(rowIndex);
      api.ensureColumnVisible(action.column);
      api.setFocusedCell(rowIndex, action.column);
    }
    return currentRow;
  }
}
