import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { CheckStateEnum } from '@app/core/enums/check/check-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Check, CheckInput } from '@app/core/model/entities/asset/check';
import { Document } from '@app/core/model/entities/document/document';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import { from, Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ChecksService {
  private sidePanelToggleSubject = new Subject<Check | null>();

  private addCheckSubject = new Subject<Check>();
  private deleteChecksSubject = new Subject<Check[]>();
  private updateCheckSubject = new Subject<Check>();
  private renewCheckSubject = new Subject<{ oldCheck: Check, renewedCheck: Check }>();

  private readonly checkInfoGraphqlFragment = gql`
    fragment CheckInfo on Check {
      id
      type
      assetId
      spaceIdsList
      state
      properties
      computedProperties
      creationDate
      creationUserId
      lastChangeDate
      lastChangeUserId
      dataDate
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private documentsService: DocumentsService,
              private assetsService: AssetsService,
              private usersService: UsersService) {
  }

  /**
   * Emits Checks of the current Organization.
   */
  public get checks$(): Observable<Check[]> {
    const QUERY = gql`
      query ChecksData($organizationId: String!) {
        checksByOrganizationId(organizationId: $organizationId) {
          ...CheckInfo
        }
      }
      ${this.checkInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Check, response.data['checksByOrganizationId'] as Check[])),
        // Fetch Users who created and last updated the Check
        switchMap(checks => from(checks)),
        mergeMap((check) => this.usersService.fetchUsersInfo(check)),
        toArray()
      );
  }

  /**
   * Emits Checks of the current Asset.
   */
  // TODO TTT-2814 merge checks$ and assetChecks$ in a new method
  public get assetChecks$(): Observable<Check[]> {
    const QUERY = gql`
      query ChecksByAssetId($assetId: String!) {
        checksByAssetId(assetId: $assetId) {
          ...CheckInfo
        }
      }
      ${this.checkInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      assetId: this.appManager.currentAsset.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Check, response.data['checksByAssetId'] as Check[])),
        // Fetch Users who created and last updated the Check
        switchMap(checks => from(checks)),
        mergeMap((check) => this.usersService.fetchUsersInfo(check)),
        toArray()
      );
  }

  /**
   * Emits a Check once it has been created.
   */
  public get checkAdded$(): Observable<Check> {
    return this.addCheckSubject.asObservable();
  }

  /**
   * Emits a Check whenever it has been updated.
   */
  public get checkUpdated$(): Observable<Check> {
    return this.updateCheckSubject.asObservable();
  }

  /**
   * Emits whenever a Check is renewed.
   */
  public get checkRenewed$(): Observable<{ oldCheck: Check, renewedCheck: Check }> {
    return this.renewCheckSubject.asObservable();
  }

  /**
   * Emits as list of Checks after they have been deleted.
   */
  public get checksDeleted$(): Observable<Check[]> {
    return this.deleteChecksSubject.asObservable();
  }

  /**
   * Emits a Check whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Check | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Call the API to create a new Check for an Asset.
   * @param assetId ID of the Asset the new Check belongs to.
   * @param type Type of the new Check.
   * @param spaceIds Spaces for the new Check.
   * @param dataDate Date of the data.
   * @return Created Asset.
   */
  public createCheck(assetId: string, type: string, spaceIds: string[]): Observable<Check> {
    const COMBINED_MUTATION = gql`
      mutation CreateCheck($assetId: String!, $checkInput: CheckInput) {
        createCheck(assetId: $assetId, checkInput: $checkInput) {
          ...CheckInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      assetId,
      checkInput: {
        type: type,
        spaceIds: spaceIds
      }
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Check, response.data['createCheck'] as Check)),
        switchMap(check => this.usersService.fetchUsersInfo(check)),
        tap(check => this.addCheckSubject.next(check)),
      );
  }

  /**
   * Call the API to update a Check.
   * @param check Check to update.
   * @param checkInput Data for updating the Check.
   * @return Updated Check.
   */
  public updateCheck(check: Check, checkInput: CheckInput): Observable<Check> {
    const COMBINED_MUTATION = gql`
      mutation UpdateCheck($checkId: String!, $checkInput: CheckInput!) {
        updateCheck(checkId: $checkId, checkInput: $checkInput) {
          ...CheckInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      checkId: check.id,
      checkInput: {
        spaceIds: check.spaceIds,
        ...checkInput
      }
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Check, response.data['updateCheck'] as Check)),
        switchMap(check => this.usersService.fetchUsersInfo(check)),
        tap(updatedCheck => this.updateCheckSubject.next(updatedCheck))
      );
  }

  /**
   * Call the API to renew an existing Check.
   * @param checkToRenew Check to be renewed.
   * @param spaceIds spaces for the new Check.
   * @return Observable that will emit the new Check once created.
   */
  public renewCheck(checkToRenew: Check, spaceIds: string[]): Observable<Check> {
    const COMBINED_MUTATION = gql`
      mutation RenewCheck($assetId: String!, $oldCheckId: String!,  $checkInput: CheckInput) {
        renewCheck(assetId: $assetId, oldCheckId: $oldCheckId, checkInput: $checkInput) {
          ...CheckInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      assetId: checkToRenew.assetId,
      oldCheckId: checkToRenew.id,
      checkInput: {
        type: checkToRenew.type,
        spaceIds: spaceIds
      }
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Check, response.data['renewCheck'] as Check)),
        switchMap(check => this.usersService.fetchUsersInfo(check)),
        tap(renewedCheck => {
          checkToRenew.state = CheckStateEnum.CHECK_CLOSED;
          this.renewCheckSubject.next({oldCheck: checkToRenew, renewedCheck: renewedCheck});
        })
      );
  }

  /**
   * Call the API to delete the given Checks.
   * @param checks List of Checks to delete.
   * @return Observable emitting true if all the checks are deleted, otherwise false.
   */
  public deleteChecks(checks: Check[]): Observable<boolean> {
    const COMBINED_MUTATION = gql`
      mutation DeleteChecks($checkIds: [String!]!) {
        deleteChecks(checkIds: $checkIds)
      }
    `;
    const MUTATION_VAR = {
      checkIds: checks.map((check: Check) => check.id)
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteChecks'] as boolean),
        tap(success => success && this.deleteChecksSubject.next(checks))
      );
  }

  /**
   * Navigate to the checks tab of the Asset's sheet.
   * @param assetId ID of the Asset to navigate to.
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'checks');
  }

  /**
   * Load all the Documents related to a given Check.
   * @param checkId id of the selected Check.
   * @return Observable emitting Documents linked to the Check.
   */
  public loadCheckDocuments(checkId: string): Observable<Document[]> {
    return this.documentsService.loadEntityDocuments(
      checkId,
      EntityTypeEnum.CHECK,
      DocumentTypeEnum.CHECK_DOCUMENT
    );
  }

  /**
   * Open the side panel with information about the selected Check.
   * @param check Check to be displayed in the sidenav.
   */
  public openCheckSidePanel(check: Check): void {
    this.sidePanelToggleSubject.next(check);
  }

  /**
   * Close the side panel.
   */
  public closeCheckSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }
}
