import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Lease } from '@app/core/model/entities/asset/lease';
import { Entity } from '@app/core/model/entities/entity';
import { Expose, Type } from 'class-transformer';

export class Occupant extends Entity {
  public entityType = EntityTypeEnum.OCCUPANT;

  public organizationId: string;
  public dataDate: string;

  @Expose({name: 'leasesList'})
  @Type(() => Lease)
  public leases: Lease[];

  @Type(() => Lease) public lastLease: Lease;

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? this.name;
  }

  /**
   * Sort this Occupant's Leases by their start date.
   */
  public sortLeaseList(): void {
    this.leases.sort((leaseA, leaseB) => {
      if (leaseA.properties['startDate'] < leaseB.properties['startDate']
        || (!leaseA.properties['startDate'] && leaseB.properties['startDate'])) {
        return 1;
      } else if (leaseA.properties['startDate'] > leaseB.properties['startDate']
        || (leaseA.properties['startDate'] && !leaseB.properties['startDate'])) {
        return -1;
      } else {
        return 0;
      }
    });
    this.lastLease = this.leases[0];
  }

  public getSortedLeases(): Lease[] {
    this.sortLeaseList();
    return this.leases.map(lease => {
      lease.occupant = this;
      return lease;
    });
  }

  public addLease(lease: Lease): void {
    this.leases.push(lease);
    lease.occupant = this;
    this.sortLeaseList();
  }

  public removeLease(leaseId: string): Lease {
    const removedLease = this.leases.splice(this.leases
      .findIndex(lease => lease.id === leaseId), 1);
    this.sortLeaseList();
    return removedLease[0];
  }

  public updateLease(updatedLease: Lease): void {
    const leaseToUpdateIndex = this.leases.findIndex((lease) =>
      lease.id === updatedLease.id
    );
    this.leases[leaseToUpdateIndex] = updatedLease;
    updatedLease.occupant = this;
    this.sortLeaseList();
  }
}

export interface OccupantInput {
  name?: string;
  dataDate?: string;
}
