<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_EQUIPMENT' | translate }}</h1>
  <mat-divider></mat-divider>
  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="equipmentForm">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.EQUIPMENT_NAME' | translate }}</mat-label>
        <input matInput id="input-equipment-name" formControlName="name" required/>
        <mat-error *ngIf="equipmentForm.get('name').hasError('required')">
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="equipmentForm.get('name').hasError('maxlength')">
          {{ 'ERROR.FIELD_MAX_LENGTH' | translate: { value: appConfig.FIELD_MAX_LENGTH } }}
        </mat-error>
      </mat-form-field>

      <div class="fx-fill fx-layout-col">
        <p class="form-custom-label">{{'LABEL.EQUIPMENT_SPACES' | translate}}</p>
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
                         [rowData]="spaces | async"
                         class="ag-theme-material"
                         id="equipment-spaces-grid"
                         rowSelection="multiple"
                         style="width: 100%; height: 300px">
        </ag-grid-angular>
        <mat-error *ngIf="equipmentForm.get('spaces').hasError('incoherentSpaces')">
          {{'ERROR.SPACES_INCOHERENT' | translate}}
        </mat-error>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button id="create-equipment-cancel-button" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button id="create-equipment-add-button" [disabled]="equipmentForm.invalid" color="accent"
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
