import { Component, Inject, OnDestroy } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { FIELD_EXTRA_DATA } from '@app/core/model/other/field-config';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './default-header.component.html'
})
export class DefaultHeaderComponent implements OnDestroy {
  public Permission = PermissionEnum;
  private destroy$ = new Subject<void>();

  public headerText: string;

  constructor(@Inject(FIELD_EXTRA_DATA) data: any,
              public appManager: AppManager,
              public accessManager: AccessManager,
              public snackbarManager: SnackbarManager,
              public popupManager: PopupManager,
              public appConfig: AppConfig,
              public translate: TranslateService) {
    this.headerText = data.headerText;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
