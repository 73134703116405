import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { CreateDocumentInput } from '@app/core/model/entities/document/document';
import { CreateDocumentData } from '@app/shared/components/file-uploader/file-uploader.component';

@Component({
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent {

  public preventUploadFiles: boolean;
  public removeAllFilesEmitter = new EventEmitter<void>();
  public uploadFormGroup: UntypedFormGroup;
  @Output() public fileUploaded = new EventEmitter<File>();
  private isUploadInProgress: boolean = false;

  private documentsToCreate: CreateDocumentData[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: UntypedFormBuilder) {
    this.uploadFormGroup = this.fb.group({
      uploadSuccessful: this.fb.control(null, Validators.required),
      uploadFileCtrl: this.fb.control(null)
    });
  }

  public removeAllFiles(): void {
    this.documentsToCreate = [];
    this.removeAllFilesEmitter.next();
  }

  public isUploadInvalid(): boolean {
    return this.isUploadInProgress;
  }

  public uploadIsInProgress(event: boolean): void {
    this.isUploadInProgress = event;
  }

  public createNewDocuments(event: CreateDocumentData): void {
    this.documentsToCreate.push(event);
  }

  public getCreateDocumentsInput(): CreateDocumentInput[] {
    return this.documentsToCreate.map( documentToCreate => {
      return <CreateDocumentInput>{
        documentName: documentToCreate.documentName,
        fileName: documentToCreate.fileName,
        documentType: this.data.uploadData.documentType,
        documentState: DocumentStateEnum.ACTIVE
      };
    });
  }
}
