import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { format } from '@app/shared/extra/decimal-format';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const gte = (min: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;
    // If there is no min value, the control is always valid
    if (Number.isNaN(min)) return null;

    const value = format(control.value);
    return +value >= min ? null : { gte: true };
  };
};
