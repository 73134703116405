import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset } from '@app/core/model/entities/asset/asset';
import { ProjectInput } from '@app/core/model/entities/project/project';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { simplifyStringForSearch } from '@app/shared/extra/utils';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface SuggestedAsset {
  asset: Asset;
  checked: boolean;
}

@Component({
  templateUrl: './project-create-modal.component.html',
  styleUrls: ['../../../../../../../shared/components/fields/chips-field-builder/entity-chips-field-builder/entity-chips-field-builder.scss']
})
export class ProjectCreateModalComponent implements OnInit, OnDestroy, GeneratesObject {

  public projectForm: FormGroup<{
    name: FormControl<string>,
    assetName: FormControl<string>,
    selectedAssetIds: FormControl<string[]>
  }>;

  public filteredAssets: Observable<SuggestedAsset[]>;
  public assets: Map<string, SuggestedAsset> = new Map();
  public selectedAssets: Asset[] = [];
  public allSelected = false;

  protected destroy$ = new Subject<void>();
  private selectionChangedSubject = new Subject<void>();

  @ViewChild('inputField') public inputField: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') public suggestions: MatAutocomplete;
  @ViewChild('trigger') public trigger: MatAutocompleteTrigger;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public appConfig: AppConfig,
              protected fb: UntypedFormBuilder,
              protected assetsService: AssetsService,
              protected columnBuilder: ColumnBuilder,
              protected translate: TranslateService) {
    this.projectForm = this.fb.group({
      name: this.fb.control(this.translate.instant('VALUE.NEW_PROJECT'), Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      assetName: this.fb.control(''),
      selectedAssetIds: this.fb.control([], Validators.required)
    });
  }

  /**
   * Fetch accessible Assets.
   */
  public ngOnInit(): void {
    //Fetch accessible Assets and filter them when assetName input is changed
    //FIXME Find a way to optimize this request : not all properties of the assets are needed (only their name and id)
    const filteredAssets$ = this.assetsService.getAssets(EntityTypeEnum.PROJECT, false)
      .pipe(
        first(),
        tap((assets) => { this.assets = new Map(assets.map(asset => [asset.id, {asset: asset, checked: false}])); }),
        switchMap(() =>
          this.projectForm.get('assetName').valueChanges.pipe(
            startWith(''),
            debounceTime(50),
            filter(value => value !== void 0),
            map(value => this.getFilteredAssets(value))
          ))
      );

    //Update filtered assets' checkbox after selection changes
    const updateFilteredAssetsSelection$ = this.selectionChangedSubject.asObservable().pipe(
      map(() => {
        this.selectedAssets = Array.from(this.assets.values()).filter(asset => asset.checked).map(asset => asset.asset);
        this.projectForm.get('selectedAssetIds').setValue(this.selectedAssets.map(asset => asset.id));

        const filteredAssets = this.getFilteredAssets(this.projectForm.get('assetName').value);
        return filteredAssets.map(suggestedAsset => {
          suggestedAsset.checked = this.isChecked(suggestedAsset.asset.id);
          return suggestedAsset;
        });
      })
    );

    this.filteredAssets = merge(filteredAssets$, updateFilteredAssetsSelection$).pipe(
      takeUntil(this.destroy$),
      tap(filteredAssets => {
        this.allSelected = Array.from(filteredAssets.values()).every(suggestedAsset => !!suggestedAsset.checked);
      })
    );
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Data from the filled form.
   * @return Project data.
   */
  public getGeneratedObject(): ProjectInput {
    const {name} = this.projectForm.getRawValue();
    const assetIds = this.projectForm.get('selectedAssetIds').value;
    return {name, assetIds};
  }

  /**
   * Add or remove an asset to the selection.
   * @param asset Asset to select or deselect.
   * @protected
   */
  protected selectToggle(asset: Asset): void {
    this.assets.set(asset.id, {asset: asset, checked: !this.isChecked(asset.id)});
    this.selectionChangedSubject.next();
  }

  /**
   * Add or remove all assets in the suggestion list to the selection when the user click on selectAll checkbox.
   * @param filteredAssets All assets in the suggestion list associated to a boolean which indicates if their checkbox is currently checked or not.
   * @protected
   */
  protected selectAllToggle(filteredAssets: SuggestedAsset[]): void {
    filteredAssets.forEach(suggestedAsset => {
      this.assets.set(suggestedAsset.asset.id, {asset: suggestedAsset.asset, checked: !this.allSelected});
    });
    this.selectionChangedSubject.next();
  }

  /**
   * Whether the checkbox of an asset is currently checked.
   * @param assetId ID of the asset to check
   * @return True if the asset's checkbox is checked, false otherwise.
   * @private
   */
  private isChecked(assetId: string): boolean {
    return this.assets.get(assetId).checked;
  }

  /**
   * Filter use by the autocomplete input.
   * @param value the input value.
   * @return SuggestedAsset[] Filtered assets with their checkbox values.
   * @private
   */
  private getFilteredAssets(value: string): SuggestedAsset[] {
    return Array.from(this.assets.values())
      .filter(suggestedAsset =>
        simplifyStringForSearch(suggestedAsset.asset.toString()).includes(simplifyStringForSearch(value))
      );
  }

  /**
   * Keep the autocomplete opened after each item is picked.
   */
  public keepPanelOpen(): void {
    requestAnimationFrame(() => {
      this.trigger.openPanel();
      this.inputField.nativeElement.focus();
    });
  }

  /**
   * Stop event propagation and call selectToggle method when a mat-option is clicked.
   * @param assets Assets to select or deselect
   * @param event MouseEvent
   */
  public optionClicked(assets: SuggestedAsset[], event: MouseEvent): void {
    event.stopPropagation();
    if (assets.length === 1) {
      this.selectToggle(assets.firstItem().asset);
    } else {
      this.selectAllToggle(assets);
    }
  }
}
