<ng-container *ngIf="observations; else empty">
  <div class="fx-layout-row fx-justify-start fx-align-center">
    <div class="label-container">
      <div class="label-content" [ngClass]="observations | checkResultColour: {checkType: checkTypes[checkTypeCode]}">
      </div>
    </div>
    <div class="result-text">
      {{ observations }}
    </div>
  </div>
</ng-container>

<ng-template #empty>
  <span>{{ emptyValue }}</span>
</ng-template>
