import { Component, Input, OnInit } from '@angular/core';
import { Status } from '@app/shared/extra/status-enum';

@Component({
  selector: 'status-message',
  templateUrl: "./status-message.component.html",
  styleUrls: ["./status-message.component.scss"]
})
export class StatusMessageComponent implements OnInit {

  @Input() public type: Status;
  @Input() public size: string = "lg";
  public icon: string;
  public colour: string;

  public ngOnInit(): void {
    switch (this.type) {
      case 'success':
        this.icon = 'mdi-check-circle';
        this.colour = '#00C853';
        break;
      case 'warning':
        this.icon = 'mdi-alert-circle';
        this.colour = '#FFD600';
        break;
      case 'error':
        this.icon = 'mdi-close-circle';
        this.colour = '#D32F2F';
        break;
      case 'info':
        this.icon = 'mdi-information';
        this.colour = '#3F51B5';
        break;
      default:
        this.icon = '';
        this.colour = 'black';
        break;
    }
  }

}
