<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  <div class="page-title">
    <span>{{ 'TITLE.MANAGE_RIGHTS' | translate }}</span>
  </div>

  <ng-container *ngIf="!client?.isTb && accessManager.hasAccess(Permission.MANAGE_CLIENT)">
    <div class="inline-field-container client-name-block page-title">
      <inline-edit-client #inlineEditClientNameInput (onSave)="saveClientName($event)" [disabled]="client?.isTb"
                          [initialValue]="client?.name" [validators]="validators" [entity]="client"
                          [full]="true" [fieldCode]="'name'">
      </inline-edit-client>
    </div>
  </ng-container>


  <div class="fx-flex"></div>
  <ng-container *ngIf="!client?.isTb && accessManager.hasAccess(Permission.MANAGE_CLIENT)">
    <div class="fx-flex-48" id="client-menu">
      <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'client-sheet-menu-button'"
                              [matMenuTriggerFor]="menu" [permissions]="[Permission.MANAGE_CLIENT]"
                              [preconditions]="true"></restricted-icon-button>
      <mat-menu #menu="matMenu">
        <restricted-menu-item (onClick)="deleteClient()" [id]="'delete-client-menu'"
                              [permissions]="[Permission.MANAGE_CLIENT]" [preconditions]="true">
          {{'BUTTON.DELETE_CLIENT' | translate}}
        </restricted-menu-item>
      </mat-menu>
    </div>
  </ng-container>
</div>
