import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OkCancelModalDialogComponent } from '@app/shared/popup/ok-cancel/ok-cancel.modal-dialog';
import { OkModalDialogComponent } from '@app/shared/popup/ok/ok.modal-dialog';
import { YesNoCancelModalDialogComponent } from '@app/shared/popup/yes-no-cancel/yes-no-cancel.modal-dialog';

export class PopupSize {
  static SMALL = new PopupSize('SMALL', {width: '20vw', height: '50vh'});
  static MEDIUM_SMALL = new PopupSize('MEDIUM_SMALL', {width: '30vw', height: '40vh'});
  static MEDIUM = new PopupSize('MEDIUM', {width: '50vw', height: '90vh'});
  static LARGE = new PopupSize('LARGE', {width: '70vw', height: '90vh'});
  static XLARGE = new PopupSize('XLARGE', {width: '90vw', height: '90vh'});

  private constructor(private key: string, private size: { width: string, height: string }) {
  }

  public width() {
    return this.size.width;
  }

  public height() {
    return this.size.height;
  }
}

@Injectable()
export class PopupManager {
  private defaultSize = PopupSize.MEDIUM_SMALL;
  private defaultMinWidth = '300px';
  private defaultMinHeight = '350px';

  constructor(private dialog: MatDialog) {
  }

  public showOkPopup(injectedData: any): MatDialogRef<OkModalDialogComponent, any> {
    return this.dialog.open(OkModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width(),
      height: this.defaultSize.height(),
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData
    });
  }

  public showOkCancelPopup(injectedData: any): MatDialogRef<OkCancelModalDialogComponent, any> {
    return this.dialog.open(OkCancelModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width(),
      height: this.defaultSize.height(),
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData,
      panelClass: 'generic-popup'
    });
  }

  public showYesNoCancelPopup(injectedData: any): MatDialogRef<YesNoCancelModalDialogComponent, any> {
    return this.dialog.open(YesNoCancelModalDialogComponent, {
      disableClose: true,
      width: this.defaultSize.width(),
      height: this.defaultSize.height(),
      minWidth: this.defaultMinWidth,
      minHeight: this.defaultMinHeight,
      data: injectedData,
      panelClass: 'generic-popup'
    });
  }

  public showGenericPopup<T>(renderComponent: ComponentType<T>, size: PopupSize, injectedData: any, panelClass?: string, backdropClass?: string, autofocus: boolean = true): MatDialogRef<T> {
    return this.dialog.open(renderComponent, {
      disableClose: true,
      width: size.width(),
      maxWidth: '95vw',
      maxHeight: '95vw',
      data: injectedData,
      panelClass: 'generic-popup',
      backdropClass: backdropClass,
      autoFocus: autofocus
    });
  }
}
