import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ok-modal-dialog',
  templateUrl: 'ok.modal-dialog.html',
  styleUrls: [
    'ok.modal-dialog.css',
  ]
})
export class OkModalDialogComponent extends HasStatusType {

  // Injected
  public title: string;
  public message: string;
  public okText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public translate: TranslateService) {
    super(data.type);
    this.title = data.dialogTitle;
    this.message = data.dialogMessage;

    this.okText = data.okText || this.translate.instant('BUTTON.OK');
  }


}
