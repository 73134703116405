import { Component, OnDestroy } from '@angular/core';
import { Entity } from '@app/core/model/entities/entity';
import { FormatType } from '@app/core/model/other/field-config';
import { getValue } from '@app/shared/extra/utils';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { GridApi, IStatusPanelParams } from 'ag-grid-community';
import { Events } from 'ag-grid-enterprise';
import { combineLatest, fromEventPattern, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IIndicatorWidget {
  // Styling
  label: string;
  icon: string;
  formatType: FormatType;
  suffix?: string;

  // Computation
  computation: 'count' | 'sum';
  property?: string[];
  filters?: any[];

  // Display
  totalValue: number;
  filteredValue: number;
}

@Component({
  selector: 'indicator-status-panel',
  templateUrl: './indicator-status-panel.component.html',
  styleUrls: ['./indicator-status-panel.component.scss']
})
export class IndicatorStatusPanelComponent implements IStatusPanelAngularComp, OnDestroy {

  public gridApi: GridApi<Entity>;
  private destroy$ = new Subject<void>();
  private indicatorsSet$ = new Subject<void>();

  public indicators: IIndicatorWidget[] = [];

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  public agInit(params: IStatusPanelParams<Entity>): void {
    this.gridApi = params.api;

    combineLatest([
      fromEventPattern(
        (handler) => this.gridApi.addEventListener(Events.EVENT_ROW_DATA_UPDATED, handler),
        (handler) => this.gridApi.removeEventListener(Events.EVENT_ROW_DATA_UPDATED, handler)
      ),
      this.indicatorsSet$.asObservable()
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initIndicators();
        this.calculateIndicatorFilters();
      });

    fromEventPattern(
      (handler) => this.gridApi.addEventListener(Events.EVENT_FILTER_CHANGED, handler),
      (handler) => this.gridApi.removeEventListener(Events.EVENT_FILTER_CHANGED, handler)
    ).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.calculateIndicatorFilters();
      });
  }

  public setIndicators(indicators: IIndicatorWidget[]): void {
    this.indicators = indicators;
    this.indicatorsSet$.next();
  }

  public initIndicators(): void {
    this.clearInitValues();
    this.gridApi.forEachNode((node, index) => {
      if (!node.group) {
        const rowData = node.data;

        this.indicators.forEach((indicator) => {
          if (this.evalFilter(rowData, indicator.filters)) {
            if (indicator.computation == 'count') {
              indicator.totalValue += 1;
            } else if (indicator.computation == 'sum') {
              indicator.totalValue += getValue(rowData, indicator.property);
            }
          }
        });
      }
    });
  }

  public calculateIndicatorFilters(): void {
    this.clearFilteredValues();
    this.gridApi.forEachNodeAfterFilter((node, index) => {
      if (!node.group) {
        const rowData = node.data;

        this.indicators.forEach((indicator) => {
          if (this.evalFilter(rowData, indicator.filters)) {
            if (indicator.computation == 'count') {
              indicator.filteredValue += 1;
            } else if (indicator.computation == 'sum') {
              indicator.filteredValue += getValue(rowData, indicator.property);
            }
          }
        });
      }
    });
  }

  private evalFilter(rowData: Entity, filters: any[]): boolean {
    if (!filters) return true;

    return filters.every(filter => {
      switch (filter['condition']) {
        case 'lte':
          return getValue(rowData, filter['field']) <= filter['value'];
        case 'gte':
          return getValue(rowData, filter['field']) >= filter['value'];
        case 'eq':
          return getValue(rowData, filter['field']) == filter['value'];
        case 'neq':
          return getValue(rowData, filter['field']) != filter['value'];
        case 'in': // Array of values
          return filter['value'].includes(getValue(rowData, filter['field']));
      }
    });
  }

  private clearInitValues(): void {
    this.indicators.forEach(i => i.totalValue = 0);
  }

  private clearFilteredValues(): void {
    this.indicators.forEach(i => i.filteredValue = 0);
  }
}
