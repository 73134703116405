import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { Project } from '@app/core/model/entities/project/project';
import { getValue, hasProperty, setValue } from '@app/shared/extra/utils';
import { Expose, Type } from 'class-transformer';

export class Work extends Entity {
  public entityType = EntityTypeEnum.WORK;
  public assetId: string;
  public identifier: number;
  public action?: string;
  public state: string;
  public organizationId: string;
  public dataDate: string;

  @Expose({name: 'spaceIdsList'})
  public spaceIds: string[];

  @Expose({name: 'equipmentIdsList'})
  public equipmentIds: string[];

  @Expose({name: 'projectIdsList'})
  @Type(() => Project)
  public projectIds: string[];

  @Type(() => Document) public documents: Document[] = [];
  @Type(() => Document) public pictures: Document[] = [];

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this.identifier.toString()} - ${this.action}`;
  }

  // Properties to be renamed
  protected static inputMapping = {
    asset: {input: ['computedProperties', 'asset', 'id'], output: 'assetId'},
    spaces: {input: ['computedProperties', 'spaces'], output: 'spaceIds'},
    equipments: {input: ['computedProperties', 'equipments'], output: 'equipmentIds'},
    projects: {input: ['computedProperties', 'projects'], output: 'projectIds'}
  };

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    // Transform input, mapping keys as defined in the inputMapping above
    for (const inputMappingElement in Work.inputMapping) {
      if (hasProperty(obj, Work.inputMapping[inputMappingElement].input)) {
        const source = getValue(obj, Work.inputMapping[inputMappingElement].input);
        setValue(obj, [Work.inputMapping[inputMappingElement].output], source);
      }
    }

    // Clean up by removing older keys
    for (const inputMappingElement in Work.inputMapping) {
      delete obj[Work.inputMapping[inputMappingElement].input.firstItem()];
    }
  }
}

export interface WorkInput {
  action?: string;
  state?: string;
  assetId?: string;
  spaceIds?: string[];
  equipmentIds?: string[];
  projectIds?: string[];
  dataDate?: string;
}
