<div class="field-builder-container">

  @if (currentMode === FieldMode.SAVING) {
    <div class="field-custom-container">
      <div class="saving-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EMPTY) {
    <div class="field-custom-container">
      <div class="empty-field-container">
        <div
          [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
          class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
          </div>
          <div class="field-container fx-layout-col fx-flex-100-66-percent">
            <div class="empty-value">
              @if (accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition) {
                <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
              } @else {
                {{ appConfig.EMPTY_FIELD_VALUE }}
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.READ) {
    <div class="field-custom-container">
      <div class="read-field-container">
        <div
          [ngClass]="{'read-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
          class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
          </div>
          <div class="field-container fx-layout-col fx-gap-5 fx-flex-100-66-percent">
            <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
              [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}"
              tabindex="0">
              <div class="formatted-string">
                {{ formatAddress() }}
              </div>
              @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
                <span
                  class="overlay-icon">
                  @if (preconditionsForEdition) {
                    <i class="mdi mdi-pencil selectable"></i>
                  } @else {
                    <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
                  }
                </span>
              }
            </div>
            <div class="fx-layout-row">
              <div (click)="onClickActivate($event)" class="map-zone fx-flex-100-50-percent">
                <single-asset-map [allowZooming]="false"
                  [allowMarkerDragging]="false"
                  [allowPanning]="false"
                  [allowClicking]="false"
                  [address]="completeAddress"
                  [permissionsForEdition]="permissionsForEdition"
                  height="250px"
                  width="100%">
                </single-asset-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EDIT) {
    <div class="field-custom-container">
      <div class="edit-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <!-- label -->
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
          </div>
          <!-- fields -->
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <form autocomplete="off" class="fx-layout-col" [formGroup]="form">
              <div class="streetNumber street fx-layout-row fx-gap-10">
                <div class="fx-flex-30-percent">
                  <!-- civic number -->
                  <mat-form-field appearance="outline">
                    <mat-label>{{ fieldGroup.fieldConfigs[0].label }}</mat-label>
                    <input formControlName="streetNumber" matInput inputFieldBuilderAutoFocus type="text">
                    @if (fieldGroup.fieldConfigs[0].suffixType) {
                      <span matTextSuffix>
                        {{ fieldGroup.fieldConfigs[0].suffixType | translate }}
                      </span>
                    }
                    @if (fieldGroup.fieldConfigs[0].tooltip) {
                      <mat-icon [matTooltipPosition]="'after'"
                        [matTooltip]="fieldGroup.fieldConfigs[0].tooltip"
                        matIconSuffix>help
                      </mat-icon>
                    }
                    @for (validator of getErroredValidators('streetNumber'); track validator) {
                      <mat-error>
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
                <div class="fx-flex-70-percent">
                  <!-- street -->
                  <mat-form-field appearance="outline">
                    <mat-label>{{ fieldGroup.fieldConfigs[1].label }}</mat-label>
                    <input formControlName="street" matInput type="text">
                    @if (fieldGroup.fieldConfigs[1].suffixType) {
                      <span matTextSuffix>
                        {{ fieldGroup.fieldConfigs[1].suffixType | translate }}
                      </span>
                    }
                    @if (fieldGroup.fieldConfigs[1].tooltip) {
                      <mat-icon [matTooltipPosition]="'after'"
                        [matTooltip]="fieldGroup.fieldConfigs[1].tooltip"
                        matIconSuffix>help
                      </mat-icon>
                    }
                    @for (validator of getErroredValidators('street'); track validator) {
                      <mat-error>
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="postalcode city country fx-layout-row fx-gap-10">
                <div class="fx-flex-20-percent">
                  <!-- postal code -->
                  <mat-form-field appearance="outline">
                    <mat-label>{{ fieldGroup.fieldConfigs[2].label }}</mat-label>
                    <input formControlName="postalCode" matInput type="text">
                    @if (fieldGroup.fieldConfigs[2].suffixType) {
                      <span matTextSuffix>
                        {{ fieldGroup.fieldConfigs[2].suffixType | translate }}
                      </span>
                    }
                    @if (fieldGroup.fieldConfigs[2].tooltip) {
                      <mat-icon [matTooltipPosition]="'after'"
                        [matTooltip]="fieldGroup.fieldConfigs[2].tooltip"
                        matIconSuffix>help
                      </mat-icon>
                    }
                    @for (validator of getErroredValidators('postalCode'); track validator) {
                      <mat-error>
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
                <div class="fx-flex-40-percent">
                  <!-- city -->
                  <mat-form-field appearance="outline">
                    <mat-label>{{ fieldGroup.fieldConfigs[3].label }}</mat-label>
                    <input formControlName="city" matInput type="text">
                    @if (fieldGroup.fieldConfigs[3].suffixType) {
                      <span matTextSuffix>
                        {{ fieldGroup.fieldConfigs[3].suffixType | translate }}
                      </span>
                    }
                    @if (fieldGroup.fieldConfigs[3].tooltip) {
                      <mat-icon [matTooltipPosition]="'after'"
                        [matTooltip]="fieldGroup.fieldConfigs[3].tooltip"
                        matIconSuffix>help
                      </mat-icon>
                    }
                    @for (validator of getErroredValidators('city'); track validator) {
                      <mat-error>
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
                <div class="fx-flex-40-percent">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ fieldGroup.fieldConfigs[4].label }}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option [value]="''">{{ 'LABEL.UNDEFINED' | translate }}</mat-option>
                      @for (fieldValue of fieldGroup.fieldConfigs[4].field.fieldValues; track fieldValue) {
                        <mat-option
                          [value]="fieldValue">{{ 'VALUE.COUNTRY_' + fieldValue | translate }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (fieldGroup.fieldConfigs[4].suffixType) {
                      <span matTextSuffix>
                        {{ fieldGroup.fieldConfigs[4].suffixType | translate }}
                      </span>
                    }
                    @if (fieldGroup.fieldConfigs[4].tooltip) {
                      <mat-icon [matTooltipPosition]="'after'"
                        [matTooltip]="fieldGroup.fieldConfigs[4].tooltip"
                        matIconSuffix>help
                      </mat-icon>
                    }
                    @for (validator of getErroredValidators('country'); track validator) {
                      <mat-error>
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="location-link">
                <!-- Location link -->
                <span tabindex="0" (click)="map.updateLocationWithAddress()">
                  {{ 'LABEL.LOCATE' | translate }}
                </span>
              </div>
              <div class="fx-layout-row">
                <div class="map-zone fx-flex-100-60-percent">
                  <single-asset-map [allowZooming]="true"
                    [allowClicking]="true"
                    [allowPanning]="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition"
                    [allowMarkerDragging]="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition"
                    [address]="completeAddress"
                    [permissionsForEdition]="permissionsForEdition"
                    (positionUpdated)="updateLatLong($event)"
                    height="350px"
                    width="100%">
                  </single-asset-map>
                </div>
              </div>
              <mat-accordion displayMode="flat" togglePosition="before">
                <mat-expansion-panel id="gps-coordinates">
                  <!-- GPS coordinates link -->
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'LABEL.GPS_COORDINATES' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="latitude longitude fx-layout-row fx-gap-10">
                    <div class="fx-flex-49-30-percent">
                      <!-- latitude -->
                      <mat-form-field appearance="outline">
                        <mat-label>{{ fieldGroup.fieldConfigs[5].label }}</mat-label>
                        <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                          formControlName="latitude" matInput
                          type="text">
                        @if (fieldGroup.fieldConfigs[5].suffixType) {
                          <span matTextSuffix>
                            {{ fieldGroup.fieldConfigs[5].suffixType | translate }}
                          </span>
                        }
                        @if (fieldGroup.fieldConfigs[5].tooltip) {
                          <mat-icon [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[5].tooltip"
                            matIconSuffix>help
                          </mat-icon>
                        }
                        @for (validator of getErroredValidators('latitude'); track validator) {
                          <mat-error>
                            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                    <div class="fx-flex-49-30-percent">
                      <!-- longitude -->
                      <mat-form-field appearance="outline">
                        <mat-label>{{ fieldGroup.fieldConfigs[6].label }}</mat-label>
                        <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                          formControlName="longitude"
                          matInput
                          type="text">
                        @if (fieldGroup.fieldConfigs[6].suffixType) {
                          <span matTextSuffix>
                            {{ fieldGroup.fieldConfigs[6].suffixType | translate }}
                          </span>
                        }
                        @if (fieldGroup.fieldConfigs[6].tooltip) {
                          <mat-icon [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[6].tooltip"
                            matIconSuffix>help
                          </mat-icon>
                        }
                        @for (validator of getErroredValidators('longitude'); track validator) {
                          <mat-error>
                            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </form>
            <div class="button-container fx-layout-row fx-justify-end fx-align-center">
              <!-- cancel + save -->
              <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)"
                mat-stroked-button>{{ 'BUTTON.CANCEL' | translate }}
              </button>
              <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                color="accent"
                mat-raised-button>{{ 'BUTTON.SAVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

</div>
