<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <input #inputText (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
         [formControl]="formControl" matInput class="ag-input-field-input" type="text" /> <span
  *ngIf="suffix">{{ suffix }}</span>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
  <div>
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>