import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpLoaderFactory } from '@app/app.module';
import { BannerRoutingModule } from '@app/features/main/banner/banner.module';
import { MainComponent } from '@app/features/main/main.component';
import { MenubarRoutingModule } from '@app/features/main/menubar/menubar.module';
import {
  OrganizationCheckRenewModalComponent
} from '@app/features/main/views/organization-checks/modals/organization-check-renew-modal/organization-check-renew-modal.component';
import {
  OrganizationContractRenewModalComponent
} from '@app/features/main/views/organization-contracts/modal/organization-contract-renew-modal/organization-contract-renew-modal.component';
import {
  OrganizationLeaseRenewModalComponent
} from '@app/features/main/views/organization-occupants/modals/organization-lease-renew-modal/organization-lease-renew-modal.component';
import {
  OrganizationSpaceDeleteModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-delete-modal/organization-space-delete-modal.component';
import {
  OrganizationSpaceMoveModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-move-modal/organization-space-move-modal.component';
import {
  OrganizationsGlobalBIReportsComponent
} from '@app/features/main/views/organizations/global-bi-reports/organizations-global-bi-reports.component';
import { OrganizationsComponent } from '@app/features/main/views/organizations/organizations.component';
import {
  WorkDuplicateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-duplicate-modal/work-duplicate-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

const components = [
  MainComponent,
  OrganizationsComponent,
  OrganizationsGlobalBIReportsComponent,

  // Common modals used both in the Asset sheet and global datagrid
  // TODO Improve module hierarchy, move modals back to their respective modules, inject entity modules where they are needed (e.g. assets module)
  WorkDuplicateModalComponent,
  OrganizationLeaseRenewModalComponent,
  OrganizationCheckRenewModalComponent,
  OrganizationContractRenewModalComponent,
  OrganizationSpaceMoveModalComponent,
  OrganizationSpaceDeleteModalComponent
];

@NgModule({
  imports: [
    SharedModule,
    BannerRoutingModule,
    MenubarRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    })
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class MainRoutingModule {
}
