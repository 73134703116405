<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled"
       [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !asyncPreconditionsForEdition}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
           [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
        <mat-chip-listbox *ngIf="assetValues?.length > 0 else empty" tabIndex="-1">
          <mat-chip-option class="clickable-field" (click)="onClickActivate($event)"
                           (keyup.enter)="onEnterActivate($event)"
                           *ngFor="let chip of (assetValues| slice:0:5)">
            <a (click)="navigateToAssetDashboard(chip.id)">
              {{ (chip.toString().length > 30 ? chip.toString().slice(0, 27) + '...' : chip.toString()) }}
            </a>
          </mat-chip-option>
          <ng-container *ngIf="assetValues?.length > 5">
            <div class="chips-container fx-layout-row fx-align-center">
              <span>
              {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (assetValues.length - 5)} }}
              </span>
            </div>
          </ng-container>
        </mat-chip-listbox>
        <ng-template #empty>
          <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
        </ng-template>
        <span
          *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
          class="overlay-icon">
          <i *ngIf="preconditionsForEdition && asyncPreconditionsForEdition else readOnly;"
             class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div #fieldContainer class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
      <form autocomplete="off" class="chips-field-builder-form" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ fieldConfig.label }}</mat-label>
          <mat-chip-grid #chipList formControlName="selectedAssetIds">
            <mat-chip-row (removed)="selectToggle(relatedAsset)"
                          *ngFor="let relatedAsset of (selectedEntities| slice:0:5)"
                          [removable]="true">
              {{ (relatedAsset.toString().length > 30 ? relatedAsset.toString().slice(0, 27) + '...' : relatedAsset.toString()) }}
              <button matChipRemove *ngIf="asyncPreconditionsForEdition">
                <i class="mdi mdi-close-circle"></i>
              </button>
            </mat-chip-row>
            <ng-container *ngIf="selectedEntities.length > 5">
                <div class="chips-container fx-layout-row fx-align-center">
                  <span>
                    {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (selectedEntities.length - 5)} }}
                  </span>
                </div>
            </ng-container>
            <input #inputField #trigger="matAutocompleteTrigger"
                   formControlName="entityName"
                   (keyup.escape)="onEscapeCancel($event)"
                   [matAutocomplete]="suggestions"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   class="chip-input"
                   type="text"
                   *ngIf="asyncPreconditionsForEdition">
          </mat-chip-grid>
          <mat-autocomplete #suggestions="matAutocomplete"
                            [panelWidth]="fieldContainer.offsetWidth-90"
                            (optionSelected)="keepPanelOpen()">
            <ng-container *ngIf="filteredEntities | async as allFilteredAssets">
              <mat-option *ngIf="allFilteredAssets.length > 0" class="suggestion">
                <div class="checkbox-container fx-layout-row fx-align-center"
                     (click)="optionClicked(allFilteredAssets, $event)">
                  <mat-checkbox [checked]="allSelected" class="checkbox">
                    {{ 'GRID.SELECT_ALL' | translate }}
                  </mat-checkbox>
                </div>
              </mat-option>

              <mat-option *ngFor="let filteredAsset of allFilteredAssets" class="suggestion">
                <div class="checkbox-container fx-layout-row fx-align-center"
                     (click)="optionClicked( [filteredAsset], $event)">
                  <mat-checkbox [checked]="filteredAsset.checked" class="checkbox">
                    {{ filteredAsset.asset.toString() }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngFor="let validator of erroredValidators">
            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
          </mat-error>
          <mat-error *ngIf="form.get('selectedAssetIds').hasError('required')">
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
          <mat-hint *ngIf="!asyncPreconditionsForEdition" style="width:80%">
            {{ 'VALIDATION.PROJECT_IS_NOT_EDITABLE' | translate }}
          </mat-hint>
        </mat-form-field>

      </form>
      <div *ngIf="isSingleField" class="save-options">
        <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
          <i class="mdi mdi-close"></i>
        </button>
        <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)"
                [disabled]="!form.valid || !asyncPreconditionsForEdition"
                class="button save">
          <i class="mdi mdi-check"></i>
        </button>
      </div>
    </div>
  </div>
</div>
