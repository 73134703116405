import { AbstractBean } from '@app/core/model/abstract-bean';
import { Module } from '@app/core/model/client/module';
import { Expose, Type } from 'class-transformer';

export class Submodule extends AbstractBean {
  public code: string;
  public order: number;

  @Type(() => Module)
  public module: Module;

  @Expose()
  public toString(): string {
    return this.code;
  }
}
