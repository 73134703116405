<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ entity?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeSidePanel()" [disableRipple]="true" id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadSpaceDocuments()"
        [class]="'mdi mdi-paperclip sm'"
        [matTooltip]="'BUTTON.ATTACH_FILE' | translate"
        [permissions]="[Permission.EDIT_SPACE, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>

      <restricted-icon-button (onClick)="deleteSpace()"
        [class]="'mdi mdi-delete sm'"
        [id]="'delete-space-button'"
        [matTooltip]="'BUTTON.DELETE' | translate"
        [permissions]="[Permission.DELETE_SPACE]"
        [preconditions]="entity?.parentPath?.length > 0">
      </restricted-icon-button>

      @if (displayAssetSheetLink) {
        <span class="fx-flex"></span>
      }

      @if (displayAssetSheetLink) {
        <div class="asset-sheet-link">
          <span (click)="navigateToAssetSheet()"> {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }} </span>
        </div>
      }
    </div>
  </div>
  <div cdk-scrollable class="sidebar-sheet fx-flex-grow">
    <div class="page-sidebar">
      <div class="form-container">
        <form-builder [eventsOrigin]="eventsOrigin"
          [formId]="formId"
          [displayHeader]="false"
          [permissionsForEdition]="permissionsForEdition">
        </form-builder>
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{ 'LABEL.DOCUMENT' | translate }} </span>
        <restricted-text-button
          (onClick)="uploadSpaceDocuments()"
          [id]="'check-upload-document-button'"
          [permissions]="[Permission.EDIT_SPACE, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{ 'BUTTON.ATTACH_FILE' | translate }}
        </restricted-text-button>
      </div>
      @for (document of entity?.documents; track document) {
        <div class="document-content">
          @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
            <a (click)="fileService.downloadFile(document.id)"
              >
              <i class="mdi mdi-file-outline xs"></i>
              <span class="file-name-label">{{ document.name }}</span>
            </a>
          } @else {
            <span class="file-name-label">{{ document.name }}</span>
          }
          <span (click)="deleteSpaceDocument(document)"
            [permission-lock]="[Permission.EDIT_SPACE, Permission.DELETE_DOCUMENT]"
            class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
        </div>
      }
    </div>
  </div>
</div>
