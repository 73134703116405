import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UsersService } from '@app/shared/services/users.service';
import { map } from 'rxjs';

/**
 * Return a validator function for checking whether a control's value matches an email address
 * currently in use by a user.
 * @param usersService UsersService for making the email checking request.
 * @return Validator function.
 */
export const userExists = (usersService: UsersService): AsyncValidatorFn => {
  return (control: AbstractControl<string>) => {
    return usersService.userExists(control.value)
      .pipe(map(userExists => userExists ? {userExists: true} : null));
  };
};
