import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'restricted-menu-item',
  templateUrl: './restricted-menu-item.component.html',
  styleUrls: ['./restricted-menu-item.component.scss']
})
export class RestrictedMenuItemComponent {

  /*
  To link with the underlying button's click event
   */
  @Output() public onClick = new EventEmitter<any>();

  /*
  Array of permission codes found in permissions.enum.ts
  to display the button
   */
  @Input() public permissions: any[] = [];
  /*
  Boolean expression of preconditions to enabling the button
   */
  @Input() public preconditions: boolean = undefined;

  /**
   *  Gets called when the user click on the button.
   *  @param event that occur due to the user interacting with a pointing device.
   */
  public click(event: MouseEvent): void {
    this.onClick.emit(event);
  }

}
