import { AbstractBean } from '@app/core/model/abstract-bean';
import { Permission } from '@app/core/model/client/permission';
import { Submodule } from '@app/core/model/client/submodule';
import { Expose, Type } from 'class-transformer';

export class Module extends AbstractBean {
  public code: string;
  public order: number;
  public isDefault: boolean;
  public isConfigurable: boolean;

  @Type(() => Permission)
  public permissions: Permission[];
  @Type(() => Submodule)
  public submodules: Submodule[];

  @Expose()
  public toString(): string {
    return this.code;
  }
}
