import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: ''
})
export class AcceptInvitationComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(route: ActivatedRoute, authService: AuthService) {
    // Redirect to Auth0's authorize endpoint with invitation details
    route.queryParamMap.pipe(
      switchMap(params => authService.loginWithRedirect({
        authorizationParams: {
          invitation: params.get('invitation'),
          organization: params.get('organization')
        }
      })),
      takeUntil(this.destroy$)
    )
      .subscribe();
  }

  /**
   * Cancel subscriptions to Observables upon component destruction.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
