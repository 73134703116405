import { AfterViewInit, Component, inject } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.scss']
})
export class LinkCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
  protected link: string;
  protected ready: boolean;
  protected emptyValue = inject(AppConfig).EMPTY_FIELD_VALUE;

  // Gets called once before the renderer is used
  public agInit(params: ICellRendererParams): void {
    this.link = this.getValueToDisplay(params);
  }

  public ngAfterViewInit(): void {
    // Delay the display of the icon to avoid tooltip malfunction
    this.ready = true;
  }

  // Gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams): boolean {
    // Set the tooltip value
    this.link = this.getValueToDisplay(params);
    return true;
  }

  public getValueToDisplay(params: ICellRendererParams): string {
    return params.value;
  }
}
