import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { VirtualTour } from '@app/core/model/entities/asset/virtual-tour';
import { VirtualToursService } from '@app/features/main/views/virtual-tours/virtual-tours.service';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { ScriptService } from '@services/script.service';
import { Observable } from 'rxjs';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AbstractVirtualTourComponent } from '@app/shared/components/virtual-tour/abstract-virtual-tour.component';

@Component({
  selector: 'virtual-tour-appsheet',
  templateUrl: '../virtual-tour.component.html',
  styleUrls: ['./virtual-tour-appsheet.component.scss'],
  providers: [VirtualToursService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Component implementing all the logics behind the Metareal Virtual tour using the Metareal SDK.
 * This component also implement appSheet panel logics.
 */
export class VirtualTourAppsheetComponent extends AbstractVirtualTourComponent {
  private appSheetBaseUrl: string;

  // Container containing the Metareal Virtual tour
  @ViewChild('metareal') public container: ElementRef;
  // Container containing the component itself
  @ViewChild('tour') public tour: ElementRef;

  @Input() public virtualTours: VirtualTour[];

  constructor(protected scriptService: ScriptService,
              protected changeDetectorRef: ChangeDetectorRef,
              protected appManager: AppManager,
              protected translate: TranslateService,
              protected fb: FormBuilder,
              private sanitizer: DomSanitizer,
              private virtualToursService: VirtualToursService) {
    super(scriptService, changeDetectorRef, appManager, translate, fb);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setAppSheetBaseUrl();
  }

  /**
   * Construct current appSheet url used in the iframe of the side pannel.
   */
  public setAppSheetBaseUrl(): void {
    this.appSheetBaseUrl = this.currentVirtualTour.appSheetURL.origin
      + this.currentVirtualTour.appSheetURL.pathname
      + this.currentVirtualTour.appSheetURL.hash.split('&')[0];
    this.panelContent = this.sanitizer.bypassSecurityTrustResourceUrl(this.appSheetBaseUrl);
  }

  /**
   * Fetch all entities related to the VirtualTour's Asset from Appsheet API
   */
  protected getEntities(): Observable<any> {
    return this.virtualToursService.getEntities(
      this.currentVirtualTour.tableName,
      this.currentVirtualTour.appAPI,
      this.currentVirtualTour.properties['appKey'],
      this.currentVirtualTour.properties['buildingId']
    );
  }

  /**
   * Update current virtual Tour
   * @param virtualTourId tour selected to be the current one
   */
  public updateCurrentTour(virtualTourId: string): void {
    super.updateCurrentTour(virtualTourId);

  }

  /**
   * Change the iframe url to display the entity panel infos.
   * AppSheet url composition example :
   *  - Base url : https://www.appsheet.com/start/ccee59e9-1432-4161-a53a-a548005d009f#appName=Carre150-1001136944-22-09-26
   *    -- https://www.appsheet.com/start/ : Appsheet application domain name.
   *    -- cee59e9-1432-4161-a53a-a548005d009f : Application UUID.
   *    -- appName=Carre150-1001136944-22-09-26 : Application name.
   *  - &defaults, &group, &page, &sort, &view : Immutable parameters.
   *  - &row : Page to be displayed related to the entity in AppSheet. We only use this parameter to navigate in AppSheet.
   *  - &table : Appsheet table the entity belongs to.
   *
   * @param entityId The entity ID
   * @return true if the panel content has been updated, false otherwise.
   * @private
   */
  protected setPanelContent(entityId: string): boolean {
    switch (this.currentVirtualTour.entity) {
      case EntityTypeEnum.WORK :
        this.panelContent = this.sanitizer.bypassSecurityTrustResourceUrl(this.appSheetBaseUrl
          + '&defaults=%5B%7B"ColumnName"%3A"actifs_id"%2C"ColumnValue"%3A"1"%7D%5D&group=%5B%5D&row='
          + entityId + '&page=detail&sort=%5B%5D&table=Déficiences&view=Informations+sur+le+BMA');
        return true;
      case EntityTypeEnum.EQUIPMENT :
        this.panelContent = this.sanitizer.bypassSecurityTrustResourceUrl(this.appSheetBaseUrl + '&page=detail&row='
          + entityId + '&sort=%5B%5D&table=Inventaire++client&view=Inventaires+Client');
        return true;
      default :
        return false;
    }
  }

  /**
   * Return the appSheet entity ID
   * @param entity the appSheet entity
   */
  protected getEntityId(entity: Record<string, any>): string {
    return entity[this.currentVirtualTour.entityIdCode];
  }

  /**
   * Format appSheet entity for display purpose
   * @param entity an appSheet entity
   */
  public valueFormatter(entity: any): string {
    switch (this.currentVirtualTour.entity) {
      case EntityTypeEnum.WORK:
        return entity?.[this.currentVirtualTour.entityName];
      case EntityTypeEnum.EQUIPMENT:
        const entityLocalisation = entity?.['localisation'];
        return (entity?.[this.currentVirtualTour.entityName] ?? '') + (entityLocalisation ? ` (${entityLocalisation})` : '');
    }
  }
}
