import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum WorkStateActionTypes {
  FieldValueChange = 'workState_change',
  FieldValueReset = 'workState_reset',
  FieldValueRevert = 'workState_revert'
}

export class WorkStateChange extends PayloadAction {
  public readonly type = WorkStateActionTypes.FieldValueChange;
}

export class WorkStateReset extends PayloadAction {
  public readonly type = WorkStateActionTypes.FieldValueReset;
}

export class WorkStateRevert implements Action {
  public readonly type = WorkStateActionTypes.FieldValueRevert;
}
