import { Injectable } from '@angular/core';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import {
  OrganizationContractCreateModalComponent
} from '@app/features/main/views/organization-contracts/modal/organization-contract-create-modal/organization-contract-create-modal.component';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { switchMap } from 'rxjs/operators';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ContractsService } from '@app/features/main/views/organization-contracts/contracts.service';
import { Contract } from '@app/core/model/entities/asset/contract';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  ContractCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/contracts/modals/contract-create-modal/contract-create-modal.component';
import { Asset } from '@app/core/model/entities/asset/asset';
import {
  OrganizationContractRenewModalComponent
} from '@app/features/main/views/organization-contracts/modal/organization-contract-renew-modal/organization-contract-renew-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { Document } from '@app/core/model/entities/document/document';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { SpinnerService } from '@app/shared/popup/spinner.service';

@Injectable()
export class ContractModalService {

  constructor(private popupManager: PopupManager,
              private contractsService: ContractsService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Open dialog with a form for the User to fill information to create a new Contract.
   * @param asset If specified, Asset the new Contract is related to.
   * @return Created Contract.
   * @throws Any encountered error.
   */
  public openAddContractDialog(asset?: Asset): Observable<Contract> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? ContractCreateModalComponent : OrganizationContractCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.CONTRACT);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(analyticsEvent);

            const {assetId, contractInput} = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.contractsService.createContract(assetId, contractInput)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog with a form for the User to fill information to renew an existing Contract.
   * @param contractToRenew Contract to be renewed.
   * @return Created Contract.
   * @throws Any encountered error.
   */
  public openRenewContractDialog(contractToRenew: Contract): Observable<Contract> {
    const dialogRef = this.popupManager.showGenericPopup(
      OrganizationContractRenewModalComponent,
      PopupSize.MEDIUM,
      {contractToRenew}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.RENEW, EntityTypeEnum.CONTRACT);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: contractToRenew.id,
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);

            const {contractInput} = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.contractsService.renewContract(contractToRenew, contractInput)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open dialog then delete Contracts upon confirmation by the User.
   * @param contracts Contracts to be deleted after confirmation
   * @return True if Contracts have been successfully deleted, false if the action was canceled by the User.
   * @throws Any encountered error.
   */
  public openDeleteContractsDialog(contracts: Contract[]): Observable<boolean> {
    const isOneContract = contracts.length === 1;
    return this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(isOneContract ? 'TITLE.DELETE_CONTRACT' : 'TITLE.DELETE_CONTRACTS'),
      dialogMessage: this.translate.instant(isOneContract ? 'MESSAGE.DELETE_CONTRACT' : 'MESSAGE.DELETE_CONTRACTS'),
      okText: this.translate.instant(isOneContract ? 'BUTTON.DELETE_CONTRACT' : 'BUTTON.DELETE_CONTRACTS'),
      type: 'warning'
    })
      .afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.CONTRACT);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties(
              {
                [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
                [AnalyticsKeyEnum.DOCUMENT_TYPE]: EntityTypeEnum.CONTRACT,
                [AnalyticsKeyEnum.ENTITY_ID]: contracts.map(contract => contract.id).toString()
              });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.contractsService.deleteContracts(contracts)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of(false);
          }
        })
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to a Contract.
   * @param contractId ID of the Contract to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadContractDocumentsDialog(contractId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      contractId,
      EntityTypeEnum.CONTRACT,
      DocumentTypeEnum.CONTRACT_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm a Contract's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteContractDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
