import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Space } from '@app/core/model/entities/asset/space';
import { Document } from '@app/core/model/entities/document/document';
import {
  SpaceCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/spaces/modals/space-create-modal/space-create-modal.component';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import {
  OrganizationSpaceCreateModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-create-modal/organization-space-create-modal.component';
import {
  OrganizationSpaceDeleteModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-delete-modal/organization-space-delete-modal.component';
import {
  OrganizationSpaceMoveModalComponent
} from '@app/features/main/views/organization-spaces/modals/organization-space-move-modal/organization-space-move-modal.component';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorManager } from '@services/managers/error.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, ignoreElements, Observable, of, switchMap } from 'rxjs';

@Injectable()
export class SpaceModalService {

  constructor(private popupManager: PopupManager,
              private spacesService: SpacesService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService,
              protected errorManager: ErrorManager) {
  }

  /**
   * Open a dialog with a form the User to fill information to create a new child Space.
   * @param parentSpace Existing Space to use as the new Space's parent.
   * @param assetName Name of the Asset the new Space is added to. Optional, read-only asset field will be displayed only of set.
   * @return Created Space.
   * @throws Any encountered error.
   */
  public openAddSpaceDialog(parentSpace: Space, assetName?: string): Observable<Space> {
    const dialogRef = this.popupManager.showGenericPopup(
      assetName ? OrganizationSpaceCreateModalComponent : SpaceCreateModalComponent,
      PopupSize.MEDIUM,
      {
        assetName,
        parentSpace
      }
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.SPACE);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(analyticsEvent);

            const {spaceInput} = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.spacesService.createSpace(parentSpace.assetId, spaceInput)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog asking the User to confirm the Space move. If the Space has children, ask the User whether to move
   * them as well, then move the impacted Spaces accordingly.
   * @param spaceToMove Space being moved.
   * @param targetSpace Space to use as spaceToMove's new parent.
   * @param hasChildren Whether the Space to be moved has children.
   * @return Updated Spaces.
   * @throws Any encountered error.
   */
  public openMoveSpaceDialog(spaceToMove: Space, targetSpace: Space, hasChildren: boolean): Observable<Space> {

    /**
     * Track modal event and call service to move the Space if the dialog's result is affirmative.
     * @param dialogResponse Dialog button clicked by the User.
     * @param moveChildren Whether to move children Spaces.
     * @return Updated Spaces.
     */
    const moveSpace = (dialogResponse: string, moveChildren: boolean) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.SPACE);

      if (dialogResponse === 'yes') {
        // User confirmed Space move

        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.ENTITY_ID]: spaceToMove.id,
          [AnalyticsKeyEnum.FIELD]: 'parentId',
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
        });
        this.analyticsService.trackEvent(analyticsEvent);

        const newParentPath: string[] = [...targetSpace.parentPath, targetSpace.id];

        // Check whether to move children then whether Space move is valid
        return this.spacesService.isSpaceMoveAllowed(spaceToMove.id, newParentPath, moveChildren)
          .pipe(
            switchMap(error => {
              // If allowed, error will be empty, otherwise it will contain the reason it is not allowed
              if (!error) {
                // Move Space
                this.spinnerService.showSpinner();
                const spaceInput = {name: spaceToMove.name, parentPath: newParentPath};
                return this.spacesService.updateSpace(spaceToMove, spaceInput, moveChildren)
                  .pipe(finalize(() => this.spinnerService.hideSpinner()));
              } else {
                // Display warning
                return this.popupManager.showOkPopup({
                  dialogTitle: this.translate.instant("TITLE.WARNING"),
                  dialogMessage: this.errorManager.transformErrorMessageQuerySaveUpdate([error.message])?.first(),
                  type: 'warning'
                })
                  .afterClosed()
                  .pipe(ignoreElements());
              }
            })
          );
      } else {
        // User canceled Space move
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
        this.analyticsService.trackEvent(analyticsEvent);
        return EMPTY;
      }
    };

    if (hasChildren) {
      // Open dialog asking what to do with children Spaces
      const dialogRef = this.popupManager.showGenericPopup(
        OrganizationSpaceMoveModalComponent,
        PopupSize.MEDIUM_SMALL,
        {type: 'warning'}
      );
      return dialogRef.afterClosed()
        .pipe(
          switchMap(dialogResponse => {
            return moveSpace(dialogResponse, dialogRef.componentInstance.getGeneratedObject().moveChildren);
          })
        );
    } else {
      // No children, open simple Ok/Cancel dialog asking for confirmation before moving the Space
      return this.popupManager.showOkCancelPopup({
        dialogTitle: this.translate.instant('TITLE.MOVE_SPACE'),
        okText: this.translate.instant('BUTTON.MOVE'),
        type: 'warning'
      })
        .afterClosed()
        .pipe(switchMap(dialogResponse => moveSpace(dialogResponse, false)));
    }
  }

  /**
   * Display a dialog asking the User to confirm the Space deletion and how the Space hierarchy should be affected,
   * then delete the Space accordingly.
   * @param space Space to be deleted.
   * @param spaceHasChildren Whether the Space to delete has children.
   * @return True if the Space was successfully deleted, false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteSpaceModal(space: Space, spaceHasChildren: boolean): Observable<boolean> {
    /**
     * Track modal event then call service to delete Space if response is affirmative.
     * @param dialogResponse Dialog button clicked by the User.
     * @param deleteChildren Whether to delete the Space's children.
     * @return Observable that completes once operation is completed successfully
     */
    const deleteSpace = (dialogResponse: string, deleteChildren: boolean) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.SPACE);

      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: space.id
        });
        this.analyticsService.trackEvent(analyticsEvent);
        this.spinnerService.showSpinner();
        return this.spacesService.deleteSpace(space, deleteChildren)
          .pipe(finalize(() => this.spinnerService.hideSpinner()));
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
        this.analyticsService.trackEvent(analyticsEvent);
        return of(false);
      }
    };

    if (spaceHasChildren) {
      // Display dialog asking the User whether children Spaces should be deleted as well
      const dialogRef = this.popupManager.showGenericPopup(
        OrganizationSpaceDeleteModalComponent,
        PopupSize.MEDIUM_SMALL,
        {type: 'warning'}
      );
      return dialogRef.afterClosed()
        .pipe(
          switchMap(dialogResponse => {
            const deleteChildren =
              dialogRef.componentInstance.getGeneratedObject().deleteMode === 'deleteSpaceAndChildren';
            return deleteSpace(dialogResponse, deleteChildren);
          })
        );
    } else {
      // Space does not have children, display simple confirmation dialog
      return this.popupManager.showOkCancelPopup({
        dialogTitle: this.translate.instant('TITLE.DELETE_SPACE'),
        dialogMessage: this.translate.instant('LABEL.DELETE_SPACE_AND_DOCUMENTS'),
        okText: this.translate.instant('LABEL.DELETE'),
        type: 'warning'
      })
        .afterClosed()
        .pipe(switchMap(dialogResponse => deleteSpace(dialogResponse, false)));
    }
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to a Space.
   * @param spaceId ID of the Space to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadSpaceDocumentsDialog(spaceId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      spaceId,
      EntityTypeEnum.SPACE,
      DocumentTypeEnum.SPACE_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm a Space Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteSpaceDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
