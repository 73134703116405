import { Component, inject } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { AppManager } from '@app/core/services/managers/app.manager';
import { MenuConfiguration } from '@app/shared/interfaces/menu-configuration';

@Component({
  selector: 'abstract-mat-tab-nav',
  templateUrl: './abstract-mat-tab-nav.component.html',
})
export class AbstractMatTabNavComponent { // TODO TTT-4143 - check if class is necessary
  public menuItems: MenuConfiguration[] = [];

  public appManager: AppManager = inject(AppManager);
  public analyticsService: AnalyticsService = inject(AnalyticsService);

  public onClick(itemKey: string): void {
    this.analyticsService.trackNavigationEvent(EventOriginEnum.TAB, itemKey);
  }

  /**
   * Defines a list of navigation tabs to be displayed in the component.
   */
  protected createMenuItems(): MenuConfiguration[] {
    return [];
  }

}
