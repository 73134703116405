import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';
import dayjs from 'dayjs';

@Component({
  selector: 'date-cell-edit',
  templateUrl: './date-cell-edit.component.html',
  styleUrls: ['./date-cell-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class DateCellEditComponent extends AbstractCellEditor implements OnDestroy {

  @ViewChild(MatInput, {static: false}) public input: MatInput;
  @ViewChild(MatDatepicker, {static: true}) public datePicker: MatDatepicker<any>;

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
    // Input must be a dayjs compatible value
    // The value in the cell editor was formatted by the date column builder, so we need to parse the localised value
    this.control.setValue(dayjs(this.params.value, this.appConfig.DATE_FORMAT).isValid() ? dayjs(this.params.value, this.appConfig.DATE_FORMAT) : '');
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    this.input.focus();
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): any {
    return dayjs(this.control.value).isValid() ? dayjs(this.control.value).format(this.appConfig.DATE_FORMAT) : '';
  }

  public dateChange(): void {
    if (this.control.valid) {
      this.stopEditing();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update tooltip message in case of validation error
   * @param type Type of the validator in error
   * @param definition Definition of the validator in error
   */
  protected setTooltip(type: string, definition: string | number): void {
    const formattedDefinition = dayjs(definition || '').isValid() ? dayjs(definition).format(this.appConfig.DATE_FORMAT) : definition;
    this.errorTooltip = this.translateService.instant(
      `ERROR.FIELD_${type}`.toUpperCase(),
      {value: formattedDefinition}
    );
  }
}
