<div class="btn-bi">
  <restricted-icon-button
    (onClick)="toggleFullScreen()"
    [id]="'fullscreen-button'"
    [class]="'mdi mdi-fullscreen md'"
    [matTooltip]="('LABEL.FULLSCREEN' | translate)">
  </restricted-icon-button>
  <restricted-icon-button
    [id]="'bi-dashboard-print-button'"
    [class]="'mdi mdi-download md'"
    [matTooltip]="('LABEL.EXPORT_REPORT' | translate)"
    [permissions]="[Permission.EXPORT_BI_REPORT]"
    [disabled]="exportInProgress"
    (onClick)="exportBIReport()">
  </restricted-icon-button>
</div>
<div *ngIf="powerBIReportConfig; else loading" class="fx-layout-col fx-justify-stretch fx-fill" #powerBI>
  <powerbi-report class="fx-fill" [embedConfig]="powerBIReportConfig" cssClassName="report-container"></powerbi-report>
</div>

<ng-template #loading>
  <spinner></spinner>
</ng-template>
