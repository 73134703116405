import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
  isValidatorFieldDependant
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { format } from '@app/shared/extra/decimal-format';
import { getValue } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'number-field-builder',
  templateUrl: './number-field-builder.component.html',
  styleUrls: ['./number-field-builder.component.scss']
})
export class NumberFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  @ViewChild('inputField') public inputField: ElementRef;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        this.fieldInitValue,
        this.computeValidators()
      )
    });

    this.form.updateValueAndValidity({emitEvent: false});
    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.setFieldValue(value ? format(value) : value);

      // When other field value changes, tell anything hooked to the valuechange to update accordingly
      if (this.formStateService.getPath(['gteField', this.fieldConfig.fieldCode])) {
        this.formStateService.getPath(['gteField', this.fieldConfig.fieldCode]).next(value);
      }
      if (this.formStateService.getPath(['lteField', this.fieldConfig.fieldCode])) {
        this.formStateService.getPath(['lteField', this.fieldConfig.fieldCode]).next(value);
      }
      if (this.formStateService.getPath(['computed'])) {
        this.formStateService.getPath(['computed']).next(value);
      }
    });
  }

  public cancel(): void {
    this.form.get('field').setValue(this.getFieldInitialValue());
    super.cancel();
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  /**
   * Set focus on the element
   */
  public focus(): void {
    if (this.focusable) {
      setTimeout(() => this.inputField.nativeElement.focus());
    }
  }

  protected setupHooks(): void {
    super.setupHooks();
    this.form.get('field').setAsyncValidators(this.computeAsyncValidators());

    // Hook to the value dependant validators if they exist on this field
    const fieldDependantValidators = this.fieldConfig.field?.validators.filter((validator) => isValidatorFieldDependant(
      validator.type));
    fieldDependantValidators.forEach(fieldDependantValidator => {
      // Create a hook on the valuechange
      if (!this.formStateService.getPath([fieldDependantValidator.type, fieldDependantValidator.definitionPath.lastItem()])) {
        this.formStateService.setPath([
          fieldDependantValidator.type,
          fieldDependantValidator.definitionPath.lastItem()
        ], new Subject<number>());
      }

      // Update gte/lte validation when reference field is updated upstream
      const valueToCompare: number = getValue(this.entity, fieldDependantValidator.definitionPath);
      this.formStateService.getPath([fieldDependantValidator.type, fieldDependantValidator.definitionPath.lastItem()])
        .pipe(
          startWith(valueToCompare),
          takeUntil(this.destroy$)
        ).subscribe((updatedValue: number) => {
        // Keep everything but current referenced gte/lte validators
        this.fieldConfig.field.validators = this.fieldConfig.field.validators
          .filter(validator => validator.ref !== fieldDependantValidator.definitionPath.lastItem());

        this.fieldConfig.field.validators.push(
          this.validationService.convertFieldDependantValidator(fieldDependantValidator, +updatedValue)
        );

        // Skip re-adding the fieldtype-specific validators
        this.form.get('field').setValidators(super.computeValidators());
        this.form.get('field').updateValueAndValidity({onlySelf: false, emitEvent: false});
        this.form.get('field').markAsTouched({onlySelf: false});
      });
    });
  }

  /**
   * Manually add fieldtype-specific validators
   * @returns {ValidatorFn}
   */
  protected computeValidators(): ValidatorFn {
    // TODO TTT-3458 Not necessary or create field builder dedicated to YEAR fields
    if (
      this.fieldConfig.field.fieldType === FieldTypeEnum.YEAR
      //To prevent duplicate
      && this.fieldConfig.field.validators.find(validator => validator.type === ValidatorType.YEAR) === void 0
    ) {
      this.fieldConfig.field.validators.push(new FieldValidator(ValidatorType.YEAR, ''));
    }

    return Validators.compose(this.fieldConfig.field?.validators
      .map(validator => this.validationService.getValidator(validator, this.entity))
    );
  }

  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }
}
