import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import {
  AssetEquipmentCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/equipments/modals/asset-equipment-create-modal/asset-equipment-create-modal.component';
import {
  EquipmentCreateModalComponent
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-create-modal/equipment-create-modal.component';
import {
  EquipmentDuplicateModalComponent
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-duplicate-modal/equipment-duplicate-modal.component';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class EquipmentModalService {

  constructor(private popupManager: PopupManager,
              private appConfig: AppConfig,
              private equipmentsService: EquipmentsService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Display a dialog for the user to fill data then create new Equipment with the data.
   * @param asset If specified, Asset to link the new Equipment to.
   * @return Created Equipment.
   */
  public openCreateEquipmentDialog(asset?: Asset): Observable<Equipment> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? AssetEquipmentCreateModalComponent : EquipmentCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.EQUIPMENT);

          if (dialogResponse === 'yes') {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(event);

            const {assetId, equipmentInput} = dialogRef.componentInstance.getGeneratedObject();
            return this.equipmentsService.createEquipment(assetId, equipmentInput);
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog for the user to select an Asset and Spaces then copy a list of existing Equipments to the selected Asset.
   * @param equipments Equipments to duplicate.
   * @return Boolean of true if all equipments has been successfully created, false otherwise.
   */
  public openDuplicateEquipmentsDialog(equipments: Equipment[]): Observable<Equipment[]> {
    const dialogRef = this.popupManager.showGenericPopup(
      EquipmentDuplicateModalComponent,
      PopupSize.MEDIUM,
      {equipments}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.DUPLICATE, EntityTypeEnum.EQUIPMENT);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: equipments.map(equipment => equipment.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            const {assetId, equipmentInput} = dialogRef.componentInstance.getGeneratedObject();
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.equipmentsService.duplicateEquipments(equipments, assetId, equipmentInput.spaceIds)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog asking the user to confirm Equipments deletion, then delete the Equipments if confirmed.
   * @param equipments Equipments to delete.
   * @return True if the Equipments have been successfully deleted, false if the user cancelled the operation.
   */
  public openDeleteEquipmentsDialog(equipments: Equipment[]): Observable<boolean> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(equipments.length === 1 ? 'TITLE.DELETE_EQUIPMENT' : 'TITLE.DELETE_EQUIPMENTS'),
      dialogMessage: this.translate.instant(equipments.length === 1 ?
        'LABEL.DELETE_EQUIPMENT_AND_DOCUMENTS'
        : 'LABEL.DELETE_EQUIPMENTS_AND_DOCUMENTS'),
      type: 'warning',
      okText: this.translate.instant('LABEL.DELETE')
    });
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.EQUIPMENT);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: equipments.map(equipment => equipment.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.DELETE
            });
            this.analyticsService.trackEvent(event);
            return this.equipmentsService.deleteEquipments(equipments);
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return of(false);
          }
        })
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to an Equipment entity.
   * @param equipmentId ID of the Equipment to link Documents to.
   * @return Created Documents.
   */
  public openUploadEquipmentDocumentsDialog(equipmentId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      equipmentId,
      EntityTypeEnum.EQUIPMENT,
      DocumentTypeEnum.EQUIPMENT_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm an Equipment's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   */
  public openDeleteEquipmentDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }

  /**
   * Display a dialog for the User to upload pictures of an Equipment entity.
   * @param equipmentId ID of the Equipment to link Documents to.
   * @return Created Documents.
   */
  public openUploadEquipmentPicturesDialog(equipmentId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      equipmentId,
      EntityTypeEnum.EQUIPMENT,
      DocumentTypeEnum.EQUIPMENT_PICTURE,
      {
        allowedContentTypes: ['image/jpeg', 'image/png'],
        maxSize: this.appConfig.MAX_IMAGE_SIZE,
        title: 'TITLE.ADD_PICTURE'
      }
    );
  }

  /**
   * Display a dialog asking the User to confirm an Equipment's picture's deletion.
   * @param imageDocument Document representing the image to delete.
   * @return True if the image's Document was successfully deleted of false if the operation was canceled by the User.
   */
  public openDeleteEquipmentPictureDialog(imageDocument: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(imageDocument, {
      dialogTitle: this.translate.instant('TITLE.DELETE_PICTURE'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_PICTURE'),
      okText: this.translate.instant('BUTTON.DELETE'),
      cancelText: this.translate.instant('BUTTON.CANCEL'),
      type: 'warning'
    });
  }

}
