import { Component, OnDestroy } from '@angular/core';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import {
  ClientGroupData
} from '@app/features/main/views/_tb/tb-users/modals/tb-user-create-edit-modal/tb-user-create-modal.component';
import { PermissionAware } from '@app/shared/decorators/permissions-awareness.decorator';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';

@Component({
  selector: 'group-grid-cell',
  template: `
    <div id="cell-container">
      <span>{{ params.value }}</span>
      <button *ngIf="showButton" (click)="toggleGroup(params.action)" color="primary" mat-icon-button
              class="fx-layout-row fx-justify-center fx-align-center"
              [disabled]="(params.action === 'Add') && data?.groupInfo?.isGroupFromClientPresent">
        <i class="mdi msm" [ngClass]="params.actionIcon"></i>
      </button>
    </div>
  `,
  styles: [`
    #cell-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  `]

})
@PermissionAware
export class GroupGridCellComponent implements ICellRendererAngularComp, OnDestroy {

  public Permission = PermissionEnum;

  public params: Partial<ICellRendererParams & { actionIcon: string, action: 'Add'|'Remove' }>;
  public data: ClientGroupData;

  public icon: string;
  public showButton: boolean;

  private destroy$ = new Subject<void>();

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.data;

    // Show the button if the tree level is more than 1
    this.showButton = this.data.groupInfo?.treeData.split(' | ').length > 1;
  }

  public toggleGroup(action: 'Add'|'Remove'): void {
    if (action === 'Add') {
      this.data.groupInfo?.toggleButton.setValue(true);
    } else if(action === 'Remove') {
      this.data.groupInfo?.toggleButton.setValue(false);
    }
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param params
   * @returns {boolean}
   */
  public refresh(params: any): boolean {
    return false;
  }
}
