import { AbstractBean } from '@app/core/model/abstract-bean';
import { Client } from '@app/core/model/client/client';
import { ClientSubscriptionModule } from '@app/core/model/client/client-subscription-module';
import { Group } from '@app/core/model/client/group';
import { Permission } from '@app/core/model/client/permission';
import { UserRole } from '@app/core/model/client/user-role';
import { PowerBIReport, ReportName, ReportType } from '@app/core/model/other/powerBI';
import { dateParser } from '@app/shared/extra/utils';
import { Expose, Transform, Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export class User extends AbstractBean {
  private _organizationPermissions: Permission[] = [];

  public isTb: boolean;
  public email: string;
  public picture?: string;
  public position: string;
  @Type(() => Permission)
  public globalPermissions: Permission[];

  @Transform(fn => dateParser(fn.value), {toClassOnly: true})
  @Type(() => String)
  public lastLogin?: Dayjs;

  @Transform(fn => dateParser(fn.value), {toClassOnly: true})
  @Type(() => String)
  public lastInvite?: Dayjs;

  @Type(() => UserRole)
  public userRole: UserRole;
  @Type(() => ClientSubscriptionModule)
  public subscriptionModules: ClientSubscriptionModule[];
  @Type(() => ClientSubscriptionModule)
  public configurableSubscriptionModules: ClientSubscriptionModule[];
  @Type(() => ClientSubscriptionModule)
  public displayableModules: ClientSubscriptionModule[];
  @Type(() => Group)
  public groups: Group[];
  @Type(() => Client)
  public clients: Client[];
  @Type(() => UserGlobalBIReport)
  public globalBIReports: UserGlobalBIReport[];

  /**
   * Set the User's permissions within the Organization that is currently loaded.
   * @param permissions Permissions to grant to the User.
   */
  public set organizationPermissions(permissions: Permission[]) {
    this._organizationPermissions = permissions;
  }

  /**
   * Get all the User's permissions, including role-based permissions and permissions within the current Organization.
   */
  public getUserPermissions(checkGlobalPermissions: boolean = false): Permission[] {
    const additionalPermissions = checkGlobalPermissions ? this.globalPermissions : this._organizationPermissions;
    return [...this.userRole.activePermissions, ...additionalPermissions];
  }

  @Expose()
  public toString(): string {
    return this.name;
  }
}

/**
 * Data for creating or updating a User.
 */
export interface UserInput {
  email?: string,
  userRole?: string,
  groupIds?: number[],
  moduleSubscriptionIds?: number[],
  globalBIReports?: GlobalBIReportInput[]
}

export class UserGlobalBIReport implements PowerBIReport {
  @Expose({name: 'reportId'})
  public id: string;

  constructor(
    public name: string,
    public defaultPageId: string = null,
    public type: ReportType = 'PowerBIReport'
  ) {}
}

export interface GlobalBIReportInput {
  id: string,
  name: ReportName,
  defaultPageId: string
}
