import { HttpClient } from '@angular/common/http';
import gettext from 'gettext-parser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type TranslationPair = { [key: string]: string };

/**
 * A class that provides translation data from a translate.po file using
 * HTTP requests.
 * @see https://github.com/unistack-org/ngx-translate-po-http-loader
 */
export class TranslatePoHttpLoader {
  private domain: string = '';

  constructor(
    private _http: HttpClient,
    private _prefix: string = 'i18n',
    private _suffix: string = '.po'
  ) { }


  /**
   * Retrieves the translation pair for the specified language.
   *
   * @param {string} lang - The language code for the translation.
   * @returns {Observable<TranslationPair>} - An observable that emits the translation pair.
   */
  public getTranslation(lang: string): Observable<TranslationPair> {
    return this._http
      .get(`${this._prefix}/${lang}${this._suffix}`, { responseType: 'text' })
      .pipe(map((contents: string) => this.parse(contents)));
  }

  /**
   * Parses the contents of a translation file and returns an object containing the translations.
   *
   * @param {string} contents - The contents of the translation file.
   * @return {TranslationPair} - An object containing the translations.
   */
  private parse(contents: string): TranslationPair  {
    const translations: {[key: string]: string} = {};
    const po = gettext.po.parse(contents, 'utf-8');
    if (!po.translations.hasOwnProperty(this.domain)) {
      return translations;
    }
    Object.keys(po.translations[this.domain])
      .forEach((key: string) => {
        const translation = po.translations[this.domain][key]['msgstr'].pop();
        if (key.length > 0 && translation.length > 0) {
          translations[key] = translation;
        }
      });
    return translations;
  }
}
