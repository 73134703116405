import { Expose } from 'class-transformer';

export class Currency {
  static CAD = new Currency('CAD', '$');
  static EUR = new Currency('EUR', '€');

  static currencies = [Currency.CAD, Currency.EUR];

  private constructor(public code: string,
                      public symbol: string) {
  }

  @Expose()
  public toFormattedString(): string {
    return this.code.toUpperCase() + ' (' + this.symbol + ')';
  }
}
