import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[inputFieldBuilderAutoFocus]',
})
export class InputAutoFocusDirective implements OnInit {

  constructor(private input: ElementRef) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
  }

}
