@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled"
      [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
      class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" [matTooltip]="fieldConfig.tooltip"
            matTooltipPosition="below" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      @if (document.documentType !== DocumentTypeEnum.PROJECT_DOCUMENT) {
        <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
          <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
             [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition,
                        'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
            <a (click)="navigateToAssetDashboard()">
              {{ getFieldValue().first()?.toString() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
            </a>
            @if (fieldConfig.computed) {
              <span
                [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
                #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-calculator tooltip-icon"></i>
              </span>
            }
            @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
              <span
                class="overlay-icon">
                @if (preconditionsForEdition) {
                  <i class="mdi mdi-pencil selectable"></i>
                } @else {
                  <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
                }
              </span>
            }
          </div>
        </div>
      } @else {
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
            [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition}">
            @if (getFieldValue()?.length > 0) {
              <mat-chip-listbox tabIndex="-1">
                @for (chip of (getFieldValue()| slice:0:5); track chip) {
                  <mat-chip-option class="clickable-field" (click)="onClickActivate($event)"
                    (keyup.enter)="onEnterActivate($event)"
                    >
                    <a (click)="navigateToAssetDashboardByAssetId(chip.id)">
                      {{ chip?.toString() }}
                    </a>
                  </mat-chip-option>
                }
                @if (getFieldValue()?.length>5) {
                  {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (getFieldValue().length - 5)} }}
                }
                @if (fieldConfig.computed) {
                  <span
                    [matTooltipPosition]="'below'"
                    [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
                    #tooltip="matTooltip"
                    (click)="onClickTooltip(tooltip)">
                    <i class="mdi mdi-calculator tooltip-icon"></i>
                  </span>
                }
              </mat-chip-listbox>
            } @else {
              <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
            }
            @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
              <span
                class="overlay-icon">
                @if (preconditionsForEdition) {
                  <i class="mdi mdi-pencil selectable"></i>
                } @else {
                  <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
                }
              </span>
            }
          </div>
        </div>
      }
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" [matTooltip]="fieldConfig.tooltip"
            (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL.ASSET' | translate}}</mat-label>
          <mat-select-asset-autocomplete #inputField [formControl]="$any(form.get('asset'))"
            [disabled]="(asyncPreconditionsForEdition$ | async) === false">
          </mat-select-asset-autocomplete>
        </mat-form-field>
        @if (isSingleField) {
          <div class="save-options">
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
              <i class="mdi mdi-close"></i>
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
              class="button save">
              <i class="mdi mdi-check"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}

