import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Document } from '@app/core/model/entities/document/document';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { PopupManager } from '@services/managers/popup.manager';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})

export class DocumentViewerComponent implements OnInit, OnDestroy {

  @Input()
  public set document(value: Document) {
    if (this.currentDocumentId !== value.id) {
      this.resetPreviewInfo();
      if (value.mimeType && value.hash) {
        this.mimeType = value.mimeType;
        this.currentDocumentId = value.id;
        this.setPreviewInfo();
      }
    }
  }

  @Input() public openInNewTab: Observable<void>;
  @Output() public documentIsLoaded = new EventEmitter<boolean>();

  public currentDocumentId: string;
  public mimeType: string;
  public previewUrl: SafeResourceUrl;
  public blobFileUrl: string;
  public cssCLass: string;
  public destroy$ = new Subject<void>();
  public unsupportedImageType: string[] = ['image/vnd.dwg', 'image/x-dwg'];

  constructor(public sanitizer: DomSanitizer,
              private popupManager: PopupManager,
              private translate: TranslateService,
              private fileService: FileService) {
  }

  public ngOnInit(): void {
    this.openInNewTab.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.previewUrl) {
        this.openInNewTabOrDownload();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setPreviewInfo(): void {
    if (this.mimeType === 'application/pdf' || (this.mimeType.includes('image') && !this.unsupportedImageType.includes(this.mimeType))) {
      // Get document file as ArrayBuffer from url
      this.fileService.getBlobFile(this.currentDocumentId, this.mimeType).subscribe((blobFile) => {
          // Determine file signature
          this.blobFileUrl = window.URL.createObjectURL(blobFile);
          this.previewUrl = this.sanitize(this.blobFileUrl);
          this.documentIsLoaded.emit(true);
        }
      );
      this.cssCLass = 'document-viewer-' + this.mimeType.slice(0, this.mimeType.search('/'));
    } else {
      switch (this.mimeType) {
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
          this.previewUrl = this.sanitize('assets/images/icon/xlsx.png');
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
          this.previewUrl = this.sanitize('assets/images/icon/docx.png');
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation' :
          this.previewUrl = this.sanitize('assets/images/icon/pptx.png');
          break;
        default:
          this.previewUrl = this.sanitize('assets/images/icon/file.png');
          this.cssCLass = 'logo';
          //Preview is not loaded since there is no preview available
          this.documentIsLoaded.emit(false);
          return;
      }
      this.cssCLass = 'logo';
      this.documentIsLoaded.emit(true);
    }
  }

  public sanitize(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public openInNewTabOrDownload(): void {
    if (this.blobFileUrl) {
      window.open(this.blobFileUrl);
    } else {
      const dialogRef = this.popupManager.showOkCancelPopup({
        type: 'warning',
        dialogTitle: this.translate.instant('TITLE.DOWNLOAD_DOCUMENT'),
        dialogMessage: this.translate.instant('LABEL.DOWNLOAD_DOCUMENT'),
        okText: this.translate.instant('BUTTON.DOWNLOAD_DOCUMENT')
      });
      dialogRef.afterClosed().subscribe((dialogResponse) => {
        if (dialogResponse === 'yes') {
          this.fileService.downloadFile(this.currentDocumentId);
        }
      });
    }
  }

  public resetPreviewInfo(): void {
    URL.revokeObjectURL(this.blobFileUrl);
    this.currentDocumentId = undefined;
    this.documentIsLoaded.emit(false);
    this.mimeType = undefined;
    this.previewUrl = undefined;
    this.cssCLass = undefined;
    this.blobFileUrl = undefined;
  }
}
