import { Component, inject } from '@angular/core';
import { AuthProvider } from '@app/core/enums/auth-provider.enum';
import { AccessManager } from '@app/core/services/managers/access.manager';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';

@Component({
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent {
  public authService = inject(AuthService);
  public accessManager = inject(AccessManager);

  protected readonly AuthProvider = AuthProvider;

  /**
   * Open a new tab and navigate to the URL for the tbmaestro accounts profile page.
   */
  public openProfilePage(): void {
    window.open(environment.profile.accountsPortal, '_blank').focus();
  }
}
