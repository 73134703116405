import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { NotificationPopupComponent } from '@app/shared/components/notification-popup/notification-popup.component';

@Injectable()
export class SnackbarManager {

  constructor(private snackBar: MatSnackBar) {
  }

  public showInfiniteSnackbar(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, 'X', {
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  public showActionSnackbar(message: string, action: string = ''): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: action ? '' : 'snackbar-no-action', // If no action is set, center the text message
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  public showGenericSnackbar(renderComponent: any): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(renderComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  public showNotificationSnackbar(message: string): MatSnackBarRef<NotificationPopupComponent> {
    return this.snackBar.openFromComponent(NotificationPopupComponent, {
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'notification-snackbar',
      data: {
        newFeatureMessage: message
      }
    });
  }

  public closeSnackbar(): void {
    this.snackBar.dismiss()
  }
}
