import { Directive, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { maxNow } from './validator';

const MAX_NOW_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxNowValidator),
  multi: true
};

@Directive({
  selector: '[maxNow][formControlName],[maxNow][formControl],[maxNow][ngModel]',
  providers: [MAX_NOW_VALIDATOR]
})
export class MaxNowValidator implements Validator, OnInit, OnChanges {
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = maxNow;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'MAX_NOW') {
        this.validator = maxNow;
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
