<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ work?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeWorkSidebar()" [disableRipple]="true"
            class="fx-layout-col fx-justify-center fx-align-center"
            id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadWorkDocuments()"
        [class]="'mdi mdi-paperclip sm'"
        [id]="'add-document-button'"
        [matTooltip]="'BUTTON.ADD_DOCUMENT' | translate"
        [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="uploadWorkPictures()"
        [class]="'mdi mdi-image-plus sm'"
        [id]="'add-picture-button'"
        [matTooltip]="'BUTTON.ADD_PICTURE' | translate"
        [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>

      <span class="fx-flex"></span>

      <div class="work-sheet-link">
        <span (click)="navigateToWorkSheet()">{{ 'BUTTON.SHOW_WORK_SHEET' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
    <div class="sidebar-picture-container fx-layout-col fx-justify-center fx-align-center">
      <img id="sidebar-picture"
        [src]="images[0]?.url || appConfig.PLACEHOLDER_CAROUSEL"
        [alt]="images[0] && (images[0].alt || 'LABEL.ALT_WORK_MAIN_PICTURE') || 'LABEL.NO_WORK_PICTURE' | translate"/>
    </div>

    <div class="page-sidebar">
      <div class="form-container">
        @if (work) {
          <form-builder
            formId="project_work_sidebar"
            [displayHeader]="false"
            [eventsOrigin]="eventsOrigin"
            [permissionsForEdition]="permissionsForEdition">
          </form-builder>
        }
        <div class="document-title fx-layout-row fx-justify-start fx-align-center">
          <span class="fx-flex-30-percent"> {{'LABEL.DOCUMENT' | translate}} </span>
          <restricted-text-button
            (click)="uploadWorkDocuments()"
            [id]="'work-upload-document-button'"
            [permissions]="[Permission.EDIT_WORK, Permission.CREATE_DOCUMENT]"
            class="btn-upload-document">
            {{'BUTTON.ADD_DOCUMENT' | translate}}
          </restricted-text-button>
        </div>
        @for (document of work?.documents; track document) {
          <div class="document-content">
            @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
              <a
                (click)="fileService.downloadFile(document.id)">
                <i class="mdi mdi-file-outline xs" aria-hidden="true"></i>
                <span class="file-name-label">{{ document.name }}</span>
              </a>
            } @else {
              <span class="file-name-label">{{ document.name }}</span>
            }
            <span (click)="deleteWorkDocument(document)"
              [permission-lock]="[Permission.EDIT_WORK, Permission.DELETE_DOCUMENT]" class="delete-document-icon">
              <i class="mdi mdi-delete xs" aria-hidden="true"></i>
            </span>
          </div>
        }
      </div>
    </div>
  </div>
</div>
