import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomCellRendererParams } from '@app/shared/components/cell-renderer/cell-renderer-params';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'editable-cell-renderer',
  templateUrl: './editable-cell-renderer.component.html',
  styleUrls: ['./editable-cell-renderer.component.scss']
})
export class EditableCellRendererComponent implements ICellRendererAngularComp {
  public ColumnType = ColumnType;
  public params: CustomCellRendererParams;

  constructor(public fb: FormBuilder,
              public translateService: TranslateService) {
  }

  /**
   * Check if the column is numerical.
   * @returns True if the column is numerical, false otherwise.
   */
  public get isNumericalColumn(): boolean {
    return this.params.colDef.type === ColumnType.NUMERICAL;
  }

  public agInit(params: CustomCellRendererParams): void {
    this.params = params;
  }

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
