import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { gql } from '@apollo/client/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Work, WorkInput } from '@app/core/model/entities/works/work';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { UsersService } from '@app/shared/services/users.service';
import { FieldService } from '@services/field.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToClassFromExist, plainToInstance } from 'class-transformer';
import { from, mergeMap, Observable, Subject, switchMap } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class WorksService {
  private sidePanelToggleSubject = new Subject<Work | null>();

  private addWorkSubject = new Subject<Work>();
  private updateWorkSubject = new Subject<Work>();
  private deleteWorksSubject = new Subject<Work[]>();

  private generalService = inject(GeneralService);
  private appManager = inject(AppManager);
  private router = inject(Router);
  private activationEndService = inject(ActivationEndService);
  private fieldService = inject(FieldService);
  private assetsService = inject(AssetsService);
  private usersService = inject(UsersService);

  private readonly workInfoGraphqlFragment = gql`
    fragment WorkInfo on Work {
      id
      identifier
      action
      state
      organizationId
      assetId
      spaceIdsList
      equipmentIdsList
      projectIdsList
      properties
      computedProperties
      creationUserId
      creationDate
      lastChangeUserId
      lastChangeDate
      dataDate
    }
  `;

  /**
   * Emits any new Work after it was created.
   */
  public get workAdded$(): Observable<Work> {
    return this.addWorkSubject.asObservable();
  }

  /**
   * Emits a Work whenever it has been updated.
   */
  public get workUpdated$(): Observable<Work> {
    return this.updateWorkSubject.asObservable();
  }

  /**
   * Emits a list of Works after they have been deleted.
   */
  public get worksDeleted$(): Observable<Work[]> {
    return this.deleteWorksSubject.asObservable();
  }

  /**
   * Emits data for the side panel to display or null whenever the side panel is closed.
   */
  public get sidePanelToggle$(): Observable<Work | null> {
    return this.sidePanelToggleSubject.asObservable();
  }


  /**
   * Make an API request to fetch Works linked to the current Organization that the current user has permission
   * to view. If an Asset ID is specified, only Works related to this Asset are fetched. Otherwise, all
   * the Organization's Works, including those not related to any Assets, are fetched.
   * @param assetId ID of Asset whose Works should be returned.
   * @return Observable of all the Organization's Works.
   */
  public loadWorks(assetId?: string): Observable<Work[]> {
    const QUERY = gql`
      query Works($organizationId: String!, $assetId: String) {
        works(organizationId: $organizationId, assetId: $assetId) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      assetId
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['works'] as Work[])),
        // Fetch Users who created and last updated the Work
        switchMap(works => from(works)),
        mergeMap(work => this.usersService.fetchUsersInfo(work)),
        toArray()
      );
  }

  /**
   * Make an API request to fetch Works linked to a specified project Id that the current user has permission
   * to view.
   * @param projectId ID of Project whose Works should be returned.
   * @return Observable of all the Project's Works.
   */
  public loadProjectWorks(projectId: string): Observable<Work[]> {
    const QUERY = gql`
      query ProjectWorks($projectId: String!, $organizationId: String!) {
        worksByProject(projectId: $projectId, organizationId: $organizationId) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      projectId,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['worksByProject'] as Work[])),
        // Fetch Users who created and last updated the Work
        switchMap(works => from(works)),
        mergeMap(work => this.usersService.fetchUsersInfo(work)),
        toArray()
      );
  }

  /**
   * Make an API request to fetch Works linked to a specified equipment ID that the current user has permission
   * to view.
   * @param equipmentId ID of an Equipment whose Works should be returned.
   * @return Observable of all the Equipment's Works.
   */
  public loadEquipmentWorks(equipmentId: string): Observable<Work[]> {
    const QUERY = gql`
      query EquipmentWorks($equipmentId: String!) {
        worksByEquipment(equipmentId: $equipmentId) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const QUERY_VAR = {equipmentId};

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['worksByEquipment'] as Work[])),
        // Fetch Users who created and last updated the Work
        switchMap(works => from(works)),
        mergeMap(work => this.usersService.fetchUsersInfo(work)),
        toArray()
      );
  }

  /**
   * Fetch Work to be displayed, based on the activated route.
   * Query Graphql cache first since the guard already query the server.
   * @param route Activated route.
   * @return Work.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Work> {
    const QUERY = gql`
      query Work($id: String!) {
        work(id: $id) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: route.paramMap.get('id')
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'cache-first')
      .pipe(
        map(response => plainToInstance(Work, response.data['work'] as Work)),
        // Fetch Users who created and last updated the Work
        switchMap(work => this.usersService.fetchUsersInfo(work)),
        tap(() => this.activationEndService.getRefreshHeaderSubject().next())
      );
  }


  /**
   * Fetch a work by its ID.
   * @param workId The Work's ID.
   * @return Observable emitting the Work.
   */
  public loadWork(workId: string): Observable<Work> {
    const QUERY = gql`
      query Work($id: String!) {
        work(id: $id) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: workId
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'network-only')
      .pipe(
        map(response => plainToInstance(Work, response.data['work'] as Work))
      );
  }

  /**
   * Get the field config for Work state and return the existing state values within the current Organization.
   * @return List of available states.
   */
  public loadWorkStates(): Observable<string[]> {
    return this.fieldService.getField('state', EntityTypeEnum.WORK)
      .pipe(map(field => field.fieldValues));;
  }


  /**
   * Make an API request to create a new Work with the provided data.
   * @param workInput Work data.
   * @return Observable emitting the new Work that was created.
   */
  public createWork(workInput: WorkInput): Observable<Work> {
    const MUTATION = gql`
      mutation CreateWork($organizationId: String!, $workInput: WorkInput!) {
        createWork(organizationId: $organizationId, workInput: $workInput) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      workInput
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['createWork'] as Work)),
        switchMap(work => this.usersService.fetchUsersInfo(work)),
        tap(newWork => this.addWorkSubject.next(newWork))
      );
  }

  /**
   * Make an API request to copy the Work into another Asset.
   * @param works Works to duplicate.
   * @param assetId Optional ID of Asset to copy the Work into.
   * @param spaceIds IDs of Spaces to link to new Work.
   * @return Observable emitting the Work that was created.
   */
  public duplicateWorks(works: Work[], assetId?: string, spaceIds?: string[]): Observable<Work[]> {
    const MUTATION = gql`
      mutation DuplicateWork($workIds: [String!]!, $assetId: String, $spaceIds: [String]) {
        duplicateWorks(workIds: $workIds, assetId: $assetId, spaceIds: $spaceIds) {
          ...WorkInfo
        }
      }${this.workInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {workIds: works.map(work => work.id), assetId, spaceIds};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['duplicateWorks'] as Work[])),
        // Fetch Users who created and last updated the Work
        switchMap(works => from(works)),
        mergeMap(work => this.usersService.fetchUsersInfo(work)),
        tap(newWork => this.addWorkSubject.next(newWork)),
        toArray()
      );
  }

  /**
   * Call the API to update a Work.
   * @param work Work to update.
   * @param workInput Data to update the Work with.
   * @return Observable emitting the updated Work once the update is completed.
   */
  public updateWork(work: Work, workInput: WorkInput): Observable<Work> {
    const MUTATION = gql`
      mutation UpdateWork($workId: String!, $workInput: WorkInput!) {
        updateWork(workId: $workId, workInput: $workInput) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      workId: work.id,
      workInput: {
        action: work.action,
        state: work.state,
        assetId: work.assetId,
        spaceIds: work.spaceIds,
        equipmentIds: work.equipmentIds,
        projectIds: work.projectIds,
        ...workInput
      }
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['updateWork'] as Work)),
        switchMap(work => this.usersService.fetchUsersInfo(work)),
        tap(updatedWork => this.updateWorkSubject.next(updatedWork))
      );
  }

  /**
   * Call the API to add related projects to specified works.
   * @param workIds List of work IDs whose related projects should be updated.
   * @param projectIds List of project IDs to add to the related projects of the specified works.
   * @return Observable emitting the updated Works once the update is completed.
   */
  public addProjectToWorks(workIds: string[], projectIds: string[]): Observable<Work[]> {
    const MUTATION = gql`
      mutation AddProjectIdsToWorks($workIds: [String!]!, $projectIds: [String!]!) {
        addProjectsToWorks(workIds: $workIds, projectIds: $projectIds) {
          ...WorkInfo
        }
      }
      ${this.workInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {workIds, projectIds};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Work, response.data['addProjectsToWorks'] as Work[])),
        // Fetch Users who created and last updated the Work
        switchMap(works => from(works)),
        mergeMap(work => this.usersService.fetchUsersInfo(work)),
        tap(newWork => this.updateWorkSubject.next(newWork)),
        toArray()
      );
  }


  /**
   * Make an API request to set the provided picture Document to be the Work's main picture.
   * @param work Work which to set the main picture of.
   * @param mainPictureDocument Document to be used as main picture.
   * @return Updated Work with main picture set.
   */
  public setWorkMainPicture(work: Work, mainPictureDocument: Document): Observable<Work> {
    const MUTATION = gql`
      mutation SetWorkMainPicture($workId: String!, $mainPictureDocumentId: String!) {
        setWorkMainPicture(workId: $workId, mainPictureDocumentId: $mainPictureDocumentId) {
          id
          properties
          lastChangeDate
          lastChangeUserId
        }
      }
    `;
    const MUTATION_VAR = {
      workId: work.id,
      mainPictureDocumentId: mainPictureDocument.id
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToClassFromExist(work, response.data['setWorkMainPicture'])),
        switchMap(work => this.usersService.fetchUsersInfo(work))
      );
  }

  /**
   * Make an API request to delete Works.
   * @param works Works to delete.
   * @return True if all Works have been deleted successfully, false otherwise.
   */
  public deleteWorks(works: Work[]): Observable<boolean> {
    const MUTATION = gql`
      mutation DeleteWork($workIds: [String!]!) {
        deleteWorks(workIds: $workIds)
      }
    `;
    const MUTATION_VAR = {workIds: works.map(work => work.id)};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteWorks'] as boolean),
        tap(success => success && this.deleteWorksSubject.next(works))
      );
  }

  /**
   * Open the sidenav with information about the selected Work.
   * @param work Work to be displayed in the side panel.
   */
  public openWorkSidePanel(work: Work): void {
    this.sidePanelToggleSubject.next(work);
  }

  /**
   * Close the sidenav.
   */
  public closeWorkSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Navigate to a Work's sheet.
   * @param workId ID of the Work to navigate to.
   * @return Router's promise
   */
  public async navigateToWorkSheet(workId: string): Promise<void> {
    await this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'works',
      'work-sheet',
      workId
    ]);
  }

  /**
   * Navigate to an Asset's sheet's works tab.
   * @param assetId ID of the Asset to navigate to.
   * @return Router's promise
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'works');
  }
}
