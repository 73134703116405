<div [ngClass]="{'fx-justify-end': isNumericalColumn ,'fx-justify-space-between': !isNumericalColumn}"
  class="ag-cell-editor fx-layout-row fx-align-center fx-gap-5">
  <div>
    {{ params.valueFormatted }}
  </div>
  <div class="fx-layout-row">
    @if (formControl.invalid) {
      <div [matTooltip]="params.customErrorMessage" (click)="onClickTooltip(tooltip)">
        <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon"></i>
      </div>
    }
    <div [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate" #tooltip="matTooltip"
      (click)="onClickTooltip(tooltip)">
      <i aria-hidden="true" class="mdi mdi-calculator tooltip-icon"></i>
    </div>
  </div>
</div>
