import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum AssetNameActionTypes {
  FieldValueChange = 'assetName_change',
  FieldValueReset = 'assetName_reset',
  FieldValueRevert = 'assetName_revert'
}

export class AssetNameChange extends PayloadAction {
  readonly type = AssetNameActionTypes.FieldValueChange;
}

export class AssetNameReset extends PayloadAction {
  readonly type = AssetNameActionTypes.FieldValueReset;
}

export class AssetNameRevert implements Action {
  readonly type = AssetNameActionTypes.FieldValueRevert;
}

export type AssetNameActions = AssetNameChange | AssetNameReset | AssetNameRevert;
