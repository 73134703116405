import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CustomCellRendererParams } from '@app/shared/components/cell-renderer/cell-renderer-params';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'percent-cell-renderer',
  templateUrl: './pinned-cell-renderer.component.html'
})
export class PinnedCellRendererComponent implements ICellRendererAngularComp {
  public params: CustomCellRendererParams;
  public formControl: FormControl;

  constructor(public fb: FormBuilder,
              public translateService: TranslateService) {
    this.formControl = this.fb.control({disabled: true});
  }

  // gets called once before the renderer is used
  public agInit(params: CustomCellRendererParams): void {
    this.formControl.setValidators(params.validators);
    this.formControl.setValue(params.value);
    if (this.formControl.invalid) {
      params.eGridCell.classList.add('error-cell');
    }
    this.params = params;
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
