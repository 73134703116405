import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldGroup
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { format } from '@app/shared/extra/decimal-format';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import dayjs, { Dayjs } from 'dayjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'market-value-field-builder',
  templateUrl: './market-value-field-builder.component.html',
  styleUrls: ['./market-value-field-builder.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class MarketValueFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;
  public form: FormGroup<{ marketValue: FormControl<string>, marketValueDate: FormControl<Dayjs> }>;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );
  }

  public ngOnInit(): void {
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isSingleField: true,
      value: {},
      initialValue: {}
    });
    this.initForm();
  }

  /**
   * Initialize form group with two fields marketValue and marketValueDate.
   */
  public initForm(): void {
    const fieldsConfig = this.fieldGroup.fieldConfigs;
    const date = dayjs(this.entity.properties['marketValueDate'] ?? '');
    this.form = new FormGroup({
      marketValue: new FormControl(
        this.entity.properties['marketValue'] ?? '',
        fieldsConfig[0].field?.validators.map(validator => this.validationService.getValidator(
          validator,
          this.entity
        ))
      ),
      marketValueDate: new FormControl(
        date.isValid() ? date : void 0,
        fieldsConfig[1].field?.validators.map(validator => this.validationService.getValidator(
          validator,
          this.entity
        ))
      )
    });

    const completeMarketValue = {
      marketValue: this.entity.properties['marketValue'],
      marketValueDate: this.entity.properties['marketValueDate']
    };

    // JSON parse and stringify are used to perform a deep copy of the address
    this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(completeMarketValue)));
    this.setFieldGroupValue(JSON.parse(JSON.stringify(completeMarketValue)));
    this.getNextState();
  }

  /**
   * Set up hooks and listen to state changes.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();
    this.getFieldGroupState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newMode) => {
        if (newMode === FieldStateMode.AFTER_SAVE) {
          this.refreshEntity();
          // update value in form, get the value formatted
          this.form.get('marketValue').setValue(this.entity.properties['marketValue']);
          this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(this.getFieldGroupValue())));
          this.getNextState();
        } else {
          this.currentMode = newMode;
        }
      });
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  public save(): void {
    const dateValue = this.form.get('marketValueDate').value;
    const marketValue = this.form.get('marketValue').value;
    this.setFieldGroupValue({
      marketValue: marketValue ? format(marketValue) : marketValue,
      marketValueDate: dateValue ? dayjs(dateValue).format(this.appConfig.DATE_FORMAT) : ''
    });
    super.save();
  }

  public cancel(): void {
    this.initForm();
  }

  public formatDate(date): string {
    return dayjs(date).format('MMMM YYYY');
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for which an error has been raised by the corresponding validator function.
   */
  public getErroredValidators(field: string): FieldValidator[] {
    const fieldConfig = this.fieldGroup.fieldConfigs.find(fieldConfig => fieldConfig.code === field);
    return fieldConfig?.field?.validators?.filter(validator => !!this.form.get(field).errors?.[validator.code])
      ?? [];
  }

  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }
}
