import { Component, inject } from '@angular/core';
import { AssetType } from '@app/core/model/entities/asset/asset-type';
import { DatagridVisualisation } from '@app/core/model/entities/visualisation/datagrid-visualisation';
import { AssetTypesService } from '@app/features/main/views/management/organization/asset-types/asset-types.service';
import {
  VisualisationToolPanelComponent
} from '@app/shared/components/visualisation-tool-panel/visualisation-tool-panel.component';
import { Observable } from 'rxjs';
import { map, mergeWith, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'asset-type-visualisation-tool-panel',
  templateUrl: './asset-type-visualisation-tool-panel.component.html',
  styleUrls: ['./asset-type-visualisation-tool-panel.component.scss'],
  providers: [AssetTypesService]
})
export class AssetTypeVisualisationToolPanelComponent extends VisualisationToolPanelComponent {

  public assetTypes: AssetType[];
  protected readonly AssetType = AssetType;
  protected assetTypeVisualisations$: Observable<DatagridVisualisation[]>;
  private params: any;
  private assetTypeService = inject(AssetTypesService);

  /**
   * Initializes the form
   */
  constructor() {
    super();
    this.assetTypeService.assetTypes$.subscribe(types => this.assetTypes = types);
    this.visualisationForm.addControl('currentAssetType', this.fb.control(AssetType.NullAssetType));
  }

  /**
   * Method used by Ag-grid to initialise the tool panel with grid parameter.
   * Initialises the tool panel with grid parameters.
   * @param params this is used to get the Grid API
   */
  public agInit(params: any): void {
    this.assetTypeService.assetTypes$.subscribe(types => {
      this.assetTypes = types;

      super.agInit(params);
      this.params = params;
    });
  }

  /**
   * Method is called after Ag-grid to initialise the tool panel with grid parameter.
   * It sets up subscriptions to handle changes in datagrid visualizations.
   */
  protected afterAgInit(): void {
    // Subscription to handle deletion of datagrid visualizations.
    this.datagridVisualisationService.deleteDatagridVisualisation$
      .pipe(takeUntil(this.destroy$))
      .subscribe(assetTypeId => {
        this.visualisationForm.controls.currentVisualisation.setValue(
          this.datagridVisualisationService.datagridVisualisations.find(visualisation =>
            visualisation.isDefault && assetTypeId === visualisation.assetTypeId
          )
        );
      });

    // Subscription to handle application of datagrid visualizations.
    this.datagridVisualisationService.applyDatagridVisualisation$
      .pipe(takeUntil(this.destroy$))
      .subscribe(datagridVisualisation => {
        this.visualisationForm.controls.currentVisualisation.setValue(datagridVisualisation);
        const currentAssetType = this.assetTypes.firstOrNull(assetType =>
          assetType.id === datagridVisualisation.assetTypeId) || AssetType.NullAssetType;
          this.visualisationForm.controls.currentAssetType.setValue(currentAssetType);
      });
  }

  /**
   * Open a dialog to create a visualisation.
   * Create for a specific asset type if a filter is set
   */
  public createVisualisation(): void {
    const assetTypeId = this.visualisationForm.controls.currentAssetType.value?.id;
    this.datagridVisualisationService.openCreateVisualisationDialog(assetTypeId);
  }

  /**
   * Update the current visualisation.
   * Update for a specific asset type if a filter is set
   */
  public updateVisualisation(): void {
    const currentVisualisation = this.visualisationForm.controls.currentVisualisation.value;
    const assetTypeId = this.visualisationForm.controls.currentAssetType.value?.id;
    this.datagridVisualisationService.updateDatagridVisualisation(
      currentVisualisation,
      this.gridStateService.gridState,
      assetTypeId
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Handles when all the visualisation data are loaded.
   * @param currentDatagridVisualisation - The current datagrid visualisation.
   * @param defaultDatagridVisualisation - The default datagrid visualisation.
   */
  public onDataLoaded(currentDatagridVisualisation: DatagridVisualisation,
                      defaultDatagridVisualisation: DatagridVisualisation): void {
    // Skip visualisation initialisation if config is missing
    if (!currentDatagridVisualisation?.config || !defaultDatagridVisualisation?.config) {
      console.error('Datagrid visualisation config is missing');
      return;
    }

    super.onDataLoaded(currentDatagridVisualisation, defaultDatagridVisualisation);

    const currentAssetType = this.assetTypes.firstOrNull(assetType => assetType.id === currentDatagridVisualisation.assetTypeId) || AssetType.NullAssetType;

    this.assetTypeVisualisations$ = this.visualisationForm.controls.currentAssetType.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        mergeWith(
          this.datagridVisualisationService.deleteDatagridVisualisation$.pipe(
            map((assetTypeId) => {
              return this.assetTypes.firstOrNull(assetType => assetType.id === assetTypeId) || AssetType.NullAssetType;
            })
          )),
        startWith(currentAssetType ?? AssetType.NullAssetType),
        map((selectedAssetType: AssetType) => {
          // Get all visualisations for selected asset type
          const assetTypeVisualisations = this.datagridVisualisationService.datagridVisualisations
            .filter(visualisation => {
              return (!visualisation.assetTypeId && selectedAssetType === AssetType.NullAssetType) ||
                (selectedAssetType instanceof AssetType && visualisation.assetTypeId === selectedAssetType.id);
            });

          if (selectedAssetType instanceof AssetType && !assetTypeVisualisations.firstOrNull(visualisation => visualisation.isDefault)) {
            // If this asset type does not have a default visualisation, create it by copying the global default
            const defaultVisualisation = <DatagridVisualisation>{
              ...defaultDatagridVisualisation.copy(),
              name: [this.translate.instant('LABEL.DEFAULT'), selectedAssetType.name].join(' '),
              assetTypeId: selectedAssetType.id
            };

            // Set filter on asset type column
            defaultVisualisation.config.filterModel = {};
            defaultVisualisation.config.filterModel[this.params.assetTypeNameColId] = {
              values: [selectedAssetType.name],
              filterType: 'set'
            };

            // Push the new default visualisation
            this.datagridVisualisationService.datagridVisualisations.push(defaultVisualisation);
            assetTypeVisualisations.push(defaultVisualisation);
          }

          return assetTypeVisualisations;
        }),
        tap(visualisations => {
          // Set the current visualisation to the first default visualisation if the current is not related to the asset type.
          const selectedVisualisation = this.visualisationForm.controls.currentVisualisation.value;
          const selectedAssetType = this.visualisationForm.controls.currentAssetType.value;

          if (selectedVisualisation.assetTypeId == selectedAssetType?.id) return;

          const defaultVisualisation = visualisations.find(visualisation => visualisation.isDefault);
          this.visualisationForm.controls.currentVisualisation.setValue(defaultVisualisation);
        })
      );

    this.visualisationForm.controls.currentAssetType.setValue(currentAssetType);
  }

  public refresh(): void {
    // Called when the user clicks the clear all button
    this.visualisationForm.controls.currentAssetType.setValue(AssetType.NullAssetType);
  }
}
