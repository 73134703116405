import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ASYNC_PRECONDITIONS_INJECTION,
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  MatSelectAutocompleteComponent
} from '@app/shared/components/mat-select-autocomplete/mat-select-autocomplete.component';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'asset-field-builder',
  templateUrl: './asset-field-builder.component.html',
  styleUrls: ['./asset-field-builder.component.scss']
})
export class AssetFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  @ViewChild('inputField') public inputField: MatSelectAutocompleteComponent<RelatedAsset>;

  public Asset = Asset;
  public assetInitValue: RelatedAsset;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
              @Inject(FIELD_ASYNC_PRECONDITIONS_INJECTION) public asyncPreconditionsForEdition$: Observable<boolean>,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              protected assetsService: AssetsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }


  /**
   * Initialize form, fetch accessible Assets then set the field's initial value based on the current entity's
   * RelatedAsset
   */
  public ngOnInit(): void {
    this.setAssetInitValue();

    // Init form
    this.form = new UntypedFormGroup({
      asset: new UntypedFormControl(this.assetInitValue, this.computeValidators(), this.computeAsyncValidators())
    });

    // Init value with data from the entity while Assets are loading
    this.setFieldValue(this.assetInitValue ?? null);
    this.setFieldInitialValue(this.assetInitValue ?? null);
    this.getNextState();
  }

  /**
   * Set the init value of the asset according to the init value of the field.
   */
  protected setAssetInitValue(): void {
    if (this.assetInitValue || !this.fieldInitValue) return;
    // Get the first asset from the list (should be the only one)
    this.assetInitValue = new RelatedAsset(this.fieldInitValue.firstItem());
  }

  /**
   * Setup hook for other fields to know when a new Asset is selected.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();
    this.form.get('asset')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(50),
        takeUntil(this.destroy$)
      )
      .subscribe((value: RelatedAsset | null) => {
        this.setFieldValue(value ?? null);
      });
    this.asyncPreconditionsForEdition$
      .subscribe(valid => {
        this.preconditionsForEdition = this.preconditionsForEdition && valid;
      });
  }

  public getFieldValue(): any {
    return new RelatedAsset(super.getFieldValue()).toString();
  }

  /**
   * Cancel edition and restore initial related Asset value.
   */
  public cancel(): void {
    super.cancel();
    this.form.get('asset').setValue(this.assetInitValue ?? null);
  }

  /**
   * Navigate to the Dashboard of the related Asset
   */
  public async navigateToAssetDashboard(): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.form.get('asset').value.id
    );
    await this.assetsService.navigateToAssetSheet(this.form.get('asset').value.id, 'dashboard');
  }


  /**
   * Set focus on the element
   */
  public focus(): void {
    if (this.focusable) {
      setTimeout(() => {
        this.inputField.onFocusIn();
      });
    }
  }
}
