import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'restricted-mini-fab-button',
  templateUrl: './restricted-mini-fab-button.component.html',
  styleUrls: ['./restricted-mini-fab-button.component.scss']
})
export class RestrictedMiniFabButtonComponent {

  /*
  Optional colour parameter
   */
  @Input() public colour: ThemePalette = undefined;
  /*
  To link with the underlying button's click event
   */
  @Output() public onClick = new EventEmitter<any>();

  /*
  Array of permission codes found in permissions.enum.ts
  to display the button
   */
  @Input() public permissions: any[] = [];
  /*
  Boolean expression of preconditions to enabling the button
   */
  @Input() public preconditions: boolean = true;

  /*
  Boolean expression of preconditions to disable the button
   */
  @Input() public disabled: boolean = false;

  @Input() public class: string = '';

  /*
  An id, use in e2e test for select the button
   */
  @Input() public id: string = '';

  @ViewChild('iconButton') public iconButton: ElementRef;

  /**
   *  Gets called when the user click on the button.
   *  @param event that occur due to the user interacting with a pointing device.
   */
  public click(event: MouseEvent): void {
    if (this.preconditions || !this.disabled) {
      this.onClick.emit(event);
    }
  }

}
