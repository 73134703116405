import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

export interface MatDayJsDateAdapterOptions {

  /**
   * When enabled, the dates have to match the format exactly.
   * See https://momentjs.com/guides/#/parsing/strict-mode/.
   */
  strict?: boolean;

  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
  useUtc?: boolean;
}

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MAT_DAYJS_DATE_ADAPTER_OPTIONS = new InjectionToken<MatDayJsDateAdapterOptions>(
  'MAT_DAYJS_DATE_ADAPTER_OPTIONS', {
    providedIn: 'root',
    factory: MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY
  });

export function MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY(): MatDayJsDateAdapterOptions {
  return {
    useUtc: false
  };
}

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class CustomDateAdapter extends DateAdapter<dayjs.Dayjs> {

  private _localeData: {
    firstDayOfWeek: number,
    longMonths: string[],
    shortMonths: string[],
    dates: string[],
    longDaysOfWeek: string[],
    shortDaysOfWeek: string[],
    narrowDaysOfWeek: string[]
  };

  private readonly DEFAULT_MONTH_NAMES;
  private readonly DEFAULT_DAY_OF_WEEK_NAMES;

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
              @Optional() @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS) private _options?: MatDayJsDateAdapterOptions) {

    super();
    this.locale = dateLocale;
    this._localeData = this.initializeParser(dateLocale);

    this.DEFAULT_DAY_OF_WEEK_NAMES = {
      'long': this._localeData.longDaysOfWeek,
      'short': this._localeData.shortDaysOfWeek,
      'narrow': this._localeData.narrowDaysOfWeek
    };

    this.DEFAULT_MONTH_NAMES = {
      'long': this._localeData.longMonths,
      'short': this._localeData.shortMonths,
      'narrow': this._localeData.shortMonths
    };
  }

  public setLocale(locale: string): any {
    super.setLocale(locale);

    this.locale = locale;
    const dayjsLocaleData = this._createDayJs().locale(locale).localeData();

    this._localeData = {
      firstDayOfWeek: dayjsLocaleData.firstDayOfWeek(),
      longMonths: dayjsLocaleData.months(),
      shortMonths: dayjsLocaleData.monthsShort(),
      dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: dayjsLocaleData.weekdays(),
      shortDaysOfWeek: dayjsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayjsLocaleData.weekdaysMin()
    };

    return this._localeData;
  }

  public addCalendarDays(date: dayjs.Dayjs, days: number): dayjs.Dayjs {
    return date.locale(this.locale).add(days, 'day');
  }

  public addCalendarMonths(date: dayjs.Dayjs, months: number): dayjs.Dayjs {
    return date.locale(this.locale).add(months, 'month');
  }

  public addCalendarYears(date: dayjs.Dayjs, years: number): dayjs.Dayjs {
    return date.locale(this.locale).add(years, 'year');
  }

  public clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.clone().locale(this.locale);
  }

  public createDate(year: number, month: number, date: number): dayjs.Dayjs {
    // dayjs will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    if (date > 31) {
      throw Error(`Invalid date "${date}". Date has to be less than 32.`);
    }
    const result = this._createDayJs([year, month, date], void 0, void 0, true)
      .startOf('day')
      .locale(this.locale);
    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }

  public format(date: dayjs.Dayjs, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('DayJsDateAdapter: Cannot format invalid date.');
    }
    return date.locale(this.locale).format(displayFormat);
  }

  public getDate(date: dayjs.Dayjs): number {
    return this._createDayJs(date).get('date');
  }

  public getDateNames(): string[] {
    return this._localeData.dates;
  }

  public getDayOfWeek(date: dayjs.Dayjs): number {
    return this._createDayJs(date).day();
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this.DEFAULT_DAY_OF_WEEK_NAMES[style];
  }

  public getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek;
  }

  public getMonth(date: dayjs.Dayjs): number {
    return this._createDayJs(date).month();
  }

  public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this.DEFAULT_MONTH_NAMES[style];
  }

  public getNumDaysInMonth(date: dayjs.Dayjs): number {
    return this._createDayJs(date).daysInMonth();
  }

  public getYear(date: dayjs.Dayjs): number {
    return this._createDayJs(date).year();
  }

  public getYearName(date: dayjs.Dayjs): string {
    return this._createDayJs(date).format('YYYY');
  }

  public invalid(): dayjs.Dayjs {
    return dayjs(null);
  }

  public isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  public isValid(date: dayjs.Dayjs): boolean {
    return date.isValid();
  }

  public parse(value: any, parseFormat: any): dayjs.Dayjs | null {
    if (value && typeof value == 'string') {
      return this._createDayJs(value, parseFormat, this.locale);
    }
    return value ? this._createDayJs(value, void 0, this.locale) : null;
  }

  public deserialize(value: any): dayjs.Dayjs | null {
    let date;
    if (value instanceof Date) {
      date = this._createDayJs(value);
    } else if (this.isDateInstance(value)) {
      // Note: assumes that cloning also sets the correct locale.
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = value.includes('T')
        ? this._createDayJs(value)
        : this._createDayJs(value, void 0, void 0, true);
    }
    if (date && this.isValid(date)) {
      return this._createDayJs(date).locale(this.locale);
    }
    return super.deserialize(value);
  }

  public toIso8601(date: dayjs.Dayjs): string {
    return date.locale(this.locale).toISOString();
  }

  public today(): dayjs.Dayjs {
    return this._createDayJs().locale(this.locale);
  }

  private _createDayJs(
    date?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    locale?: string,
    keepLocalTime?: boolean
  ): dayjs.Dayjs {
    const {strict, useUtc}: MatDayJsDateAdapterOptions = this._options || {};

    const result = date instanceof Date || typeof date === 'number' || !format
      ? dayjs(date, void 0, locale, strict)
      : dayjs(date, format, locale, strict);

    return useUtc ? result.utc(keepLocalTime) : result;
  }

  private initializeParser(dateLocale): any {
    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    dayjs.extend(weekday);
    dayjs.extend(customParseFormat);
    dayjs.extend(localeData);
    dayjs.extend(arraySupport);
    return this.setLocale(dateLocale);
  }
}
