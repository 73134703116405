import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { Asset } from '@app/core/model/entities/asset/asset';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import {
  ProjectCreateModalComponent
} from '@app/features/main/views/projects/projects-inventory/modals/project-create-modal/project-create-modal.component';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './asset-project-create-modal.component.html'
})
export class AssetProjectCreateModalComponent extends ProjectCreateModalComponent {

  private readonly asset: Asset;

  constructor(appConfig: AppConfig,
              fb: UntypedFormBuilder,
              assetsService: AssetsService,
              columnBuilder: ColumnBuilder,
              translate: TranslateService,
              projectsService: ProjectsService,
              @Inject(MAT_DIALOG_DATA) data: { asset: Asset }) {
    super(appConfig, fb, assetsService, columnBuilder, translate);
    this.asset = data.asset;
  }

  /**
   * Set current Asset value as project's related asset.
   */
  public ngOnInit(): void {
    this.projectForm.get('selectedAssetIds').setValue([this.asset.id]);
  }
}
