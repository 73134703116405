import { Component, inject } from '@angular/core';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Contract, ContractInput } from '@app/core/model/entities/asset/contract';
import { Document } from '@app/core/model/entities/document/document';
import { ContractsService } from '@app/features/main/views/organization-contracts/contracts.service';
import { ContractModalService } from '@app/features/main/views/organization-contracts/modal/contract-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { AbstractSidebar } from '@app/shared/components/side-panel/abstract-sidebar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'contract-sidebar',
  templateUrl: './contract-sidebar.component.html',
  providers: [ContractModalService, DocumentModalService]
})
export class ContractSidebarComponent extends AbstractSidebar<Contract> {

  public permissionsForEdition: string[] = [PermissionEnum.EDIT_CONTRACT];

  // Injection
  protected translate: TranslateService = inject(TranslateService);
  private contractsService: ContractsService = inject(ContractsService);
  private contractModalService: ContractModalService = inject(ContractModalService);

  constructor() {
    super();

    this.entitiesUpdated$ = this.contractsService.contractUpdated$;
    this.sidePanelToggle$ = this.contractsService.sidePanelToggle$
      .pipe(switchMap(contractData => {
        return this.fieldService.getField('provider', EntityTypeEnum.CONTRACT)
          .pipe(
            map(field => {
              this.validators = field.validators;
              return contractData;
            })
          );
      }));
    this.afterLoadRequests.push(this.loadContractDocuments.bind(this));
  }

  /**
   * Loads the Contract Documents.
   * @param contract Contract to load the Documents for.
   * @return Observable<Document[]>.
   */
  private loadContractDocuments(contract: Contract): Observable<Document[]> {
    return this.contractsService.loadContractDocuments(contract.id)
      .pipe(
        takeUntil(this.destroy$),
        tap(documents => this.entity.documents = documents)
      );
  }

  /**
   * API call to update the Contract.
   * @param data The update input.
   * @returns The updated Contract.
   */
  protected override updateEntity(data: Record<string, any>): Observable<Contract> {
      Contract.transformPropertiesForInput(data);
      return this.contractsService.updateContract(this.entity, data as ContractInput)
        .pipe(
          tap(updatedContract => {
            updatedContract.documents = this.entity.documents;
            this.entity = updatedContract;
          })
        );
  }

  /**
   * Close the side panel.
   */
  public override closeSidePanel(): void {
    this.contractsService.closeContractSidePanel();
  }

  /**
   * Open a dialog for the User to renew the Contract.
   */
  public renewContract(): void {
    this.analyticsService.trackNavigationEvent(
      this.eventsOrigin,
      NavigateToEnum.RENEW_DIALOG,
      EntityTypeEnum.CONTRACT,
      this.entity.id
    );
    this.contractModalService.openRenewContractDialog(this.entity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CONTRACT_RENEWED')));
  }

  /**
   * Open a confirmation dialog prior to deleting the Contract.
   */
  public deleteContract(): void {
    this.analyticsService.trackNavigationEvent(
      this.eventsOrigin,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.CONTRACT,
      this.entity.id
    );
    this.contractModalService.openDeleteContractsDialog([this.entity])
      .pipe(
        takeUntil(this.destroy$),
        filter(success => success)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CONTRACT_DELETED')));
  }

  /**
   * Display a dialog for the user to upload Documents linked to a Contract.
   */
  public uploadContractDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.CONTRACT,
      this.entity.id
    );
    this.contractModalService.openUploadContractDocumentsDialog(this.entity.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((documents) => {
        this.entity.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Display a confirmation dialog before deleting a Contract's Document.
   * @param document Document to delete.
   */
  public deleteContractDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      this.eventsOrigin,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.contractModalService.openDeleteContractDocumentDialog(document)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documentIsDeleted => {
        if (documentIsDeleted) {
          this.entity.documents = this.entity.documents.filter(doc => doc.id !== document.id);
        }
      });
  }

  /**
   * Navigate to the Contract's related Asset sheet's contracts tab.
   */
  public async navigateToAssetSheet(): Promise<void> {
    this.closeSidePanel();
    this.analyticsService.trackNavigationEvent(
      this.eventsOrigin,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity.assetId
    );
    await this.contractsService.navigateToAssetSheet(this.entity.assetId);
  }
}
