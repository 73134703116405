<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <input #inputText [formControl]="formControl" matInput class="mat-input-element ag-input-field-input" type="text"
    (focusout)="onFocusOut()"
    (focusin)="fullRowEdit && inputText.select()"
    [matAutocomplete]="auto"/> @if (suffix) {
    <span>{{suffix}}</span>
  }
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected()">
    @for (fieldValue of filteredSuggestions | async; track fieldValue) {
      <mat-option
      [value]="fieldValue">{{ fieldValue }}</mat-option>
    }
  </mat-autocomplete>

  @if (control.invalid) {
    <div>
      <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
    </div>
  }
  @if (control.pending) {
    <div>
      <i class="mdi mdi-loading mdi-spin loading tooltip-icon"></i>
    </div>
  }
  @if (!fullRowEdit) {
    <div>
      <i class="mdi mdi-check" (click)="stopEditing()"></i>
    </div>
  }
</div>
