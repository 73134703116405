import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052032}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TBMAESTRO}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{3T}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{3T}_need_to_be_licensed___{3T}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_February_2025}____[v3]_[01]_MTczOTQwNDgwMDAwMA==17ca07187b553f7e405f2da722920a32');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
