import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MyProfileComponent } from '@app/features/main/views/my-pages/my-profile/my-profile.component';
import { MyReportComponent } from '@app/features/main/views/my-pages/my-report/my-report.component';
import { SharedModule } from '@app/shared/shared.module';

const components = [
  MyProfileComponent,
  MyReportComponent
];

@NgModule({
  imports: [
    SharedModule,
    NgOptimizedImage
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class MyPagesModule {
}
