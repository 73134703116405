export enum DocumentTypeEditPermissionEnum {
  ASSET_PICTURE = 'VIEW_ASSET',
  ASSET_DOCUMENT = 'VIEW_ASSET',
  CHECK_DOCUMENT = 'EDIT_CHECK',
  LEASE_DOCUMENT = 'EDIT_OCCUPANT',
  CONTRACT_DOCUMENT = 'EDIT_CONTRACT',
  SPACE_DOCUMENT = 'EDIT_SPACE',
  WORK_PICTURE = 'EDIT_WORK',
  WORK_DOCUMENT = 'EDIT_WORK',
  EQUIPMENT_PICTURE = 'EDIT_EQUIPMENT',
  EQUIPMENT_DOCUMENT = 'EDIT_EQUIPMENT',
  PROJECT_DOCUMENT = 'EDIT_PROJECT',
}
