import { Component } from '@angular/core';
import {
  TextFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';

@Component({
  selector: 'link-field-builder',
  templateUrl: './link-field-builder.component.html',
  styleUrls: ['./link-field-builder.component.scss']
})
export class LinkFieldBuilderComponent extends TextFieldBuilderComponent {

  /**
   * Open the link in a new tab
   */
  public openInNewTab(): void {
    window.open(this.getFieldValue(), '_blank', 'noopener,noreferrer');
  }

  /**
   * Open the link in a new tab and stop the event propagation to not enter in edition mode.
   * @param event
   */
  public onLinkClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
  }
}
