import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { IRelatedAsset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import { getValue, hasProperty, setValue } from '@app/shared/extra/utils';
import { Expose, Type } from 'class-transformer';

export class Project extends Entity {
  public entityType = EntityTypeEnum.PROJECT;

  public identifier: number;
  public organizationId: string;
  public dataDate: string;

  @Expose({name: 'assetIdsList'})
  public assetIds: string[];

  @Type(() => Document) public documents: Document[] = [];

  @Expose({name: 'projectIdsList'})
  public projectIds: string[];

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this.identifier} - ${this.name}`;
  }

  // Properties to be renamed
  protected static inputMapping = {
    assets: {input: ['computedProperties', 'assets'], output: 'assetIds'},
    projects: {input: ['computedProperties', 'projects'], output: 'projectIds'}
  };

  /**
   * Get all related assets inside the Project computed properties.
   * @return List of related assets
   */
  public get relatedAssets(): RelatedAsset[] {
    return this.computedProperties?.['assets']
        ?.map((asset: IRelatedAsset) => new RelatedAsset(asset))
      ?? [];
  }

  /**
   * Transforms this Project to a RelatedProject.
   */
  public toRelatedProject(): RelatedProject {
    return new RelatedProject({
      id: this.id,
      name: this.name,
      identifier: this.identifier,
      displayName: this.computedProperties?.['displayName']
    });
  }

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    // Transform input, mapping keys as defined in the inputMapping above
    for (const inputMappingElement in Project.inputMapping) {
      if (hasProperty(obj, Project.inputMapping[inputMappingElement].input)) {
        const source = getValue(obj, Project.inputMapping[inputMappingElement].input);
        setValue(obj, [Project.inputMapping[inputMappingElement].output], source);

      }
    }

    // Clean up by removing older keys
    for (const inputMappingElement in Project.inputMapping) {
      delete obj[Project.inputMapping[inputMappingElement].input.firstItem()];
    }
  }
}

export type IRelatedProject = IRelatedEntity;

export class RelatedProject {
  public id: string;
  public displayName: string;

  constructor(relatedProject: IRelatedProject) {
    this.id = relatedProject.id;
    this.displayName = relatedProject.displayName
      ?? `${relatedProject.identifier ?? '?'} - ${relatedProject.name ?? '?'}`;
  }

  @Expose()
  public toString(): string {
    return this.displayName;
  }
}

export interface ProjectInput {
  name?: string;
  assetIds?: string[];
  projectIds?: string[];
  dataDate?: string;
}
