export enum AnalyticsKeyEnum {
  ENTITY_TYPE = 'entityType',
  ENTITY_ACTION = 'entityAction',
  MODULE = 'module',
  DIALOG_ACTION = 'dialogAction',
  INLINE_ACTION = 'inlineAction',
  DOCUMENT_TYPE = 'documentType',
  ENTITY_ID = 'entityId',
  EVENT_ORIGIN = 'eventOrigin',
  CURRENT_PAGE = 'currentPage',
  NAVIGATE_TO = 'navigateTo',
  FIELD = 'field',
  GRID_ACTION_TYPE = 'gridActionType',
  REPORT_ID = 'reportId',
  REPORT_TYPE = 'reportType'
}
