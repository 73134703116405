import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Dayjs } from 'dayjs';

import { afterDate } from './validator';

const AFTER_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AfterDateValidator),
  multi: true
};

@Directive({
  selector: '[afterDateValidator][formControlName],[afterDateValidator][formControl],[afterDateValidator][ngModel]',
  providers: [AFTER_DATE_VALIDATOR]
})
export class AfterDateValidator implements Validator, OnInit, OnChanges {
  @Input() startDate: Dayjs;
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = afterDate(this.startDate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'startDate' && Object.prototype.hasOwnProperty.call(changes, key)) {
        this.validator = afterDate(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
