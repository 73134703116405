import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Dayjs } from 'dayjs';

import { beforeDate } from './validator';

const BEFORE_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => BeforeDateValidator),
  multi: true
};

@Directive({
  selector: '[beforeDateValidator][formControlName],[beforeDateValidator][formControl],[beforeDateValidator][ngModel]',
  providers: [BEFORE_DATE_VALIDATOR]
})
export class BeforeDateValidator implements Validator, OnInit, OnChanges {
  @Input() startDate: Dayjs;
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = beforeDate(this.startDate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'startDate' && Object.prototype.hasOwnProperty.call(changes, key)) {
        this.validator = beforeDate(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
