<div class="new-feature-container fx-layout-row">
  <div class="new-feature-tilted-box fx-layout-col fx-justify-end fx-align-center">
    {{ 'LABEL.NEW_FEATURE'| translate }}</div>
  <div class="fx-layout-col">
    <div class="fx-layout-row new-feature-message-container">
      <div class="new-feature-message"> {{message}} </div>
    </div>
  </div>
  <div class="fx-layout-col">
    <button (click)="dismiss()" class="close-button">
      <i class="mdi mat-icon mdi-close"></i>
    </button>
    <button (click)="reloadPage()" class="reload-button" [matTooltip]="'BUTTON.LOAD_NEW_VERSION' | translate" matTooltipPosition="below">
      <i class="mdi mat-icon mdi-reload"></i>
    </button>
  </div>
</div>
