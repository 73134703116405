import { Component } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AggregationFunctionEnum } from '@app/core/enums/aggregation-function.enum';
import { AgRendererComponent } from 'ag-grid-angular';
import { IAggFunc, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'aggregation-grid-component',
  template: `
    <span>
      <span *ngIf="displayPrefix" #tooltip="matTooltip"
            [matTooltipPosition]="'below'"
            (click)="onClickTooltip(tooltip)"
            [matTooltip]="tooltipValue | translate">
        {{ tooltipSymbol }}
      </span>
      <span [ngStyle]="styles">{{ cellValue }}</span>
    </span>
  `
})

export class AggregationGridComponent implements AgRendererComponent {
  private touchScreenQuery: MediaQueryList;
  public cellValue: string;
  public displayPrefix = false;
  public tooltipValue = '';
  public tooltipSymbol = '';
  public styles: { [p: string]: boolean };

  // gets called once before the renderer is used
  public agInit(params: ICellRendererParams & { styles: { [p: string]: boolean } }): void {
    this.setPrefix(params.column.getAggFunc());

    this.displayPrefix = params.node.group && !!params.valueFormatted;
    this.cellValue = params.valueFormatted;
    this.styles = params.styles;
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.displayPrefix = params.node.group && !!params.valueFormatted;
    this.cellValue = params.valueFormatted;
    return false;
  }

  /**
   * On touch screen devices only, toggle the tooltip after pressing the icon.
   * @param tooltip MatTooltip to toggle.
   */
  public onClickTooltip(tooltip: MatTooltip): void {
    if (this.touchScreenQuery.matches) {
      tooltip.toggle();
    }
  }

  public setPrefix(aggFunc: string | IAggFunc): void {
    switch (aggFunc) {
      case AggregationFunctionEnum.SUM:
        this.tooltipSymbol = 'Σ';
        this.tooltipValue = 'TOOLTIP.SUM';
        break;
      case AggregationFunctionEnum.AVERAGE:
        this.tooltipSymbol = 'μ';
        this.tooltipValue = 'TOOLTIP.AVERAGE';
        break;
      default:
        break;
    }
  }
}
