<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  <ng-container *ngIf="equipment">
    <div class="equipment-name-block page-title">
      <div>{{ equipment?.toString() }}</div>
    </div>
  </ng-container>

  <div class="fx-flex"></div>
  <div id="equipment-menu" class="fx-flex-48">
    <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'equipment-sheet-menu-button'"
                            [matMenuTriggerFor]="menu"
                            [preconditions]="accessManager.hasAccess(Permission.VIEW_ASSET) || accessManager.hasAccess(Permission.DELETE_EQUIPMENT)"></restricted-icon-button>
    <mat-menu #menu="matMenu">
      <restricted-menu-item (onClick)="deleteEquipment()" [id]="'delete-equipment-menu'"
                            [permissions]="[Permission.DELETE_EQUIPMENT]"
                            [preconditions]="true">
        {{ 'BUTTON.DELETE_EQUIPMENT' | translate }}
      </restricted-menu-item>
      <restricted-menu-item (onClick)="showAssetSheet()" [id]="'show_asset_sheet_menu'"
                            [permissions]="[Permission.VIEW_ASSET]" [preconditions]="true">
        {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }}
      </restricted-menu-item>
    </mat-menu>
  </div>
</div>
