import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { thenReturn } from '@app/shared/extra/utils';
import { authGuardFn } from '@auth0/auth0-angular';
import { AccessManager } from '@services/managers/access.manager';
import { Observable, of, switchMap } from 'rxjs';

/**
 * Perform OAuth 2.0 authentication through redirection then fetch the authentication user's information from the API.
 * If the user is authenticated and their information could successfully be fetched, the route can be activated.
 * @param route Activated route.
 * @param state Router state.
 * @return An Observable that will emit true once the user is authenticated or false otherwise.
 */
export default function authenticationGuard(route: ActivatedRouteSnapshot,
                                            state: RouterStateSnapshot): Observable<boolean> {
  const accessManager = inject(AccessManager);
  return authGuardFn(route, state)
    .pipe(
      switchMap(canActivate => {
        if (!canActivate) return of(canActivate);
        if (accessManager.currentUser) return of(true);
        return accessManager.loadCurrentUser()
          .pipe(thenReturn(true));
      })
    );
}
