<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.RENEW_CHECK' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto"
                      [formGroup]="checkCharacteristicsForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LABEL.CHECK_TYPE' | translate }}</mat-label>
      <input matInput
             formControlName="type"
             [required]="true"
             [value]="checkCharacteristicsForm.get('type').value"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LABEL.SPACE' | translate }}</mat-label>
      <input matInput
             formControlName="space"
             [required]="true"
             [value]="checkToRenewSpaces.toString()"/>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [id]="'check-renew-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [id]="'check-renew-save-button'" color="accent" mat-raised-button matDialogClose="yes">
      {{ 'BUTTON.RENEW' | translate }}
    </button>
  </mat-dialog-actions>
</div>
