import { Component, OnDestroy } from '@angular/core';
import { ModuleGridFooterService } from '@app/shared/components/module-grid/module-grid-footer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'module-grid-footer',
  template: `
    <div>
      {{ 'MODULE.' + module.moduleName | translate }}
      <span [ngClass]="{'valid': valid, 'invalid': !valid}">({{ module.usedCount }} / {{ module.totalCount }})</span>
    </div>
  `,
  styles: [`
    div {
      font-weight: bold;
    }
  `]

})
export class ModuleGridFooterComponent implements ICellRendererAngularComp, OnDestroy {
  public module: any; //
  public index: number; // The index of the column from which to get the module

  private destroy$ = new Subject<void>();

  constructor(private moduleService: ModuleGridFooterService) {
  }

  /**
   * Get the validity of the number of selected users by module
   */
  public get valid(): boolean {
    return this.module.usedCount <= this.module.totalCount;
  }

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: any): void {
    // Passed by pinnedRowCellRendererParams
    this.index = params.index;
    // Passed by the pinned row data
    this.module = params.data[this.index];

    // Listens to changes in selection and shows the result
    this.moduleService.footerMapSubject.pipe(takeUntil(this.destroy$)).subscribe((map) => {
      this.module.usedCount = map.get(this.module.moduleName).count;
    });
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param params
   * @returns {boolean}
   */
  public refresh(params: any): boolean {
    return false;
  }
}
