import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Space } from '@app/core/model/entities/asset/space';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

export const assetValueGteSpaceChildrenProperty = (spacesService: SpacesService, field: string, assetUpdated: Asset): AsyncValidatorFn => {

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let spaceArea: number;
    let spaceToUpdate: Space;

    if (control.pristine) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        spaceArea = value as number;

        if (!spaceArea) {
          return of(null);
        }

        return spacesService.loadAssetRootSpace(assetUpdated.id);
      }),
      switchMap((globalSpace: Space) => {
        spaceToUpdate = globalSpace;

        if (!spaceToUpdate) {
          return of(null);
        }

        return spacesService.loadChildrenSpaces(spaceToUpdate.id);
      }),
      map((spaces: Space[]) => {
        if (spaces?.length) {
          let childrenArea = spaces
            .filter(space => {
              return space.id !== spaceToUpdate.id
                && space.properties[field]
                && (spaceToUpdate.parentPath.length + 1) === space.parentPath.length;
            })
            .map(space => +space.properties[field])
            .reduce((prev, acc) => prev + acc, 0);

          if (childrenArea > spaceArea) {
            return <ValidationErrors>{assetValueGteSpaceChildrenProperty: true};
          }
        }
        return null;
      }),
      first(),
      tap(() => control.markAsTouched())
    );
  };
};
