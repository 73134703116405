<div class="ag-large-text-input ag-labeled ag-label-align-left ag-input-field">
  <div class="ag-wrapper ag-input-wrapper">
    <mat-chip-grid #chipList class="chip-list">
      <mat-chip-row class="chip fx-layout-row fx-justify-space-between fx-align-center" (removed)="remove(chip)"
                *ngFor="let chip of chips; let i = index" [removable]="true">
        <span class="chip-content"> {{ getFieldValue(chip) }} </span>
        <button matChipRemove>
          <i class="mdi mdi-close-circle"></i>
        </button>
      </mat-chip-row>
      <input [formControl]="formControl" matInput class="ag-input-field-input" type="text" [matAutocomplete]="auto"
             [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             (focusout)="onFocusOut($event)" (keyup.enter)="onEnter($event)">
    </mat-chip-grid>

    <mat-autocomplete [panelWidth]="'190px'" #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let fieldValue of filteredSuggestions | async"
                  [value]="fieldValue">{{ getFieldValue(fieldValue) }}</mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
  <div>
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>

