import { inject, Injectable } from '@angular/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { environment } from '@env/environment';
import { GeneralService } from '@services/general.service';
import { defer, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  private generalService = inject(GeneralService);

  /**
   * Generate and download the import template.
   * @param organizationId the organization ID
   * @param entityType the entity type
   * @param locale the locale to use
   * @param importConfigurationKey key of configuration related to import
   * @param isExport whether the template is filled with data
   */
  public downloadImportTemplate(
    organizationId: string,
    entityType: EntityTypeEnum,
    locale: string,
    importConfigurationKey: string = null,
    isExport = false
  ): Observable<any> {

    let url = environment.backend.baseUrl + '/import/template'
      + `?organizationId=${organizationId}`
      + `&locale=${locale}`
      + `&entityType=${entityType}`
      + `&isExport=${isExport}`;

    if (importConfigurationKey != null) {
      url += `&importConfigurationKey=${importConfigurationKey}`;
    }

    // Transform Promise in Observable
    return defer(() => from(this.generalService.httpGetAsBlob(url))).pipe(
      tap((httpResponseBlob) => {
        const objectUrl = URL.createObjectURL(httpResponseBlob.body);
        const filename = httpResponseBlob.headers.get('Content-Disposition')
          .split(';')
          .find(n => n.includes('filename='))
          .replace(/"/g, '')
          .replace('filename=', '')
          .trim();
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = objectUrl;
        anchor.click();
        URL.revokeObjectURL(objectUrl);
      })
    );
  }
}
