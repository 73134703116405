import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular/lib/interfaces';
import { ITooltipParams } from 'ag-grid-enterprise';

@Component({
  selector: 'material-tooltip',
  templateUrl: './material-tooltip.component.html',
  styleUrls: ['./material-tooltip.component.scss'],
})
export class MaterialTooltipComponent implements ITooltipAngularComp {

  public tooltip: string;
  public params: any;

  public agInit(params: ITooltipParams): void {
    this.params = params;
    this.tooltip = this.params.valueFormatted;
  }



}
