import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.scss']
})
export class LinkCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
  public link: string;
  public ready: boolean;

  // gets called once before the renderer is used
  public agInit(params: ICellRendererParams): void {
    this.link = this.getValueToDisplay(params);
  }

  public ngAfterViewInit(): void {
    // Delay the displayed of the icon to avoid tooltip malfunction
    this.ready = true;
  }

  // gets called whenever the user gets the cell to refresh
  public refresh(params: ICellRendererParams): boolean {
    // set the tooltip value
    this.link = this.getValueToDisplay(params);
    return true;
  }

  public getValueToDisplay(params: ICellRendererParams): string {
    return params.value;
  }
}
