import { inject, Injectable } from '@angular/core';
import { ModuleCard } from '@app/core/enums/modules.enum';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Entity } from '@app/core/model/entities/entity';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import { Organization } from '@app/core/model/entities/organization/organization';
import { Project } from '@app/core/model/entities/project/project';
import { getLocale } from '@app/shared/extra/utils';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ignoreElements, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class AppManager {
  private translate = inject(TranslateService);

  public moduleMap: Map<string, ModuleCard> = new Map<string, ModuleCard>();
  public entityObservable$ = new ReplaySubject<boolean>(1);
  /**
   * Map of indicators for extra info in datagrid displays
   * Can show filtered values
   */
  private _currentEntityStack: Entity[] = [];
  private _currentOrganization: Organization;

  constructor() {
    Object.keys(ModuleCard).forEach((moduleEnumElement) => {
      const module = ModuleCard[moduleEnumElement];
      this.moduleMap.set(module.code, module);
    });
  }

  public get currentEntity(): Entity | undefined {
    return this._currentEntityStack.last();
  }

  public set currentEntity(entity: Entity) {
    if (!environment.production) console.log('Before set:', this._currentEntityStack.last());
    this._currentEntityStack.push(entity);
    this.entityObservable$.next(true);
    if (!environment.production) console.log('After set:', this._currentEntityStack.last());
  }

  public unloadCurrentEntity(): void {
    if (!environment.production) console.log('Before unload:', this._currentEntityStack.last());
    this._currentEntityStack.pop();
    if (!environment.production) console.log('After unload:', this._currentEntityStack.last());
  }

  /**
   * Empty the stack then make the current Organization to be the curren entity.
   */
  public emptyOrganizationEntities(): void {
    this._currentEntityStack = [this.currentOrganization];
  }

  public emptyCurrentEntityStack(): void {
    this._currentEntityStack = [];
  }

  public get currentOrganization(): Organization {
    return this._currentOrganization;
  }

  public set currentOrganization(organization: Organization) {
    this._currentOrganization = organization;
  }

  /**
   * Return the current Asset when applicable. An Asset needs to be selected in the Assets grid or the Asset sheet
   * needs to be open, otherwise undefined is returned.
   * @return Current Asset or undefined.
   */
  public get currentAsset(): Asset | undefined {
    return this._currentEntityStack.find<Asset>((entity): entity is Asset => {
      // instanceof makes the app crash for some reason, so we use the prototype instead
      return entity['__typename'] === 'Asset';
    });
  }

  /**
   * Return the current Project when applicable. A project needs to be selected in the Projects grid or the Project sheet
   * needs to be open, otherwise undefined is returned.
   * @return Current Project or undefined.
   */
  public get currentProject(): Project | undefined {
    return this._currentEntityStack.find<Project>((entity): entity is Project => {
      // instanceof makes the app crash for some reason, so we use the prototype instead
      return entity['__typename'] === 'Project';
    });
  }

  /**
   * Return the current Equipment when applicable. Equipment needs to be selected in the Equipments grid or the Equipment sheet
   * needs to be open, otherwise undefined is returned.
   * @return Current Equipment or undefined.
   */
  public get currentEquipment(): Equipment | undefined {
    return this._currentEntityStack.find<Equipment>((entity): entity is Equipment => {
      // instanceof makes the app crash for some reason, so we use the prototype instead
      return entity['__typename'] === 'Equipment';
    });
  }

  /**
   * Load and use translations corresponding to a language.
   * @param lang Language to switch to.
   * @return Empty Observable.
   */
  public useLang(lang: string): Observable<void> {
    return this.translate.use(lang)
      .pipe(ignoreElements());
  }

  /**
   * Load and use translations corresponding to the browser's default locale, if supported.
   * If the browser's locale is not supported, fallbacks to the translate module's default language.
   * @return Empty Observable.
   */
  public resetLang(): Observable<void> {
    return this.useLang(getLocale().substring(0, 2));
  }
}
