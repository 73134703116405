export class CheckType {
  public code: string;
  public values: CheckTypeValues;
  public observation: CheckTypeObservation;
}

type checkTypeValueLevel = 'none' | 'info' | 'warn' | 'error';

export interface CheckTypeValues {
  [p: string]: checkTypeValueLevel;
}

export interface CheckTypeObservation {
  'raised': checkTypeValueLevel;
  'not_raised': checkTypeValueLevel;
}
