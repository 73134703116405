import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';

@Component({
  templateUrl: './organization-space-delete-modal.component.html'
})
export class OrganizationSpaceDeleteModalComponent extends HasStatusType {

  public deleteSpaceForm: FormGroup<{ deleteMode: FormControl<'deleteSpaceAndChildren' | 'deleteSpaceOnly'> }>;

  constructor(private fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) data: { type: string }) {
    super(data.type);

    this.deleteSpaceForm = this.fb.group({
      deleteMode: this.fb.control(null, Validators.required)
    });
  }

  /**
   * Get form data to use for deleting a Space.
   * @return Delete Space form data.
   */
  public getGeneratedObject(): { deleteMode: 'deleteSpaceAndChildren' | 'deleteSpaceOnly' } {
    return {deleteMode: this.deleteSpaceForm.get('deleteMode').value};
  }
}
