<div class="field-builder-container">
  <div *ngIf="currentMode === FieldMode.SAVING" class="field-custom-container">
    <div class="saving-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EMPTY" class="field-custom-container">
    <div class="empty-field-container">
      <div
        [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div class="empty-value">
            <ng-container
              *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition; else noPermissions">
              <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                              [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
            </ng-container>
            <ng-template #noPermissions>
              {{ appConfig.EMPTY_FIELD_VALUE }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.READ" class="field-custom-container">
    <div *ngFor="let field of fields" class="read-field-container">
      <div
        [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ 'LABEL.' + field.label | uppercase | translate }}
          <span *ngIf="getFieldConfig(field.label).tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="getFieldConfig(field.label).tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" [tabindex]="0"
               [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
            <ng-container *ngIf="getFieldConfig(field.label).hasPictograms && field.value; else noPictogram">
              <div>
                <img class='pictogram'
                     [src]="appConfig.PICTOGRAM_FOLDER_PATH + getFieldConfig(field.label).pictogram(field.value)"
                     [alt]="field.value">
                <span *ngIf="getFieldConfig(field.label).isPictogramWithText">{{ field.value }}</span>
              </div>
            </ng-container>
            <ng-template #noPictogram>
              <span>{{ field.value }}</span>
            </ng-template>
            <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
              <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
              <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EDIT" class="field-custom-container">
    <div class="edit-field-container">
      <div class="field-container fx-layout-col" tabindex="0" #gridContainer>
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>

        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
                         class="ag-theme-material clickable-rows"
                         id="technical-category-datagrid"
                         rowSelection="single"
                         style="height: 500px; width: 100%;">
        </ag-grid-angular>

        <div class="button-container fx-layout-row fx-justify-end fx-align-center">
          <button id="cancel-button"
                  (click)="onClickCancel()"
                  (keyup.escape)="onEscapeCancel($event)"
                  mat-stroked-button>
            {{ 'BUTTON.CANCEL' | translate }}
          </button>
          <button id="save-button"
                  (click)="onClickSave()"
                  [disabled]="false"
                  color="accent"
                  mat-raised-button>
            {{ 'BUTTON.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
