<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <mat-select #inputSelect [formControl]="formControl" class="ag-input-field-input"
    [placeholder]="control.value['entityName']" (selectionChange)="stopEditing()">
    @if (optional) {
      <mat-option [value]="''">{{ 'LABEL.UNDEFINED' | translate }}</mat-option>
    }
    @for (entity of entityList; track entity) {
      <mat-option [value]="entity">{{ entity.entityName }}</mat-option>
    }
  </mat-select>

  @if (control.invalid) {
    <div>
      <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
    </div>
  }
</div>
