import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

/**
 * Verify that all the values in the list are unique
 * @param list
 */
export const distinct = (list: any[]) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;

    return list.includes(control.value) ? {distinct: true} : null;
  };
};

/***
 * Check if the value of each control of the array is unique
 */
export const distinctRiskFieldValueOrders = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent || isPresent(Validators.required(control))) {
      return null;
    }
    const fieldValue = control.value;
    const controlArray = control.parent as FormArray;
    const fieldValueOccurrence = controlArray.controls.filter(control => control.value == fieldValue).length;
    if (fieldValueOccurrence < 2) {
        controlArray.controls.filter(control => control.invalid).forEach(control => control.updateValueAndValidity());
        return null;
    } else {
      return { unique: true };
    }
  }
};

export const distinctRiskParamFields = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent?.parent || isPresent(Validators.required(control))) {
      return null;
    }
    const newValue = control.value?.code;
    const paramGroupFrom = control.parent.parent as FormGroup;
    const filedControls = Object.values(paramGroupFrom.controls).map(param => param.get('field'));
    const fieldOccurrence = filedControls.filter(param => param.value?.code == newValue).length;
    if (fieldOccurrence < 2) {
      filedControls.filter(control => control.invalid).forEach(control => control.updateValueAndValidity());
      return null;
    } else {
      return { distinctRiskParamFields: true };
    }
  };
};

