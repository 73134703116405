import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';
import dayjs, { Dayjs } from 'dayjs';

export const afterDate = (dateToCompare: Dayjs): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!dateToCompare || isPresent(Validators.required(control))) {
      return null;
    }
    const endDate = dayjs(control.value);
    const startDate = dayjs(dateToCompare);
    return (startDate.isAfter(endDate) || endDate.isSame(startDate)) ? { afterDate: true } : null;
  };
};
