import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { InjectableRxStompConfig, RxStompService } from '@stomp/ng2-stompjs';
import { tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import SockJS from 'sockjs-client';

/**
 * Build a RxStompService based on a SockJS Websocket and with built-in OAuth authentication.
 * @param authService AuthService to call for retrieving access tokens for Websocket authentication.
 * @return RxStompService.
 */
export function stompServiceFactory(authService: AuthService): RxStompService {
  const config: InjectableRxStompConfig = {
    // Which server?
    webSocketFactory: () => new SockJS(environment.backend.baseUrl + environment.backend.graphql.endpoint),


    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 20000, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    reconnectDelay: 1000,
    beforeConnect: client => {
      return new Promise((resolve, reject) => {
        authService.getAccessTokenSilently()
          .pipe(
            catchError(() => {
              // In case of error with authentication, redirect the user so they can login again
              return authService.loginWithRedirect({
                authorizationParams: {
                  prompt: 'login'
                }
              });
            }),
            tap(token => {
              // Set the Authorization header for the CONNECT message
              client.stompClient.connectHeaders = {
                Authorization: `Bearer ${token}`
              };
            })
          )
          .subscribe({
            complete: () => resolve(),
            error: err => reject(err)
          });
      });
    }
  };
  const rxStompService = new RxStompService();
  rxStompService.configure(config);
  rxStompService.stompErrors$.subscribe(console.warn);
  rxStompService.webSocketErrors$.subscribe(console.warn);
  return rxStompService;
}
