<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_CONTRACT' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="contractForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.CONTRACT_PROVIDER' | translate}}</mat-label>
        <input matInput [id]="'create_contract_provider'" formControlName="provider" required/>
        @if (contractForm.controls.provider.hasError('required')) {
          <mat-error>
            {{'ERROR.FIELD_REQUIRED' | translate}}
          </mat-error>
        }
        @if (contractForm.controls.provider.hasError('maxlength')) {
          <mat-error>
            {{'ERROR.FIELD_MAX_LENGTH' | translate: { value: appConfig.FIELD_MAX_LENGTH } }}
          </mat-error>
        }
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.ALLOCATED_SPACES' | translate}} *</p>
        <ag-grid-angular (gridReady)="onGridReady($event)"
          [gridOptions]="gridOptions"
          class="ag-theme-material"
          id="contract-allocated-spaces-grid"
          style="width: 100%; height: 300px;">
        </ag-grid-angular>
        @if (contractForm.controls.spaces.touched && contractForm.controls.spaces.hasError('required')) {
          <mat-error
            >
            {{'ERROR.FIELD_REQUIRED' | translate}}
          </mat-error>
        }
        @if (contractForm.controls.spaces.hasError('incoherentSpaces')) {
          <mat-error>
            {{'ERROR.SPACES_INCOHERENT' | translate}}
          </mat-error>
        }
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel" [id]="'cancel-button'">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!contractForm.valid"
      [id]="'add-button'"
      color="accent"
      mat-raised-button
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate}}
    </button>
  </mat-dialog-actions>
</div>
