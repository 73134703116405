<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center fx-gap-10">
  <input #inputText [formControl]="params.textControl" matInput class="mat-input-element ag-input-field-input"
    type="text"
    (keydown.tab)="onTextInputTab($event)"
    (focusin)="fullRowEdit && inputText.select()"/>
  @if (params.textControl.invalid) {
    <div>
      <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="textErrorTooltip"></i>
    </div>
  }
  <span>{{ '/' }}</span>
  <mat-select [formControl]="params.suffixControl"
    class="suffix-input mat-input-element ag-input-field-input"
    panelWidth="50px"
    hideSingleSelectionIndicator="true"
    [placeholder]="params.suffixControl.value">
    @for (suffixValue of suffixValues; track suffixValue) {
      <mat-option
      [value]="suffixValue">{{suffixValue}}</mat-option>
    }
  </mat-select>
  @if (params.suffixControl.invalid) {
    <div>
      <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="suffixErrorTooltip"></i>
    </div>
  }
  @if (!fullRowEdit) {
    <div>
      <i aria-hidden="true" class="mdi mdi-check" (click)="stopEditing()"></i>
    </div>
  }
</div>
