import { Component } from '@angular/core';
import { ModuleCard } from '@app/core/enums/modules.enum';
import { ClientSubscriptionModule } from '@app/core/model/client/client-subscription-module';
import { AppManager } from '@services/managers/app.manager';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'module-icon-list',
  template: `
    <div class="module-icon-block" *ngFor="let module of modules">
      <module-icon [moduleIcon]="module.moduleIcon" [moduleName]="module.moduleName"></module-icon>
    </div>
  `,
  styles: [`
    .module-icon-block {
      display: inline-flex;
    }
  `]
})
export class ModuleIconListComponent implements ICellRendererAngularComp {

  public modules: { moduleIcon: { namespace: string, source: string }, moduleName: string }[] = [];
  private params: any;

  constructor(private appManager: AppManager) {
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;

    // params value is a client subscription
    if (params.value) {
      this.modules = params.value.map((subscriptionModule: ClientSubscriptionModule) => {
        const card: ModuleCard = this.appManager.moduleMap.get(subscriptionModule.module.code);
        return {
          moduleName: card.name,
          moduleIcon: card.icon
        };
      });
    }
  }

  public refresh(params: any): boolean {
    return false;
  }
}
