import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event as IEvent, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { reducers } from '@app/shared/store/store';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { ErrorManager } from '@services/managers/error.manager';
import { NotificationManager } from '@services/managers/notification.manager';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Angulartics2Mixpanel } from 'angulartics2';
import dayjs from 'dayjs';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import LogRocket from 'logrocket';
import { EMPTY, finalize, from, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private accessManager: AccessManager,
              private store: Store<any>,
              private router: Router,
              private translate: TranslateService,
              private websocketService: RxStompService,
              private errorManager: ErrorManager,
              private mixpanel: Angulartics2Mixpanel,
              private activationEndService: ActivationEndService,
              private updates: SwUpdate,
              private notificationManager: NotificationManager) {

    //Remove all current caches
    from(caches.keys())
      .pipe(
        switchMap(keys => from(keys)),
        mergeMap(key => caches.delete(key)),
        finalize(() => this.initLang())
      )
      .subscribe();

    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: IEvent) => {
        this.activationEndService.pushRouteEvent(event);
      });
    this.mixpanel.startTracking();

    if (environment.production) {
      this.updates.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        })),
        switchMap((newVersion) => {
          return from(this.updates.activateUpdate())
            .pipe(
              filter(updated => !!updated),
              tap(() => {
                console.log('Updated successfully to version ' + newVersion.available.hash);
                const newFeatureMessage = newVersion.available.appData['newFeatureMessage'];
                if (newFeatureMessage) {
                  this.notificationManager.addNewFeatureMessage(newFeatureMessage);
                }
              })
            );
        }),
        switchMap(() => this.translate.reloadLang(this.translate.currentLang))
      ).subscribe((translations) => {
        this.translate.setTranslation(this.translate.currentLang, translations);
      });

      this.updates.unrecoverable.subscribe(unrecoverable => {
        console.error(unrecoverable);
      });
    }
    if (environment.logRocket.enabled) {
      // init logRocket
      LogRocket.init(environment.logRocket.appId);
    }
  }

  public ngOnInit(): void {
    Object.entries(reducers).forEach((entry) => {
      // key = entry[0], reducer = entry[1]
      this.store.addReducer(entry[0], entry[1]);
    });

    // Manage webSocket stomp error, if error is Unauthorized access goto error page with code 401
    // otherwise, display an error popup
    this.websocketService.stompErrors$.pipe(
      takeUntil(this.destroy$),
      mergeMap(error => {
        if (error.headers.message && error.headers.message.includes('Disconnect')) {
          return this.accessManager.logout(false)
            .pipe(finalize(() => this.errorManager.handleError({statusCode: 401})));
        } else {
          return EMPTY.pipe(
            finalize(() => {
              this.errorManager.handleQueryError({
                message: error.headers.message,
                statusCode: 489,
                errorCode: null
              }, 'Error ws query');
            })
          );
        }
      })
    ).subscribe();

    // Manage webSocket error, display an error popup
    this.websocketService.webSocketErrors$.subscribe((val: Event) => {
      this.errorManager.handleQueryError({
        message: val.type,
        statusCode: 489,
        errorCode: null
      }, 'Error ws query');
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initLang(): void {
    // Get browser locale
    const lang = this.translate.getBrowserLang();
    const cultureLang = this.translate.getBrowserCultureLang();

    this.translate.setDefaultLang('fr');
    if (lang.match(/en|fr/)) {
      // TTT-2618 - Dynamically import language modules?
      // FIXME Find a way to wait for the language modules before loading the page
      /*import(`@/../@angular/common/locales/${cultureLang}.mjs`)
        .then(localModule => {
          registerLocaleData(localModule.default, lang);
        });*/
      // Init Angular locale
      registerLocaleData(localeFr, 'fr');
      registerLocaleData(localeEn, 'en');

      // Init translate service locale
      this.translate.addLangs([lang]);
      this.translate.use(lang).subscribe();

      // Init DayJS locale
      dayjs.extend(localizedFormat);
      dayjs.extend(utc);
      dayjs.locale(cultureLang.toLowerCase() || this.translate.defaultLang);
    }
  }
}
