import { ReduxStore } from '@app/shared/store/reducers/field-value.reducer';

export const reducers = {
  clientName: ReduxStore.createFieldReducer('clientName'),
  assetName: ReduxStore.createFieldReducer('assetName'),
  assetState: ReduxStore.createFieldReducer('assetState'),
  createCheckSpace: ReduxStore.createFieldReducer('createCheckSpace'),
  organizationName: ReduxStore.createFieldReducer('organizationName'),
  groupName: ReduxStore.createFieldReducer('groupName'),
  leaseType: ReduxStore.createFieldReducer('leaseType'),
  userEmail: ReduxStore.createFieldReducer('userEmail'),
  leaseStatus: ReduxStore.createFieldReducer('leaseStatus'),
  occupantName: ReduxStore.createFieldReducer('occupantName'),
  createOccupantName: ReduxStore.createFieldReducer('createOccupantName'),
  documentName: ReduxStore.createFieldReducer('documentName'),
  createDatagridVisualisationName: ReduxStore.createFieldReducer('createDatagridVisualisationName'),
  documentState : ReduxStore.createFieldReducer('documentState'),
  documentType: ReduxStore.createFieldReducer('documentType'),
  documentRelatedAsset: ReduxStore.createFieldReducer('documentRelatedAsset'),
  createSpaceName: ReduxStore.createFieldReducer('createSpaceName'),
  createContractName: ReduxStore.createFieldReducer('createContractName'),
  createDashboardName: ReduxStore.createFieldReducer('createDashboardName'),
  workAction: ReduxStore.createFieldReducer('workAction'),
  workState: ReduxStore.createFieldReducer('workState'),
  workSpaces: ReduxStore.createFieldReducer('workSpaces'),
  workCreationUser: ReduxStore.createFieldReducer('workCreationUser'),
  workCreationDate: ReduxStore.createFieldReducer('workCreationDate'),
  workLastChangeUser: ReduxStore.createFieldReducer('workLastChangeUser'),
  workLastChangeDate: ReduxStore.createFieldReducer('workLastChangeDate'),
  unitCrv: ReduxStore.createFieldReducer('unitCrv')
};
