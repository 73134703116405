import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Space } from '@app/core/model/entities/asset/space';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

export const lteSpaceParentProperty = (spacesService: SpacesService, field: string, spaceToUpdate: Space): AsyncValidatorFn => {

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let newFieldValue = 0;
    let parentArea: number;

    if (control.pristine || !spaceToUpdate.parentPath?.length) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(value => {
        newFieldValue = value as number;
        return spacesService.loadSpace(spaceToUpdate.parentPath.lastItem());
      }),
      switchMap((parentSpace: Space) => {
        parentArea = parentSpace.properties[field] as number;

        if (!parentArea) {
          return of(null);
        }

        return spacesService.loadChildrenSpaces(spaceToUpdate.parentPath.lastItem());
      }),
      map((spaces: Space[] | null) => {

        if (!spaces?.length) {
          return null;
        }

        const totalArea = spaces
          .filter(space => {
            return space.id != spaceToUpdate.id
              && space.properties[field]
              && spaceToUpdate.parentPath.length == space.parentPath.length;
          })
          .map(space => space.properties[field])
          .reduce((prev, acc) => +prev + +acc, newFieldValue);

        if (totalArea > parentArea) {
          return {lteSpaceParentProperty: true};
        }

        return null;
      }),
      first(),
      tap(() => control.markAsTouched())
    );
  };
};
