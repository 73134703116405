<div class="full-size fx-layout-col">
  <!-- Header -->
  <ng-template headerTemplate></ng-template>

  <!-- Breadcrumb -->
  <div *ngIf="breadCrumbs && breadCrumbs.length > 1 && !data.hideBreadcrumb"
       class="breadcrumb fx-layout-row fx-justify-start fx-align-center fx-hide-xs">
    <span *ngFor="let crumb of breadCrumbs; index as ind; last as isLast" [ngClass]="{'active' : isLast}"
          class="breadcrumb-item fx-layout-row fx-justify-start fx-align-center">
      <ng-container *ngIf="ind === breadCrumbs.length - 1; else regularCrumb">
        <span>{{ crumb.name | translate : crumb.optionalParams }}</span>
      </ng-container>
      <ng-template #regularCrumb>
        <span (click)="onNavigate(crumb)" [routerLink]="'/' + crumb.urls.join('/')">
          {{ crumb.name | translate : crumb.optionalParams }}
        </span>
        <span class="mdi mdi-chevron-right breadcrumb-icon"></span>
      </ng-template>
    </span>
  </div>
</div>
