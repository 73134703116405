import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupManager } from '@services/managers/popup.manager';

export type AppError = {
  statusCode: number,
  errorTitle?: string,
  errorDescription?: string,
  errorCode?: number
};

@Injectable()
export class ErrorManager {
  constructor(private dialog: MatDialog,
              private popupManager: PopupManager,
              private router: Router,
              private translate: TranslateService,
              private authService: AuthService) {
  }

  /**
   * Handle an application error by either navigating to the corresponding error page or displaying the error details
   * in a pop-up.
   * @param error Error.
   */
  public handleError(error: AppError): void {
    this.dialog.closeAll();
    switch (error.statusCode) {
      case 400:
        // HTTP Unauthorized
        // Bad credentials
        this.router.navigate(['error', 'forbidden']).catch(console.error);
        break;
      case 401:
        // HTTP Unauthorized
        // Invalid token
        this.router.navigate(['error', 'forbidden']).catch(console.error);
        break;
      case 403:
        // HTTP Forbidden
        // User does not have permission for action
        this.router.navigate(['error', 'access']).catch(console.error);
        break;
      case 404:
        // HTTP Not found
        // Managed by Angular Router (but added here for consistency)
        this.router.navigate(['error', 'missing']).catch(console.error);
        break;
      case 480:
        // graphql data fetching error
        this.popupManager.showOkPopup({
          dialogTitle: this.formatErrorValidationMsg(error.errorDescription),
          dialogMessage: error.errorTitle,
          type: 'error'
        });
        break;
      case 481:
      // graphql validation error (invalid syntax or value type in query send)
      // eslint-disable-next-line no-fallthrough
      case 489:
        // graphql API error / other graphql error
        this.popupManager.showOkPopup({
          dialogTitle: this.translate.instant('TITLE.UNKNOWN_ERROR'),
          dialogMessage: error.errorDescription
            + this.translate.instant('MESSAGE.ERROR_CODE', { 'code': error.errorCode }),
          type: 'error'
        });
        break;
      case 490:
      case 503:
        // Service Unavailable
        // Query failed / Network error
        this.router.navigate(['error', 'unavailable']).catch(console.error);
        break;
      case 491:
      case 510:
        // Error in ABP when executing a query
        this.popupManager.showOkPopup({
          dialogTitle: this.translate.instant(error.errorDescription),
          dialogMessage: this.translate.instant('TITLE.UNKNOWN_ERROR'),
          type: 'error'
        });
        break;
      default:
        // other status code error
        this.router.navigate(['error', 'unknown']).catch(console.error);
        break;
    }
  }

  /**
   * Handle errors encountered during API request by either navigating to the corresponding error page or displaying
   * the error details in a pop-up.
   * @param graphQLError Error returned by the GraphQL API.
   * @param title Pop-up title, if applicable.
   */
  public handleQueryError(graphQLError: any, title: string): void {
    this.handleError({
      errorDescription: graphQLError.message,
      errorTitle: title,
      statusCode: graphQLError.statusCode,
      errorCode: graphQLError.errorCode
    });
  }

  /**
   * Handle any network error other than API error by either navigating to the corresponding error page or displaying
   * the error details in a pop-up, except for login_required errors for which the user is redirected to the
   * authentication page.
   * @param networkError Error.
   */
  public handleNetworkError(networkError: any): void {
    if (['invalid_grant', 'login_required'].includes(networkError['error'])) {
      // Token request errors from OpenID provider should initiate a new authentication
      this.authService.loginWithRedirect({
        authorizationParams: {
          prompt: 'login'
        },
        appState: {
          target: this.router.routerState.snapshot.url
        }
      })
        .subscribe();
    } else if (networkError['status']) {
      this.handleError({
        errorDescription: networkError['description'],
        errorTitle: networkError['title'] ?? this.translate.instant('TITLE.UNKNOWN_ERROR'),
        statusCode: networkError['status']
      });
    } else {
      // service unavailable, no response in failed query
      this.handleError({ statusCode: 503 });
    }
  }

  /**
   * Parse and translate list of server message from query update and save entity
   * @param errorMessages : string array of server error
   * @return string array of translated message
   */
  public transformErrorMessageQuerySaveUpdate(errorMessages: string[]): string[] {
    const translatedMessagesList: string[] = [];
    for (const message of errorMessages) {
      translatedMessagesList.push(this.formatErrorValidationMsg(message));
    }
    return translatedMessagesList;
  }

  /**
   * Parse and translate server error message from query save and update
   * each error message is a string with a specific format
   * message format: ERROR_CODE,propertyCode,value,otherPropertyCode, the separate char is ','
   * only the ERROR_CODE is required on all error message, the other property is additional information for validation error
   * the property ERROR_CODE,propertyCode,otherPropertyCode is translate
   * the property propertyCode,value,otherPropertyCode are params in the ERROR_CODE message
   * @param errorMessage server error message
   * @return translated message
   */
  private formatErrorValidationMsg(errorMessage: string): string {
    if (errorMessage == null) {
      return this.translate.instant('TITLE.UNKNOWN_ERROR');
    }
    const messageVar = errorMessage.split(',');
    const errorCode = messageVar[0];
    let propertyLabel = '';
    if (messageVar.length >= 2) {
      propertyLabel = this.translate.instant(messageVar[1].toUpperCase());
      // If it couldn't translate the property, then use the original value
      if (propertyLabel === messageVar[1].toUpperCase()) {
        propertyLabel = messageVar[1];
      }
    }
    let value = '';
    if (messageVar.length >= 3) {
      value = messageVar[2];
    }
    let otherPropertyLabel = '';
    if (messageVar.length === 4) {
      otherPropertyLabel = this.translate.instant(messageVar[3].toUpperCase());
      // If it couldn't translate the property, then use the original value
      if (otherPropertyLabel === messageVar[3].toUpperCase()) {
        otherPropertyLabel = messageVar[3];
      }
    }
    return this.translate.instant(errorCode, {
      'propertyLabel': propertyLabel,
      'value': value,
      'otherPropertyLabel': otherPropertyLabel
    });
  }
}
