<div class="card-container-container">
  <div class="card-container">
    <mat-card>
      <mat-card-content>
        <div class="fx-fill fx-layout-col fx-justify-start fx-align-stretch">
          <ng-container *ngIf="!!globalBIReport; else noGlobalReport">
            <powerBI-report class="fx-fill"
                            [workspaceId]="workspaceId"
                            [reportId]="globalBIReport.id"
                            [relatedEntityType]="EntityType.ORGANIZATION"
                            [reportDefaultPage]="globalBIReport.defaultPageId"></powerBI-report>
          </ng-container>
          <ng-template #noGlobalReport>
            <div class="empty-section fx-fill fx-layout-col fx-justify-center fx-align-center">
              <h4 id="no_global_report_label">
                {{ "LABEL.NO_GLOBAL_REPORT" | translate }}
              </h4>
            </div>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>