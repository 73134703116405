import { Injectable } from '@angular/core';
import { ContractStateEnum } from '@app/core/enums/contract/contract-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Contract, ContractInput } from '@app/core/model/entities/asset/contract';
import { Document } from '@app/core/model/entities/document/document';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { from, Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContractsService {
  private sidePanelToggleSubject = new Subject<Contract | null>();

  private addContractSubject = new Subject<Contract>();
  private updateContractSubject = new Subject<Contract>();
  private renewContractSubject = new Subject<{ renewedContract: Contract, oldContract: Contract }>();
  private deleteContractsSubject = new Subject<Contract[]>();

  private readonly contractInfoGraphqlFragment = gql`
    fragment ContractInfo on Contract {
      id
      provider
      assetId
      spaceIdsList
      status
      properties
      computedProperties
      creationDate
      creationUserId
      lastChangeDate
      lastChangeUserId
      dataDate
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private documentsService: DocumentsService,
              private assetsService: AssetsService,
              private usersService: UsersService) {
  }

  /**
   * Fetches all Contracts belonging to the current Organization from the API.
   */
  public get contracts$(): Observable<Contract[]> {
    const QUERY = gql`
      query OrganizationContracts($organizationId: String!) {
        contractsByOrganizationId(organizationId: $organizationId) {
          ...ContractInfo
        }
      }
      ${this.contractInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Contract, response.data['contractsByOrganizationId'] as Contract[])),
        // Fetch Users who created and last updated the Contract
        switchMap(contracts => from(contracts)),
        mergeMap((contract) => this.usersService.fetchUsersInfo(contract)),
        toArray()
      );
  }

  /**
   * Emits Contracts of the current Asset.
   */
  // TODO TTT-2814 merge contracts$ and assetContracts$ in a new method
  public get assetContracts$(): Observable<Contract[]> {
    const QUERY = gql`
      query AssetsContracts($assetId: String!) {
        assetContractsById(assetId: $assetId) {
          ...ContractInfo
        }
      }
      ${this.contractInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      assetId: this.appManager.currentAsset.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Contract, response.data['assetContractsById'] as Contract[])),
        // Fetch Users who created and last updated the Contract
        switchMap(contracts => from(contracts)),
        mergeMap((contract) => this.usersService.fetchUsersInfo(contract)),
        toArray()
      );
  }

  /**
   * Get a list of available types of Contract for the current Organization.
   * @return Contract types.
   */
  public get contractTypes$(): Observable<string[]> {
    const COMBINED_QUERY = gql`
      query OrganizationContractTypes($organizationId: String!) {
        contractTypesByOrganizationId(organizationId: $organizationId)
      }
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(map(response => response.data['contractTypesByOrganizationId'] as string[]));
  }

  /**
   * Emits a Contract once after it has been created.
   */
  public get contractAdded$(): Observable<Contract> {
    return this.addContractSubject.asObservable();
  }

  /**
   * Emits a Contract whenever it has been updated.
   */
  public get contractUpdated$(): Observable<Contract> {
    return this.updateContractSubject.asObservable();
  }

  /**
   * Emits a Contract after it has been renewed.
   */
  public get contractRenewed$(): Observable<{ renewedContract: Contract, oldContract: Contract }> {
    return this.renewContractSubject.asObservable();
  }

  /**
   * Emits a list of Contracts after they have been deleted.
   */
  public get contractsDeleted$(): Observable<Contract[]> {
    return this.deleteContractsSubject.asObservable();
  }

  /**
   * Emits a Contract whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Contract | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Make an API call that will create a new Contract with the provided data.
   * @param assetId ID of the Asset the new Contract is related to.
   * @param contractInput Data for creating a new Contract.
   * @return New Contract.
   */
  public createContract(assetId: string, contractInput: any): Observable<Contract> {
    const COMBINED_MUTATION = gql`
      mutation CreateContract($assetId: String!, $addContractInput: AddContractInput) {
        createContract(assetId: $assetId, addContractInput: $addContractInput) {
          ...ContractInfo
        }
      }
      ${this.contractInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {assetId, addContractInput: contractInput};

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Contract, response.data['createContract'] as Contract)),
        switchMap(contract => this.usersService.fetchUsersInfo(contract)),
        tap(newContract => this.addContractSubject.next(newContract))
      );
  }

  /**
   * Call the API to update a Contract.
   * @param contract Contract to update.
   * @param contractInput Data for updating the Contract.
   * @return Updated Contract.
   */
  public updateContract(contract: Contract, contractInput: ContractInput): Observable<Contract> {
    const COMBINED_MUTATION = gql`
      mutation UpdateContract($contractId: String!, $contractInput: UpdateContractInput!) {
        updateContract(contractId: $contractId, updateContractInput: $contractInput) {
          ...ContractInfo
        }
      }
      ${this.contractInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      contractId: contract.id,
      contractInput: {
        provider: contract.provider,
        spaceIds: contract.spaceIds,
        ...contractInput
      }
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Contract, response.data['updateContract'] as Contract)),
        switchMap(contract => this.usersService.fetchUsersInfo(contract)),
        tap(updatedContract => this.updateContractSubject.next(updatedContract))
      );
  }

  /**
   * Renew an existing Contract.
   * @param contractToRenew Existing Contract to renew then close.
   * @param contractInput Data used to renew contract.
   * @return New Contract.
   */
  public renewContract(contractToRenew: Contract, contractInput: ContractInput): Observable<Contract> {
    const COMBINED_MUTATION = gql`
      mutation RenewContract($assetId: String!, $oldContractId: String!, $contractInput: RenewContractInput!){
        renewContract(assetId: $assetId, oldContractId: $oldContractId, renewContractInput: $contractInput){
          ...ContractInfo
        }
      }
      ${this.contractInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      assetId: contractToRenew.assetId,
      oldContractId: contractToRenew.id,
      contractInput
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Contract, response.data['renewContract'] as Contract)),
        switchMap(contract => this.usersService.fetchUsersInfo(contract)),
        tap(renewedContract => {
          contractToRenew.status = ContractStateEnum.CONTRACT_CLOSED;
          this.renewContractSubject.next({renewedContract, oldContract: contractToRenew});
        })
      );
  }

  /**
   * Make an API call to delete the given Contracts.
   * @param contracts Contracts to be deleted.
   * @return True if all Contracts were deleted, false otherwise.
   */
  public deleteContracts(contracts: Contract[]): Observable<boolean> {
    const COMBINED_MUTATION = gql`
      mutation DeleteContracts($contractIds: [String!]!) {
        deleteContracts(contractIds: $contractIds)
      }
    `;
    const MUTATION_VAR = {
      contractIds: contracts.map(contract => contract.id),
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteContracts'] as boolean),
        tap(success => {
          if (success) {
            this.deleteContractsSubject.next(contracts);
          }
        })
      );
  }

  /**
   * Navigate to the contracts tab of the Asset's sheet.
   * @param assetId Id of the Asset to navigate to.
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'contracts');
  }

  /**
   * Load all the documents related to a Contract
   * @param contractId ID of Contract whose Documents should be loaded.
   * @return Documents related to the Contract.
   */
  public loadContractDocuments(contractId: string): Observable<Document[]> {
    return this.documentsService.loadEntityDocuments(
      contractId,
      EntityTypeEnum.CONTRACT,
      DocumentTypeEnum.CONTRACT_DOCUMENT
    );
  }

  /**
   * Open the sidenav with information about the selected Contract.
   * @param contract Contract to be displayed in the side panel.
   */
  public openContractSidePanel(contract: Contract): void {
    this.sidePanelToggleSubject.next(contract);
  }

  /**
   * Close the side panel.
   */
  public closeContractSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }
}
