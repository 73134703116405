import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Dashboard } from '@app/core/model/entities/dashboard/dashboard';
import { DashboardService } from '@app/shared/components/dashboard/dashboard.service';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { AccessManager } from '@services/managers/access.manager';
import { GridApi } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren('chartsContainer') public chartsContainer: QueryList<any>;
  @ViewChild('chartsSidePanel') public chartsSidePanel: MatSidenav;

  @Input() public gridApi: GridApi;
  @Input() public relatedEntityType: EntityTypeEnum;

  public isToolPanelOpen = false;
  public preferenceIdKey = 'dashboardId';
  private dashboards: Dashboard[] = [];
  private destroy$ = new Subject<void>();
  public chartDivIds: string[] = [];

  constructor(public dashboardService: DashboardService,
              private userPreferencesService: UserPreferencesService,
              public accessManager: AccessManager) {
  }

  /**
   * Load the charts.
   */
  public ngOnInit(): void {
    this.dashboardService.loadDashboards(this.relatedEntityType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dashboards => {
        this.dashboards = dashboards.map(dashboard => {
          dashboard.isModified = false;
          return dashboard;
        });
        const defaultDashboard = this.dashboards.find(dashboard => dashboard.isDefault);

        // get preferred dashboard id
        const preferredDashboardId = this.userPreferencesService.currentUserPreferences.preferences[this.preferenceIdKey];

        // Get the dashboard of the user's preference, otherwise get the default dashboard
        if (preferredDashboardId) {
          const preferredDashboard = dashboards.find(dashboard => dashboard.id === preferredDashboardId);
          this.dashboardService.currentDashboard = preferredDashboard ? preferredDashboard : defaultDashboard;
        } else {
          this.dashboardService.currentDashboard = defaultDashboard;
        }

        // Create id for each chart div needed in DOM
        this.dashboardService.currentDashboard.charts.forEach(() => {
          this.chartDivIds.push('chartId' + this.chartDivIds.length);
        });

        // Change the popup anchor to display dropdown in the chart
        this.gridApi.setPopupParent(document.body);
      });

    // Add a new dashboard
    this.dashboardService.dashboardApplied$.pipe(takeUntil(this.destroy$))
      .subscribe((newDashboard) => {
        this.dashboards.push(newDashboard);
      });

    // Change current dashboard to display by destroying the previous one and creating chartDivIds of the new one.
    this.dashboardService.dashboardReplaced$.pipe(takeUntil(this.destroy$))
      .subscribe((previousDashboard) => {
        this.destroyCharts(previousDashboard);
        this.dashboardService.currentDashboard.charts.forEach(() => {
          this.chartDivIds.push('chartId' + this.chartDivIds.length);
        });
        this.chartsContainer.notifyOnChanges();
      });

    // Update current dashboard
    this.dashboardService.dashboardUpdated$.pipe(takeUntil(this.destroy$))
      .subscribe((updateDashboard) => {
        const index = this.dashboards.findIndex(db => db.id == updateDashboard.id);
        this.dashboards[index].charts = this.dashboardService.currentDashboard.charts;
      });

    // Delete current dashboard
    this.dashboardService.dashboardDeleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe((previousDashboard) => {
        this.dashboards = this.dashboards.filter(dashboard => dashboard.id != previousDashboard.id);
      });
  }

  /**
   * If charts are loaded, add them to DOM and subscribe to side panel.
   */
  public ngAfterViewInit(): void {
    // Create charts after that all div required have been created in DOM
    this.chartsContainer.changes.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.chartsContainer.length == this.dashboardService.currentDashboard.charts.length) {
          this.createChartFromSelectedRangeData();
        }
      });

    // Manage sidePanel
    this.dashboardService.sidePanelToggleSubject.pipe(takeUntil(this.destroy$))
      .subscribe((dashboards) => {
        if (dashboards) {
          this.chartsSidePanel.open().catch(console.error);
          this.isToolPanelOpen = true;
        } else {
          this.chartsSidePanel.close().catch(console.error);
          this.isToolPanelOpen = false;
        }
      });

  }

  /**
   * Destroy all charts and end subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyCharts(this.dashboardService.currentDashboard);
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Create all the chars registered in the current dashboard view
   */
  private createChartFromSelectedRangeData(): void {
    this.dashboardService.currentDashboard?.charts
      .map((chart, index) => {
        const chartDiv = document.getElementById(this.chartDivIds[index]);

        //configure cell range
        chart.config.cellRange.rowStartIndex = 0;
        chart.config.cellRange.rowEndIndex = this.gridApi.getDisplayedRowCount() - 1;
        chart.config.suppressChartRanges = true;
        chart.config.chartContainer = chartDiv;
        chart.chartContainer = chartDiv;

        //build formatter
        chart = this.dashboardService.addFormatterOptions(chart);
        //Save the chartRef in the config
        chart.chartRef = this.gridApi.createRangeChart(chart.config);
        return chart;
      });
  }

  /**
   * Destroy all the charts in the current view
   */
  private destroyCharts(dashboard: Dashboard): void {
    this.chartDivIds = [];
    dashboard?.charts?.map(chart => {
      chart.chartRef?.destroyChart();
      //Remove obsoletes references
      chart.chartRef = undefined;
      chart.chartId = undefined;
      chart.chartContainer = undefined;
    });
  }

  /**
   * Open the side panel displaying dashboard's configuration.
   */
  public openDashboardToolPanel(): void {
    this.dashboardService.sidePanelToggleSubject.next(this.dashboards);
  }
}
