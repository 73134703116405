import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AbstractBean } from '@app/core/model/abstract-bean';
import { FieldConfig, FormNode } from '@app/core/model/other/field-config';
import { Expose, Transform, Type } from 'class-transformer';

export interface ColumnGroupInput {
  code: string;
  label: string;
  order: number;
}

export class GridConfig extends AbstractBean implements FormNode {
  public code: string;

  @Expose({name: 'columnGroupsList'})
  @Type(() => ColumnGroup)
  public columnGroups: ColumnGroup[];

  @Expose({name: '__typename'})
  public graphqlTypename: string;

  public get label(): string {
    return `${'MENU.' + this.code.toUpperCase()}`;
  }
}

export class ColumnGroup implements FormNode {
  public code: string;
  public order: number;
  public customOptions?: {
    propertiesPath?: string
    [option: string]: any
  };
  public label: string;

  @Transform(({value}) => EntityTypeEnum[value])
  public entityType: EntityTypeEnum;

  @Expose({name: 'columnsList'})
  @Type(() => FieldConfig)
  public columns: FieldConfig[];

  @Expose({name: '__typename'})
  public graphqlTypename: string;
}
