import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';

@Injectable()
export class JmapService {

  constructor(private generalService: GeneralService,
              private appManager: AppManager) {
  }

  /**
   * get jMap session ID
   * @return jMap sessionId
   */
  public getSessionId(): Observable<JSON> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const uri = environment.backend.baseUrl + environment.backend.jmap.endpoint;
    const body = {
      'organizationId': this.appManager.currentOrganization.id
    };

    return this.generalService.httpPostAsJson(uri, body, headers);
  }

  /**
   * Open jMap in fullscreen mode
   * @param iframe The iframe wrapping the jMap iframe
   */
  public toggleFullScreen(iframe: HTMLIFrameElement): void {
    iframe.requestFullscreen().catch(console.error);
  }

}
