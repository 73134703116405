import { Component, inject } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'check-observations-cell',
  templateUrl: './check-observations-cell.component.html'
})
export class CheckObservationsCellComponent implements ICellRendererAngularComp {

  public params: any;
  public checkTypes: { [k: string]: CheckType };
  public checkTypeCode: string;
  public observations: string;
  protected readonly emptyValue = inject(AppConfig).EMPTY_FIELD_VALUE;

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * There are two cases for the cell : the first one is when we receive an array of assetChecks (master grid) and the second is when we receive a simple check (detail grid),
   * so we need to check if the data received has one or more elements.
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!params.value) return;

    this.checkTypes = this.params.checkTypes;
    const {type, value} = (params.node.group)
      // When the row is a group, the data is store as JSON string and is an object containing the check type and the check observations.
      // Ex: {type: 'electricity', value: 'Some observations'}
      ? JSON.parse(params.value)
      // In ungroup row, fetch the check type from the row data.
      : {type: params.data.type, value: params.value};
    this.checkTypeCode = type;
    this.observations = value;
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @returns {boolean}
   */
  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
