import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Check } from '@app/core/model/entities/asset/check';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { IRelatedSpace, RelatedSpace } from '@app/core/model/entities/asset/space';
import { IRelatedEntity } from '@app/core/model/entities/entity';
import { CheckTypeService } from '@app/shared/services/check-type.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './organization-check-renew-modal.component.html'
})
export class OrganizationCheckRenewModalComponent implements OnInit {

  public checkCharacteristicsForm: FormGroup<{
    type: FormControl<string>,
    space: FormControl<string>
  }>;

  public checkToRenew: Check;
  public checkToRenewSpaces: RelatedSpace[];
  public checkToRenewType: CheckType;

  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
              private fb: UntypedFormBuilder,
              private checkTypeService: CheckTypeService,
              @Inject(MAT_DIALOG_DATA) data: { checkToRenew: Check }) {
    this.checkToRenew = data.checkToRenew;
    this.checkToRenewSpaces = this.checkToRenew.computedProperties.spaces
      .map((space: IRelatedSpace) => new RelatedSpace(space));
  }

  /**
   * Load Check type and init form.
   */
  public ngOnInit(): void {
    this.checkTypeService.getCheckTypeByCode(this.checkToRenew.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe(checkType => this.checkToRenewType = checkType);

    this.checkCharacteristicsForm = this.fb.group({
      type: [{
        value: this.translateService.instant('VALUE.' + this.checkToRenew.type.toUpperCase()),
        disabled: true
      }],
      space: [{
        value: this.checkToRenew?.computedProperties?.spaces?.map((space: IRelatedEntity) => space.displayName).sort().join(', '),
        disabled: true
      }]
    });
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get the filled data for renewing a Check.
   * @return Check's data.
   */
  public getGeneratedObject(): { spaceIds: string[] } {
    return {
      spaceIds: this.checkToRenew.spaceIds
    };
  }
}
