<mat-expansion-panel [expanded]="true" [disabled]="!displayHeader" class="expansion-panel-gap no-shadow">
  @if (displayHeader && sectionLabel) {
    <mat-expansion-panel-header class="section-header">
      {{ sectionLabel }}
    </mat-expansion-panel-header>
  }
  <div [ngClass]="{'section-body': displayHeader}" class="fx-layout-col">
    @if (empty) {
      <span class="empty-label">
        {{ sectionEmptyLabel ?? ('LABEL.EMPTY_SECTION' | translate) }}
      </span>
    }
    <ng-container fieldGroupAnchor></ng-container>
  </div>
</mat-expansion-panel>
