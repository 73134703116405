<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div class="read-value computed">
        <ng-container *ngIf="fieldConfig.hasPictograms && getFieldValue(); else noPictogram">
          <div>
            <img class='pictogram'
                 [src]="appConfig.PICTOGRAM_FOLDER_PATH + fieldConfig.pictogram(getFieldValue())"
                 [alt]="getFieldValue()">
            <span *ngIf="fieldConfig.isPictogramWithText">{{ getFieldValue() }}</span>
          </div>
        </ng-container>
        <ng-template #noPictogram>
          <span [ngStyle]="customCss">
            <span>
              {{ (fieldConfig.customOptions?.['translate'] ? translateValue(getFieldValue()) : getFieldValue())
              | fieldFormatType: fieldConfig.customOptions?.['formatType']
              | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}
              }}
            </span>
            <span *ngIf="fieldConfig.suffixType">
              {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
            </span>
          </span>
          <span [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-calculator tooltip-icon"></i>
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
