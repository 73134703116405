import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset } from '@app/core/model/entities/asset/asset';
import {
  EquipmentCreateModalComponent
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-create-modal/equipment-create-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './asset-equipment-create-modal.component.html'
})
export class AssetEquipmentCreateModalComponent extends EquipmentCreateModalComponent implements OnInit {

  private readonly asset: Asset;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { asset: Asset }) {
    super();
    this.asset = data.asset;
  }

  /**
   * Set initial Asset value so Spaces can be loaded.
   */
  public ngOnInit(): void {
    super.ngOnInit();
    this.equipmentForm.get('asset').setValue(this.asset.toRelatedAsset());
  }

}
