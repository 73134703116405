import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenubarComponent } from '@app/features/main/menubar/menubar.component';
import { SidenavService } from '@app/features/main/sidenav.service';
import { SharedModule } from '@app/shared/shared.module';

const components = [
  MenubarComponent
];

@NgModule({
  imports: [
    SharedModule,
    NgOptimizedImage,
  ],
  providers: [
    SidenavService
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class MenubarRoutingModule {
}
