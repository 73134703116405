@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
         class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div class="read-value computed">
          @if (fieldConfig.hasPictograms && getFieldValue()) {
            <div>
              <img class='pictogram'
                   [src]="appConfig.PICTOGRAM_FOLDER_PATH + fieldConfig.pictogram(getFieldValue())"
                   [alt]="getFieldValue()">
              @if (fieldConfig.isPictogramWithText) {
                <span>{{ getFieldValue() }}</span>
              }
            </div>
          } @else {
            <span [ngStyle]="customCss">
            <span>
              {{
                (fieldConfig.customOptions?.translate ? translateValue(getFieldValue()) : getFieldValue())
                  | fieldFormatType: fieldConfig.customOptions?.formatType : appManager.currentOrganization.currency
                  | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}
              }}
            </span>
              @if (fieldConfig.suffixType) {
                <span>
                  {{ fieldConfig.suffixType | translate }}
                </span>
              }
            </span>
            <span [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
                  #tooltip="matTooltip"
                  (click)="onClickTooltip(tooltip)">
              <i class="mdi mdi-calculator tooltip-icon"></i>
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}
