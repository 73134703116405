import { Component, inject } from '@angular/core';
import { ActionEnum, EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEditPermissionEnum } from '@app/core/enums/document/document-type-edit-permission.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Document, DocumentInput } from '@app/core/model/entities/document/document';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { AbstractSidebar } from '@app/shared/components/side-panel/abstract-sidebar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'document-sidebar',
  templateUrl: './document-sidebar.component.html',
  providers: [DocumentModalService]
})
export class DocumentSidebarComponent extends AbstractSidebar<Document>{

  public permissionsForEdition: string[] = [PermissionEnum.EDIT_DOCUMENT];
  public permissionsForDeletion: string[] = [PermissionEnum.DELETE_DOCUMENT];

  // Injection
  protected translate: TranslateService = inject(TranslateService);
  private documentsService = inject(DocumentsService);
  private documentModalService = inject(DocumentModalService);

  constructor() {
    super();

    this.entitiesUpdated$ = this.documentsService.documentUpdated$;
    this.sidePanelToggle$ = this.documentsService.sidePanelToggle$
      .pipe(switchMap(documentData => {
        return this.fieldService.getField('name', EntityTypeEnum.DOCUMENT)
          .pipe(
            map(field => {
              this.validators = field.validators;
              return documentData;
            })
          );
      }));
    this.afterLoadActions.push(document => this.setDocumentPermissionsByDocumentType(document.documentType));
  }

  /**
   * Adjust the required permissions for edition and deletion with the Document types, ie with the permission
   * to edit and delete the related entity.
   * @param documentType The Document's type.
   */
  private setDocumentPermissionsByDocumentType(documentType: DocumentTypeEnum): void {
    this.permissionsForEdition = [PermissionEnum.EDIT_DOCUMENT];
    this.permissionsForDeletion = [PermissionEnum.DELETE_DOCUMENT];
    const relatedEntityEditPermission = DocumentTypeEditPermissionEnum[documentType];
    if (relatedEntityEditPermission !== void 0) {
      this.permissionsForEdition.push(relatedEntityEditPermission);
      this.permissionsForDeletion.push(relatedEntityEditPermission);
    }
  }

  /**
   * API call to update the Document.
   * @param data The update input.
   * @returns The updated Document.
   */
  protected override updateEntity(data: Record<string, any>): Observable<Document> {
    Document.transformPropertiesForInput(data);
    return this.documentsService.updateDocument(this.entity, data as DocumentInput).pipe(
      tap((updatedDocument) => {
        this.entity = updatedDocument;
      })
    );
  }

  /**
   * Close the side panel.
   */
  public closeSidePanel(): void {
    this.documentsService.closeDocumentSidePanel();
  }

  /**
   * Display a dialog for the User to delete or archive the Document.
   */
  public deleteDocument(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      this.entity.id
    );
    this.documentModalService.openDeleteDocumentsDialog([this.entity])
      .pipe(takeUntil(this.destroy$))
      .subscribe(({dialogResponse}) => {
        if (dialogResponse === 'delete') {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_DELETED'));
        } else if (dialogResponse === 'archive') {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
        }
      });
  }

  /**
   * Download the Document's file
   */
  public downloadDocument(): void {
    this.analyticsService.trackEvent(new AnalyticsEvent(ActionEnum.DOWNLOAD, EntityTypeEnum.DOCUMENT));
    this.fileService.downloadFile(this.entity.id);
  }

  /**
   * Navigate to the Document's related Asset's sheet's. Open either the pictures or documents tab depending on the
   * current Document's type.
   */
  public async navigateToAssetSheet(): Promise<void> {
    this.closeSidePanel();
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity.assets.first().assetId
    );
    await this.documentsService.navigateToAssetSheet(
      this.entity.documentType,
      this.entity.assets.first().assetId
    );
  }
}
