<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <mat-select #inputSelect [formControl]="formControl" class="ag-input-field-input"
              [placeholder]="control.value['entityName']" (selectionChange)="stopEditing()">
    <mat-option *ngIf="optional" [value]="''">{{ 'LABEL.UNDEFINED' | translate }}</mat-option>
    <mat-option *ngFor="let entity of entityList" [value]="entity">{{ entity.entityName }}</mat-option>
  </mat-select>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
</div>
