import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

  constructor() {
  }

  public ngOnInit(): void {
  }

}
