<ng-container [ngSwitch]="state">
  <span *ngSwitchCase="'accepted'">{{ 'VALUE.INVITATION_ACCEPTED' | translate }}</span>
  <span *ngSwitchCase="'none'">{{ 'VALUE.INVITATION_NONE' | translate }}</span>
  <div *ngSwitchDefault>
    <span>{{ 'VALUE.INVITATION_SENT' | translate }}</span>
    <restricted-icon-button (onClick)="onClickResend($event)"
                            [class]="'mdi mdi-refresh sm'"
                            [matTooltip]="'TOOLTIP.RESEND_INVITATION' | translate"
                            [permissions]="[Permission.MANAGE_USER]"
                            [disabled]="state === 'sending'"
                            [spin]="state === 'sending'"
                            [preconditions]="state === 'pending_resend'">
    </restricted-icon-button>
  </div>
</ng-container>
