import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AccessManager } from '@app/core/services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';

@Directive({
  selector: '[permission-lock]'
})
export class PermissionLockDirective implements OnChanges, AfterViewInit {

  @Input('permission-lock') private permissions: any[] = [];

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private accessManager: AccessManager,
              private appManager: AppManager) {
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.updatePermissionRenderer();
  }

  public ngAfterViewInit(): void {
    this.updatePermissionRenderer();
  }

  private updatePermissionRenderer(): void {
    if (this.permissions.length > 0) {
      this.permissions.forEach((permission) => {
        if (!this.accessManager.hasAccess(permission, !this.appManager.currentOrganization)) {
          this.renderer.setAttribute(this.el.nativeElement, 'hidden', 'true');
        }
      });
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'hidden');
    }
  }
}
