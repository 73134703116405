import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import { Project } from '@app/core/model/entities/project/project';
import {
  AssetProjectCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/projects/modals/asset-project-create-modal.component';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import {
  ProjectCreateModalComponent
} from '@app/features/main/views/projects/projects-inventory/modals/project-create-modal/project-create-modal.component';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { Observable } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ProjectModalService {

  constructor(private popupManager: PopupManager,
              private projectsService: ProjectsService,
              private translate: TranslateService,
              private analyticsService: AnalyticsService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Display a dialog for the user to fill data then create new Project with the data.
   * @param asset If specified, Asset to link the new Project to.
   * @return Created Project.
   */
  public openCreateProjectDialog(asset?: Asset): Observable<Project> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? AssetProjectCreateModalComponent : ProjectCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        tap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.PROJECT)
            .addProperties({
              [AnalyticsKeyEnum.DIALOG_ACTION]: dialogResponse === 'yes' ? ActionEnum.SAVE : ActionEnum.CANCEL
            });
          this.analyticsService.trackEvent(analyticsEvent);
        }),
        filter(dialogResponse => dialogResponse === 'yes'),
        tap(() => this.spinnerService.showSpinner()),
        switchMap(() => this.projectsService.createProject(dialogRef.componentInstance.getGeneratedObject())),
        finalize(() => this.spinnerService.hideSpinner())
      );
  }


  /**
   * Display a dialog asking the user to confirm Projects deletion, then delete the Projects if confirmed.
   * @param projects Projects to delete.
   * @return True if the Projects have been successfully deleted, false if the user cancelled the operation.
   */
  public openDeleteProjectsDialog(projects: Project[]): Observable<boolean> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(projects.length === 1 ? 'TITLE.DELETE_PROJECT' : 'TITLE.DELETE_PROJECTS'),
      dialogMessage: this.translate.instant(projects.length === 1 ? 'LABEL.DELETE_PROJECT_AND_DOCUMENTS' : 'LABEL.DELETE_PROJECTS_AND_DOCUMENTS'),
      type: 'warning',
      okText: this.translate.instant('LABEL.DELETE')
    });
    return dialogRef.afterClosed()
      .pipe(
        tap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.PROJECT);
          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: projects.map(project => project.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.DELETE
            });
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
          }
          this.analyticsService.trackEvent(analyticsEvent);
        }),
        filter(dialogResponse => dialogResponse === 'yes'),
        tap(() => this.spinnerService.showSpinner()),
        switchMap(() => this.projectsService.deleteProjects(projects)),
        finalize(() => this.spinnerService.hideSpinner())
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to a Project entity.
   * @param projectId ID of the Project to link Documents to.
   * @return Created Documents.
   */
  public openUploadProjectDocumentsDialog(projectId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      projectId,
      EntityTypeEnum.PROJECT,
      DocumentTypeEnum.PROJECT_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm a Project's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   */
  public openDeleteProjectDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
