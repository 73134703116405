import { ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';


/**
 * Create a validator function which validates that the value entered in the form control field for the current entity's
 * status is not ACTIVE when the end date is set and has passed.
 * @param getEndDate Function that returns the end date associated with the current entity.
 * @param activeStatus Value that correspond to an ACTIVE status for the current entity.
 * @return A validator function for the form control.
 */
export function endDateIsBeforeToday(getEndDate: () => string, activeStatus: any): ValidatorFn {
  return (control): ValidationErrors | null => {
    const now = dayjs();
    const endDate = getEndDate();
    if (endDate && dayjs(endDate).endOf('day').isBefore(now) && control.value === activeStatus) {
      return {endDateIsBeforeToday: true};
    }
    return null;
  };
}

/**
 * Create a validator function which validates that the value entered in the form control field for the current entity's
 * status is not EXPIRED when the end date is set and is in the future.
 * @param getEndDate Function that returns the end date associated with the current entity.
 * @param expiredStatus Value that correspond to an EXPIRED status for the current entity.
 * @return A validator function for the form control.
 */
export function endDateIsAfterToday(getEndDate: () => string, expiredStatus: any): ValidatorFn {
  return (control): ValidationErrors | null => {
    const now = dayjs();
    const endDate = getEndDate();
    if (endDate && dayjs(endDate).endOf('day').isAfter(now) && control.value === expiredStatus) {
      return {endDateIsAfterToday: true};
    }
    return null;
  };
}

/**
 * Create a validator function which validates that the value entered in the form control field for the current entity's
 * status is not EXPIRED when the end date is not set.
 * @param getEndDate Function that returns the end date associated with the current entity.
 * @param expiredStatus Value that correspond to an EXPIRED status for the current entity.
 * @return A validator function for the form control.
 */
export function endDateIsUndefined(getEndDate: () => string, expiredStatus: any): ValidatorFn {
  return (control): ValidationErrors | null => {
    if (!getEndDate() && control.value === expiredStatus) {
      return {endDateIsUndefined: true};
    }
    return null;
  };
}
