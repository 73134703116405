import { Component, Input } from '@angular/core';
import { ModuleCard } from '@app/core/enums/modules.enum';
import { AppManager } from '@services/managers/app.manager';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'module-icon',
  template: `
    <div class="module-icon-container">
      <div class="module-icon">
        <i [matTooltip]="moduleName | translate"
        [ngClass]="[moduleIcon.namespace, moduleIcon.source, 'mat-icon', 'mat-accent', 'icon-color']"></i>
      </div>
      @if (withName === true) {
        <div>
          <span>{{ moduleName | translate}}</span>
        </div>
      }
    </div>
    `,
  styles: [`
    .module-icon-container {
      display: flex;
      align-items: center;
    }

    .icon-color {
      color: var(--mat-icon-color)
    }

    .module-icon {
      margin-right: 10px;
    }
  `]
})
export class ModuleIconComponent implements ICellRendererAngularComp {

  @Input() public withName: boolean = false;
  @Input() public moduleIcon: { namespace: string, source: string } = {namespace: '', source: ''};
  @Input() public moduleName: string = '';

  private params: any;

  constructor(private appManager: AppManager) {
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;

    // params value is a client subscription module
    if (params.value) {
      this.withName = true;

      const card: ModuleCard = this.appManager.moduleMap.get(params.value.module.code);
      this.moduleName = card.name;
      this.moduleIcon = card.icon;
    }
  }

  public refresh(params: any): boolean {
    return false;
  }
}
