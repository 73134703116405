import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
/**
 * Helper Service to use a native JavaScript external script into a component
 */
export class ScriptService {
  private renderer: Renderer2;

  /**
   * A map of all scripts added by this service, to avoid downloads of duplicates.
   * @private
   */
  private loadedScripts$: {[url: string]: ReplaySubject<string>} = {};

  // RendererFactory2 is use to avoid null provider error
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Append the JS tag to a div element if the script is not already loaded.
   * @param container The html element
   * @param src The path to the script
   * @returns An Observable that emit when the script is loaded
   */
  public loadJsScript<T extends HTMLElement>(container: ElementRef<T>, src: string): Observable<string> {
    if (this.loadedScripts$[src]) return this.loadedScripts$[src].asObservable();
    // Load the script if it's not present
    this.loadedScripts$[src] = new ReplaySubject<string>();
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.id = crypto.randomUUID();
    script.type = 'text/javascript';
    script.defer = true;
    script.async = true;
    script.src = src;
    script.onload = (): void => {
      this.loadedScripts$[src].next(script.id);
      this.loadedScripts$[src].complete();
    };
    container.nativeElement.appendChild(script);
    return this.loadedScripts$[src].asObservable();
  }

}
