<div #tour (fullscreenchange)="onFullscreenChange()" [ngClass]="{hidden: !loaded}">
  <div #metareal class="metareal-container" id="metareal" tabindex="0"></div>

<div *ngIf="currentVirtualTour.useAppSheet else myaPanel" [ngClass]="{show: panelVisible, infopanel: true}">
  <span class="mdi mdi-close mdi-36px close-btn" id="button-close" (click)="hidePanel()"></span>
  <iframe class="panel-content" id="myPanelContent" [title]="'PANEL_CONTENT' | translate" [src]="panelContent"></iframe>
</div>
<ng-template #myaPanel>
  <div [ngClass]="{show: panelVisible, infopanel: true}">
    <asset-works-sidebar *ngIf="currentVirtualTour.entity === EntityTypeEnum.WORK"
                         class="sheet-nav fx-fill">
    </asset-works-sidebar>
    <equipments-sidebar *ngIf="currentVirtualTour.entity === EntityTypeEnum.EQUIPMENT"
                        formId="asset_equipment_sidebar"
                        class="sheet-nav fx-fill">
    </equipments-sidebar>
  </div>
</ng-template>

<div id="entity-selector" *ngIf="virtualTours?.length > 0 || entities?.length > 0"
     [ngClass]="{hidden: !loaded}" class="entity-list">
  <div [ngClass]="{'virtual-tour-menu-expanded' : menuExpanded}"
       class="virtual-tour-menu" id="virtual-tour-menu">
    <div class="virtual-tour-menu-icon mdi mdi-home-map-marker sm" (click)="expandPanel()"></div>
    <div class="virtual-tour-menu-content">
      <div class="select-container">
        <mat-form-field appearance="fill" *ngIf="this.virtualTours.length > 0">
          <mat-label> {{currentVirtualTour.name}}</mat-label>
          <mat-select [formControl]="selectTourForm" (selectionChange)="loadTourFromSelectList($event)">
            <mat-option *ngFor="let tour of virtualTours" [value]="tour.id">
              {{tour.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="entities.length > 0">
          <mat-label>{{'VALUE.ENTITY_' + currentVirtualTour.entity | translate}}</mat-label>
          <mat-select-autocomplete [formControl]="labelsForm"
                                   [dataSource]="entities"
                                   [displayWith]="valueFormatter.bind(this)">
          </mat-select-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<restricted-icon-button
  [ngStyle]="{'right': panelVisible ? 'calc(512px + 1%)' : '1%', 'bottom': fullscreen ? '-92%' : '-87%', 'position': 'absolute'}"
  (onClick)="toggleFullScreen()"
  [id]="'virtual-tour-toggle-fullscreen-button'"
  [class]=" fullscreen ? 'mdi mdi-fullscreen-exit md' : 'mdi mdi-fullscreen md'"
  [preconditions]="loaded"
  [withWhiteBackground]="true">
</restricted-icon-button>
</div>
<spinner [ngClass]="{hidden: loaded}"></spinner>
