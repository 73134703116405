export enum PermissionEnum {
  // Organization
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  EDIT_STORAGE_ORGANIZATION = 'EDIT_STORAGE_ORGANIZATION',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  MANAGE_CONFIG_ORGANIZATION = 'MANAGE_CONFIG_ORGANIZATION',
  VIEW_ALL_ORGANIZATIONS = 'VIEW_ALL_ORGANIZATIONS',

  // Rights
  FULL_VIEW_USER = 'FULL_VIEW_USER',
  MANAGE_USER = 'MANAGE_USER',
  VIEW_CLIENT = 'VIEW_CLIENT',
  MANAGE_USER_CLIENT = 'MANAGE_USER_CLIENT',
  MANAGE_CLIENT = 'MANAGE_CLIENT',
  MANAGE_GROUP = 'MANAGE_GROUP',
  FULL_MANAGE_GROUP = 'FULL_MANAGE_GROUP',
  VIEW_SUBSCRIPTION = 'VIEW_SUBSCRIPTION',

  // Assets
  VIEW_ASSET = 'VIEW_ASSET',
  CREATE_ASSET = 'CREATE_ASSET',
  IMPORT_ASSET = 'IMPORT_ASSET',
  EDIT_ASSET = 'EDIT_ASSET',
  DELETE_ASSET = 'DELETE_ASSET',
  EXPORT_ASSET = 'EXPORT_ASSET',

  // Projects
  VIEW_PROJECT = 'VIEW_PROJECT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  EXPORT_PROJECT = 'EXPORT_PROJECT',
  IMPORT_PROJECT = 'IMPORT_PROJECT',

  // Documents
  VIEW_DOCUMENT = 'VIEW_DOCUMENT',
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',
  EDIT_DOCUMENT = 'EDIT_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  EXPORT_DOCUMENT = 'EXPORT_DOCUMENT',

  // Spaces
  VIEW_SPACE = 'VIEW_SPACE',
  CREATE_SPACE = 'CREATE_SPACE',
  EDIT_SPACE = 'EDIT_SPACE',
  DELETE_SPACE = 'DELETE_SPACE',
  IMPORT_SPACE = 'IMPORT_SPACE',
  EXPORT_SPACE = 'EXPORT_SPACE',

  // Checks
  VIEW_CHECK = 'VIEW_CHECK',
  CREATE_CHECK = 'CREATE_CHECK',
  EDIT_CHECK = 'EDIT_CHECK',
  DELETE_CHECK = 'DELETE_CHECK',
  IMPORT_CHECK = 'IMPORT_CHECK',
  EXPORT_CHECK = 'EXPORT_CHECK',

  // Occupants
  VIEW_OCCUPANT = 'VIEW_OCCUPANT',
  CREATE_OCCUPANT = 'CREATE_OCCUPANT',
  EDIT_OCCUPANT = 'EDIT_OCCUPANT',
  DELETE_OCCUPANT = 'DELETE_OCCUPANT',
  IMPORT_OCCUPANT = 'IMPORT_OCCUPANT',
  EXPORT_OCCUPANT = 'EXPORT_OCCUPANT',

  // Contracts
  VIEW_CONTRACT = 'VIEW_CONTRACT',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  EDIT_CONTRACT = 'EDIT_CONTRACT',
  DELETE_CONTRACT = 'DELETE_CONTRACT',
  IMPORT_CONTRACT = 'IMPORT_CONTRACT',
  EXPORT_CONTRACT = 'EXPORT_CONTRACT',

  // Works
  VIEW_WORK = 'VIEW_WORK',
  CREATE_WORK = 'CREATE_WORK',
  EDIT_WORK = 'EDIT_WORK',
  DELETE_WORK = 'DELETE_WORK',
  IMPORT_WORK = 'IMPORT_WORK',
  EXPORT_WORK = 'EXPORT_WORK',

  // Virtual Tour
  VIEW_VIRTUAL_TOUR = 'VIEW_VIRTUAL_TOUR',
  MANAGE_VIRTUAL_TOUR = 'MANAGE_VIRTUAL_TOUR',

  // CRV
  VIEW_CRV = 'VIEW_CRV',
  EDIT_CRV = 'EDIT_CRV',
  DELETE_CRV = 'DELETE_CRV',
  CREATE_CRV = 'CREATE_CRV',
  IMPORT_CRV = 'IMPORT_CRV',
  EXPORT_CRV = 'EXPORT_CRV',

  // Risks
  VIEW_RISK = 'VIEW_RISK',
  EDIT_RISK = 'EDIT_RISK',
  IMPORT_RISK = 'IMPORT_RISK',
  EXPORT_RISK = 'EXPORT_RISK',

  // Equipments
  VIEW_EQUIPMENT = 'VIEW_EQUIPMENT',
  CREATE_EQUIPMENT = 'CREATE_EQUIPMENT',
  EDIT_EQUIPMENT = 'EDIT_EQUIPMENT',
  DELETE_EQUIPMENT = 'DELETE_EQUIPMENT',
  IMPORT_EQUIPMENT = 'IMPORT_EQUIPMENT',
  EXPORT_EQUIPMENT = 'EXPORT_EQUIPMENT',

  // PowerBI
  VIEW_BI_REPORT = 'VIEW_BI_REPORT',
  EDIT_BI_REPORT = 'EDIT_BI_REPORT',
  EXPORT_BI_REPORT = 'EXPORT_BI_REPORT',

  // JMap
  EDIT_CREDENTIALS_JMAP = 'EDIT_CREDENTIALS_JMAP',
  VIEW_JMAP = 'VIEW_JMAP',

  // AssetAudit
  VIEW_ASSET_AUDIT = 'VIEW_ASSET_AUDIT',
  EDIT_ASSET_AUDIT = 'EDIT_ASSET_AUDIT',
  EXPORT_ASSET_AUDIT = 'EXPORT_ASSET_AUDIT',

  // Snapshots
  VIEW_SNAPSHOT = 'VIEW_SNAPSHOT',
  CREATE_SNAPSHOT = 'CREATE_SNAPSHOT',
  EDIT_SNAPSHOT = 'EDIT_SNAPSHOT',
  DELETE_SNAPSHOT = 'DELETE_SNAPSHOT',
  IMPORT_SNAPSHOT = 'IMPORT_SNAPSHOT',

  // Retool apps
  VIEW_RETOOL_APP = 'VIEW_RETOOL_APP',
  MANAGE_RETOOL_APP = 'MANAGE_RETOOL_APP',

  // Asset Type
  MANAGE_ASSET_TYPE = 'MANAGE_ASSET_TYPE',

  // FieldConfig
  VIEW_FIELD_CONFIG = 'VIEW_FIELD_CONFIG',
  EDIT_FIELD_CONFIG = 'EDIT_FIELD_CONFIG'
}
