import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Entity } from '@app/core/model/entities/entity';
import { Category } from '@app/core/model/other/classification';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
  FieldGroup
} from '@app/core/model/other/field-config';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FieldAnchorDirective } from '@app/shared/components/fields/field-anchor.directive';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { Dimensions } from '@app/shared/extra/column-dimensions.enum';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { getValue } from '@app/shared/extra/utils';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { ClassificationService } from '@app/shared/services/classification.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import {
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
  SelectionChangedEvent
} from 'ag-grid-community';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'technical-category-field-builder',
  templateUrl: './classification-field-builder.component.html',
  styleUrls: ['./classification-field-builder.component.scss'],
  providers: [ClassificationService]
})
export class ClassificationFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  @ViewChild(FieldAnchorDirective, { static: true }) public fieldHost: FieldAnchorDirective;
  @ViewChild('gridContainer') public gridContainer: ElementRef;

  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public fields: { label: string, value: string }[] = [];
  private lastSelectedRowNode: any;
  private forceSelection: boolean;

  protected validationService = inject(ValidationService);
  private translate = inject(TranslateService);
  private columnBuilder = inject(ColumnBuilder);
  private classificationService = inject(ClassificationService);
  public generalService = inject(GeneralService);
  private gridOptionsService = inject(GridOptionsService);

  /**
   * Init grid options.
   */
  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );

    const extraGridOptions: GridOptions = {
      getRowId: (params): string => params.data.id,
      defaultColDef: {
        resizable: true,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        filter: true
      },
      treeData: true,
      getDataPath: (data: Category): string[] => data.path,
      suppressContextMenu: true,
      animateRows: true,
      context: this,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">' + this.translate.instant('LABEL.LOADING') + '</span>',
      suppressMovableColumns: true,
      localeText: this.columnBuilder.localeTextCommon(),
      autoGroupColumnDef: {
        headerName: this.translate.instant('LABEL.' + this.fieldGroup.classificationCode),
        valueGetter: ({ data }: { data: Category }): string => data?.label,
        tooltipValueGetter: ({ data }: { data?: Category }): string => data?.label,
        checkboxSelection: true,
        colId: 'ag-Grid-AutoColumn',
        cellClass: ColumnType.TEXT,
        width: Dimensions.XL,
        cellRendererParams: { suppressCount: true }
      },
      onSelectionChanged: (event: SelectionChangedEvent) => {
        if (!this.forceSelection) return;
        const api = event.api;
        const selectedNodes = api.getSelectedNodes();

        // If a rowNode is selected, then save it as lastSelectedRowNode,
        // Otherwise if row is unselected, force selection of the lastSelectedRowNode
        if (selectedNodes.length > 0) {
          this.lastSelectedRowNode = selectedNodes[0];
        } else if (this.lastSelectedRowNode) {
          this.lastSelectedRowNode.setSelected(true);
        }
      },
      getMainMenuItems: (params: GetMainMenuItemsParams): string[] => params.defaultItems,
      getRowHeight: (): number => this.gridOptionsService.staticGridOptions.rowHeight,
      onRowSelected: this.onRowSelected.bind(this),
      rowMultiSelectWithClick: true,
      onGridSizeChanged: (): void => {
        this.gridApi.sizeColumnsToFit();
      }
    };
    this.gridOptions = {
      ...this.gridOptionsService.staticGridOptions,
      ...extraGridOptions
    };
  }

  /**
   * Init FormStateService value and load Organization Classification.
   */
  public ngOnInit(): void {
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isDisplayed: new BehaviorSubject(true),
      isSingleField: false,
      value: this.initialValue,
      initialValue: this.initialValue
    });
    this.fields = this.getFieldsValues();
    this.getNextState();
  }

  /**
   * Setup hooks and listen for state change.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();

    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe(newMode => {
      if (newMode === FieldStateMode.AFTER_SAVE) {
        this.refreshEntity();
        this.setFieldGroupValue(this.initialValue);
        this.setFieldGroupInitialValue(this.getFieldGroupValue());
        this.fields = this.getFieldsValues();
        this.getNextState();
      } else {
        this.currentMode = newMode;
      }
    });
  }

  /**
   * Auto-size column and select row corresponding to the category if it is present.
   * @param params Ag-grid params.
   */
  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.classificationService.getClassificationByCode(this.fieldGroup.classificationCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(categories => {
        const currentValue = this.getFieldGroupValue();

        // We get computed categories, they should be in categories path, otherwise they're not supposed to be shown
        const computedCategories = this.fieldGroup.fieldConfigs.filter(fc => fc.computed)
          .map(fc => categories.find(c => currentValue[fc.fieldCode] === c.label).id);
        const filteredCategories = computedCategories.length ? categories.filter(c => {
          return computedCategories.every(compCat => c.path.includes(compCat));
        }) : categories;
        this.gridApi.setRowData(filteredCategories);

        // Disable deselection if at least one level of category is computed
        this.forceSelection = !!computedCategories.length;

        // Expand and select current value
        // Note: since data come from the API, null values are supposed to always be the last items of the array
        Object.entries(currentValue).forEach(([label, value]) => {
          const level = this.fieldGroup.fieldConfigs.find(fc => fc.fieldCode === label).classificationLevel;
          const categoryToExpandOrSelect = filteredCategories.find(category => {
            return category.label === value && category.path.length === level;
          });
          if (level === Object.keys(currentValue).length) {
            // Select category
            this.gridApi.getRowNode(categoryToExpandOrSelect.id).setSelected(true);
          } else {
            // Expand parent category
            this.gridApi.getRowNode(categoryToExpandOrSelect.id).setExpanded(true);
          }
        });
        this.gridApi.sizeColumnsToFit();
      });
  }

  /**
   * Update the FieldGroup's value in the FormStateService whenever a row is selected.
   * @param params Ag-grid selection event.
   */
  public onRowSelected(params: RowSelectedEvent): void {
    if (params.node.isSelected()) {
      const category = params.data as Category;
      this.setFieldGroupValue(this.convertPathToCategories(category.path));
    } else if (this.gridApi.getSelectedRows().length === 0) {
      this.setFieldGroupValue('');
    }
  }

  /**
   * Get the config of a category level field.
   * @param fieldCode Field's code.
   * @return Field's config.
   */
  public getFieldConfig(fieldCode: string): FieldConfig {
    return this.fieldGroup.fieldConfigs.find(fieldConfig => fieldConfig.fieldCode === fieldCode);
  }

  /**
   * Switch field to edit mode then focus and scroll element into view.
   */
  public edit(): void {
    super.edit();

    // Focus and scroll to field
    setTimeout(() => {
      this.gridContainer.nativeElement.focus();
      this.gridContainer.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center' });
    });
  }

  /**
   * Fetch the initial value of each field of the fieldGroup.
   * @return Object containing initial value of each entity field.
   * @private
   */
  private get initialValue(): { [k: string]: string } {
    return Object.fromEntries(
      this.fieldGroup.fieldConfigs.reduce((prev, fc) => {
        const value = getValue(this.entity, fc.fieldPath);
        if (!value) return prev;
        prev.push([fc.fieldCode, value]);
        return prev;
      }, [])
    );
  }

  /**
   * Match category ids with category keys of the entity.
   * @param path List of Category ids.
   * @return Object containing entity field and the category label for each id.
   * @private
   */
  private convertPathToCategories(path: string[]): { [k: string]: string } {
    // Assign category levels or use default value.
    const pathCategories = path.reduce((categories, id, level) => {
      const category = this.classificationService.categories.find(category => category.id === id);
      const field = this.fieldGroup.fieldConfigs.find(f => f.classificationLevel === level + 1);
      categories[field.fieldCode] = category.label;
      return categories;
    }, {} as any);
    return {
      ...Object.fromEntries(
        this.fieldGroup.fieldConfigs.map(fc => fc.fieldCode).map(fieldCode => [fieldCode, ''])
      ),
      ...pathCategories
    };
  }

  /**
   * Get the label and value of each subfield of the FieldGroup.
   * @return Fields' values.
   * @private
   */
  private getFieldsValues(): { label: string, value: string }[] {
    return Object.entries(this.getFieldGroupValue())
      .map(([label, value]: [string, string]) => ({ label, value }));
  }
}
