import { Directive, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { integer } from './validator';

const INTEGER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IntegerValidator),
  multi: true
};

@Directive({
  selector: '[integer][formControlName],[integer][formControl],[integer][ngModel]',
  providers: [INTEGER_VALIDATOR]
})
export class IntegerValidator implements Validator, OnInit, OnChanges {
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = integer;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'INTEGER') {
        this.validator = integer;
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
