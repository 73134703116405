import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';

/**
 * Maps a field type to the validators it supports
 * @param fieldType the fieldType selected for which we want the validators
 * @return a list of validator types and whether they require a definition
 */
export default function validatorFieldTypeMapping(fieldType: FieldTypeEnum): {validator: ValidatorType, hasDefinition: boolean}[] {
  const validators: {validator: ValidatorType, hasDefinition: boolean}[] = [];

  if ([FieldTypeEnum.TEXT, FieldTypeEnum.TEXTAREA,
    FieldTypeEnum.TEXT_AUTOCOMPLETE, FieldTypeEnum.SUGGESTBOX,
    FieldTypeEnum.CHIPS, FieldTypeEnum.CHIPS_AUTOCOMPLETE].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.MAX_LENGTH, hasDefinition: true},
      {validator: ValidatorType.UNIQUE, hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.NUMERIC].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.MIN_VALUE, hasDefinition: true},
      {validator: ValidatorType.MAX_VALUE, hasDefinition: true},
      {validator: ValidatorType.INTEGER, hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.CHIPS, FieldTypeEnum.CHIPS_AUTOCOMPLETE, FieldTypeEnum.LIST_MULTIVALUE].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.DISTINCT, hasDefinition: false}
    );
  }
  if ([FieldTypeEnum.DATE, FieldTypeEnum.DATETIME].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.MAX_NOW, hasDefinition: false},
      {validator: ValidatorType.AFTER_DATE, hasDefinition: true},
      {validator: ValidatorType.BEFORE_DATE, hasDefinition: true}
    );
  }
  if ([FieldTypeEnum.YEAR].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.MIN_VALUE, hasDefinition: true},
      {validator: ValidatorType.MAX_VALUE, hasDefinition: true}
    );
  }
  if ([FieldTypeEnum.RADIOBUTTON].includes(fieldType)) {
    validators.push();
  }
  if ([FieldTypeEnum.LINK, FieldTypeEnum.EMAIL].includes(fieldType)) {
    validators.push(
      {validator: ValidatorType.REGEX, hasDefinition: true} // How to implement visually?
      /*
      TODO V2 - TTT-3417
      string contains, startsWith or endsWith definition?
       */
    );
  }

  /*
  TODO V2 - TTT-3417
  Some validators reference other fields and will be added to their corresponding lists in V2
  - requiredTrue
  - requiredFalse
  - equalTo
  - notEqualTo
  - afterDateField,
  - beforeDateField
  - gteField
  - lteField
   */

  return validators;
};
