@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
         class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
             [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
          @if (getFieldValue()?.length > 0) {
            <mat-chip-listbox tabIndex="-1">
              @for (chip of getFieldValue(); track chip; let i = $index) {
                <mat-chip-option (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                                 [ngStyle]="getChipColor(i)">
                  {{ translateValue(chip) }}
                </mat-chip-option>
              }
            </mat-chip-listbox>
          } @else {
            <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
          }
          @if (fieldConfig.suffixType) {
            <span>{{ fieldConfig.suffixType | translate }}</span>
          }
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span class="overlay-icon">
              @if (preconditionsForEdition) {
                <i class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate"
                   class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div #fieldContainer class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
        <form autocomplete="off" class="chips-field-builder-form" [formGroup]="form">
          <mat-form-field appearance="outline" [formGroup]="form">
            <mat-label>{{ fieldConfig.label }}</mat-label>
            <mat-chip-grid #chipList>
              @for (chip of chips; track chip; let i = $index) {
                <mat-chip-row (removed)="remove(chip)"
                              [removable]="true"
                              [ngStyle]="getChipColor(i)">
                  {{ translateValue(chip) }}
                  <button matChipRemove>
                    <i class="mdi mdi-close-circle"></i>
                  </button>
                </mat-chip-row>
              }
              <input #inputField (matChipInputTokenEnd)="input($event)" formControlName="field"
                     (keyup.escape)="onEscapeCancel($event)"
                     [matAutocomplete]="suggestions"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     class="chip-input"
                     type="text">
            </mat-chip-grid>
            @if (fieldConfig.suffixType) {
              <span matTextSuffix>{{ fieldConfig.suffixType | translate }}</span>
            }
            <mat-autocomplete #suggestions="matAutocomplete" (optionSelected)="select($event)"
                              class="autocomplete-container">
              @for (fieldValue of filteredOptions | async; track fieldValue) {
                <mat-option [value]="fieldValue">
                  {{ translateValue(fieldValue) }}
                </mat-option>
              }
            </mat-autocomplete>
            @for (validator of erroredValidators; track validator) {
              <mat-error>
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </mat-error>
            }
          </mat-form-field>
        </form>
        @if (isSingleField) {
          <div class="save-options">
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
              <i class="mdi mdi-close"></i>
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                    class="button save">
              <i class="mdi mdi-check"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
