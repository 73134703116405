export enum UserRoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN_TB = 'ADMIN_TB',
  ADMIN = 'ADMIN',
  USER_TB = 'USER_TB',
  USER = 'USER'
}

export class UserRoleDefinition {
  static SUPER_ADMIN = new UserRoleDefinition(UserRoleEnum.SUPER_ADMIN,
    [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.ADMIN_TB, UserRoleEnum.ADMIN, UserRoleEnum.USER_TB, UserRoleEnum.USER]);
  static ADMIN_TB = new UserRoleDefinition(UserRoleEnum.ADMIN_TB,
    [UserRoleEnum.ADMIN, UserRoleEnum.ADMIN_TB, UserRoleEnum.USER_TB, UserRoleEnum.USER]);
  static ADMIN = new UserRoleDefinition(UserRoleEnum.ADMIN,
    [UserRoleEnum.USER, UserRoleEnum.ADMIN]);
  static USER_TB = new UserRoleDefinition(UserRoleEnum.USER_TB,
    []);
  static USER = new UserRoleDefinition(UserRoleEnum.USER,
    []);

  private constructor(public roleCode: UserRoleEnum,
                      public controlOver: UserRoleEnum[]) {
  }
}
