import { inject, Injectable } from '@angular/core';
import { User } from '@app/core/model/client/user';
import { Entity } from '@app/core/model/entities/entity';
import { thenReturn } from '@app/shared/extra/utils';
import { GeneralService } from '@services/general.service';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { map, merge, Observable, of, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UsersService {
  private generalService = inject(GeneralService);

  /**
   * Check whether an email address is currently being used by a user.
   * @param email Email address to check.
   * @return Observable that emits a single boolean value indicating whether a user was found.
   */
  public userExists(email: string): Observable<boolean> {
    const query = gql`
      query UserExists($email: String!) {
        userExists(email: $email)
      }
    `;
    const variables = {email};
    return this.generalService.get(query, variables)
      .pipe(map(response => response.data['userExists'] as boolean));
  }

  /**
   * Retrieve information about the User that created an entity and the last User who updated it
   * and update the entity's fields accordingly. First looks for the User in the Apollo cache, then makes an API request
   * if the User was not found in it.
   * @param entity Entity which creation User and last change User's info should be filled.
   * @return An Observable that will emit the updated Entity.
   */
  public fetchUsersInfo<T extends Entity>(entity: T): Observable<T> {
    // If creationUserId or lastChangeUserId is missing
    if (!entity.creationUserId || !entity.lastChangeUserId) {
      return of(entity);
    }

    const QUERY = gql`
      query UserInfo($userId: Int!) {
        userInfo(id: $userId) {
          id
          name
        }
      }
    `;

    return merge(
      // Split into two queries because of Apollo caching optimizations.
      this.generalService.get(QUERY, {userId: entity.creationUserId}, 'cache-first').pipe(
        tap(response => entity.creationUser = plainToInstance(User, response.data['userInfo'])),
      ),
      this.generalService.get(QUERY, {userId: entity.lastChangeUserId}, 'cache-first').pipe(
        tap(response => entity.lastChangeUser = plainToInstance(User, response.data['userInfo'])),
      )
    )
      .pipe(thenReturn(entity));
  }

  /**
   * Send or resend an invitation to a User.
   * @param user User to whom to send an invitation.
   * @return An Observable that will emit the User once their invitation has been sent, with lastInvite updated.
   */
  public resendInvitation(user: User): Observable<User> {
    const MUTATION = gql`
      mutation ResendInvitation($userId: Int!) {
        resendUserInvitation(userId: $userId) {
          id
          name
          lastInvite
        }
      }
    `;
    return this.generalService.set(MUTATION, {userId: user.id})
      .pipe(map(response => plainToInstance(User, response.data['resendUserInvitation'])));
  }
}
