import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { CreateDocumentInput, Document } from '@app/core/model/entities/document/document';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { TranslateService } from '@ngx-translate/core';
import { SectionService } from '@services/section.service';
import dayjs from 'dayjs';
import {
  OrganizationDocumentCreateModalComponent
} from '@app/features/main/views/organization-documents/modals/organization-document-create-modal/organization-document-create-modal.component';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';

@Component({
  templateUrl: './project-documents-create-modal.component.html'
})
export class ProjectDocumentsCreateModalComponent extends OrganizationDocumentCreateModalComponent {

  constructor(appConfig: AppConfig,
              documentsService: DocumentsService,
              sectionService: SectionService,
              columnBuilder: ColumnBuilder,
              translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    super(appConfig, documentsService, sectionService, columnBuilder, translate, data);

    // Add columns that are not relevant for a Project's Document
    this.propertiesGridColumnState = [
      {colId: 'defaultSortCol', sort: 'asc'},
      {colId: 'assets', hide: true},
      {colId: 'assetDocumentSection', hide: true},
      {colId: 'documentState', hide: true}
    ];
  }

  /**
   * Create a Document input for each row of the properties grid.
   * @return Document inputs.
   */
  public getCreateDocumentsInput(): CreateDocumentInput[] {
    const documentsToUpdate: CreateDocumentInput[] = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        documentsToUpdate.push(
          <CreateDocumentInput>{
            fileName: node.data.fileName,
            documentType: DocumentTypeEnum.PROJECT_DOCUMENT,
            documentState: DocumentStateEnum.ACTIVE,
            documentName: (node.data.document.documentName)
              ? node.data.document.documentName + (node.data.document as Document).getExtension()
              : node.data.document.name,
            properties: {
              // Use spread to add optional field if condition is fulfilled
              ...(node.data.document.documentCategory && {documentCategory: node.data.document.documentCategory}),
              ...(node.data.document.date && {documentDate: dayjs(node.data.document.date).format(this.appConfig.DATE_FORMAT)}),
              ...(node.data.document.description && {description: node.data.document.description})
            }
          }
        );
      });
    }
    return documentsToUpdate;
  }
}
