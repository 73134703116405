import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';
import * as path from 'path-browserify';

export const maxLengthFileName = (maxLength: number, extension: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const name = control.pristine ? path.basename(control.value, extension) : control.value;

    return name.length <= maxLength - extension.length ? null : {maxLengthFileName: true};
  };
};
