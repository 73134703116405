import { Component, inject, Input } from '@angular/core';
import { Currency } from '@app/core/enums/currency.enum';
import { FormatType } from '@app/core/model/other/field-config';
import { AppManager } from '@services/managers/app.manager';

@Component({
  selector: 'indicator-widget',
  templateUrl: './indicator-widget.component.html',
  styleUrls: ['./indicator-widget.component.scss']
})
export class IndicatorWidgetComponent {

  @Input() public isAnyFilterPresent: boolean;
  public currency: Currency;

  @Input() public label: string;
  @Input() public icon: string;
  @Input() public formatType: FormatType;
  @Input() public suffix: string;

  @Input() public totalValue: number;
  @Input() public filteredValue: number;

  protected appManager = inject(AppManager);

  constructor() {
    this.currency = this.appManager.currentOrganization.currency;
  }

}
