import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum CreateDatagridVisualisationNameActionTypes {
  FieldValueChange = 'createDatagridVisualisationName_change',
  FieldValueReset = 'createDatagridVisualisationName_reset',
  FieldValueRevert = 'createDatagridVisualisationName_revert'
}

export class CreateDatagridVisualisationNameChange extends PayloadAction {
  readonly type = CreateDatagridVisualisationNameActionTypes.FieldValueChange;
}

export class CreateDatagridVisualisationNameReset extends PayloadAction {
  readonly type = CreateDatagridVisualisationNameActionTypes.FieldValueReset;
}

export class CreateDatagridVisualisationNameRevert implements Action {
  readonly type = CreateDatagridVisualisationNameActionTypes.FieldValueRevert;
}
