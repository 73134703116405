import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const percent = (control: AbstractControl): ValidationErrors | null => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  return /^(?:-?\d+|-?\d{1,3}(?:\s\d{3})+)?(?:,\d{1,2})?$/.test(control.value) ? null : { percent: true };
};
