import { ContractStateEnum } from '@app/core/enums/contract/contract-state.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { getValue, hasProperty, setValue } from '@app/shared/extra/utils';
import { Expose, Transform, Type } from 'class-transformer';

export class Contract extends Entity {
  public entityType = EntityTypeEnum.CONTRACT;

  public assetId: string;
  public provider: string;

  @Expose({name: 'spaceIdsList'})
  public spaceIds: string[];
  @Type(() => Document) public documents: Document[] = [];
  @Transform(fn => ContractStateEnum[fn.value]) public status: ContractStateEnum;

  public dataDate: string;

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? this.provider.toString();
  }

  // Properties to be renamed
  protected static inputMapping = {
    spaces: {input: ['computedProperties', 'spaces'], output: 'spaceIds'}
  };

  /**
   * Some GraphQL inputs require different keys than those available in the Form State Service
   * since those keys correspond to field codes, so we need to replace them accordingly
   * @param obj the Graphql input object
   */
  public static transformPropertiesForInput(obj: Record<string, unknown>): void {
    for (const inputMappingElement in Contract.inputMapping) {
      // Transform input, mapping keys as defined in the inputMapping above
      if (hasProperty(obj, Contract.inputMapping[inputMappingElement].input)) {
        const source = getValue(obj, Contract.inputMapping[inputMappingElement].input);
        setValue(obj, [Contract.inputMapping[inputMappingElement].output], source);
      }
    }

    // Clean up by removing older keys
    for (const inputMappingElement in Contract.inputMapping) {
      delete obj[Contract.inputMapping[inputMappingElement].input.first()];
    }
  }
}

export interface ContractInput {
  provider?: string,
  spaceIds?: string[];
  dataDate?: string;
}
