import { Injectable } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Space, SpaceInput } from '@app/core/model/entities/asset/space';
import { Document } from '@app/core/model/entities/document/document';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { thenReturn } from '@app/shared/extra/utils';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { from, Observable, of, Subject, tap } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SpacesService {
  private sidePanelToggleSubject = new Subject<Space | null>();

  private addSpaceSubject = new Subject<Space>();
  private updateSpacesSubject = new Subject<Space[]>();
  private deleteSpaceSubject = new Subject<{ deletedSpace: Space, deleteChildren: boolean }>();

  private readonly spaceInfoGraphqlFragment = gql`
    fragment SpaceInfo on Space {
      id
      name
      identifier
      parentPathList
      assetId
      properties
      computedProperties
      creationDate
      creationUserId
      lastChangeDate
      lastChangeUserId
      dataDate
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private appConfig: AppConfig,
              private documentsService: DocumentsService,
              private assetsService: AssetsService,
              private usersService: UsersService) {
  }

  /**
   * Emits Spaces of the current Organization.
   */
  public get spaces$(): Observable<Space[]> {
    const COMBINED_QUERY = gql`
      query SpacesByOrganizationId($organizationId: String!) {
        spacesByOrganizationId(organizationId: $organizationId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['spacesByOrganizationId'] as Space[])),
        tap(spaces => {
          // Set hasChildren flag
          spaces.forEach(space => {
            space.hasChildren = spaces.some(otherSpace => otherSpace.parentPath.includes(space.id));
          });
        }),
        // Fetch Users who created and last updated the Space
        switchMap(spaces => from(spaces)),
        mergeMap((space) => this.usersService.fetchUsersInfo(space)),
        toArray()
      );
  }

  /**
   * Emits a Space once it has been created.
   */
  public get spaceAdded$(): Observable<Space> {
    return this.addSpaceSubject.asObservable();
  }

  /**
   * Emits Spaces whenever they have been updated.
   */
  public get spacesUpdated$(): Observable<Space[]> {
    return this.updateSpacesSubject.asObservable();
  }

  /**
   * Emits a Space after it has been deleted.
   */
  public get spaceDeleted$(): Observable<{ deletedSpace: Space, deleteChildren: boolean }> {
    return this.deleteSpaceSubject.asObservable();
  }

  /**
   * Emits a Space whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Space | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Fetch Space belonging to a spaceId.
   * @param spaceId ID of the Space to return.
   * @return Space
   */
  public loadSpace(spaceId: string): Observable<Space> {
    const COMBINED_QUERY = gql`
      query SpaceById($spaceId: String!) {
        spaceById(spaceId: $spaceId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {spaceId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['spaceById'] as Space)),
      );
  }

  /**
   * Fetch Spaces belonging to an Asset.
   * @param assetId ID of the Asset whose Spaces to return.
   * @return Asset's Spaces.
   */
  // TODO TTT-2814 merge with spaces$
  public loadSpaces(assetId: string): Observable<Space[]> {
    const COMBINED_QUERY = gql`
      query SpacesByAssetId($assetId: String!) {
        spacesByAssetId(assetId: $assetId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {assetId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['spacesByAssetId'] as Space[])),
        tap(spaces => {
          // Set hasChildren flag
          spaces.forEach(space => {
            space.hasChildren = spaces.some(otherSpace => otherSpace.parentPath.includes(space.id));
          });
        }),
        // Fetch Users who created and last updated the Space
        switchMap(spaces => from(spaces)),
        mergeMap((space) => this.usersService.fetchUsersInfo(space)),
        toArray()
      );
  }

  /**
   * Fetch children of a space.
   * @param spaceId ID of the parent Space.
   * @return Space's children.
   */
  public loadChildrenSpaces(spaceId: string): Observable<Space[]> {
    const COMBINED_QUERY = gql`
      query GetSpacesByParentId($parentSpaceId: String!) {
        spacesByParentId(parentSpaceId: $parentSpaceId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {parentSpaceId: spaceId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['spacesByParentId'] as Space[]))
      );
  }

  /**
   * Fetch the root space of an asset.
   * @param assetId ID of the asset to which get the root space.
   * @return Space root related to an asset Space.
   */
  public loadAssetRootSpace(assetId: string): Observable<Space> {
    const COMBINED_QUERY = gql`
      query GetRootSpaceByAssetId($assetId: String!) {
        rootSpaceByAssetId(assetId: $assetId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {assetId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['rootSpaceByAssetId'] as Space)),
      );
  }

  /**
   * Create new Space in an Asset and within the given parent Space.
   * @param assetId ID of the Asset the new Space belongs to.
   * @param spaceInput New Space's information.
   * @return Created Space.
   */
  public createSpace(assetId: string, spaceInput: any): Observable<Space> {
    const COMBINED_MUTATION = gql`
      mutation CreateSpaceMutation($assetId: String!, $spaceInput: SpaceInput!) {
        createSpace(assetId: $assetId, spaceInput: $spaceInput) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {assetId, spaceInput};

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['createSpace'] as Space)),
        switchMap(newSpace => {
          newSpace.hasChildren = false;
          this.addSpaceSubject.next(newSpace);

          // Refresh parent Spaces
          return this.getRelatedSpacesById(newSpace.id)
            .pipe(
              switchMap(spaces => from(spaces)),
              mergeMap((space) => this.usersService.fetchUsersInfo(space)),
              toArray(),
              map(updatedSpaces => {
                // Set hasChildren flag
                updatedSpaces.forEach(space => {
                  space.hasChildren = updatedSpaces.some(otherSpace => otherSpace.parentPath.includes(space.id));
                });
                this.updateSpacesSubject.next(updatedSpaces);
                return newSpace;
              })
            );
        })
      );
  }

  /**
   * Call the API to update a Space.
   * @param space Space to update.
   * @param spaceInput Data for updating the Space.
   * @param moveChildren If true, children will be moved with the space, otherwise they'll be reattached to the space's current parent.
   * @return Updated Space.
   */
  public updateSpace(space: Space, spaceInput: SpaceInput, moveChildren: boolean = false): Observable<Space> {
    const COMBINED_MUTATION = gql`
      mutation updateSpace($spaceId: String!, $spaceInput: SpaceInput!, $moveSpaceChildren: Boolean){
        updateSpace(spaceId: $spaceId, spaceInput: $spaceInput, moveSpaceChildren: $moveSpaceChildren){
          ...SpaceInfo
        }
      }${this.spaceInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      spaceId: space.id,
      spaceInput: {
        name: space.name,
        parentPath: space.parentPath,
        ...spaceInput
      },
      moveSpaceChildren: moveChildren
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Space, response.data['updateSpace'] as Space)),
        switchMap(updatedSpace => {
          // Refresh updated spaces depending on if the parentPath was updated or not
          const updatedSpaces = spaceInput.hasOwnProperty('parentPath')
            ? this.getUpdatedSpacesAfterMovingSpace(space.id, space.parentPath.lastItem())
            : this.getRelatedSpacesById(updatedSpace.id);

          return updatedSpaces
            .pipe(
              switchMap(spaces => from(spaces)),
              mergeMap((space) => this.usersService.fetchUsersInfo(space)),
              toArray(),
              map(updatedSpaces => {
                this.updateSpacesSubject.next(updatedSpaces);
                return updatedSpace;
              })
            );
        })
      );
  }

  /**
   * Call the API to delete the given Space. If the Space has children, they can either be deleted as well, or moved
   * to the deleted Space's parent.
   * @param space Space to delete.
   * @param deleteChildren Whether to delete the Space's children as well.
   * @return Observable emitting true if the Space was deleted, otherwise false.
   */
  public deleteSpace(space: Space, deleteChildren: boolean): Observable<boolean> {
    const COMBINED_MUTATION = gql`
      mutation deleteSpace($spaceId: String!, $deleteChildren: Boolean!) {
        deleteSpace(spaceId: $spaceId, deleteChildren: $deleteChildren)
      }
    `;
    const MUTATION_VAR = {spaceId: space.id, deleteChildren};

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteSpace'] as boolean),
        mergeMap(success => {
          if (success) {
            this.deleteSpaceSubject.next({deletedSpace: space, deleteChildren});

            // Refresh parent Spaces
            return this.getRelatedSpacesById(space.parentPath.lastItem())
              .pipe(
                tap(updatedSpaces => this.updateSpacesSubject.next(updatedSpaces)),
                thenReturn(true)
              );
          } else {
            return of(false);
          }
        })
      );
  }

  /**
   * Navigate to the spaces tab of the Asset's sheet.
   * @param assetId Id of the Asset to navigate to.
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'spaces');
  }

  /**
   * Load all the Documents related to a given Space.
   * @param spaceId ID of the Space the Documents are related to.
   * @return Observable emitting Documents linked to the Space.
   */
  public loadSpaceDocuments(spaceId: string): Observable<Document[]> {
    return this.documentsService.loadEntityDocuments(
      spaceId,
      EntityTypeEnum.SPACE,
      DocumentTypeEnum.SPACE_DOCUMENT
    );
  }

  /**
   * Open the side panel with information about the selected Space.
   * @param space Space to be displayed in the side panel.
   */
  public openSpaceSidePanel(space: Space): void {
    this.sidePanelToggleSubject.next(space);
  }

  /**
   * Close the side panel.
   */
  public closeSpaceSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Check whether the Space's parent path does not exceed the maximum allowed number of parents.
   * @param space Space to check.
   * @return True if the Space does not exceed the maximum number of parents, false if the limit is exceeded.
   */
  public isPathLengthValid(space: Space): boolean {
    // FIXME use maxSubspaceCount from organization once the configuration is added
    // this.appManager.currentOrganization.maxSubspaceCount;
    return space.parentPath.length < this.appConfig.MAX_SUBSPACE_COUNT;
  }

  /**
   * Return whether moving a Space to another parent is allowed. This does not actually move the Space,
   * use {@link updateSpace} for moving Spaces after checking using this method.
   * @param spaceId ID of the Space that needs to be moved.
   * @param parentPath ID of the intended new parent Space.
   * @param moveChildren Whether the move would also move the Space's children.
   * @return Null if move is allowed, error returned otherwise.
   */
  public isSpaceMoveAllowed(spaceId: string, parentPath: string[], moveChildren: boolean): Observable<Error | null> {
    const COMBINED_MUTATION = gql`
      query validateMoveSpace($spaceId: String!, $parentPath: [String]!, $moveChildren: Boolean!){
        isSpaceMoveAllowed(spaceId: $spaceId, parentPath: $parentPath, moveSpaceChildren: $moveChildren)
      }
    `;
    const MUTATION_VAR = {spaceId, parentPath, moveChildren};

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => {
          const error = response.data['isSpaceMoveAllowed'] as string;
          return error ? new Error(error) : null;
        })
      );
  }

  /**
   * Fetch all related spaces of a Space (including itself) from the API.
   * @param spaceId ID of the Space to look for parents.
   * @return Related Spaces.
   */
  private getRelatedSpacesById(spaceId: string): Observable<Space[]> {
    const COMBINED_QUERY = gql`
      query GetRelatedSpacesById($spaceId: String!) {
        spaceAndRelatedSpaces(spaceId: $spaceId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {spaceId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(map(response => plainToInstance(Space, response.data['spaceAndRelatedSpaces'] as Space[])));
  }

  /**
   * Return all Spaces that hav been updated after moving a Space.
   * @param spaceId ID of the moved Space.
   * @param oldParentSpaceId ID of the Space's parent before it was moved.
   * @return Updated Spaces.
   */
  private getUpdatedSpacesAfterMovingSpace(spaceId: string, oldParentSpaceId: string): Observable<Space[]> {
    // TODO : TTT-2240 return updated with updateParentId method
    const COMBINED_QUERY = gql`
      query UpdatedSpacesAfterMovingSpace($spaceId: String!, $oldParentSpaceId: String!) {
        updatedSpacesAfterMovingSpace(spaceId: $spaceId, oldParentSpaceId: $oldParentSpaceId) {
          ...SpaceInfo
        }
      }
      ${this.spaceInfoGraphqlFragment}
    `;
    const QUERY_VAR = {spaceId, oldParentSpaceId};

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(map(response => plainToInstance(Space, response.data['updatedSpacesAfterMovingSpace'] as Space[])));
  }
}
