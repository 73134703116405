import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { Space } from '@app/core/model/entities/asset/space';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './organization-space-create-modal.component.html'
})
export class OrganizationSpaceCreateModalComponent implements OnDestroy, GeneratesObject {

  public spaceForm: FormGroup<{
    assetName: FormControl<string>,
    parentSpaceName: FormControl<string>,
    spaceName: FormControl<string>
  }>;

  protected assetName: string;
  protected parentSpace: Space;

  protected destroy$ = new Subject<void>();

  constructor(public appConfig: AppConfig,
              protected fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) data: { assetName?: string, parentSpace: Space }) {
    this.assetName = data.assetName ?? '';
    this.parentSpace = data.parentSpace;

    // Init form
    this.spaceForm = this.fb.group({
      assetName: this.fb.control({value: this.assetName, disabled: true}),
      parentSpaceName: this.fb.control({value: this.parentSpace.name, disabled: true}),
      spaceName: this.fb.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ]))
    });
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get form data to use for creating a new Space.
   * @return Create Space form data.
   */
  public getGeneratedObject(): { spaceInput: { name: string, parentPath: string[] } } {
    return {
      spaceInput: {
        name: this.spaceForm.get('spaceName').value,
        parentPath: [...this.parentSpace.parentPath, this.parentSpace.id]
      }
    };
  }
}
