import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Lease } from '@app/core/model/entities/asset/lease';
import {
  OrganizationLeaseCreateModalComponent
} from '@app/features/main/views/organization-occupants/modals/organization-lease-create-modal/organization-lease-create-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';

@Component({
  templateUrl: './organization-lease-renew-modal.component.html'
})
export class OrganizationLeaseRenewModalComponent extends OrganizationLeaseCreateModalComponent {

  private leaseToRenew: Lease;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { leaseToRenew: Lease }) {
    super();
    this.leaseToRenew = data.leaseToRenew;
  }

  /**
   * Init lease form.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Set form value for the Occupant's name and disable input
    this.leaseForm.controls.occupantName.setValue(this.leaseToRenew.occupant.name);
    this.leaseForm.controls.occupantName.disable();

    this.leaseForm.controls.asset.setValue(
      new RelatedAsset(this.leaseToRenew.computedProperties.asset.first())
    );
  }

  /**
   * Return empty Observable. This override method does not fetch accessible Assets from the
   * API as it is not necessary in that case.
   * @return Empty Observable.
   * @protected
   */
  protected fetchAssets(): Observable<RelatedAsset[]> {
    return EMPTY;
  }
}
