@if (fieldInitValue?.length) {
  <!-- FIXME: should be handled by section filters -->
  @if (currentMode === FieldMode.READ) {
    <div class="read-field-container">
      <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldConfig.label }}
        </div>
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div
            (click)="accessManager.hasAllNeededPermissions(permissionsForEdition) ? onClickActivate($event) : $event.stopPropagation()"
            (keyup.enter)="accessManager.hasAllNeededPermissions(permissionsForEdition) ? onEnterActivate($event) : $event.stopPropagation()"
            [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition), 'width-auto' : widthAuto, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}"
            tabindex="0">
            @for (space of spacesMap | keyvalue; track space; let i = $index) {
              <span class="field-value">
                @if (i!==0) {
                  <span>, </span>
                }
                <a (click)="onSpaceFieldClick(space.key)" class="clickable-field" tabindex="0">{{ space.value }}</a>
              </span>
            }
            @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
              <span class="overlay-icon">
                @if (preconditionsForEdition) {
                  <i class="mdi mdi-pencil selectable"></i>
                } @else {
                  <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
                }
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (currentMode === FieldMode.EDIT) {
    <div>
      <div class="field-row fx-layout-col">
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldConfig.label }}
        </div>
        <ng-container>
          <div class="field-container fx-flex">
            <div class="ag-grid-container" #gridContainer>
              <ag-grid-angular (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"
                class="ag-theme-material clickable-rows"
                id="tree-datagrid"
                rowSelection="single"
                style="height: 100%; width: 100%; margin: 10px 0;">
              </ag-grid-angular>
            </div>
            @for (validator of erroredValidators; track validator) {
              <mat-error>
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </mat-error>
            }
            @if (isSingleField) {
              <div class="button-container fx-layout-row fx-justify-end fx-align-center">
                <button id="cancel-button"
                  (click)="onClickCancel()"
                  (keyup.escape)="onClickCancel()"
                  mat-stroked-button>
                  {{ 'BUTTON.CANCEL' | translate }}
                </button>
                <button id="save-button"
                  (click)="onClickSave()"
                  [disabled]="!form.valid || form.get('field').pending"
                  color="accent"
                  mat-raised-button>
                  {{ 'BUTTON.SAVE' | translate }}
                </button>
              </div>
            }
          </div>
        </ng-container>
      </div>
    </div>
  }
}
