import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum ClientNameActionTypes {
  FieldValueChange = 'clientName_change',
  FieldValueReset = 'clientName_reset',
  FieldValueRevert = 'clientName_revert'
}

export class ClientNameChange extends PayloadAction {
  readonly type = ClientNameActionTypes.FieldValueChange;
}

export class ClientNameReset extends PayloadAction {
  readonly type = ClientNameActionTypes.FieldValueReset;
}

export class ClientNameRevert implements Action {
  readonly type = ClientNameActionTypes.FieldValueRevert;
}

export type ClientNameActions = ClientNameChange | ClientNameReset | ClientNameRevert;
