import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { VirtualTour } from '@app/core/model/entities/asset/virtual-tour';
import { Entity } from '@app/core/model/entities/entity';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { VirtualToursService } from '@app/features/main/views/virtual-tours/virtual-tours.service';
import { WorksService } from '@app/features/main/views/works/works.service';
import { AbstractVirtualTourComponent } from '@app/shared/components/virtual-tour/abstract-virtual-tour.component';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { ScriptService } from '@services/script.service';
import { EMPTY, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'virtual-tour-mya',
  templateUrl: '../virtual-tour.component.html',
  styleUrls: ['../virtual-tour.component.scss'],
  providers: [VirtualToursService, WorksService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Component implementing all the logics behind the Metareal Virtual tour using the Metareal SDK.
 */
export class VirtualTourMyaComponent extends AbstractVirtualTourComponent implements OnInit {
  // Container containing the Metareal Virtual tour
  @ViewChild('metareal') public container: ElementRef;
  // Container containing the component itself
  @ViewChild('tour') public tour: ElementRef;

  @Input() public virtualTours: VirtualTour[];

  constructor(protected scriptService: ScriptService,
              protected changeDetectorRef: ChangeDetectorRef,
              protected appManager: AppManager,
              protected translate: TranslateService,
              protected fb: FormBuilder,
              private virtualToursService: VirtualToursService,
              private worksService: WorksService,
              private equipmentService: EquipmentsService) {
    super(scriptService, changeDetectorRef, appManager, translate, fb);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.worksService.sidePanelToggle$.pipe(
      takeUntil(this.destroy$),
      filter(entity => entity === null)
    ).subscribe(() => this.hidePanel());

    this.equipmentService.sidePanelToggle$.pipe(
      takeUntil(this.destroy$),
      filter(entity => entity === null)
    ).subscribe(() => this.hidePanel());
  }

  /**
   * Fetch all entities related to the VirtualTour's Asset from MyA API
   */
  protected getEntities(): Observable<Entity[]> {
    switch (this.currentVirtualTour.entity) {
      case EntityTypeEnum.WORK:
        return this.worksService.loadWorks(this.currentVirtualTour.assetId);
      case EntityTypeEnum.EQUIPMENT:
        return this.equipmentService.loadEquipments(this.currentVirtualTour.assetId);
      default:
        return EMPTY;
    }
  }

  protected setPanelContent(entityId: string): boolean {
    switch (this.currentVirtualTour.entity) {
      case EntityTypeEnum.WORK:
        const work = this.entities.find(entity => entity.identifier === Number.parseInt(entityId));
        work ? this.worksService.openWorkSidePanel(work) : alert(this.translate.instant('MESSAGE.NO_RELATED_ENTITY'));
        return !!work;
      case EntityTypeEnum.EQUIPMENT:
        const equipment = this.entities.find(entity => entity.identifier === Number.parseInt(entityId));
        equipment ? this.equipmentService.openEquipmentSidePanel(equipment) : alert(this.translate.instant(
          'MESSAGE.NO_RELATED_ENTITY'));
        return !!equipment;
      default:
        return false;
    }
  }

  /**
   * Return the appSheet entity ID
   * @param entity the appSheet entity
   */
  protected getEntityId(entity: Entity): string {
    return (entity['identifier'] as number).toString();
  }

  /**
   * Format appSheet entity for display purpose
   * @param entity an appSheet entity
   */
  public valueFormatter(entity: Entity): string {
    return entity?.toString();
  }
}
