import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category, Classification } from '@app/core/model/other/classification';
import { gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { AppManager } from '@services/managers/app.manager';
import { GeneralService } from '@services/general.service';

@Injectable()
export class ClassificationService {

  public categories: Category[];

  constructor(private appManager: AppManager,
              private generalService: GeneralService) {
  }

  /**
   * Make an API request to check if a Classification is linked to the current Organization
   * that the current user have permission to view.
   * @return Observable of a boolean, true if a classification exists, otherwise false.
   */
  public classificationExistsInOrganization(code: string): Observable<boolean> {
    const QUERY = gql`
      query ClassificationExistsQuery($organizationId: String!, $code: String!) {
        classificationExists(organizationId: $organizationId, code: $code)
      }
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      code: code
    };
    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => {
        return response.data['classificationExists'];
      })
    );
  }

  /**
   * Make an API request to fetch the current Organization's technical classifications.
   * @param code Code to retrieve classification
   * @param level determines the path level of technical classifications to fetch,
   * if not specified, all the technical classifications will be fetched.
   * @return Observable of the current Organization's technical classifications.
   */
  public getClassificationByCode(code: string, level?: number): Observable<Category[]> {
    if (this.categories?.length > 0) {
      return of(this.categories);
    } else {
      const QUERY = gql`
        query Classification($organizationId: String!, $includePathList: Boolean!, $code: String!, $level: Int) {
          classification(organizationId: $organizationId, code: $code, level: $level) {
            id
            code
            organizationId
            categoriesList {
              label
              code
              pathList @include(if: $includePathList)
            }
          }
        }
      `;
      const QUERY_VAR = {
        organizationId: this.appManager.currentOrganization.id,
        includePathList: !level || level > 1,
        code,
        level
      };
      return this.generalService.get(QUERY, QUERY_VAR)
        .pipe(
          map(response => {
            const classification = plainToInstance(
              Classification,
              response.data['classification'] as Classification
            );
            this.categories = classification.categories;
            return this.categories;
          })
        );
    }
  }
}
