<div id="dialog-container">
  <h1 mat-dialog-title>{{'TITLE.ADD_DOCUMENT' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-stepper orientation="horizontal" [linear]="true">
    <ng-template matStepperIcon="edit">
      <i class="mdi mdi-check"></i>
    </ng-template>

    <!-- File uploading -->
    <mat-step [label]="'LABEL.UPLOAD' | translate">
      <file-uploader [uploadData]="uploadData"
                     (uploadInProgress)="uploadIsInProgress($event)"
                     (fileUploaded)="onFileUploaded($event)"
                     class="uploader-container fx-fill">
      </file-uploader>

      <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close="cancel">{{'BUTTON.CANCEL' | translate}}</button>
        <button [disabled]="isUploadInvalid()"
                [id]="'document-upload-next-button'"
                color="accent"
                mat-raised-button
                matStepperNext>
          {{ 'BUTTON.NEXT' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>

    <!-- Document properties -->
    <mat-step [label]="'LABEL.CHARACTERISTICS' | translate">
      <mat-dialog-content>
        <div class="fx-fill">
          <ag-grid-angular (gridReady)="onGridReady($event)"
                           [gridOptions]="gridOptions"
                           [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
                           class="ag-theme-material"
                           id="create-organization-documents-grid"
                           rowSelection="single"
                           style="height: 500px; width: 100%;">
          </ag-grid-angular>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close="cancel">{{'BUTTON.CANCEL' | translate}}</button>
        <button [id]="'document-characteristics-previous-button'"
                mat-stroked-button
                matStepperPrevious>
          {{ 'BUTTON.PREVIOUS' | translate }}
        </button>
          <button [disabled]="isEditing"
                [id]="'document-characteristics-save-button'"
                color="accent"
                mat-raised-button
                matDialogClose="yes">
          {{'BUTTON.ADD' | translate}}
        </button>
      </mat-dialog-actions>
    </mat-step>
  </mat-stepper>
</div>
