<div class="fx-fill fx-layout-col fx-justify-start fx-align-stretch"
     style="text-align: center"
     id="organizations-container"
     *ngIf="organizationsWithLogo$ | async as organizationsWithLogo; else loading">

  <h3 class="main-title"> {{'TITLE.USER_ORGANIZATIONS' | translate}}</h3>
  <div class="fx-layout-row fx-wrap fx-justify-center" *ngFor="let organizationsRow of balanceItemsInArrays(organizationsWithLogo, 5)">
    <mat-card class="organization-item fx-layout-row fx-justify-center"
              tabindex="0"
              *ngFor="let organizationWithLogo of organizationsRow"
              (click)="goToOrganization(organizationWithLogo.organization.id)"
              (keyup.enter)="goToOrganization(organizationWithLogo.organization.id)">
      <mat-card-content class="organization-content fx-layout-col fx-justify-center fx-align-center">
        <div [style.background-image]="organizationWithLogo.logoUrl" class="organization-profile-picture"></div>
        <span class="organization-name">{{ organizationWithLogo.organization.name }}</span>
      </mat-card-content>
    </mat-card>
  </div>

  <h3 *ngIf="globalBIReports?.length > 0" class="main-title"> {{'TITLE.GLOBAL_BI_REPORTS' | translate}}</h3>
  <div class="fx-layout-row fx-wrap fx-justify-center">
    <mat-card class="organization-item fx-layout-row fx-justify-center fx-align-center"
              tabindex="0"
              *ngFor="let globalBIReport of globalBIReports"
              (click)="navigateToGlobalBIReport(globalBIReport.report.name)"
              (keyup.enter)="navigateToGlobalBIReport(globalBIReport.report.name)">
      <mat-card-content class="organization-content fx-layout-col fx-justify-center fx-align-center">
        <i class="fx-fill mdi mdi-file-chart-outline xl"></i>
        <span class="organization-name">{{ globalBIReport.label | uppercase | translate }}</span>
      </mat-card-content>
    </mat-card>
  </div>

</div>

<ng-template #loading>
  <spinner></spinner>
</ng-template>