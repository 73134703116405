import { Entity } from '@app/core/model/entities/entity';

export class JMapAuthDetails extends Entity {
  public url: string;
  public projectId: number;
  public username: string;
  public organizationId: string;

  public toString(): string {
    return this.username;
  }
}
