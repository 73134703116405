import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import {
  AbstractMultipleAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'multiple-autocomplete-cell-text-cell-edit',
  templateUrl: './abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component.html',
  styleUrls: ['./abstract-multiple-autocomplete-cell-edit/abstract-multiple-autocomplete-cell-edit.component.scss']
})
export class MultipleEntityAutocompleteCellEditComponent extends AbstractMultipleAutocompleteCellEditComponent {

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  public getFieldValue(value: ChipInput): string {
    return value.entityName;
  }

  public getValue(): ChipInput[] {
    return this.chips.length > 0 ? this.chips : undefined;
  }

  public newInput(entity: ChipInput): void {
    if (!this.control.valid) return;
    if ((entity.entityName == '-')) {
      // remove all entities
      this.chips = [];
    } else if ((entity.entityName || '').trim()) {
      // Add the new chip
      this.chips.push(entity);
      this.setFilterSuggestions();
    }
    this.adjustColumnWidth();
    this.control.setValue('');

  }

  protected filter(value: string): ChipInput[] {
    const filterValue = value.toLowerCase();
    return this.suggestedOptions.filter(entity => {
      return entity.entityName.toLowerCase().includes(filterValue) && !this.chips.includes(entity);
    });
  }

  public sortSuggestedOption(): void {
    this.suggestedOptions.sort((entityA, entityB) => entityA.entityName.compareTo(entityB.entityName));
  }

  public setFilterSuggestions(): void {
    this.filteredSuggestions = this.control.valueChanges
      .pipe(
        startWith(''),
        map((value) => {
          // We cannot do equality check on two objects, so we compare the ids
          const entityIds = this.chips.map(it => it.entityId);
          return typeof value === 'string' && value.trim() ? this.filter(value) : this.suggestedOptions.filter(entity => {
            return !entityIds.includes(entity.entityId);
          });
        })
      );
  }

  public onEnter(_: KeyboardEvent): void {
    //do nothing
  }
}

export interface ChipInput {
  entityName: string;
  entityId: string;
}
