import { Expose, Type } from 'class-transformer';

/**
 * Represents a technical classification for Works.
 */
export class Classification {
  public id: string;
  public organizationId: string;

  @Type(() => Category)
  @Expose({name: 'categoriesList'})
  public categories: Category[];
}

/**
 * Represents a technical category within a Classification.
 */
export class Category {
  public label: string;
  public code: string;

  @Expose({name: 'pathList'})
  public path: string[];

  /**
   * Get the id of the Category based on its path.
   * @return Category's id.
   */
  public get id(): string {
    return this.path[this.path.length - 1];
  }

  /**
   * Get the Category's depth within the Classification's tree hierarchy.
   * @return Category's level.
   */
  public get level(): number {
    return this.path.length;
  }
}

/**
 * Interface used by entities that have technical category's data in their model data
 */
export interface RelatedTechnicalCategory {
  technicalCategoryCode: string;
  technicalCategoryLabel: string;
}
