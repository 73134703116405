import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppConfig } from '@app/core/app.config';

@Component({
  selector: 'image-uploader-preview',
  templateUrl: './image-uploader-preview.component.html',
  styleUrls: ['./image-uploader-preview.component.scss']
})
export class ImageUploaderPreviewComponent {
  protected srcImg: string;
  protected placeHolder = inject(AppConfig).PLACEHOLDER_PICTURE;
  protected readonly maxSize: number = inject(AppConfig).MAX_IMAGE_SIZE;
  protected fileIsValid = true;

  @Output()
  public imageSelected = new EventEmitter<string | ArrayBuffer>();

  /**
   * Emit the selected image as base64.
   * @param image image File.
   */
  private emitSelectedImage(image: File): void {
    const reader = new FileReader();
    reader.onloadend = (): void => this.imageSelected.emit(reader.result);
    reader.readAsDataURL(image);
  }

  /**
   * Load and show the selected image in the preview area
   * @param event
   */
  public previewImage(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.fileIsValid = file.size <= this.maxSize;

    if (!this.fileIsValid) return;

    this.emitSelectedImage(file);
    this.srcImg = URL.createObjectURL(file);
  }
}
