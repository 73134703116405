import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ComponentRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ASYNC_PRECONDITIONS_INJECTION,
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldGroup
} from '@app/core/model/other/field-config';
import {
  AbstractFieldBuilder,
  AbstractFieldGroupBuilder,
  FieldStateMode
} from '@app/shared/components/fields/abstract.field';
import { FieldAnchorDirective } from '@app/shared/components/fields/field-anchor.directive';
import { getFieldBuilder } from '@app/shared/components/fields/field-builder-selector';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'group-field-builder',
  templateUrl: './group-field-builder.component.html',
  styleUrls: ['./group-field-builder.component.scss']
})
export class GroupFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  @ViewChild(FieldAnchorDirective, {static: true}) public fieldHost: FieldAnchorDirective;
  // Hold component references to all builders inside the group
  public fieldBuilders: ComponentRef<AbstractFieldBuilder>[] = [];

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );
  }

  public ngOnInit(): void {
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isSingleField: false,
      value: {},
      isDisplayed: new BehaviorSubject<boolean>(true),
      initialValue: {},
      requiredTrue: {}
    });
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    // Subscribe to any change in state of the group
    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      if (newMode === FieldStateMode.AFTER_SAVE) {
        this.refreshEntity();
        this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(this.getFieldGroupValue())));
        this.getNextState();
      } else {
        if (newMode === FieldStateMode.EDIT) {
          // find which fieldBuilder requested focus
          const fieldBuilder = this.fieldBuilders.find(fb => fb.instance.hasRequestedFocus());
          if (fieldBuilder) { // if found, set focus
            fieldBuilder.instance.focus();
            fieldBuilder.instance.resetRequestFocus();
          } else { // if no field requested focus, set the first one focused
            this.fieldBuilders[0]?.instance.focus();
          }
        }
        this.currentMode = newMode;
      }
    });

    this.initFieldGroup();
  }

  public initFieldGroup(): void {
    const fieldGroupContainerRef = this.fieldHost.viewContainerRef;
    fieldGroupContainerRef.clear();

    this.fieldGroup.fieldConfigs.forEach((fieldConfig, index) => {
      // get the field type to create
      const fieldComponent = getFieldBuilder(fieldConfig);

      // Create the corresponding component if it is recognized
      if (fieldComponent.fieldBuilder !== null) {
        const fieldRef = fieldGroupContainerRef.createComponent(fieldComponent.fieldBuilder, {
          index: index,
          injector: Injector.create({
            parent: fieldGroupContainerRef.injector,
            providers: [
              {provide: FIELD_CONFIG_INJECTION, useValue: fieldConfig},
              {provide: FIELD_ENTITY_INJECTION, useValue: this.entity},
              {
                provide: FIELD_PRECONDITIONS_INJECTION,
                useValue: this.preconditionsForEdition && this.validationService.fieldIsEditable(
                  fieldConfig,
                  this.entity
                )
              },
              {
                provide: FIELD_ASYNC_PRECONDITIONS_INJECTION,
                useValue: this.validationService.getAsyncPreconditionsToEdit(fieldConfig.field?.validators, this.entity)
              },
              {provide: FIELD_PERMISSIONS_INJECTION, useValue: this.permissionsForEdition},
              {
                provide: FIELD_EXTRA_DATA, useValue: {
                  isSingleField: false,
                  fieldGroupCode: this.fieldGroup.code,
                  propertiesPath: this.data.propertiesPath,
                  focusable: fieldComponent.focusable
                }
              }
            ]
          })
        });
        fieldRef.changeDetectorRef.detectChanges();

        this.fieldBuilders.push(fieldRef);
      } else {
        console.warn('Le type de champ "' + fieldConfig + '" n\'est pas reconnu');
      }

    });

    this.getNextState();
  }

  public cancel(): void {
    this.fieldBuilders.forEach(fieldBuilder => {
      fieldBuilder.instance.cancel();
    });
    super.cancel();
  }

  public isGroupInvalid(): boolean {
    return this.fieldBuilders.some(fieldBuilder => {
      return fieldBuilder.instance.form.invalid || fieldBuilder.instance.form.pending;
    });
  }
}
