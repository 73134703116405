<div *ngIf="preconditions" [permission-lock]="permissions" [ngClass]="{'with-margins': withMargins}"
     class="btn-container">
  <button #iconButton (click)="internalButtonClick($event)"
          class="fx-layout-row fx-justify-center fx-align-center"
          [color]="colour"
          [id]="id"
          [ngClass]="{'with-white-background': withWhiteBackground}"
          [disabled]="!preconditions || disabled"
          type="button"
          mat-icon-button>
    <i [class]="class" [ngClass]="{'highlighted': highlighted, 'mdi-spin': spin}"></i>
  </button>
</div>
