@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
      class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
          [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
          <span>{{ formatValue(getFieldValue()) | translate | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}</span>
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span class="overlay-icon">
              @if (preconditionsForEdition) {
                <i class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
        <form autocomplete="off" [formGroup]="form">
          <mat-form-field appearance="outline">
            <mat-label>{{ fieldConfig.label }}</mat-label>
            <mat-select (selectionChange)="onClickSave()" formControlName="field" (keyup.escape)="cancel()">
              @if (optional) {
                <mat-option [value]="''">{{ 'LABEL.UNDEFINED' | translate }}</mat-option>
              }
              <mat-option [value]="true">{{ formatValue(true) | translate }}</mat-option>
              <mat-option [value]="false">{{ formatValue(false) | translate }}</mat-option>
            </mat-select>
            @for (validator of erroredValidators; track validator) {
              <mat-error>
                {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
              </mat-error>
            }
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
}
