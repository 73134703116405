<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_ASSET' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="assetForm" novalidate>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET_NAME' | translate }}</mat-label>
        <input matInput formControlName="assetName" [id]="'input-asset-name'"/>
        @if (assetForm.get('assetName').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
        @if (assetForm.get('assetName').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate:{value: appConfig.FIELD_MAX_LENGTH} }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSETSTATE' | translate }}</mat-label>
        <input matInput formControlName="assetState" [matAutocomplete]="assetStateAutocomplete" [id]="'input-asset-state'">
        <mat-autocomplete #assetStateAutocomplete="matAutocomplete">
          @for (fieldValue of filteredAssetStates$ | async; track fieldValue) {
            <mat-option [value]="fieldValue">
              {{ fieldValue }}
            </mat-option>
          }
        </mat-autocomplete>
        @if (assetForm.get('assetState').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
        @if (assetForm.get('assetState').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET_TYPE' | translate }}</mat-label>
        <mat-select formControlName="assetType" [required]="true" [id]="'input-asset-type'">
          @for (type of assetTypes$ | async; track type) {
            <mat-option [value]="type">
              {{ type }}
            </mat-option>
          }
        </mat-select>
        @if (assetForm.get('assetType').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [id]="'asset-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!assetForm.valid"
      [id]="'asset-save-button'"
      color="accent"
      mat-raised-button
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
