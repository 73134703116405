import { UntypedFormArray, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';
import { PermissionEnum } from '@app/core/enums/permissions.enum';

/***
 * Check if the required permissions are selected when the given permission is selected.
 *
 * Example: Let A the permissions to check, B and C the required permissions.
 *
 * A is not selected => valid
 *
 * A is selected and both B and C are selected => valid
 *
 * A is selected and one of B, C or both are not selected => invalid
 */
export const incoherentPermissions = (permissionToChek: PermissionEnum, requiredPermissions: PermissionEnum[]): ValidatorFn => {
  return (controlArray: UntypedFormArray): { [key: string]: boolean } => {
    if (isPresent(Validators.required(controlArray))) {
      return null;
    }
    //Get the state of the checkboxes
    const selectedPermissions = controlArray.controls.reduce((selectedPermissions, control) => {
      requiredPermissions.forEach(permission => {
        if (control.value.name === permission) selectedPermissions[permission] = !!control.value.selected;
      });
      if (control.value.name === permissionToChek) selectedPermissions[permissionToChek] = !!control.value.selected;
      return selectedPermissions;
    }, {});
    //Validation condition
    const validation = selectedPermissions[permissionToChek]
      && !requiredPermissions.every( permission => selectedPermissions[permission]);

    return !validation ? null : {incoherentPermissions: true};
  };
};
