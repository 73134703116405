import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import {
  EquipmentModalService
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-modal.service';
import { EquipmentsService } from '@app/features/main/views/equipments/equipments.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'equipment-header',
  templateUrl: './equipment-header.component.html',
  styleUrls: ['./equipment-header.component.scss'],
  providers: [EquipmentModalService, DocumentModalService]
})
export class EquipmentHeaderComponent implements OnInit, OnDestroy {

  public equipment: Equipment;

  public Permission = PermissionEnum;

  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService,
              public accessManager: AccessManager,
              private activationEndService: ActivationEndService,
              private appManager: AppManager,
              public appConfig: AppConfig,
              private titleService: Title,
              private equipmentsService: EquipmentsService,
              private analyticsService: AnalyticsService,
              private snackbarManager: SnackbarManager,
              private router: Router,
              private equipmentModalService: EquipmentModalService) {
  }

  /**
   * Get current Equipment entity, initialize form fields and set page title.
   */
  public ngOnInit(): void {
    this.equipment = this.appManager.currentEntity as Equipment;

    // Update Equipment as it is updated outside the header
    this.equipmentsService.equipmentUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(updatedEquipment => this.equipment = updatedEquipment);

    this.activationEndService.pushOptionalParams({
      'TITLE.EQUIPMENT_IDENTIFIER': {
        identifier: this.equipment.identifier.toString()
      }
    });
    this.titleService.setTitle(`${this.translate.instant('TITLE.EQUIPMENT_IDENTIFIER', {identifier: this.equipment.identifier})} - myA`);
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open the 'delete Equipment' dialog then delete the current Equipment entity if the user confirms.
   */
  public deleteEquipment(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.EQUIPMENT,
      this.equipment.id
    );
    this.equipmentModalService.openDeleteEquipmentsDialog([this.equipment])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EQUIPMENT_DELETED'));
        this.router.navigate(['organization', this.appManager.currentOrganization.id, 'equipments'], {replaceUrl: true})
          .catch(console.error);
      });
  }

  /**
   * Redirects the user to the Asset sheet attached to the current Equipment
   */
  public showAssetSheet(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.equipment.assetId
    );

    this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'assets',
      'asset-sheet',
      this.equipment.assetId,
      'equipments'
    ]).catch(console.error);
  }
}
