import { inject, Injectable } from '@angular/core';
import { DocumentTypeEditPermissionEnum } from '@app/core/enums/document/document-type-edit-permission.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { UserRoleDefinition, UserRoleEnum } from '@app/core/enums/user-role-enum';
import { User } from '@app/core/model/client/user';
import { UserRole } from '@app/core/model/client/user-role';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import { AuthService, LogoutOptions } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { RxStompService } from '@stomp/ng2-stompjs';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import LogRocket from 'logrocket';
import { Observable, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AccessManager {
  public userRoleMap = new Map<string, UserRoleDefinition>();
  private _currentUser: User | null;

  private readonly subscriptionGraphQlFragment = gql`
    fragment SubscriptionInfoForGrid on ClientSubscription {
      id
      startDate
      endDate
      contractId
    }
  `;

  private generalService = inject(GeneralService);
  private authService = inject(AuthService);
  private websocketService = inject(RxStompService);
  private appManager = inject(AppManager);

  constructor() {
    Object.keys(UserRoleDefinition).forEach((userRoleEnumElement) => {
      const userRole = UserRoleDefinition[userRoleEnumElement] as UserRoleDefinition;
      this.userRoleMap.set(userRole.roleCode, userRole);
    });
  }

  /**
   * Load the current User's data from the API.
   * @return An Observable that emits the current User.
   */
  public loadCurrentUser(): Observable<User> {
    const COMBINED_QUERY = gql`
      query ME {
        me {
          id
          name
          email
          isTb
          clients {
            id
            isTb
            organizationId
            subscriptions {
              ...SubscriptionInfoForGrid
            }
          }
          groups {
            assetIds
            assetTypeIds
            allAssetAccess
            allAssetTypeAccess
            client {
              organizationId
            }
          }
          userRole {
            id
            isTb
            code
            order
            activePermissions {
              id
              code
            }
          }
          globalPermissions {
            code
          }
          globalBIReports {
            reportId
            name
            defaultPageId
          }
        }
      }
      ${this.subscriptionGraphQlFragment}
    `;

    return this.generalService.get(COMBINED_QUERY)
      .pipe(
        map(response => plainToInstance(User, response.data['me'] as User)),
        map(me => {
          this._currentUser = me;

          // Open Websocket for User
          this.websocketService.activate();

          if (environment.logRocket.enabled) {
            // Add user info in LogRocket monitoring
            LogRocket.identify(me.id, {
              email: me.email
            });
          }

          return me;
        })
      );
  }

  /**
   * Currently authenticated user.
   */
  public get currentUser(): User | null {
    return this._currentUser;
  }

  /**
   * Check whether a permission is granted to the current User.
   * @param permissionCode Permission's code.
   * @param checkGlobalPermissions Whether to check for global permissions. If false, only check permissions for current
   *                               organization. Defaults to false.
   * @return True if the permission is granted, false otherwise.
   */
  public hasAccess(permissionCode: string, checkGlobalPermissions: boolean = false): boolean {
    return this._currentUser?.getUserPermissions(checkGlobalPermissions)
        .some(permission => permission.code === permissionCode)
      ?? false;
  }

  /**
   * Check whether all the permissions are granted to the current user.
   * @param permissionCodes Permissions to check.
   * @return True if the current User has ALL permissions granted, false otherwise.
   */
  public hasAllNeededPermissions(permissionCodes: string[]): boolean {
    return permissionCodes.every(permissionCode => this.hasAccess(permissionCode)) ?? false;
  }

  /**
   * Check whether the current user is allowed to manage another user who has a given role.
   * @param role Role of the other user to check against the current user's role.
   * @return True if the current user's role allows them to manage a user with the given role, false otherwise.
   */
  public canManageRole(role: UserRole): boolean {
    const currentUserRole = this.userRoleMap.get(this._currentUser.userRole.code);
    const otherUserRole = this.userRoleMap.get(role.code);

    // As the UserRoleDefinition defines how each role has control
    // over which role, if a role is in a definition, then it has control over it
    return currentUserRole.controlOver.includes(otherUserRole.roleCode);
  }

  /**
   * Check whether the current user is granted access to an organization.
   * @param organizationId ID of the organization to check for access permission.
   * @return True if the current user has access to the organization, false otherwise
   */
  public hasAccessToOrganization(organizationId: string): boolean {
    return this._currentUser?.clients.some(client => client.organizationId === organizationId)
      ?? false;
  }

  /**
   * Return whether the current user is a super administrator.
   */
  public isSuperAdmin(): boolean {
    return this._currentUser?.userRole.code === UserRoleEnum.SUPER_ADMIN;
  }

  /**
   * Check whether the current user has a given role.
   * @param userRoleCode Role to check against the current user.
   * @return True if the role is assigned to the current user, false otherwise.
   */
  public isUserRole(userRoleCode: UserRoleEnum): boolean {
    return this._currentUser?.userRole.code === userRoleCode;
  }

  /**
   * Depending on the type of entity, return custom edit permissions.
   * Used when an entity edition need permissions from multiple entities.
   * For example to edit a check document, edit_document and edit_check permission are needed.
   *
   * @param entity
   * @return string[] custom permissions needed for the edition of the entity
   */
  public getCustomEditPermissionByEntityType(entity: Entity): string[] | undefined {
    let extraPermissions: string[];
    if (entity instanceof Document) {
      extraPermissions = [PermissionEnum.EDIT_DOCUMENT];
      const relatedEntityEditPermission = DocumentTypeEditPermissionEnum[entity.documentType];
      if (relatedEntityEditPermission !== void 0) {
        extraPermissions.push(DocumentTypeEditPermissionEnum[entity.documentType]);
      }
    }
    return extraPermissions;
  }

  /**
   * Log out the currently authenticated user, clear currentUser as well as the AppManager's current Organization,
   * and close the Websocket.
   * @param redirect Whether to redirect the client to the home page after logging out. Defaults to true.
   * @return Observable that emits once logout and cleanup are completed.
   */
  public logout(redirect = true): Observable<void> {
    const options: LogoutOptions = redirect ? {
      logoutParams: {
        returnTo: window.location.origin
      }
    } : {openUrl: false};
    return this.authService.logout(options)
      .pipe(
        // Clear current user and current organization
        tap(() => {
          this._currentUser = null;
          this.appManager.currentOrganization = null;
        }),
        // Close Websocket
        switchMap(() => this.websocketService.deactivate())
      );
  }
}
