import { Component, OnDestroy } from '@angular/core';
import { UserGridFooterService } from '@app/shared/components/user-grid/user-grid-footer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'user-grid-footer',
  template: `
    <div>
      Utilisateurs
      <span [ngClass]="{'valid' : valid, 'invalid': !valid }">({{ user.selectionCount }} / {{ user.totalCount }})</span>
    </div>
  `,
  styles: [`
    div {
      font-weight: bold;
    }
  `]

})
export class UserGridFooterComponent implements ICellRendererAngularComp, OnDestroy {
  public user: any;

  private destroy$ = new Subject<void>();

  constructor(private userService: UserGridFooterService) {
  }

  /**
   * Get the validity of the number of selected users
   */
  public get valid(): boolean {
    return this.user.selectionCount <= this.user.totalCount;
  }

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    // Passed by the pinned row data
    this.user = params.data['userFooterCell'];

    // Listens to changes in selection and shows the result
    this.userService.footerSubject.pipe(takeUntil(this.destroy$)).subscribe((footer) => {
      this.user.selectionCount = footer;
    });
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param params
   * @returns {boolean}
   */
  public refresh(params: any): boolean {
    return false;
  }
}
