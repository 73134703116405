import { Component, ComponentRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FIELD_EXTRA_DATA } from '@app/core/model/other/field-config';
import { FieldGroupAnchorDirective } from '@app/shared/components/field-groups/field-group-anchor.directive';
import { AbstractFieldGroupBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'section-builder',
  templateUrl: './section-builder.component.html',
  styleUrls: ['./section-builder.component.scss']
})
export class SectionBuilderComponent implements OnInit, OnDestroy {

  public sectionLabel: string;
  public sectionEmptyLabel: string;
  public displayHeader: boolean;
  public empty: boolean = true;
  public fieldGroupRefs: ComponentRef<AbstractFieldGroupBuilder>[] = [];
  private destroy$ = new Subject<void>();

  @ViewChild(FieldGroupAnchorDirective, {static: true}) public fieldGroupHost: FieldGroupAnchorDirective;

  constructor(@Inject(FIELD_EXTRA_DATA) data: any,
              private formStateService: FormStateService) {
    this.sectionLabel = data.sectionLabel;
    this.sectionEmptyLabel = data.sectionEmptyLabel;
    this.displayHeader = data.displayHeader;
  }

  /**
   * Display an empty section label if the section contains only computed fields and all of them have no value
   */
  public ngOnInit(): void {
    this.fieldGroupRefs.forEach(fieldGroup => {
      const fieldGroupConfig = fieldGroup.instance.fieldGroup;
      if (fieldGroupConfig.fieldGroupType == 'single') {
        fieldGroupConfig.fieldConfigs.forEach(fieldConfig => {
          if (fieldConfig.field.computed) {
            this.formStateService.getPath([fieldGroupConfig.code, 'isDisplayed'])
              .pipe(takeUntil(this.destroy$))
              .subscribe(isDisplayed => this.empty = this.empty && !isDisplayed);
          } else {
            this.empty = false;
          }
        });
      } else {
        this.empty = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
