import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { merge, Observable } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  template: ``
})
export abstract class AbstractMultipleAutocompleteCellEditComponent extends AbstractCellEditor {

  public suffix?: string;
  public suggestedOptions?: any[];
  public filteredSuggestions: Observable<any[]>;

  public chips: any[];
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild(MatInput, {static: false}) public input: MatInput;
  @ViewChild(MatChipGrid, {static: false}) public chipList: MatChipGrid;
  @ViewChild(MatAutocomplete, {static: false}) public autocomplete: MatAutocomplete;

  protected constructor(public fb: UntypedFormBuilder,
                        public formStateService: FormStateService,
                        public validationService: ValidationService,
                        public translateService: TranslateService,
                        public appConfig: AppConfig) {

    super(fb, formStateService, validationService, translateService, appConfig);
  }

  public isPopup(): boolean {
    return true;
  }

  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
    // Input must be empty in chip cell editor
    this.control.setValue('');

    this.suffix = this.params.suffix;
    this.suggestedOptions = this.params.suggestedOptions || [];

    if (this.params.value && this.params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
      // Make copy otherwise, it's a reference
      this.chips = [...this.params.value];
      this.adjustColumnWidth();
    } else {
      this.chips = [];
    }

    // Add distinct validator
    if (this.params.validators?.find(validator => validator.type === ValidatorType.DISTINCT)) {
      this.control.addValidators(ExtraValidators.distinct(this.chips));
      this.control.updateValueAndValidity();
    }

    if (this.suggestedOptions) {
      this.sortSuggestedOption();
      this.setFilterSuggestions();
    }
  }


  public afterGuiAttached(): void {
    this.input.focus();
  }

  public abstract getFieldValue(value: any): string;

  public remove(chip: any): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1).pop();
      this.setFilterSuggestions();
      this.adjustColumnWidth();
    }
  }

  public abstract newInput(value: any): void;

  public isCancelAfterEnd(): boolean {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    return !this.control.valid; // A cancellation before creating the chip means we can ignore if the field input is actually dirty
  }

  protected abstract filter(value: string): any[];

  public adjustColumnWidth(): void {
    this.params.columnApi.autoSizeColumn(this.params.column);
  }

  public optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.newInput(event.option.value);
  }

  public abstract sortSuggestedOption(): void;

  public abstract setFilterSuggestions(): void;

  public onFocusOut(event: any): void {
    // If we lose focus on the cell editor to something that isn't one of the existing chips
    if (!event.relatedTarget?.classList.contains('mat-chip')) {
      // If the autocomplete box is open
      if (this.autocomplete.isOpen) {
        //Perform the save only if no option was selected
        merge([
          this.autocomplete.optionSelected.asObservable(),
          this.autocomplete.closed.asObservable()
        ])
          .pipe(first(), map(() => true), takeUntil(this.destroy$))
          .subscribe(res => {
            if (!res) this.stopEditing();
          });
      }
    }
  }

  public abstract onEnter(event: KeyboardEvent): void;

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
