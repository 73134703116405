import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { thenReturn } from '@app/shared/extra/utils';
import { OrganizationsService } from '@app/shared/services/organizations.service';
import { Observable } from 'rxjs';

/**
 * Load the current Organization based on the ID found in the URL then signal that the route can be activated.
 * Current User's preferences and permissions within the Organization are loaded as well.
 * @param route Activated route.
 * @return Observable that emits true once the Organization has been loaded.
 */
export default function organizationGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
  return inject(OrganizationsService).loadOrganization(route.params['oid'])
    .pipe(thenReturn(true));
}
