import { AbstractBean } from '@app/core/model/abstract-bean';
import { Module } from '@app/core/model/client/module';
import { Expose, Type } from 'class-transformer';

export class Permission extends AbstractBean {
  public code: string;
  public order: number;
  public isDefault: boolean;

  @Type(() => Module)
  public module: Module;

  @Expose()
  public toString(): string {
    return this.code;
  }

}
