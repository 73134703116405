<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div
        (click)="accessManager.hasAllNeededPermissions(permissionsForEdition) ? onClickActivate($event) : $event.stopPropagation()"
        (keyup.enter)="accessManager.hasAllNeededPermissions(permissionsForEdition) ? onEnterActivate($event) : $event.stopPropagation()"
        [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition), 'width-auto' : widthAuto, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}"
        tabindex="0">
          <span class="field-value" *ngFor="let space of spacesMap | keyvalue; let i = index">
            <span *ngIf="i !== 0">, </span>
            <a (click)="onSpaceFieldClick(space.key)" class="clickable-field" tabindex="0">{{ space.value }}</a>
          </span>
        <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
          <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
    </div>

    <ng-container *ngIf="!noAsset; else noAssetMessage">
      <div class="field-container fx-flex">
        <div class="ag-grid-container" #gridContainer>
          <ag-grid-angular (gridReady)="onGridReady($event)"
                           [gridOptions]="gridOptions"
                           [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
                           [domLayout]="'autoHeight'"
                           class="ag-theme-material clickable-rows"
                           id="tree-datagrid"
                           rowSelection="multiple"
                           style="height: 100%; width: 100%; margin: 10px 0;">
          </ag-grid-angular>
        </div>
        <mat-error *ngFor="let validator of erroredValidators">
          {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
        </mat-error>

        <div class="button-container fx-layout-row fx-justify-end fx-align-center" *ngIf="isSingleField">
          <button id="cancel-button"
                  (click)="onClickCancel()"
                  (keyup.escape)="onClickCancel()"
                  mat-stroked-button>
            {{ 'BUTTON.CANCEL' | translate }}
          </button>
          <button id="save-button"
                  (click)="onClickSave()"
                  [disabled]="!form.valid || form.get('field').pending"
                  color="accent"
                  mat-raised-button>
            {{ 'BUTTON.SAVE' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #noAssetMessage>
      <div class="field-container fx-flex-100-66-percent-min-500">
        <p id="no-asset-selected-message">
          {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
