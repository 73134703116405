import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RelatedSpace, Space } from '@app/core/model/entities/asset/space';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const checkSpaces: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const spaces = control.value as (Space | RelatedSpace)[];
  if ( isPresent(Validators.required(control)) || spaces.length < 2 ) {
    return null;
  }
  const valid = spaces.every(space =>
    spaces.every(spaceToCompare =>
      !space.parentPath || !space.parentPath.includes(spaceToCompare.id)
    )
  );

  return valid ? null : {
    incoherentSpaces: true
  };
};
