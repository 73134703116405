import { Injectable } from '@angular/core';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckTypeService {

  private checkTypes$ = new ReplaySubject<{ [k: string]: CheckType }>(1);
  public hasCheckTypes: boolean = false;

  private readonly checkInfoGraphqlFragment: any;

  constructor(private generalService: GeneralService,
              private appManager: AppManager) {
    this.checkInfoGraphqlFragment = gql`
    fragment CheckTypeInfo on CheckType {
      code
      values
      observation
    }
    `;
  }

  public getCheckTypesObservable(): Observable<{ [k: string]: CheckType }> {
    return this.checkTypes$.asObservable();
  }


  public loadChecksTypeData(): Observable<{ [k: string]: CheckType }> {
    const QUERY = gql`
        query OrganizationCheckTypes($organizationId: String!) {
          checkTypesByOrganizationId(organizationId: $organizationId) {
            ...CheckTypeInfo
          }
        }${this.checkInfoGraphqlFragment}
      `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          const checkTypesList = plainToInstance(CheckType, response.data['checkTypesByOrganizationId'] as CheckType[]);
          const checkTypes: { [k: string]: CheckType } = {};
          checkTypesList.forEach((type) => checkTypes[type.code] = type);
          this.hasCheckTypes = checkTypesList.length > 0;
          this.checkTypes$.next(checkTypes);
          return checkTypes;
        })
      );
  }

  public getCheckTypeByCode(code: string): Observable<CheckType> {
    const COMBINED_QUERY = gql`
      query CheckTypeByCodeAndOrganizationId($code: String!, $organizationId: String!) {
        checkTypeByCodeAndOrganizationId(checkTypeCode: $code, organizationId: $organizationId) {
          ...CheckTypeInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      code,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => {
          return plainToInstance(CheckType, response.data['checkTypeByCode'] as CheckType);
        })
      );
  }
}
