import { Injectable } from '@angular/core';
import { DatagridVisualisationConfig } from '@app/core/model/entities/visualisation/datagrid-visualisation';
import { ColumnState } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject } from 'rxjs';

export interface GridState extends DatagridVisualisationConfig {
  modified: boolean;
}

@Injectable({providedIn: 'root'})
export class GridStateService {

  public sessionStorageKey: string;
  public gridStateModified = false;

  private locked = false;

  private gridReadySubject = new ReplaySubject<ColumnState[]>(1);
  private restoreGridStateSubject = new Subject<GridState>();
  private selectRowSubject = new ReplaySubject<string>(1);

  /**
   * Emits the grid's initial column state once it is ready and column definitions have been loaded.
   */
  public get gridReady$(): Observable<ColumnState[]> {
    return this.gridReadySubject.asObservable();
  }

  /**
   * Emits a new GridState that needs to be applied to a datagrid.
   */
  public get restoreGridState$(): Observable<GridState> {
    return this.restoreGridStateSubject.asObservable();
  }

  /**
   * Emits the ID of a row to be selected, when applicable.
   */
  public get selectRow$(): Observable<string> {
    return this.selectRowSubject.asObservable();
  }

  /**
   * Grid state that is currently stored in the session storage. Return null if no grid state is currently present.
   * Note: this may not be the state that is currently applied. To apply the stored state to the grid, call {@see restoreGridState}.
   */
  public get gridState(): GridState | null {
    // FIXME Calling this getter multiple times in a row causes a noticeable delay if the storage item is large enough
    //  if necessary, we can cache the value in a local variable and use that instead.
    const sessionStorageValue = sessionStorage.getItem(this.sessionStorageKey);
    return sessionStorageValue ? JSON.parse(sessionStorageValue) : null;
  }

  /**
   * Save the grid state config for the current grid in the session storage.
   * Notes: this will not update the grid's actual state. To apply the stored state to the grid, call {@see restoreGridState}.
   * If a previous state is currently being restored, calls to this setter are ignored. This is to prevent unnecessary
   * updates of the session storage value as the grid applies the state that already exists.
   * @param state Grid state to persist in the session storage.
   */
  public set gridState(state: GridState) {
    // If the service is locked, calls to this setter are ignored.
    // This can be used while restoring a grid's state to ignore changes that are caused by the state being restored.
    if (this.locked) return;

    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(state));
  }

  /**
   * Column state that is currently stored in the session storage. Shorthand for {@see gridState.columnState}.
   */
  public get columnState(): ColumnState[] | null {
    return this.gridState?.columnState ?? null;
  }

  /**
   * Update the column state that is stored in the session storage and set the GridState's modified flag.
   * If a previous state is currently being restored, calls to this setter are ignored. This is to prevent unnecessary
   * updates of the session storage value as the grid applies the state that already exists.
   * @param columnState Column state to persist in the session storage.
   */
  public set columnState(columnState: ColumnState[]) {
    this.gridState = {
      ...this.gridState,
      columnState
    };
    this.gridStateModified = true;
  }

  /**
   * Filter model that is currently stored in the session storage. Shorthand for {@see gridState.filterModel}.
   */
  public get filterModel(): { [key: string]: any } | null {
    return this.gridState?.filterModel ?? null;
  }

  /**
   * Update the filter model that is stored in the session storage and set the GridState's modified flag.
   * If a previous state is currently being restored, calls to this setter are ignored. This is to prevent unnecessary
   * updates of the session storage value as the grid applies the state that already exists.
   * @param filterModel Filter model to persist in the session storage.
   */
  public set filterModel(filterModel: { [key: string]: any }) {
    this.gridState = {
      ...this.gridState,
      filterModel
    };
    this.gridStateModified = true;
  }

  /**
   * Whether the state that is currently stored in the session storage has been updated or is the original state.
   * Shorthand for {@see gridState.modified}.
   */
  public get modified(): boolean | null {
    return this.gridStateModified;
  }

  /**
   * Set whether the current state has been modified in the session storage.
   * If a previous state is currently being restored, calls to this setter are ignored. This is to prevent unnecessary
   * updates of the session storage value as the grid applies the state that already exists.
   * @param modified Value to update the modified flag with.
   */
  public set modified(modified: boolean) {
    this.gridState = {...this.gridState, modified};
  }

  /**
   * Signal to the service that the grid is ready and all column definitions have been loaded. This will apply the
   * previously saved grid state, if any.
   * @param initialColumnState Original column state, before any visualisation has been applied.
   */
  public onGridReady(initialColumnState: ColumnState[]): void {
    this.restoreGridState();
    this.gridReadySubject.next(initialColumnState);
  }

  /**
   * Resets the grid ready and select row Observables so that all listeners are unsubscribed and the service is ready
   * to be used for another datagrid.
   */
  public onGridDestroyed(): void {
    // Reset gridReadySubject
    this.gridReadySubject.complete();
    this.gridReadySubject = new ReplaySubject<ColumnState[]>(1);

    // Reset selectRowSubject
    this.selectRowSubject.complete();
    this.selectRowSubject = new ReplaySubject<string>(1);

    // Reset lock
    this.unlockGridState();
  }

  /**
   * Apply the GridState which is currently saved in the session storage to the grid.
   */
  public restoreGridState(): void {
    this.gridStateModified = false;
    const gridState: GridState = this.gridState;
    if (gridState != null) {
      this.restoreGridStateSubject.next(gridState);
    }
  }

  /**
   * Temporarily stop updating the grid state saved to the session storage. Setting gridState, filterModel, columnState
   * or modified will have no effect while the lock is active.
   * This can be used while restoring a state so that events raised by AG-grid will not unnecessarily modify the state.
   * Calls to setters will be ignored until unlockGridState() is called.
   */
  public lockGridState(): void {
    this.locked = true;
  }

  /**
   * Resume saving grid state to the session storage after setters are called.
   */
  public unlockGridState(): void {
    this.locked = false;
  }

  /**
   * Mark a row to be initially selected by its ID.
   * @param rowId ID of the row to select.
   */
  public setRowToSelect(rowId: string): void {
    this.selectRowSubject.next(rowId);
  }

  /**
   * Clears the row marked to be initially selected.
   */
  public resetRowToSelect(): void {
    this.selectRowSubject.complete();
    this.selectRowSubject = new ReplaySubject<string>(1);
  }
}
