import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Space } from '@app/core/model/entities/asset/space';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

export const gteSpaceChildrenProperty = (spacesService: SpacesService, field: string, spaceToUpdate: Space): AsyncValidatorFn => {

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let spaceArea: number;

    if (control.pristine || !spaceToUpdate?.parentPath?.length) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => {
        spaceArea = value as number;

        if (!spaceArea) {
          return of(null);
        }

        return spacesService.loadChildrenSpaces(spaceToUpdate.id);
      }),
      map((spaces: Space[] | null) => {

        if (!spaces?.length) {
          return null;
        }

        const childrenArea = spaces
          .filter(space => {
            return space.id != spaceToUpdate.id
              && space.properties[field]
              && (spaceToUpdate.parentPath.length + 1) == space.parentPath.length;
          })
          .map(space => +space.properties[field])
          .reduce((prev, acc) => prev + acc, 0);

        if (childrenArea > spaceArea) {
          return {gteSpaceChildrenProperty: true};
        }

        return null;
      }),
      first(),
      tap(() => control.markAsTouched())
    );
  };
};
