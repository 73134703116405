<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled"
       [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span #tooltip="matTooltip" (click)="onClickTooltip(tooltip)" *ngIf="fieldConfig.tooltip"
            [matTooltip]="fieldConfig.tooltip" matTooltipPosition="below">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
      <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
           [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition,
                        'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}" tabindex="0">
        <a (click)="navigateToAssetDashboard()">
          {{ getFieldValue() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
        </a>
        <span
          *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
          class="overlay-icon">
            <i *ngIf="preconditionsForEdition else readOnly;"
               class="mdi mdi-pencil selectable"></i>
            <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span #tooltip="matTooltip" (click)="onClickTooltip(tooltip)" *ngIf="fieldConfig.tooltip"
            [matTooltip]="fieldConfig.tooltip">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <ng-container *ngIf="preconditionsForEdition else readOnly;">
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'LABEL.ASSET' | translate }}</mat-label>
          <mat-select-asset-autocomplete #inputField [disabled]="(asyncPreconditionsForEdition$ | async) === false"
                                         [entityType]="entity.entityType"
                                         [formControl]="$any(form.get('asset'))">
          </mat-select-asset-autocomplete>
          <mat-hint *ngIf="(asyncPreconditionsForEdition$ | async) === false">
            {{ 'VALIDATION.WORK_HAS_RELATED_EQUIPMENT' | translate }}
          </mat-hint>
        </mat-form-field>
        <div *ngIf="isSingleField" class="save-options">
          <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
            <i class="mdi mdi-close"></i>
          </button>
          <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                  class="button save">
            <i class="mdi mdi-check"></i>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #readOnly>
      <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
        <div
          [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition}"
          tabindex="0">
          <a (click)="navigateToAssetDashboard()">
            {{ getFieldValue() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
          </a>
          <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
            <i class="mdi mdi-pencil-off tooltip-icon"></i>
        </span>
        </div>
      </div>
    </ng-template>

  </div>
</div>
