import { Component, HostListener, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';

@Component({
  selector: 'text-area-cell-edit',
  templateUrl: './text-area-cell-edit.component.html',
  styleUrls: ['./text-area-cell-edit.component.scss']
})
export class TextAreaCellEditComponent extends AbstractCellEditor {

  @ViewChild(MatInput, {static: false}) public input: MatInput;

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {

    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   *  Gets called once after initialised. If you return true, the editor will
   *  appear in a popup, so is not constrained to the boundaries of the cell.
   */
  public isPopup(): boolean {
    return true;
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    this.input.focus();
  }

  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    const keyCode = event.key;
    if (keyCode === 'ArrowLeft' ||
      keyCode === 'ArrowUp' ||
      keyCode === 'ArrowDown' ||
      keyCode === 'ArrowRight' ||
      (event.shiftKey && keyCode === 'Enter')) { // shift+enter allows for newlines
      event.stopPropagation();
    }
  }

}
