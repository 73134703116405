import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { User } from '@app/core/model/client/user';
import { IRelatedAsset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { IRelatedSpace, RelatedSpace } from '@app/core/model/entities/asset/space';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import { RelatedEquipment } from '@app/core/model/entities/equipments/equipment';
import { IRelatedProject, RelatedProject } from '@app/core/model/entities/project/project';
import { FieldConfig, FormatType } from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AggregationGridComponent } from '@app/shared/components/aggregation-grid/aggregation-grid.component';
import {
  DateTimeCellEditComponent
} from '@app/shared/components/cell-edit/date-time-cell-edit/date-time-cell-edit.component';
import { NumberCellEditComponent } from '@app/shared/components/cell-edit/number-cell-edit/number-cell-edit.component';
import {
  PercentCellEditComponent
} from '@app/shared/components/cell-edit/percent-cell-edit/percent-cell-edit.component';
import {
  LinkCellRendererComponent
} from '@app/shared/components/cell-renderer/link-cell-renderer/link-cell-renderer.component';
import {
  PictogramCellRendererComponent
} from '@app/shared/components/cell-renderer/pictogram-cell-renderer/pictogram-cell-renderer.component';
import { Dimensions } from '@app/shared/extra/column-dimensions.enum';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { ExcelType } from '@app/shared/extra/excel-type.enum';
import { customCssRenderer, getValue, isNullOrEmpty, setValue, translateValueBuilder } from '@app/shared/extra/utils';
import { FieldFormatTypePipe } from '@app/shared/pipes/field-format-type.pipe';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import compareTo from '@private/dayjs-compareto';
import { AppManager } from '@services/managers/app.manager';
import {
  CellClassParams,
  CellKeyDownEvent,
  CellRendererSelectorResult,
  CellValueChangedEvent,
  ICellRendererParams,
  KeyCreatorParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueParserParams,
  ValueSetterParams
} from 'ag-grid-community';
import {
  CellEditorSelectorResult,
  ColDef,
  ICellEditorParams,
  IDateFilterParams,
  SuppressKeyboardEventParams,
  ValueGetterParams
} from 'ag-grid-enterprise';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { distinctUntilChanged, first, map, skipWhile } from 'rxjs/operators';
import { MaterialTooltipComponent } from '../components/material-tooltip/material-tooltip.component';

interface Options {
  formatType?: FormatType;
  suffix?: string;
  translate?: boolean;
  fieldConfig?: FieldConfig;
  propertiesPath?: string;
  noFilter: boolean;
  validate: (event: CellValueChangedEvent, onSuccess: () => void, onFailure: () => void) => void;
}

/**
 * ColDef with additional properties
 */
export interface ExtendedColDef extends Partial<ColDef & Options> {
  onPaste?: (params: ProcessCellForExportParams) => any,
  onCopy?: (params: ProcessCellForExportParams) => any,
  onKeyDownDelete?: (event: CellKeyDownEvent) => void,
  editModeEnabled?: boolean
  fullRowEdit?: boolean
}

type cellClassType = ColumnType | ExcelType | string;

@Injectable()
export class ColumnBuilder {
  constructor(private translate: TranslateService,
              private appConfig: AppConfig,
              private fieldFormatPipe: FieldFormatTypePipe,
              private appManager: AppManager,
              private validationService: ValidationService) {
    dayjs.extend(compareTo);
    dayjs.extend(customParseFormat);
  }

  public static getCustomChartTheme(): any {
    const colors = ['#90B7D1', '#E84D83', '#00946E', '#BC8748', '#830562', '#00ACE4', '#DE8DB7', '#9BC776', '#092D7D', '#E42435', '#8EA1A2',
      '#A66531', '#E8CB00', '#625146', '#BE0E2F', '#4F145B', '#005794', '#FFFF00', '#1CE6FF', '#FF34FF', '#FF4A46', '#008941', '#006FA6',
      '#A30059', '#FFDBE5', '#7A4900', '#0000A6', '#63FFAC', '#B79762', '#004D43', '#8FB0FF', '#997D87', '#5A0007', '#809693', '#1B4400',
      '#4FC601', '#3B5DFF', '#4A3B53', '#FF2F80', '#61615A', '#BA0900', '#6B7900', '#00C2A0', '#FFAA92', '#FF90C9', '#B903AA', '#D16100',
      '#000035', '#7B4F4B', '#A1C299', '#300018', '#0AA6D8', '#013349', '#00846F', '#FFB500', '#C2FFED', '#A079BF', '#CC0744', '#C0B9B2',
      '#C2FF99', '#001E09', '#00489C', '#6F0062', '#0CBD66', '#EEC3FF', '#456D75', '#B77B68', '#7A87A1', '#788D66', '#885578', '#FAD09F',
      '#FF8A9A', '#D157A0', '#BEC459', '#456648', '#0086ED', '#886F4C', '#34362D', '#B4A8BD', '#00A6AA', '#452C2C', '#636375', '#A3C8C9',
      '#FF913F', '#938A81', '#575329', '#00FECF', '#B05B6F', '#8CD0FF', '#3B9700', '#04F757', '#C8A1A1', '#1E6E00', '#7900D7', '#A77500',
      '#6367A9', '#A05837', '#6B002C', '#772600', '#D790FF', '#9B9700', '#549E79', '#FFF69F', '#72418F', '#BC23FF', '#99ADC0', '#3A2465',
      '#922329', '#5B4534', '#404E55', '#0089A3', '#CB7E98', '#A4E804', '#324E72', '#6A3A4C', '#83AB58', '#004B28', '#C8D0F6', '#A3A489',
      '#806C66', '#222800', '#BF5650', '#E83000', '#66796D', '#DA007C', '#FF1A59', '#8ADBB4', '#5B4E51', '#C895C5', '#FF6832', '#66E1D3',
      '#CFCDAC', '#D0AC94', '#7ED379', '#012C58', '#7A7BFF', '#D68E01', '#353339', '#78AFA1', '#FEB2C6', '#75797C', '#837393', '#943A4D',
      '#B5F4FF', '#D2DCD5', '#9556BD', '#6A714A', '#001325', '#02525F', '#0AA3F7', '#E98176', '#DBD5DD', '#5EBCD1', '#3D4F44', '#7E6405',
      '#02684E', '#962B75', '#8D8546', '#9695C5', '#E773CE', '#D86A78', '#3E89BE', '#CA834E', '#518A87', '#5B113C', '#55813B', '#E704C4',
      '#00005F', '#A97399', '#4B8160', '#59738A', '#FF5DA7', '#F7C9BF', '#643127', '#513A01', '#6B94AA', '#51A058', '#A45B02', '#1D1702',
      '#E20027', '#E7AB63', '#4C6001', '#9C6966', '#64547B', '#97979E', '#006A66', '#391406', '#F4D749', '#0045D2', '#006C31', '#DDB6D0',
      '#7C6571', '#9FB2A4', '#00D891', '#15A08A', '#BC65E9', '#C6DC99', '#203B3C', '#671190', '#6B3A64', '#F5E1FF', '#FFA0F2', '#CCAA35',
      '#374527', '#8BB400', '#797868', '#C6005A', '#C86240', '#29607C', '#402334', '#7D5A44', '#CCB87C', '#B88183', '#AA5199', '#B5D6C3',
      '#A38469', '#9F94F0', '#A74571', '#B894A6', '#71BB8C', '#00B433', '#789EC9', '#6D80BA', '#953F00', '#5EFF03', '#1BE177', '#BCB1E5',
      '#76912F', '#003109', '#0060CD', '#D20096', '#895563', '#29201D', '#5B3213', '#A76F42', '#89412E', '#1A3A2A', '#494B5A', '#A88C85',
      '#F4ABAA', '#A3F3AB', '#00C6C8', '#EA8B66', '#958A9F', '#BDC9D2', '#9FA064', '#BE4700', '#658188', '#83A485', '#453C23', '#47675D',
      '#3A3F00', '#DFFB71', '#868E7E', '#98D058', '#6C8F7D', '#D7BFC2', '#3C3E6E', '#D83D66', '#2F5D9B', '#6C5E46', '#D25B88', '#5B656C',
      '#00B57F', '#545C46', '#866097', '#365D25', '#252F99', '#00CCFF', '#674E60', '#FC009C', '#92896B'];
    return {
      'tb-default': {
        baseTheme: 'ag-default',
        palette: {
          fills: colors,
          strokes: colors
        },
        overrides: {
          common: {
            legend: {
              position: 'bottom'
            },
            title: {
              enabled: true,
              text: 'Titre',
              fontFamily: 'Roboto, sans-serif',
              fontSize: 18,
              color: '#212121'
            }
          },
          cartesian: {
            axes: {
              number: {
                label: {
                  fontSize: 10,
                  fontFamily: 'Arial, sans-serif',
                  color: '#212121',
                  padding: 10,
                  autoRotate: true
                }
              },
              category: {
                label: {
                  fontSize: 10,
                  fontFamily: 'Arial, sans-serif',
                  color: '#212121',
                  padding: 10,
                  autoRotate: true
                }
              }
            }
          },
          polar: {
            series: {
              pie: {
                sectorLabel: {
                  fontSize: 10,
                  fontFamily: 'Arial, sans-serif',
                  color: '#212121'
                },
                calloutLabel: {
                  fontSize: 10,
                  fontFamily: 'Arial, sans-serif',
                  color: '#212121'
                }
              }
            }
          }
        }
      }
    };
  }

  public createStringColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => string),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const valueFormatter = (params: ValueFormatterParams): string => {
      // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
      if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
        if (!!options.translate) {
          // Translate the value directly
          return this.translateValue(params.value.toUpperCase(), options.colId) + options.suffix;
        } else {
          return params.value + options.suffix;
        }
      } else {
        return this.appConfig.GRID_EMPTY_VALUE;
      }
    };

    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: valueFormatter,
      // filtering
      suppressFiltersToolPanel: !!options.noFilter,
      menuTabs: (!options.noFilter && ['filterMenuTab'] || []).concat('generalMenuTab'), // Always keep generalMenuTab, but only add filter if allowed
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: valueFormatter,
        cellRendererSelector: (params): CellRendererSelectorResult => {
          if (!params || [this.appConfig.GRID_EMPTY_VALUE, this.translate.instant('GRID.SELECT_ALL')].includes(params.valueFormatted)) {
            return undefined;
          }

          return this.cellRendererSelector(true, options.fieldConfig, params.node?.group);
        }
      },
      cellRendererSelector: (params): CellRendererSelectorResult => {
        if (!params || [this.appConfig.GRID_EMPTY_VALUE, this.translate.instant('GRID.SELECT_ALL')].includes(params.valueFormatted)) {
          return undefined;
        }

        return this.cellRendererSelector(false, options.fieldConfig, params.node?.group);
      },
      // sorting
      comparator: (valueA: string, valueB: string): number => {
        if (!!options.translate) {
          return this.stringWithNumberComparator(
            this.translateValue(valueA, options.colId) + options.suffix,
            this.translateValue(valueB, options.colId) + options.suffix
          );
        } else {
          return this.stringWithNumberComparator(valueA, valueB);
        }
      },
      // editing
      cellEditor: 'textCellEditor',
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: params.formatValue(params.value),
          // Text fields can accept autocompletes
          suggestedOptions: options.fieldConfig.field.fieldValues
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        if (!!options.translate) {
          return this.stringWithNumberComparator(
            this.translateValue(valueA, options.colId) + options.suffix,
            this.translateValue(valueB, options.colId) + options.suffix
          ) === 0;
        } else {
          return this.stringWithNumberComparator(valueA, valueB) === 0;
        }
      },
      valueParser: (params: ValueParserParams): string => {
        // Trim whitespaces before setting the value
        return params.newValue.trim().replaceAll('\\n', ' ');
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): string => {
        // No specific processing for strings
        return params.value;
      },
      // FIXME Enable isValueTaken validation with paste
      // disable pasting if the column has isValueTaken validator
      suppressPaste: !!options.fieldConfig?.field?.validators.find(validator => {
        return validator.type === ValidatorType.UNIQUE;
      }),
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'],
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createUserInfoColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => User),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const valueFormatter = (params: ValueFormatterParams): string => {
      // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
      if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
        return params.value.toString();
      } else {
        return this.appConfig.GRID_EMPTY_VALUE;
      }
    };
    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params)?.toString();
        } else {
          return undefined;
        }
      },
      valueFormatter: valueFormatter,
      // filtering
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: valueFormatter
      },
      // sorting
      comparator: (valueA: User, valueB: User): number => {
        return this.stringWithNumberComparator(valueA.toString(), valueB.toString());
      },
      equals: (valueA: User, valueB: User): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }
        return this.stringWithNumberComparator(valueA.toString(), valueB.toString()) === 0;
      },
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'],
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createListColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => string),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const valueFormatter = (params: ValueFormatterParams): string => {
      // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
      if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
        if (!!options.translate) {
          // Translate the value with the column id as a grouping factor
          return this.translate.instant(`VALUE.${options.colId}_${params.value}`.toUpperCase()) + options.suffix;
        } else {
          return params.value + options.suffix;
        }
      } else {
        return this.appConfig.GRID_EMPTY_VALUE;
      }
    };

    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: valueFormatter,
      // filtering
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: valueFormatter,
        cellRendererSelector: (params): CellRendererSelectorResult => {
          if (!params || [this.appConfig.GRID_EMPTY_VALUE, this.translate.instant('GRID.SELECT_ALL')].includes(params.valueFormatted)) {
            return undefined;
          }

          return this.cellRendererSelector(true, options.fieldConfig, params.node?.group);
        }
      },
      // sorting
      comparator: (valueA: any, valueB: any): number => {
        if (typeof valueA === 'number' || typeof valueB === 'number') {
          return this.numberComparator(valueA, valueB);
        } else if (!!options.translate) {
          return this.stringWithNumberComparator(
            this.translateValue(valueA, options.colId) + options.suffix,
            this.translateValue(valueB, options.colId) + options.suffix
          );
        } else {
          return this.stringWithNumberComparator(valueA, valueB);
        }
      },
      cellRendererSelector: (params): CellRendererSelectorResult => {
        if (!params || [this.appConfig.GRID_EMPTY_VALUE, this.translate.instant('GRID.SELECT_ALL')].includes(params.valueFormatted)) {
          return undefined;
        }

        return this.cellRendererSelector(false, options.fieldConfig, params.node?.group);
      },
      // editing
      cellEditor: 'selectCellEditor',
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          // List is a special case where the selected value must be formatted first
          value: {
            entityId: params.value,
            entityName: params.formatValue(params.value)
          },
          // We send all the possible values as a combination of value/translation
          entityList: options.fieldConfig.field.fieldValues?.map((fieldValue) => ({
            entityId: fieldValue,
            entityName: params.formatValue(fieldValue)
          }))
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        if (!!options.translate) {
          return this.stringWithNumberComparator(
            valueA ? this.translateValue(valueA, options.colId) + options.suffix : null,
            valueB ? this.translateValue(valueB, options.colId) + options.suffix : null
          ) === 0;
        } else {
          return this.stringWithNumberComparator(valueA, valueB) === 0;
        }
      },
      valueParser: (params: ValueParserParams): string => {
        // Trim whitespaces before setting the value
        return params.newValue.trim().replaceAll('\\n', ' ');
      },
      // copying
      onCopy: (params): any => {
        // Copy the translated value from the list if available instead of its code
        return options.fieldConfig?.customOptions?.['translate']
          ? this.translateValue(params.value, options.fieldConfig?.code)
          : params.value;
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): string => {
        // Assuming a translated value is pasted, we need to find if it is present among other translated values
        const formattedValue = params.value.trim().replaceAll('\\n', ' ');
        const listValues = [].concat(options.fieldConfig?.field?.fieldValues);
        const index = listValues
          .map(fieldValue => this.translateValue(fieldValue, options.fieldConfig?.code))
          .indexOf(formattedValue);

        if (params.value == '') {
          return params.value;
        }
        return index >= 0 ? // Check if the value is found in the list
          listValues[index] :
          getValue(params.node.data, options.fieldConfig.fieldPath); // reject the new value, set the old one
      },
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'],
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createTextAreaColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => string),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, ['ellipsis', ExcelType.TEXT]);
      },
      width: Dimensions.XXL,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: (params: ValueFormatterParams): string => {
        // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
        if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
          if (!!options.translate) {
            // Translate the value directly
            return this.translate.instant(`VALUE.${params.value}`.toUpperCase()) + options.suffix;
          } else {
            return params.value + options.suffix;
          }
        } else {
          return this.appConfig.GRID_EMPTY_VALUE;
        }
      },
      cellRenderer: MaterialTooltipComponent,
      // filtering
      filter: 'agTextColumnFilter',
      // sorting
      comparator: this.comparator.bind(this),
      // editing
      cellEditor: 'textAreaEditor',
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: params.value
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        return this.comparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): string => {
        // Trim whitespaces before setting the value
        return params.newValue.trim().replaceAll('\\n', '\n');
      },
      // copy
      onCopy: (params): any => {
        // In edit mode, we allow to copy escape character so that lines return within the text-area cells are not
        // consider as cell value separator.
        return params.column.getColDef().editable ? params.value?.replaceAll('\n', '\\n') : params.value;
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): string => {
        // No specific processing for textarea values
        return params.value;
      },
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // other
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams): boolean => {
        const keyCode = params.event.key;
        const colDef = params.colDef as ExtendedColDef;
        return keyCode === 'ArrowLeft' ||
          keyCode === 'ArrowUp' ||
          keyCode === 'ArrowDown' ||
          keyCode === 'ArrowRight' ||
          (params.event.shiftKey && keyCode === 'Enter') ||
          (colDef.fullRowEdit && keyCode === 'Enter') ||
          (keyCode === 'Delete' && colDef.editModeEnabled);
      },
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createNumericalColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => number),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {

    return {
      headerName: header,
      headerClass: ColumnType.NUMERICAL,
      cellClass: (params: CellClassParams): string[] => {
        const additionalClass = options?.formatType ? options.formatType : ExcelType.NUMERICAL;
        return this.getCellClass(params, options, ColumnType.NUMERICAL, [additionalClass]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: (params: ValueFormatterParams): string => {
        // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
        if (params.value === 0 || (params.value && !Number.isNaN(params.value) && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE)) {
          return this.fieldFormatPipe.transform(params.value, options.formatType) + options.suffix;
        } else {
          return this.appConfig.GRID_EMPTY_VALUE;
        }
      },
      cellRenderer: AggregationGridComponent,
      cellRendererParams: (params: ICellRendererParams): any => {
        //If current row is a group, use one of its children none grouped rows instead
        const entity = params.node.group ? params.node.allLeafChildren[0]?.data : params.data;
        return {
          styles: params.valueFormatted != this.appConfig.GRID_EMPTY_VALUE ? customCssRenderer(
            options.fieldConfig.customCss,
            entity
          ) : {},
          column: params.column,
          valueFormatted: params.valueFormatted
        };
      },
      // filtering
      filter: 'agNumberColumnFilter',
      // sorting
      comparator: this.numberComparator.bind(this),
      // editing
      cellEditor: NumberCellEditComponent,
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: valueGetter({node: params.node, data: params.data, colDef: params.colDef} as ValueGetterParams)
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        return this.numberComparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): number => {
        // Cast to Number
        return Number(params.newValue);
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): number => {
        // Verify that the pasted value is a number
        return (!Number.isNaN(params.value) || params.value == '')
          ? params.value
          : getValue(params.node.data, options.fieldConfig.fieldPath); // reject the new value, set the old one
      },
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'] || 'series',
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createPercentColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => number),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {

    return {
      headerName: header,
      headerClass: ColumnType.PERCENT,
      cellClass: (params: CellClassParams): string[] => {
        const additionalClass = options?.formatType ? options.formatType : ExcelType.NUMERICAL;
        return this.getCellClass(params, options, ColumnType.NUMERICAL, [additionalClass]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: (params: ValueFormatterParams): string => {
        // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
        if (params.value === 0 || (params.value && !Number.isNaN(params.value) && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE)) {
          return this.fieldFormatPipe.transform(params.value, options.formatType);
        } else {
          return this.appConfig.GRID_EMPTY_VALUE;
        }
      },
      cellRenderer: AggregationGridComponent,
      // filtering
      filter: 'agNumberColumnFilter',
      // sorting
      comparator: this.numberComparator.bind(this),
      // editing
      cellEditor: PercentCellEditComponent,
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: valueGetter({node: params.node, data: params.data, colDef: params.colDef} as ValueGetterParams)
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        return this.numberComparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): number => {
        // Cast to Number
        return Number(params.newValue);
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): number => {
        // Verify that the pasted value is a number
        return (!Number.isNaN(params.value) || params.value == '')
          ? params.value
          : getValue(params.node.data, options.fieldConfig.fieldPath); // reject the new value, set the old one
      },
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'] || 'series',
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createBooleanColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => boolean),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const valueFormatter = (params: ValueFormatterParams): string => {
      switch (params.value) {
        case true:
        case 'true':
          return this.translate.instant('LABEL.YES');
        case false:
        case 'false':
          return this.translate.instant('LABEL.NO');
        default:
          return this.appConfig.GRID_EMPTY_VALUE;
      }
    };

    return {
      headerName: header,
      headerClass: ColumnType.BOOLEAN,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.BOOLEAN, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      valueFormatter: valueFormatter,
      // filtering
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: valueFormatter
      },
      // sorting
      comparator: this.comparator.bind(this),
      // editing
      cellEditor: 'selectCellEditor',
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: {
            entityId: params.value,
            entityName: params.formatValue(params.value)
          },
          entityList: [
            {entityId: true, entityName: this.translate.instant('LABEL.YES')},
            {entityId: false, entityName: this.translate.instant('LABEL.NO')}
          ]
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        return this.comparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): boolean => {
        // Cast to Boolean
        return Boolean(params.newValue);
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): boolean => {
        // Verify that the pasted value is a boolean
        const newValue = params.value.toBooleanString();
        return (newValue === 'true' || newValue === 'false' || newValue == '')
          ? params.value
          : getValue(params.node.data, options.fieldConfig.fieldPath); // reject the new value, set the old one
      },
      // other
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createDateColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => string),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const cellEditor =
      FieldTypeEnum.DATETIME === options.fieldConfig.field.fieldType ? DateTimeCellEditComponent : 'dateCellEditor';
    const displayDate =
      FieldTypeEnum.DATETIME === options.fieldConfig.field.fieldType ? this.appConfig.DISPLAY_DATE_FORMAT : this.appConfig.DISPLAY_DATETIME_FORMAT;
    return {
      headerName: header,
      headerClass: ColumnType.DATE,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.DATE, [ExcelType.DATE]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      filterValueGetter: (params: ValueGetterParams): string => {
        // We give the date without time and zone for filtering
        return dayjs(valueGetter(params)).format(this.appConfig.DISPLAY_DATE_FORMAT);
      },
      valueFormatter: (params: ValueFormatterParams): string => {
        // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
        if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
          return this.appConfig.getFormattedDate(params.value, options.fieldConfig.field.fieldType);
        } else {
          return this.appConfig.GRID_EMPTY_VALUE;
        }
      },
      // filtering
      filter: 'agDateColumnFilter',
      filterParams: <IDateFilterParams>{
        comparator: (filterDateAtMidnight, cellValue): number => {
          if (cellValue !== this.appConfig.GRID_EMPTY_VALUE) {
            return dayjs(cellValue).compareTo(dayjs(filterDateAtMidnight));
          } else {
            return undefined;
          }
        }
      },
      // sorting
      comparator: this.dateComparator.bind(this),
      // editing
      cellEditor: cellEditor,
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: params.formatValue(params.value)
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        return this.dateComparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): string => {
        // Cast to Date, ignore empty values as Dayjs returns the current date if the value is empty
        return params.newValue !== '' ? dayjs(params.newValue).format(displayDate) : params.newValue;
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): Dayjs => {
        // Verify that the pasted value is a date compatible with Dayjs
        return dayjs(params.value).isValid() || params.value === ''
          ? params.value
          : getValue(params.node.data, options.fieldConfig.fieldPath); // reject the new value, set the old one
      },
      // others...
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'] || 'time',
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createMultiValueColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => any[]),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const valueFormatter = (params: ValueFormatterParams): string => {
      // If value is not GRID_EMPTY_VALUE, format it properly, otherwise return GRID_EMPTY_VALUE
      if (params.value && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
        if (Array.isArray(params.value)) {
          if (params.value.length === 0) return this.appConfig.GRID_EMPTY_VALUE;
          if (!!options.translate) {
            // Translate the value with the column id as a grouping factor
            // Two lines to fix https://github.com/biesbjerg/ngx-translate-extract/issues/250
            const values = params.value.map(item => this.translateValue(item, options.colId) + options.suffix);
            return values?.join(', ');
          } else {
            return params.value?.map(item => item + options.suffix)?.join(', ');
          }
        } else {
          return params.value;
        }
      } else {
        return this.appConfig.GRID_EMPTY_VALUE;
      }
    };

    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          const value = valueGetter(params);
          // return undefined if undefined
          if (Array.isArray(value) && value.length > 0) {
            return value.map(it => it.toString());
          }
          // GRID_EMPTY_VALUE, if undefined or empty array
          return [];
        } else {
          return undefined;
        }

      },
      valueFormatter: valueFormatter,
      // filtering
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: valueFormatter
      },
      filterValueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          const value = valueGetter(params);
          if (Array.isArray(value) && value.length > 0) {
            return value.map(it => it.toString());
          }
          // GRID_EMPTY_VALUE, if undefined or empty array
          return this.appConfig.GRID_EMPTY_VALUE;
        } else {
          return undefined;
        }
      },
      // sorting
      comparator: (valueA, valueB): number => {
        if (Array.isArray(valueA)) {
          valueA = valueA.join();
        }
        if (Array.isArray(valueB)) {
          valueB = valueB.join();
        }
        return this.stringWithNumberComparator(valueA, valueB);
      },
      // editing
      cellEditorSelector: (params: ICellEditorParams): CellEditorSelectorResult => {
        if (params.value instanceof Entity) {
          return <CellEditorSelectorResult>{
            component: 'multipleEntityAutocompleteCellEditor'
          };
        } else {
          return <CellEditorSelectorResult>{
            component: 'multipleAutocompleteCellEditor'
          };
        }
      },
      cellEditorParams: (params: ICellEditorParams): any => {
        return {
          fieldConfig: options.fieldConfig,
          validators: options.fieldConfig.field.validators,
          value: params.value,
          // Chip fields can accept autocompletes
          suggestedOptions: options.fieldConfig.field.fieldValues
        };
      },
      equals: (valueA: any, valueB: any): boolean => {
        if (!valueA && !valueB) {
          // If both values are different kinds of falsy, they are presumed to be equal
          return true;
        }

        if (Array.isArray(valueA)) {
          valueA = valueA.join();
        }
        if (Array.isArray(valueB)) {
          valueB = valueB.join();
        }
        return this.stringWithNumberComparator(valueA, valueB) === 0;
      },
      valueParser: (params: ValueParserParams): string[] => {
        // Assuming the new value is an array, trim the values
        return (Array.isArray(params.newValue) ? params.newValue : [])
          .map((val: string) => val.trim().replaceAll('\\n', ' '));
      },
      // pasting
      onPaste: (params: ProcessCellForExportParams): string[] => {
        // Assuming the pasted data is a string comma separated values, split them and return them
        const arr = params.value.split(',').map((val: string) => val.trim().replaceAll('\\n', ' '));
        // Remove duplicates
        return arr.filter((elem: string, index: number) => arr.indexOf(elem) === index && elem !== '');
      },
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // other
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams): boolean => {
        const keyCode = params.event.key;
        const colDef = params.colDef as ExtendedColDef;
        return params.editing || (keyCode === 'Delete' && colDef.editModeEnabled);
      },

      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createLinkColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => string),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: (params: ValueGetterParams): any => {
        // execute valueGetter
        // return even if undefined
        if (this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) {
          return valueGetter(params);
        } else {
          return undefined;
        }
      },
      cellRenderer: LinkCellRendererComponent,
      // filtering
      filter: 'agTextColumnFilter',
      // sorting
      sortable: true,
      // editing
      // pasting
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'],
      onCellClicked: (params): void => {
        window.open(params.value, '_blank', 'noopener,noreferrer');
      },
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };
  }

  public createAssetColumn(
    header: string,
    valueGetter: ((params: ValueGetterParams) => IRelatedAsset[]),
    options: Partial<ColDef & Options> = {hide: false}
  ): ExtendedColDef {
    const subValueGetter = (params: ValueGetterParams): any => {
      const value = valueGetter(params)?.firstItem();
      if (!this.validationService.validateConditions(params.data, options.fieldConfig?.conditionsToView)) return void 0;
      if (!value) return void 0;

      return new RelatedAsset(value);
    };
    return {
      headerName: header,
      headerClass: ColumnType.TEXT,
      cellClass: (params: CellClassParams): string[] => {
        return this.getCellClass(params, options, ColumnType.TEXT, [ExcelType.TEXT]);
      },
      width: Dimensions.LG,
      // displaying
      valueGetter: subValueGetter,
      valueFormatter: (params: ValueFormatterParams): string => {
        return params.value?.toString() ?? this.appConfig.GRID_EMPTY_VALUE;
      },
      // filtering
      filter: 'agSetColumnFilter',
      filterParams: {
        convertValuesToStrings: true,
        keyCreator: (params: KeyCreatorParams) => params.colDef.keyCreator(params).toString()
      },
      // sorting
      comparator: (valueA, valueB): number => {
        return this.stringWithNumberComparator(valueA?.toString(), valueB?.toString());
      },
      // editing
      // TODO entity editing
      // copying
      onCopy: (params): string => params.value,
      // pasting
      // TODO entity pasting
      // other
      chartDataType: options.fieldConfig?.customOptions?.['chartDataType'],
      // Add common option for all type of column.
      ...this.commonColDef(options),
      // Add all defined options at the end to override any previously set properties
      ...options
    };

  }

  public createColumn(inputType: FieldTypeEnum,
                      header = '',
                      valueGetter: ((params: ValueGetterParams) => any),
                      options: Partial<ColDef & Options> = {hide: false}): ColDef {
    const currency = this.appManager.currencyMap.get(this.appManager.currentOrganization.currency);
    options.suffix = (options.suffix)
      ? this.translate.instant('SUFFIX.' + options.suffix.toUpperCase(), {currency: currency.symbol})
      : '';

    switch (inputType) {
      case FieldTypeEnum.TEXT:
      case FieldTypeEnum.TEXT_AUTOCOMPLETE:
      case FieldTypeEnum.TEXT_HEADER:
        return this.createStringColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.USER_INFO:
        return this.createUserInfoColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.TEXTAREA:
        return this.createTextAreaColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.NUMERIC:
      case FieldTypeEnum.YEAR:
        return this.createNumericalColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.PERCENT:
        return this.createPercentColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.RADIOBUTTON:
        return this.createBooleanColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.DATE:
      case FieldTypeEnum.DATETIME:
        return this.createDateColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.LIST:
      case FieldTypeEnum.NUMERIC_LIST:
      case FieldTypeEnum.DOCUMENT_TYPE:
        return this.createListColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.CHIPS:
      case FieldTypeEnum.CHIPS_AUTOCOMPLETE:
      case FieldTypeEnum.LIST_MULTIVALUE:
        return this.createMultiValueColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.PROJECTS:
        const relatedProjectsValueGetter = (params: ValueGetterParams): any => {
          return valueGetter(params)?.map((project: IRelatedProject) => new RelatedProject(project));
        };
        return this.createMultiValueColumn(this.translate.instant(header), relatedProjectsValueGetter, options);
      case FieldTypeEnum.EQUIPMENTS:
        const relatedEquipmentsValueGetter = (params: ValueGetterParams): any => {
          return valueGetter(params)?.map((equipment: IRelatedEntity) => new RelatedEquipment(equipment));
        };
        return this.createMultiValueColumn(this.translate.instant(header), relatedEquipmentsValueGetter, options);
      case FieldTypeEnum.SPACES:
        const relatedSpacesValueGetter = (params: ValueGetterParams): any => {
          return valueGetter(params)?.map((space: IRelatedSpace) => new RelatedSpace(space));
        };
        return this.createMultiValueColumn(this.translate.instant(header), relatedSpacesValueGetter, options);
      case FieldTypeEnum.ASSETS:
        const relatedAssetsValueGetter = (params: ValueGetterParams): any => {
          return valueGetter(params)?.map((asset: IRelatedAsset) => new RelatedAsset(asset));
        };
        return this.createMultiValueColumn(this.translate.instant(header), relatedAssetsValueGetter, options);
      case FieldTypeEnum.LINK:
        return this.createLinkColumn(this.translate.instant(header), valueGetter, options);
      case FieldTypeEnum.ASSET:
        return this.createAssetColumn(this.translate.instant(header), valueGetter, options);
      default:
        return this.createStringColumn(this.translate.instant(header), valueGetter, options);
    }
  }

  // Grouped column definition, apply on grouped column
  public createGroupColumnDef(header: string): ColDef {
    return {
      headerName: header,
      menuTabs: ['generalMenuTab'],
      minWidth: Dimensions.XL,
      suppressMovable: true
    };
  }

  public dateComparator(valueA, valueB): number {
    const dateA = dayjs(valueA);
    const dateB = dayjs(valueB);
    if (dateA.isValid() && !dateB.isValid()) {
      return 1;
    } else if (dateB.isValid() && !dateA.isValid()) {
      return -1;
    } else if (dateA.isValid() && dateB.isValid()) {
      return dateA.compareTo(dateB);
    }
  }

  public comparator(valueA, valueB): number {
    if (valueA && !valueB) {
      return 1;
    } else if (valueB && !valueA) {
      return -1;
    } else if (valueA && valueB) {
      return valueA.localeCompare ? valueA.localeCompare(valueB) : 0;
    }
  }

  /**
   * Compare two strings that can have common number inside them using the first part of the string and then the number if present.
   * @param valueA
   * @param valueB
   */
  public stringWithNumberComparator(valueA: string, valueB: string): number {
    if (isNullOrEmpty(valueB) && isNullOrEmpty(valueA)) {
      return 0;
    } else if (!isNullOrEmpty(valueA) && isNullOrEmpty(valueB)) {
      return 1;
    } else if (!isNullOrEmpty(valueB) && isNullOrEmpty(valueA)) {
      return -1;
    } else {
      const nameArrayA = valueA.toString().match(/\D+|\d+/g);
      const nameArrayB = valueB.toString().match(/\D+|\d+/g);
      let index = 0;
      let result = 0;
      while (result === 0 && index < nameArrayA.length && index < nameArrayB.length) {
        if (typeof parseInt(nameArrayA[index], 0) === 'number' && typeof parseInt(nameArrayB[index], 0) === 'number'
          && !Number.isNaN(parseInt(nameArrayA[index], 0)) && !Number.isNaN(parseInt(nameArrayB[index], 0))) {
          result = this.numberComparator(parseInt(nameArrayA[index], 0), parseInt(nameArrayB[index], 0));
        } else {
          result = nameArrayA[index].localeCompare(nameArrayB[index]);
        }
        index++;
      }
      if (result === 0 && nameArrayA.length !== nameArrayB.length) {
        return nameArrayA.length - nameArrayB.length;
      } else {
        return result;
      }
    }
  }

  public localeTextCommon(): any {
    return {
      of: this.translate.instant('GRID.OF'),
      totalRows: this.translate.instant('GRID.TOTAL_ROWS'),
      filteredRows: this.translate.instant('GRID.FILTERED_ROWS'),
      selectedRows: this.translate.instant('GRID.SELECTED_ROWS'),
      totalAndFilteredRows: this.translate.instant('GRID.TOTAL_AND_FILTERED_ROWS'),
      count: this.translate.instant('GRID.COUNT'),
      average: this.translate.instant('GRID.AVERAGE'),
      avg: this.translate.instant('GRID.AVG'),
      sum: this.translate.instant('GRID.SUM'),
      min: this.translate.instant('GRID.MIN'),
      max: this.translate.instant('GRID.MAX'),
      none: this.translate.instant('GRID.NONE'),
      selectAll: this.translate.instant('GRID.SELECT_ALL'),
      searchOoo: this.translate.instant('GRID.SEARCH_FROM_MENU'),
      search: this.translate.instant('GRID.SEARCH_FROM_MENU'),
      blanks: this.translate.instant('GRID.BLANKS'),
      filterFromMenu: this.translate.instant('GRID.FILTER_FROM_MENU'),
      applyFilter: this.translate.instant('GRID.APPLY_FILTER'),
      clearFilter: this.translate.instant('GRID.CLEAR_FILTER'),
      equals: this.translate.instant('GRID.EQUALS'),
      notEqual: this.translate.instant('GRID.NOT_EQUALS'),
      lessThan: this.translate.instant('GRID.LESS_THAN'),
      greaterThan: this.translate.instant('GRID.GREATER_THAN'),
      lessThanOrEqual: this.translate.instant('GRID.LESS_THAN_OR_EQUALS'),
      greaterThanOrEqual: this.translate.instant('GRID.GREATER_THAN_OR_EQUALS'),
      inRange: this.translate.instant('GRID.IN_RANGE'),
      contains: this.translate.instant('GRID.CONTAINS'),
      notContains: this.translate.instant('GRID.NOT_CONTAINS'),
      startsWith: this.translate.instant('GRID.STARTS_WITH'),
      endsWith: this.translate.instant('GRID.ENDS_WITH'),
      andCondition: this.translate.instant('GRID.AND_CONDITION'),
      orCondition: this.translate.instant('GRID.OR_CONDITION'),
      group: this.translate.instant('GRID.GROUP'),
      columns: this.translate.instant('GRID.COLUMNS'),
      filters: this.translate.instant('GRID.FILTERS'),
      filterOoo: this.translate.instant('GRID.FILTER_FROM_MENU'),
      rowGroupColumns: this.translate.instant('GRID.ROW_GROUP_COLUMNS'),
      rowGroupColumnsEmptyMessage: this.translate.instant('GRID.ROW_GROUP_COLUMNS_EMPTY_MESSAGE'),
      valueColumns: this.translate.instant('GRID.VALUE_COLUMNS'),
      pivotMode: this.translate.instant('GRID.PIVOT_MODE'),
      groups: this.translate.instant('GRID.GROUPS'),
      pivots: this.translate.instant('GRID.PIVOTS'),
      valueColumnsEmptyMessage: this.translate.instant('GRID.VALUE_COLUMNS_EMPTY_MESSAGE'),
      pivotColumnsEmptyMessage: this.translate.instant('GRID.PIVOT_COLUMNS_EMPTY_MESSAGE'),
      toolPanelButton: this.translate.instant('GRID.TOOL_PANEL_BUTTON'),
      noRowsToShow: this.translate.instant('GRID.NO_ROWS_TO_SHOW'),
      enabled: this.translate.instant('GRID.ENABLED'),
      pinColumn: this.translate.instant('GRID.PIN_COLUMN'),
      valueAggregation: this.translate.instant('GRID.VALUE_AGGREGATION'),
      autosizeThiscolumn: this.translate.instant('GRID.AUTOSIZE_THIS_COLUMN'),
      autosizeAllColumns: this.translate.instant('GRID.AUTOSIZE_ALL_COLUMNS'),
      groupBy: this.translate.instant('GRID.GROUP_BY'),
      ungroupBy: this.translate.instant('GRID.UNGROUP_BY'),
      ungroupAll: this.translate.instant('GRID.UNGROUP_ALL'),
      resetColumns: this.translate.instant('GRID.RESET_COLUMNS'),
      expandAll: this.translate.instant('GRID.EXPAND_ALL'),
      collapseAll: this.translate.instant('GRID.COLLAPSE_ALL'),
      toolPanel: this.translate.instant('GRID.TOOL_PANEL'),
      export: this.translate.instant('GRID.EXPORT'),
      csvExport: this.translate.instant('GRID.CSV_EXPORT'),
      excelExport: this.translate.instant('GRID.EXCEL_EXPORT'),
      excelXmlExport: this.translate.instant('GRID.EXCEL_XML_EXPORT'),
      pivotChartAndPivotMode: this.translate.instant('GRID.PIVOT_CHART_AND_PIVOT_MODE'),
      pivotChart: this.translate.instant('GRID.PIVOT_CHART'),
      chartRange: this.translate.instant('GRID.CHART_RANGE'),
      columnChart: this.translate.instant('GRID.COLUMN_CHART'),
      groupedColumn: this.translate.instant('GRID.GROUPED_COLUMN'),
      stackedColumn: this.translate.instant('GRID.STACKED_COLUMN'),
      normalizedColumn: this.translate.instant('GRID.NORMALIZED_COLUMN'),
      barChart: this.translate.instant('GRID.BAR_CHART'),
      groupedBar: this.translate.instant('GRID.GROUPED_BAR'),
      stackedBar: this.translate.instant('GRID.STACKED_BAR'),
      normalizedBar: this.translate.instant('GRID.NORMALIZED_BAR'),
      pieChart: this.translate.instant('GRID.PIE_CHART'),
      pie: this.translate.instant('GRID.PIE'),
      doughnut: this.translate.instant('GRID.DOUGHNUT'),
      line: this.translate.instant('GRID.LINE'),
      xyChart: this.translate.instant('GRID.X_Y_CHART'),
      scatter: this.translate.instant('GRID.SCATTER'),
      bubble: this.translate.instant('GRID.BUBBLE'),
      areaChart: this.translate.instant('GRID.AREA_CHART'),
      area: this.translate.instant('GRID.AREA'),
      stackedArea: this.translate.instant('GRID.STACKED_AREA'),
      normalizedArea: this.translate.instant('GRID.NORMALIZED_AREA'),
      pinLeft: this.translate.instant('GRID.PIN_LEFT'),
      pinRight: this.translate.instant('GRID.PIN_RIGHT'),
      noPin: this.translate.instant('GRID.NO_PIN'),
      copy: this.translate.instant('GRID.COPY'),
      copyWithHeaders: this.translate.instant('GRID.COPY_WITH_HEADERS'),
      paste: this.translate.instant('GRID.PASTE'),
      pivotChartTitle: this.translate.instant('GRID.PIVOT_CHART_TITLE'),
      rangeChartTitle: this.translate.instant('GRID.RANGE_CHART_TITLE'),
      settings: this.translate.instant('GRID.SETTINGS'),
      data: this.translate.instant('GRID.DATA'),
      format: this.translate.instant('GRID.FORMAT'),
      defaultCategory: this.translate.instant('GRID.NONE_CATEGORY'),
      categories: this.translate.instant('GRID.CATEGORIES'),
      series: this.translate.instant('GRID.SERIES'),
      xyValues: this.translate.instant('GRID.X_Y_VALUES'),
      paired: this.translate.instant('GRID.PAIRED'),
      axis: this.translate.instant('GRID.AXIS'),
      color: this.translate.instant('GRID.COLOR'),
      thickness: this.translate.instant('GRID.THICKNESS'),
      xRotation: this.translate.instant('GRID.X_ROTATION'),
      yRotation: this.translate.instant('GRID.Y_ROTATION'),
      ticks: this.translate.instant('GRID.TICKS'),
      width: this.translate.instant('GRID.WIDTH'),
      length: this.translate.instant('GRID.LENGTH'),
      padding: this.translate.instant('GRID.PADDING'),
      chart: this.translate.instant('GRID.CHART'),
      title: this.translate.instant('GRID.TITLE'),
      background: this.translate.instant('GRID.BACKGROUND'),
      font: this.translate.instant('GRID.FONT'),
      top: this.translate.instant('GRID.TOP'),
      right: this.translate.instant('GRID.RIGHT'),
      bottom: this.translate.instant('GRID.BOTTOM'),
      left: this.translate.instant('GRID.LEFT'),
      labels: this.translate.instant('GRID.LABELS'),
      size: this.translate.instant('GRID.SIZE'),
      minSize: this.translate.instant('GRID.MIN_SIZE'),
      maxSize: this.translate.instant('GRID.MAX_SIZE'),
      legend: this.translate.instant('GRID.LEGEND'),
      position: this.translate.instant('GRID.POSITION'),
      markerSize: this.translate.instant('GRID.MARKER_SIZE'),
      markerStroke: this.translate.instant('GRID.MARKER_STROKE'),
      markerPadding: this.translate.instant('GRID.MARKER_PADDING'),
      itemPaddingX: this.translate.instant('GRID.ITEM_PADDING_X'),
      itemPaddingY: this.translate.instant('GRID.ITEM_PADDING_Y'),
      strokeWidth: this.translate.instant('GRID.STROKE_WIDTH'),
      offset: this.translate.instant('GRID.OFFSET'),
      offsets: this.translate.instant('GRID.OFFSETS'),
      tooltips: this.translate.instant('GRID.TOOLTIPS'),
      callout: this.translate.instant('GRID.CALLOUT'),
      markers: this.translate.instant('GRID.MARKERS'),
      shadow: this.translate.instant('GRID.SHADOW'),
      blur: this.translate.instant('GRID.BLUR'),
      xOffset: this.translate.instant('GRID.X_OFFSET'),
      yOffset: this.translate.instant('GRID.Y_OFFSET'),
      lineWidth: this.translate.instant('GRID.LINE_WIDTH'),
      normal: this.translate.instant('GRID.NORMAL'),
      bold: this.translate.instant('GRID.BOLD'),
      italic: this.translate.instant('GRID.ITALIC'),
      boldItalic: this.translate.instant('GRID.BOLD_ITALIC'),
      predefined: this.translate.instant('GRID.PREDEFINED'),
      fillOpacity: this.translate.instant('GRID.FILL_OPACITY'),
      strokeOpacity: this.translate.instant('GRID.STROKE_OPACITY'),
      columnGroup: this.translate.instant('GRID.COLUMN_GROUP'),
      barGroup: this.translate.instant('GRID.BAR_GROUP'),
      pieGroup: this.translate.instant('GRID.PIE_GROUP'),
      lineGroup: this.translate.instant('GRID.LINE_GROUP'),
      scatterGroup: this.translate.instant('GRID.SCATTER_GROUP'),
      areaGroup: this.translate.instant('GRID.AREA_GROUP'),
      groupedColumnTooltip: this.translate.instant('GRID.GROUPED_COLUMN_TOOLTIP'),
      stackedColumnTooltip: this.translate.instant('GRID.STACKED_COLUMN_TOOLTIP'),
      normalizedColumnTooltip: this.translate.instant('GRID.NORMALIZED_COLUMN_TOOLTIP'),
      groupedBarTooltip: this.translate.instant('GRID.GROUPED_BAR_TOOLTIP'),
      stackedBarTooltip: this.translate.instant('GRID.STACKED_BAR_TOOLTIP'),
      normalizedBarTooltip: this.translate.instant('GRID.NORMALIZED_BAR_TOOLTIP'),
      pieTooltip: this.translate.instant('GRID.PIE_TOOLTIP'),
      doughnutTooltip: this.translate.instant('GRID.DOUGHNUT_TOOLTIP'),
      lineTooltip: this.translate.instant('GRID.LINE_TOOLTIP'),
      groupedAreaTooltip: this.translate.instant('GRID.GROUPED_AREA_TOOLTIP'),
      stackedAreaTooltip: this.translate.instant('GRID.STACKED_AREA_TOOLTIP'),
      normalizedAreaTooltip: this.translate.instant('GRID.NORMALIZED_AREA_TOOLTIP'),
      scatterTooltip: this.translate.instant('GRID.SCATTER_TOOLTIP'),
      bubbleTooltip: this.translate.instant('GRID.BUBBLE_TOOLTIP'),
      noDataToChart: this.translate.instant('GRID.NO_DATA_TO_CHART'),
      pivotChartRequiresPivotMode: this.translate.instant('GRID.PIVOT_CHART_REQUIRES_PIVOT_MODE'),
      visualisation: this.translate.instant('GRID.VISUALISATION'),
      loadingOoo: this.translate.instant('GRID.LOADING')
    };
  }

  /**
   * Translate the value if there is a key in the po files. Otherwise, return the value.
   * @param value
   * @param code the field's code
   */
  private translateValue(value: string, code: string): string {
    return translateValueBuilder(this.translate)(value, code);
  }

  /**
   * Get the CSS classes for a cell.
   * @param params The parameters for the cell class containing the data of the entity.
   * @param options The column definition and other options containing the field config.
   * @param columnType The type of the column to add as a class of th cell.
   * @param additionalCLasses Additional CSS classes to be added (default is undefined).
   * @returns An array of strings representing the CSS classes for the cell to added.
   * @private
   */
  private getCellClass(params: CellClassParams,
                       options: Partial<ColDef & Options>,
                       columnType: ColumnType,
                       additionalCLasses: string[] = undefined): string[] {
    const cellClasses: string[] = [columnType];
    if (!this.validationService.fieldIsEditable(options?.fieldConfig, params.data)) {
      cellClasses.push('readonly-column');
    }
    return [...cellClasses, ...additionalCLasses];
  };

  /**
   * Create a fragment of a column definition used by all type of column.
   * @param options containing the column definition and options of the column.
   * @return Fragment of a column definition
   * @private
   */
  private commonColDef(options: Partial<ColDef & Options>): any {
    return {
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams): boolean => {
        const keyCode = params.event.key;
        const colDef = params.colDef as ExtendedColDef;
        // Suppress the keyboard event if the user press enter on full row edit
        if (colDef.fullRowEdit && keyCode === 'Enter') {
          return true;
        }

        // If the user did not press delete or editable is undefined do not suppress the keyboard event.
        if (keyCode !== 'Delete' || !colDef.editable) {
          return false;
        }
        // Check if the column is editable
        return colDef.editable instanceof Function ? colDef.editable(params) : colDef.editable;
      },
      onKeyDownDelete: (event: CellKeyDownEvent): void => {
        if (options.fieldConfig.field.computed) return;

        const newValue = '';
        event.node.setDataValue(event.column, newValue, 'keyDownDelete');
      },
      valueSetter: (params: ValueSetterParams): boolean => {
        if (!params.node.group) {
          /**
           * ColDef Path contains the trace from the entity root to the value to set
           * Ex: path ['name'] => { name: 'newValue' }
           * Ex: path ['properties', 'assetType'] =>
           *     {
           *        properties: {
           *           assetType: 'newValue'
           *        }
           *     }
           */
          const {fieldConfig, propertiesPath} = params.colDef as ExtendedColDef;
          const root = propertiesPath ? [propertiesPath] : [];
          const path = fieldConfig.fieldPath;
          setValue(params.data, root.concat(path), params.newValue);
          return true;
        } else {
          return false;
        }
      },
      validate: (params: CellValueChangedEvent, onSuccess: () => void, onFailure: () => void): void => {
        const {fieldConfig} = params.colDef as ExtendedColDef;
        const control = new UntypedFormControl(
          {value: null},
          Validators.compose(
            fieldConfig.field.validators?.map((validator: FieldValidator) => this.validationService.getValidator(
              validator,
              params.node.data
            ))
          ),
          Validators.composeAsync(
            fieldConfig.field.validators?.map((validator: FieldValidator) => this.validationService.getAsyncValidator(
              validator,
              params.node.data,
              fieldConfig.field.code
            ))
          )
        );

        // Wait for async validation
        control.statusChanges.pipe(
          distinctUntilChanged(),
          skipWhile((status) => status === 'PENDING'),
          map((value) => {
            return value === 'VALID';
          }),
          first()
        ).subscribe(valid => {
          // Trigger the proper callback based on the control validity
          if (valid) {
            onSuccess ? onSuccess() : void 0;
          } else {
            onFailure ? onFailure() : void 0;
          }
        });

        control.setValue(params.newValue);
      }
    };
  }

  private numberComparator(valueA, valueB): number {
    if (valueA === this.appConfig.GRID_EMPTY_VALUE || valueA === void 0) {
      valueA = Number.MIN_SAFE_INTEGER;
    }
    if (valueB === this.appConfig.GRID_EMPTY_VALUE || valueB === void 0) {
      valueB = Number.MIN_SAFE_INTEGER;
    }

    valueA = Number(valueA);
    valueB = Number(valueB);

    if (valueA && !valueB) {
      return 1;
    } else if (valueB && !valueA) {
      return -1;
    } else if (valueA && valueB) {
      return valueA.compareTo(valueB);
    }
  }

  /**
   * If cell has a pictogram, use the pictogram cell renderer to display it.
   * @param withText If not specified in fieldConfig, whether the pictogram should have a text of explanation.
   * @param fieldConfig Field config of the current row.
   * @param isGroup True if the column is a group, false otherwise
   */
  private cellRendererSelector(withText: boolean,
                               fieldConfig: FieldConfig,
                               isGroup: boolean = false): CellRendererSelectorResult {
    return fieldConfig?.hasPictograms
      ? {
        component: PictogramCellRendererComponent,
        params: {
          values: fieldConfig.pictograms,
          withText: fieldConfig.isPictogramWithText || withText,
          group: isGroup
        }
      }
      : void 0;
  }
}
