import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { getPowerBIReportInputs } from '@app/core/model/other/powerBI';
import { AccessManager } from '@services/managers/access.manager';

@Component({
  templateUrl: './global-bi-reports-header.component.html'
})
export class GlobalBiReportsHeaderComponent implements OnInit {

  public globalBIReportLabel: string = 'LABEL.DEFAULT_GLOBAL_BI_REPORT';

  constructor(private route: ActivatedRoute,
              private accessManager: AccessManager) {

  }

  /**
   * Set global Power BI report's label according to its name (variable passed in url parameter).
   */
  public ngOnInit(): void {
    this.route.firstChild.params.subscribe((params: Params) => {
      if (params.hasOwnProperty('name')) {
        const globalBIReport = this.accessManager.currentUser.globalBIReports?.find(globalReport =>
          globalReport.name === params.name
        );
        this.globalBIReportLabel = getPowerBIReportInputs().find(input => input.reportFormControlName === globalBIReport.name)?.label;
      }
    });
  }
}
