import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserRoleEnum } from '@app/core/enums/user-role-enum';
import { Dashboard } from '@app/core/model/entities/dashboard/dashboard';
import { DashboardService } from '@app/shared/components/dashboard/dashboard.service';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dashboard-tool-panel',
  templateUrl: './dashboard-tool-panel.component.html',
  styleUrls: ['./dashboard-tool-panel.component.scss']
})
export class DashboardToolPanelComponent implements OnInit, OnDestroy {

  @Input() public preferenceIdKey: string;

  public dashboards: Dashboard[];
  public dashboardForm: FormGroup<{
    currentDashboard: FormControl<Dashboard>
  }>;

  private destroy$ = new Subject<void>();

  constructor(public dashboardService: DashboardService,
              private snackbarManager: SnackbarManager,
              private translate: TranslateService,
              private userPreferencesService: UserPreferencesService,
              public accessManager: AccessManager,
              private fb: UntypedFormBuilder) {
    this.dashboardForm = this.fb.group({
      currentDashboard: this.fb.control('', Validators.required)
    });
  }

  /**
   * Initialize form and listen for actions on dashboard entities
   */
  public ngOnInit(): void {

    // Load all dashboards and set the current dashboard
    this.dashboardService.sidePanelToggleSubject
      .pipe(
        takeUntil(this.destroy$),
        filter(dashboards => !!dashboards)
      ).subscribe(dashboards => {
      this.dashboards = dashboards;
      this.dashboardForm.get('currentDashboard').setValue(this.dashboardService.currentDashboard);
    });

    // Listen for value change in the form to update current dashboard
    this.dashboardService.sidePanelToggleSubject
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.dashboardForm.get('currentDashboard').valueChanges)
      )
      .subscribe(dashboard => {
        if (dashboard.id != this.dashboardService.currentDashboard.id) {
          this.userPreferencesService.currentUserPreferences.preferences[this.preferenceIdKey] = dashboard.id;
          this.userPreferencesService.updateUserPreferences();
          this.dashboardService.changeDashboard(dashboard);
        }
      });

    // Apply a new dashboard as form's value
    this.dashboardService.sidePanelToggleSubject
      .pipe(
        takeUntil(this.destroy$),
        filter(dashboards => !!dashboards),
        switchMap(() => this.dashboardService.dashboardApplied$)
      )
      .subscribe(newDashboard => {
        if (!this.dashboards.find(dashboard => dashboard.id == newDashboard.id)) this.dashboards.push(newDashboard);
        this.dashboardForm.get('currentDashboard').setValue(newDashboard);
      });

    // Delete previous dashboard from dashboards list and apply default
    this.dashboardService.sidePanelToggleSubject
      .pipe(
        takeUntil(this.destroy$),
        filter(dashboards => !!dashboards),
        switchMap(() => this.dashboardService.dashboardDeleted$)
      )
      .subscribe(previousDashboard => {
        const defaultDashboard = this.dashboards.find(dashboard => dashboard.isDefault);
        this.dashboards = this.dashboards.filter(dashboard => dashboard.id != previousDashboard.id);
        this.dashboardForm.get('currentDashboard').setValue(defaultDashboard);
      });
  }

  /**
   * Stop Observable subscriptions. Prevent memory leak.
   */
  public ngOnDestroy(): void {
    // Prevent memory leak
    this.closeDashboardToolPanel();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open the dialog to create a new dashboard by copying the current one
   */
  public createDashboard(): void {
    this.dashboardService.openCreateDashboardDialog();
  }

  /**
   * Open the dialog to delete the current dashboard
   */
  public deleteDashboard(): void {
    const currentDashboard = this.dashboardForm.get('currentDashboard').value;
    this.dashboardService.openDeleteDashboardDialog(currentDashboard).subscribe(() => {
      this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DASHBOARD_DELETED'));
    });
  }

  /**
   * Listen for charts' updates
   */
  public updateDashboard(): void {
    this.dashboardService.updateDashboard().pipe(takeUntil(this.destroy$)).subscribe(dashboard => {
      const index = this.dashboards.findIndex(db => db.id == dashboard.id);
      this.dashboards[index].charts = this.dashboardService.currentDashboard.charts;
    });
  }

  /**
   * Bypass Material escape handler to close dashboard tool panel.
   * @param event
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeDashboardToolPanel();
    }
  }

  /**
   * Close dashboard sidebar
   */
  public closeDashboardToolPanel(): void {
    this.dashboardService.sidePanelToggleSubject.next(null);
  }

  /**
   * Check if the user has permission to edit dashboard.
   * Only super admin, admin tb and admin user role can edit dashboard.
   *
   * @return true if the user has permission, false otherwise
   */
  public hasPermissionForEdition(): boolean {
    return this.accessManager.isSuperAdmin()
      || this.accessManager.isUserRole(UserRoleEnum.ADMIN_TB)
      || this.accessManager.isUserRole(UserRoleEnum.ADMIN);
  }
}
