<div class="full-size fx-layout-col">
  <!-- Header -->
  <ng-template headerTemplate></ng-template>

  <!-- Breadcrumb -->
  @if (breadCrumbs && breadCrumbs.length > 1 && !data.hideBreadcrumb) {
    <div
      class="breadcrumb fx-layout-row fx-justify-start fx-align-center fx-hide-xs">
      @for (crumb of breadCrumbs; track crumb; let ind = $index; let isLast = $last) {
        <span [ngClass]="{'active' : isLast}"
          class="breadcrumb-item fx-layout-row fx-justify-start fx-align-center">
          @if (ind === breadCrumbs.length - 1) {
            <span>{{ crumb.name | translate : crumb.optionalParams }}</span>
          } @else {
            <span (click)="onNavigate(crumb)" [routerLink]="'/' + crumb.urls.join('/')">
              {{ crumb.name | translate : crumb.optionalParams }}
            </span>
            <span class="mdi mdi-chevron-right breadcrumb-icon"></span>
          }
        </span>
      }
    </div>
  }
</div>
