import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Group } from '@app/core/model/client/group';
import { User } from '@app/core/model/client/user';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'group-cell-renderer',
  templateUrl: './group-cell-renderer.component.html'
})
export class GroupsCellRenderer implements ICellRendererAngularComp {
  public groups: Group[];
  public form = new FormControl<Group>(null, Validators.required);

  /**
   * gets called once before the renderer is used
   */
  public agInit(params: ICellRendererParams & { groups: Group[] }): void {
    this.groups = params.groups;
    this.form.setValue(params.value?.[0]);
    this.form.valueChanges.subscribe(group => {
      const user: User = params.node.data;
      user.groups = [group];
      params.api.applyTransaction({update: [user]});
    });
  }

  /**
   * gets called whenever the user gets the cell to refresh
   */
  public refresh(_: ICellRendererParams): boolean {
    return true;
  }

  /**
   * Utility function to compare two groups
   * @param g1 a first Group
   * @param g2 a second Group
   * @returns true if the group ids are equal, false otherwise
   */
  public compareGroups(g1: Group, g2: Group): boolean {
    return g1?.id === g2?.id;
  }
}
