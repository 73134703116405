<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
           [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
        <ng-container *ngIf="fieldConfig.hasPictograms && getFieldValue(); else noPictogram">
          <div>
            <img class='pictogram'
                 [src]="appConfig.PICTOGRAM_FOLDER_PATH + fieldConfig.pictogram(getFieldValue())"
                 [alt]="getFieldValue()">
            <span *ngIf="fieldConfig.isPictogramWithText">{{ getFieldValue() }}</span>
          </div>
        </ng-container>
        <ng-template #noPictogram>
          <ng-container *ngIf="fieldConfig.customOptions?.['translate']; else noTranslation">
            <span>{{ ('VALUE.' + fieldConfig.fieldCode + '_' + getFieldValue()) | uppercase | translate | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}</span>
          </ng-container>
          <ng-template #noTranslation>
            <span>{{ getFieldValue() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}</span>
          </ng-template>
          <span *ngIf="fieldConfig.suffixType">
            {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
          </span>
        </ng-template>
        <span *ngIf="fieldConfig.computed"
              [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate" #tooltip="matTooltip"
              (click)="onClickTooltip(tooltip)">
          <i class="mdi mdi-calculator tooltip-icon"></i>
        </span>
        <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
              class="overlay-icon">
          <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
      <form autocomplete="off" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ fieldConfig.label }}</mat-label>
          <mat-select formControlName="field" (keyup.escape)="onEscapeCancel($event)">
            <mat-option *ngIf="optional" [value]="''">
              {{ 'LABEL.UNDEFINED' | translate }}
            </mat-option>
            <mat-option *ngFor="let fieldValue of options" [value]="fieldValue">
              <ng-container *ngIf="fieldConfig.customOptions?.['translate']; else noTranslation">
                {{ ('VALUE.' + fieldConfig.fieldCode + '_' + fieldValue) | uppercase | translate }}
              </ng-container>
              <ng-template #noTranslation>
                {{ fieldValue }}
              </ng-template>
            </mat-option>
          </mat-select>
          <span *ngIf="fieldConfig.suffixType" matTextSuffix>
            {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
          </span>
          <mat-error *ngFor="let validator of erroredValidators">
            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
          </mat-error>
        </mat-form-field>
      </form>
      <div *ngIf="isSingleField" class="save-options">
        <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
          <i class="mdi mdi-close"></i>
        </button>
        <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                class="button save">
          <i class="mdi mdi-check"></i>
        </button>
      </div>
    </div>
  </div>
</div>
