<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <input [formControl]="formControl" matInput type="datetime-local" matIconSuffix/>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>

  <div>
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>