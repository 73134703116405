import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Check } from '@app/core/model/entities/asset/check';
import { Document } from '@app/core/model/entities/document/document';
import {
  CheckCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/checks/modals/check-create-modal/check-create-modal.component';
import { ChecksService } from '@app/features/main/views/organization-checks/checks.service';
import {
  OrganizationCheckCreateModalComponent
} from '@app/features/main/views/organization-checks/modals/organization-check-create-modal/organization-check-create-modal.component';
import {
  OrganizationCheckRenewModalComponent
} from '@app/features/main/views/organization-checks/modals/organization-check-renew-modal/organization-check-renew-modal.component';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CheckModalService {

  constructor(private popupManager: PopupManager,
              private checksService: ChecksService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Open a dialog for the User to fill information to create a new Check.
   * @param asset If specified, Asset to link the new Check to.
   * @return Created Check.
   */
  public openAddCheckDialog(asset?: Asset): Observable<Check> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? CheckCreateModalComponent : OrganizationCheckCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.CHECK);

          if (dialogResponse === 'yes') {
            const {assetId, type, spaceIds} = dialogRef.componentInstance.getGeneratedObject();
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.checksService.createCheck(assetId, type, spaceIds)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open a dialog for the User to fill information to renew an existing Check.
   * @param checkToRenew Existing Check to be renewed.
   * @return Renewed Check.
   */
  public openRenewCheckDialog(checkToRenew: Check): Observable<Check> {
    const dialogRef = this.popupManager.showGenericPopup(
      OrganizationCheckRenewModalComponent,
      PopupSize.MEDIUM,
      {checkToRenew}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.RENEW, EntityTypeEnum.CHECK);

          if (dialogResponse === 'yes') {
            const {spaceIds} = dialogRef.componentInstance.getGeneratedObject() as { spaceIds: string[] };
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: checkToRenew.id,
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.checksService.renewCheck(checkToRenew, spaceIds)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open dialog then delete Checks once confirmed by the User.
   * @param checks List of Checks to delete.
   * @return True if the operation was successful or false if it has been canceled by the User.
   * @throws Any encountered error.
   */
  public openDeleteChecksDialog(checks: Check[]): Observable<boolean> {
    return this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(checks.length === 1 ? 'TITLE.DELETE_CHECK' : 'TITLE.DELETE_CHECKS'),
      dialogMessage: this.translate.instant(checks.length === 1 ? 'MESSAGE.DELETE_CHECK' : 'MESSAGE.DELETE_CHECKS'),
      okText: this.translate.instant('BUTTON.DELETE'),
      type: 'warning'
    })
      .afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.CHECK);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
              [AnalyticsKeyEnum.ENTITY_ID]: checks.map((check: Check) => check.id).toString()
            });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.checksService.deleteChecks(checks)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of(false);
          }
        })
      );
  }

  /**
   * Open a dialog to upload files and create Documents linked to the given Check.
   * @param checkId ID of the Check which created Documents should be linked to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadCheckDocumentsDialog(checkId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      checkId,
      EntityTypeEnum.CHECK,
      DocumentTypeEnum.CHECK_DOCUMENT
    );
  }

  /**
   * Open a confirmation dialog to delete a Check's Document.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted, false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteCheckDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
