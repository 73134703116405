import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CheckStateEnum } from '@app/core/enums/check/check-state.enum';
import dayjs from 'dayjs';


/**
 * Create a validator function which validates that the value entered in the form control field for the current Check's
 * state is either CHECK_ABSENT or CHECK_CLOSED which is always a valid state.
 * @param getRealisationDate Function that returns the realisation date associated with the current Check.
 * @param getPeriodicity  Function that returns the periodicity associated with the current Check as a number of months.
 * @return A validator function for the state form control.
 */
export function realisationDateIsAfterToday(getRealisationDate: () => string,
                                            getPeriodicity: () => number): ValidatorFn {
  return (control: AbstractControl<CheckStateEnum>) => {
    // Closed and validation in progress are always a valid state
    if (control.value in [CheckStateEnum.CHECK_VALIDATION_IN_PROGRESS, CheckStateEnum.CHECK_CLOSED]) return null;

    const realisationDate = getRealisationDate();
    const now = dayjs();
    const periodicity = getPeriodicity();

    if (periodicity && realisationDate && dayjs(realisationDate).isAfter(now) && control.value !== CheckStateEnum.CHECK_ABSENT) {
      return {realisationDateIsAfterToday: true};
    }

    return null;
  };
}

/**
 * Create a validator function which validates that the value entered in the form control field for the current Check's
 * state is not CHECK_ABSENT when the Check's realisation date is passed.
 * @param getRealisationDate Function that returns the realisation date associated with the current Check.
 * @param getPeriodicity Function that returns the periodicity associated with the current Check as a number of months.
 * @return A validator function for the state form control.
 */
export function realisationDateIsBeforeToday(getRealisationDate: () => string,
                                             getPeriodicity: () => number): ValidatorFn {
  return (control: AbstractControl<CheckStateEnum>) => {
    const realisationDate = getRealisationDate();
    const now = dayjs();
    const periodicity = getPeriodicity();

    if (periodicity && realisationDate && dayjs(realisationDate).isBefore(now) && [CheckStateEnum.CHECK_ABSENT, CheckStateEnum.CHECK_UNDEFINED].includes(
      control.value)) {
      return {realisationDateIsBeforeToday: true};
    }

    return null;
  };
}

/**
 * Create a validator function which validates that the value entered in the form control field for the current Check's
 * state is not CHECK_EXPIRED when the Check's realisation date is passed and the Check is still valid.
 * @param getRealisationDate Function that returns the realisation date associated with the current Check.
 * @param getPeriodicity Function that returns the periodicity associated with the current Check as a number of months.
 * @return A validator function for the state form control.
 */
export function renewDateIsAfterToday(getRealisationDate: () => string, getPeriodicity: () => number): ValidatorFn {
  return (control: AbstractControl<CheckStateEnum>) => {
    const realisationDate = getRealisationDate();
    const periodicity = getPeriodicity();
    const renewDate = periodicity && realisationDate ? dayjs(realisationDate).add(periodicity, 'month') : null;
    const now = dayjs();

    if (renewDate && dayjs(realisationDate).isBefore(now) && renewDate.isAfter(now) && control.value === CheckStateEnum.CHECK_EXPIRED) {
      return {renewDateIsAfterToday: true};
    }

    return null;
  };
}

/**
 * Create a validator function which validates that the value entered in the form control field for the current Check's
 * state is CHECK_ABSENT or CHECK_CLOSED when the Check's renew date is undefined.
 * @param getRealisationDate Function that returns the realisation date associated with the current Check.
 * @param getPeriodicity Function that returns the periodicity associated with the current Check as a number of months.
 * @return A validator function for the state form control.
 */
export function renewDateIsUndefined(
  getRealisationDate: () => string,
  getPeriodicity: () => number
): ValidatorFn {
  return (control: AbstractControl<CheckStateEnum>) => {
    const realisationDate = getRealisationDate();
    const periodicity = getPeriodicity();

    if ((!periodicity || !realisationDate) && control.value === CheckStateEnum.CHECK_EXPIRED) {
      return {renewDateIsUndefined: true};
    }

    return null;
  };
}
