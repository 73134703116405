@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled"
      [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
      class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" [matTooltip]="fieldConfig.tooltip"
            matTooltipPosition="below" (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
          [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
          <span>{{ form.get('budgetTotal').value | fieldFormatType: fieldConfig.customOptions?.formatType : appManager.currentOrganization.currency }}</span>
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span class="overlay-icon">
              @if (preconditionsForEdition) {
                <i class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div class="edit-field-container">
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" [matTooltip]="fieldConfig.tooltip"
            (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="edit-budget-field-container fx-flex-100-66-percent fx-layout-col">
        @for (row of budgetRows.controls; track row; let i = $index) {
          <div
            class="budget-row fx-layout-row fx-justify-start fx-align-baseline"
            [formGroup]="row">
            <mat-form-field appearance="outline" class="fx-flex-none-30-percent">
              <mat-label>{{ 'LABEL.BUDGET_LABEL' | translate }}</mat-label>
              <input matInput type="text" formControlName="label"/>
              @if (row.get('label').hasError('maxlength')) {
                <mat-error>
                  {{ 'ERROR.FIELD_MAX_LENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-flex-none-15-percent">
              <mat-label>{{ 'LABEL.BUDGET_QUANTITY' | translate }}</mat-label>
              <input #quantityInput matInput type="number" formControlName="quantity" required/>
              @if (row.get('quantity').hasError('required')) {
                <mat-error>
                  {{ 'ERROR.FIELD_REQUIRED' | translate }}
                </mat-error>
              }
              @if (row.get('quantity').hasError('min')) {
                <mat-error>
                  {{ 'ERROR.MIN_VALUE' | translate: {value: 1} }}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-flex-none-15-percent">
              <mat-label>{{ 'LABEL.BUDGET_UNIT' | translate }}</mat-label>
              <input matInput
                type="text"
                formControlName="unit"
                [matAutocomplete]="autocompleteUnit"
                (focusin)="valueChanges.next(row.get('unit').value)"/>
              <mat-autocomplete #autocompleteUnit="matAutocomplete">
                @for (unit of filteredUnits | async; track unit) {
                  <mat-option [value]="unit">{{ unit }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fx-flex-none-20-percent">
              <mat-label>{{ 'LABEL.BUDGET_UNITPRICE' | translate }}</mat-label>
              <input #unitPriceInput matInput type="number" formControlName="unitPrice" required/>
              @if (row.get('unitPrice').hasError('required')) {
                <mat-error>
                  {{ 'ERROR.FIELD_REQUIRED' | translate }}
                </mat-error>
              }
              @if (row.get('unitPrice').hasError('min')) {
                <mat-error>
                  {{ 'ERROR.MIN_VALUE' | translate: {value: 0} }}
                </mat-error>
              }
            </mat-form-field>
            <span [id]="'total_row_' + i" class="align-right fx-flex-none-15-percent">
              <span>{{ quantityInput.value * unitPriceInput.value | fieldFormatType: 'precise_currency' : appManager.currentOrganization.currency }}</span>
            </span>
            <restricted-icon-button (onClick)="removeBudgetRow(i)"
              [id]="'remove-budget-row-' + i + '-button'"
              class="fx-flex-initial"
              [class]="'mdi mdi-minus-circle xs'"
              [matTooltip]="'BUTTON.REMOVE_ROW' | translate">
              {{ 'BUTTON.REMOVE_ROW' | translate }}
            </restricted-icon-button>
          </div>
        }
        <div class="budget-total fx-layout-row fx-justify-start fx-align-center">
          <restricted-icon-button [id]="'add-budget-row-button'"
            (onClick)="addBudgetRow()"
            class="fx-flex-initial"
            [class]="'mdi mdi-plus-circle sm'"
            [matTooltip]="'BUTTON.ADD_ROW' | translate">
            {{ 'BUTTON.ADD_ROW' | translate }}
          </restricted-icon-button>
          <span class="align-right fx-flex-no-shrink-55-percent">{{ 'LABEL.TOTAL' | translate }}</span>
          <span id="budget-total-value" class="align-right fx-flex-none-25-percent">
            {{ form.get('budgetTotal').value | fieldFormatType: 'precise_currency' : appManager.currentOrganization.currency }}
          </span>
        </div>
        <div class="button-container fx-layout-row fx-justify-stretch fx-align-center">
          @for (validator of erroredValidators; track validator) {
            <mat-error>
              {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
            </mat-error>
          }
          <span class="fx-flex"></span>
          <button id="cancel-button" (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" mat-stroked-button>
            {{ 'BUTTON.CANCEL' | translate }}
          </button>
          <button id="save-button"
            (click)="onClickSave()"
            (keyup.escape)="onEscapeCancel($event)"
            [disabled]="!form.valid || !budgetRows.valid"
            color="accent"
            mat-raised-button>
            {{ 'BUTTON.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
}
