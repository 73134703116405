import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from '@app/core/model/entities/equipments/equipment';
import {
  EquipmentCreateModalComponent
} from '@app/features/main/views/equipments/equipments-inventory/modals/equipment-create-modal/equipment-create-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { EntityTypeEnum } from "@app/core/enums/entity-type.enum";

@Component({
  templateUrl: './equipment-duplicate-modal.component.html'
})
export class EquipmentDuplicateModalComponent extends EquipmentCreateModalComponent {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  protected modalTitle: string;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { equipments: Equipment[] }) {
    super();
    this.modalTitle = data.equipments.length > 1 ? 'TITLE.DUPLICATE_EQUIPMENTS' : 'TITLE.DUPLICATE_EQUIPMENT';
    this.equipmentForm.get('name').setValue(data.equipments[0].name);
  }
}
