<div class="fx-layout-col fx-justify-center fx-align-center fx-gap-20">
  <img [lazyLoad]="srcImg" width="450px" height="300px" alt="image" [defaultImage]="placeHolder"/>
  <button type="button" mat-stroked-button (click)="imageInput.click()">
    {{ 'BUTTON.CHOOSE_PICTURE' | translate }}
  </button>
  <input hidden (change)="previewImage($event)" accept="image/*" #imageInput type="file">
  @if (!fileIsValid) {
    <span class="mat-mdc-form-field-error">
      {{ 'ERROR.UPLOAD_FILE_SIZE' | translate: {'max': this.maxSize / 1024} }}
    </span>
  }
</div>