import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { AppConfig } from '@app/core/app.config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { EMPTY, from, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

export interface CustomTextSuffixCellEditorParams {
  textValue: any;
  textControl: UntypedFormControl;
  textValidators: FieldValidator[];
  suffixValue: any;
  suffixControl: UntypedFormControl;
  suffixValidators: FieldValidator[];
  suffixValues: string[];
  valueInBetween: string;
}

@Component({
  selector: 'text-suffix-cell-edit',
  templateUrl: './text-suffix-cell-edit.component.html',
  styleUrls: ['./text-suffix-cell-edit.component.scss']
})
export class TextSuffixCellEditComponent extends AbstractCellEditor implements ICellEditorAngularComp, OnDestroy {

  public valueInBetween: string;
  public suffixValues: string[];
  public declare params: Partial<ICellEditorParams & CustomCellEditorParams & CustomTextSuffixCellEditorParams>;

  public textErrorTooltip: string;
  public suffixErrorTooltip: string;

  @ViewChild(MatInput) public textInput: MatInput;
  @ViewChild(MatSelect) public suffixInput: MatSelect;

  constructor(public fb: FormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams & CustomTextSuffixCellEditorParams>): void {
    this.valueInBetween = params.valueInBetween;
    this.suffixValues = params.suffixValues;

    super.agInit(params);

    // Set the validators of the controls.
    params.textControl.setValidators(this.computeValidators(params.textValidators));
    params.textControl.setAsyncValidators(this.computeAsyncValidators(params.textValidators));
    params.suffixControl.setValidators(this.computeValidators(params.suffixValidators));
    params.suffixControl.setAsyncValidators(this.computeAsyncValidators(params.suffixValidators));

    // Set value to the controls.
    // The controls will check if it's valid or not.
    params.textControl.setValue(params.textValue);
    params.suffixControl.setValue(params.suffixValue);

    // If the form's group control is invalid, show it on the GUI.
    if (this.control.invalid) {
      this.params.eGridCell.classList.add('error-cell');
      this.textErrorTooltip = this.getControlError(params.textControl, params.textValidators);
      this.suffixErrorTooltip = this.getControlError(params.suffixControl, params.suffixValidators);
    }

    // Update the tooltips when on the control is invalid.
    this.getControlErrorObservable(params.textControl, params.textValidators)
      .subscribe(errorMessage => {
        this.textErrorTooltip = errorMessage;
      });

    this.getControlErrorObservable(params.suffixControl, params.suffixValidators)
      .subscribe(errorMessage => {
        this.suffixErrorTooltip = errorMessage;
      });

    // When the control statues changes, show it on the GUI.
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        const classList = this.params.eGridCell.classList;
        if (status === 'INVALID') {
          classList.add('error-cell');
        } else {
          classList.remove('error-cell');
        }
      });
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    if (!this.fullRowEdit) {
      this.textInput.focus();
    }
  }

  /**
   * Gets called when focus should be put into the editor.
   */
  public focusIn(): void {
    this.textInput.focus();
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): any {
    return this.control.value;
  }

  /**
   *  Gets called once after editing is complete. If you return true, then the new
   *  value will not be used. The editing will have no impact on the record.
   */
  public isCancelAfterEnd(): boolean {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    return !this.control.valid || this.control.pristine;
  }

  /**
   * Remove the error class on the cell GUI and stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get the message error of an invalid control.
   * @param control to check.
   * @param validators of the control.
   * @return error message if the control is invalid.
   * @private
   */
  private getControlError(control: FormControl, validators: FieldValidator[]): string {
    const validator = validators?.firstOrNull((validator) => {
      return control.hasError(validator.type.toLowerCase());
    });
    return !!validator ? this.translateService.instant(
      `ERROR.FIELD_${validator.type}`,
      {value: validator?.definition}
    ) : undefined;
  }

  /**
   * Observable when the control statues changes.
   * @param control to observe.
   * @param validators of the control.
   * @return error message if the control is invalid.
   * @private
   */
  private getControlErrorObservable(control: FormControl, validators: FieldValidator[]): Observable<string> {
    return control.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          if (validators?.length) {
            return from(validators)
              .pipe(filter(validator => control.hasError(validator.type.toLowerCase())));
          } else {
            return EMPTY;
          }
        }),
        map((validator) => {
          return this.translateService.instant(
            `ERROR.FIELD_${validator.type}`,
            {value: validator.definition}
          );
        })
      );
  }

  /**
   * Event thrown when the text input is focused
   * and the user is pressing the tab key.
   * @param event Keyboard event.
   */
  public onTextInputTab(event: KeyboardEvent): void {
    event.preventDefault();
    // Focus to the suffix input and open the list.
    this.suffixInput.focus();
    this.suffixInput.open();
  }

}
