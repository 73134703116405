import { Directive, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { year } from './validator';

const YEAR_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => YearValidator),
  multi: true
};

@Directive({
  selector: '[year][formControlName],[year][formControl],[year][ngModel]',
  providers: [YEAR_VALIDATOR]
})
export class YearValidator implements Validator, OnInit, OnChanges {
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = year;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'YEAR') {
        this.validator = year;
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
