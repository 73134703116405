<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_PROJECT_TO_WORKS' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="workForm">
      <div class="project-form-field">

        <ng-container *ngIf="assetIds.length > 0; else noAssetMessage">
          <ng-container *ngIf="projects.length > 0; else noProjectMessage">

            <mat-form-field appearance="outline">
              <input formControlName="project"
                     [matAutocomplete]="projectAutocomplete"
                     [id]="'input-works-project'"
                     matInput
                     required>

              <mat-autocomplete #projectAutocomplete="matAutocomplete">
                <mat-option *ngFor="let project of projects" [value]="project">
                  {{ project }}
                </mat-option>
              </mat-autocomplete>

              <mat-error *ngIf="workForm.controls.project.hasError('required')"
                         class="small-font text-danger support-text">
                {{ 'ERROR.FIELD_REQUIRED' | translate }}
              </mat-error>
              <mat-error *ngIf="workForm.controls.project.hasError('notInArray')"
                         class="small-font text-danger support-text">
                {{ 'ERROR.ENTITY_NOT_FOUND' | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-template #noProjectMessage>
            <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
              <p id="no-related_project-message">
                {{ 'LABEL.NO_RELATED_PROJECTS' | translate }}
              </p>
            </div>
          </ng-template>

        </ng-container>

        <ng-template #noAssetMessage>
          <div class="field-container fx-flex-100-66-percent-min-500">
            <p id="no-asset-selected-message">
              {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
            </p>
          </div>
        </ng-template>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [id]="'update-work-cancel-button'" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [id]="'update-work-add-button'" [disabled]="workForm.invalid" color="accent"
            matDialogClose="yes">
      {{ 'BUTTON.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
