import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import {
  CreateFieldInput,
  Field,
  FieldLocationInformation,
  FormatType,
  SuffixType,
  UpdateFieldInput
} from '@app/core/model/other/field-config';
import validatorFieldTypeMapping
  from '@app/features/main/views/management/organization/field-configuration/validator-field-type-mapping';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { map, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  private createFieldSubject = new Subject<Field>();
  private updateFieldSubject = new Subject<Field>();
  private deleteFieldsSubject = new Subject<Field[]>();

  private readonly fieldGraphqlFragment: DocumentNode;

  constructor(private appManager: AppManager,
              private generalService: GeneralService) {

    this.fieldGraphqlFragment = gql`
      fragment FieldInfo on Field {
        id
        code
        label
        tooltip
        entityType
        checkType
        fieldType
        formula
        parentPathList
        fieldValuesList
        validatorsList {
          definition
          type
        }
      }
    `;
  }

  /**
   * Emits any new Field after it was created.
   */
  public get fieldCreated$(): Observable<Field> {
    return this.createFieldSubject.asObservable();
  }

  /**
   * Emits a Field whenever it has been updated.
   */
  public get fieldUpdated$(): Observable<Field> {
    return this.updateFieldSubject.asObservable();
  }

  /**
   * Emits a list of Fields after they have been deleted.
   */
  public get fieldsDeleted$(): Observable<Field[]> {
    return this.deleteFieldsSubject.asObservable();
  }

  /**
   * Query to get a field from an organization
   * @return Observable containing a single field
   */
  public getField(fieldCode: string, entityType: EntityTypeEnum): Observable<Field> {
    const COMBINED_QUERY = gql`
      query Field($code: String!, $entityType: String!, $organizationId: String!) {
        field(code: $code, entityType: $entityType, organizationId: $organizationId) {
          ...FieldInfo
        }
      }${this.fieldGraphqlFragment}
    `;

    const QUERY_VAR = {
      code: fieldCode,
      entityType: entityType,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => {
          return plainToInstance(Field, response.data['field'] as Field);
        })
      );
  }

  /**
   * Query to get every field from an organization
   * @param computedFieldFilter Optional filter on whether the fields are computed or not. Defaults to all fields.
   * @param entityType Optional filter on the entity type of the fields to return
   * @return Observable containing array of fields
   */
  public getFields(computedFieldFilter: 'ONLY_COMPUTED' | 'NOT_COMPUTED' | 'ALL' = 'ALL', entityType?: EntityTypeEnum): Observable<Field[]> {
    const COMBINED_QUERY = gql`
      query FieldsByOrganizationId($organizationId: String!, $entityType: String, $computed: FieldComputation) {
        fields(organizationId: $organizationId, entityType: $entityType, computed: $computed) {
          ...FieldInfo
        }
      }${this.fieldGraphqlFragment}
    `;

    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      computed: computedFieldFilter,
      ...(entityType && {entityType: entityType})
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => {
          return plainToInstance(Field, response.data['fields'] as Field[]);
        })
      );
  }

  /**
   * Query to get every available field to an organization that is not already configured for it.
   * These fields might only exist in a classification or for a currency that does not correspond to the organization's
   * @return Observable containing array of fields
   */
  public getMyaFields(entityType: EntityTypeEnum): Observable<FieldLocationInformation[]> {
    const COMBINED_QUERY = gql`
      query FetchMyaDefaultFields($entityType: String!, $organizationId: String!) {
        defaultFieldsByEntityType(entityType: $entityType, organizationId: $organizationId) {
          code
          label
          classificationType
          currency
          isTb
        }
      }
    `;

    const QUERY_VAR = {
      entityType,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => {
          return response.data['defaultFieldsByEntityType'] as FieldLocationInformation[];
        })
      );
  }

  /**
   * Query to add an available field to an organization that is not already configured for it
   * These fields might only exist in a classification or for a currency that does not correspond to the organization's
   * @return Observable containing array of fields
   */
  public addMyaField(fieldToAdd: FieldLocationInformation): Observable<Field> {
    const COMBINED_QUERY = gql`
      mutation AddMyaDefaultField($fieldInput: FieldLocationInformationInput!, $organizationId: String!) {
        addMyaField(fieldInput: $fieldInput, organizationId: $organizationId) {
          ...FieldInfo
        }
      }${this.fieldGraphqlFragment}
    `;

    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      fieldInput: fieldToAdd
    };

    return this.generalService.set(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => plainToInstance(Field, response.data['addMyaField'] as Field)),
        tap(field => this.createFieldSubject.next(field))
      );
  }


  /**
   * Creates a field given an input containing information about the field itself
   * such as the module it belongs to, its type
   * and other fieldconfig information such as the label and the tooltip
   */
  public createField(field: CreateFieldInput): Observable<Field> {
    const COMBINED_MUTATION = gql`
      mutation CreateField($createFieldInput: CreateFieldInput!, $organizationId: String!) {
        createField(organizationId: $organizationId, createFieldInput: $createFieldInput){
          ...FieldInfo
        }
      }${this.fieldGraphqlFragment}
    `;
    const MUTATION_VAR = {
      createFieldInput: field,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Field, response.data['createField'] as Field)),
        tap(field => this.createFieldSubject.next(field))
      );
  }

  /**
   * Updates a field given an input of a few field properties
   * @param fieldId Id of the field to update
   * @param updateFieldInput Input containing the new label and the optional empty label and tooltip
   * @return Observable containing the newly updated field
   */
  public updateField(fieldId: string, updateFieldInput: UpdateFieldInput): Observable<Field> {
    const COMBINED_MUTATION = gql`
      mutation UpdateField($fieldId: String!, $updateFieldInput: UpdateFieldInput!, $organizationId: String!) {
        updateField(fieldId: $fieldId, updateFieldInput: $updateFieldInput, organizationId: $organizationId){
          ...FieldInfo
        }
      }${this.fieldGraphqlFragment}
    `;
    const MUTATION_VAR = {
      fieldId,
      updateFieldInput,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(Field, response.data['updateField'] as Field)),
        tap(field => this.updateFieldSubject.next(field))
      );
  }

  /**
   * Deletes a list of fields
   * @param fields Fields to delete
   * @return Observable containing response if the operation was successful
   */
  public deleteFields(fields: Field[]): Observable<boolean> {
    const COMBINED_MUTATION = gql`
      mutation DeleteFields($fieldIds: [String!]!) {
        deleteFields(fieldIds: $fieldIds)
      }
    `;

    const MUTATION_VAR = {
      fieldIds: fields.map(field => field.id),
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteFields'] as boolean),
        tap(success => success && this.deleteFieldsSubject.next(fields))
      );
  }

  /**
   * Fetches the list of available validators for a given field type
   * @param fieldType Type of the field
   * @return Observable containing list of validators
   */
  public getValidators(fieldType: string): {validator: ValidatorType, hasDefinition: boolean}[] {
    return validatorFieldTypeMapping(FieldTypeEnum[fieldType]);
  }

  /**
   * Fetches the list of available field types that can be created for an organization.
   * Some other types in the FieldTypeEnum are not allowed as they have a specific use.
   * @return the field types
   */
  public getFieldTypes(): FieldTypeEnum[] {
    return [
      FieldTypeEnum.TEXT,
      FieldTypeEnum.TEXTAREA,
      FieldTypeEnum.NUMERIC,
      FieldTypeEnum.EMAIL,
      FieldTypeEnum.YEAR,
      FieldTypeEnum.LIST,
      FieldTypeEnum.NUMERIC_LIST,
      FieldTypeEnum.TEXT_AUTOCOMPLETE,
      FieldTypeEnum.LIST_MULTIVALUE,
      FieldTypeEnum.SUGGESTBOX,
      FieldTypeEnum.CHIPS,
      FieldTypeEnum.CHIPS_AUTOCOMPLETE,
      FieldTypeEnum.RADIOBUTTON,
      FieldTypeEnum.DATE,
      FieldTypeEnum.DATETIME,
      FieldTypeEnum.LINK
    ];
  }

  /**
   * Returns the list of available format types
   * @return list of format types
   */
  public getFormatTypes(): FormatType[] {
    return [
      "integer",
      "percent",
      "precise_number",
      "numeric",
      "duration",
      "scientific"
    ];
  }

  /**
   * Returns the list of available suffix types
   * @return list of suffix types
   */
  public getSuffixTypes(): SuffixType[] {
    return [
      "currency",
      "currency_year",
      "currency_excluding_taxes",
      "currency_squarefoot",
      "currency_squaremeter",
      "currency_squaremeter_year",
      "floor",
      "kgeq",
      "kgeqco2_kwh_year",
      "kgeqco2_squaremeter",
      "kgeqco2_squaremeter_year",
      "kmh",
      "kwh",
      "kwhpe",
      "kwhpe_squaremeter",
      "kwhpe_year",
      "kwh_squaremeter",
      "kwh_squaremeter_year",
      "kwh_year",
      "year",
      "month",
      "hour",
      "percent",
      "squarefoot",
      "squaremeter",
      "unit",
      "mm",
      "m",
      "cm",
      "repkman",
    ];
  }
}
