import { Component } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { User } from '@app/core/model/client/user';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'custom-avatar',
  templateUrl: './avatar-cell-renderer.component.html',
  styleUrls: ['./avatar-cell-renderer.component.scss']
})
export class AvatarCellRendererComponent implements ICellRendererAngularComp {
  public name?: string;
  public src?: string;

  constructor(private appConfig: AppConfig) {}

  /**
   * Initialize cell renderer with cell's parameters which should include the picture and name to be displayed.
   * @param params Cell renderer params.
   */
  public agInit(params: ICellRendererParams<any, User>): void {
    this.name = params.value?.name;
    this.src = params.value?.picture ?? this.appConfig.PLACEHOLDER_PROFILE_PICTURE;
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @returns {boolean}
   */
  public refresh(): boolean {
    return false;
  }
}
