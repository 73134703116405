import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { gql } from '@apollo/client/core';
import { Document } from '@app/core/model/entities/document/document';
import { Equipment, EquipmentInput } from '@app/core/model/entities/equipments/equipment';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToClassFromExist, plainToInstance } from 'class-transformer';
import { from, mergeMap, Observable, Subject, switchMap } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EquipmentsService {
  private sidePanelToggleSubject = new Subject<Equipment | null>();

  private addEquipmentSubject = new Subject<Equipment>();
  private updateEquipmentSubject = new Subject<Equipment>();
  private deleteEquipmentsSubject = new Subject<Equipment[]>();

  private readonly equipmentInfoGraphqlFragment = gql`
    fragment EquipmentInfo on Equipment {
      id
      identifier
      name
      organizationId
      assetId
      spaceIdsList
      properties
      computedProperties
      creationUserId
      creationDate
      lastChangeUserId
      lastChangeDate
      dataDate
    }
  `;

  constructor(private activationEndService: ActivationEndService,
              private appManager: AppManager,
              private assetsService: AssetsService,
              private generalService: GeneralService,
              private router: Router,
              private usersService: UsersService) {
  }

  /**
   * Emits every new Equipment after it was created.
   */
  public get equipmentAdded$(): Observable<Equipment> {
    return this.addEquipmentSubject.asObservable();
  }

  /**
   * Emits an Equipment whenever it has been updated.
   */
  public get equipmentUpdated$(): Observable<Equipment> {
    return this.updateEquipmentSubject.asObservable();
  }

  /**
   * Emits Equipments whenever they have been deleted.
   */
  public get equipmentsDeleted$(): Observable<Equipment[]> {
    return this.deleteEquipmentsSubject.asObservable();
  }

  /**
   * Emits data for the side panel to display or null whenever the side panel is closed.
   */
  public get sidePanelToggle$(): Observable<Equipment | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Make an API request to fetch Equipments linked to the current Organization that the current user have permission
   * to view. If an Asset ID is specified, only Equipments related to this Asset are fetched. Otherwise, all
   * the Organization's Equipments, including those not related to any Assets, are fetched.
   * @param assetId ID of Asset whose Equipments should be returned.
   * @return Observable emitting a list of all Equipments.
   */
  public loadEquipments(assetId?: string): Observable<Equipment[]> {
    const QUERY = gql`
      query Equipments($organizationId: String!, $assetId: String) {
        equipments(organizationId: $organizationId, assetId: $assetId) {
          ...EquipmentInfo
        }
      }
      ${this.equipmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      assetId
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Equipment, response.data['equipments'] as Equipment[])),
        // Fetch Users who created and last updated the Equipment
        switchMap(equipments => from(equipments)),
        mergeMap(equipment => this.usersService.fetchUsersInfo(equipment)),
        toArray()
      );
  }

  /**
   * Fetch Equipment to be displayed, based on the activated route.
   * Query Apollo graphql cache first since the guard already query the server.
   * @param route Activated route.
   * @return Equipment.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Equipment> {
    const QUERY = gql`
      query Equipment($id: String!) {
        equipment(id: $id) {
          ...EquipmentInfo
        }
      }
      ${this.equipmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: route.paramMap.get('id')
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'cache-first')
      .pipe(
        map(response => plainToInstance(Equipment, response.data['equipment'] as Equipment)),
        // Fetch Users who created and last updated the Equipment
        switchMap(equipment => this.usersService.fetchUsersInfo(equipment)),
        tap(() => this.activationEndService.getRefreshHeaderSubject().next())
      );
  }

  /**
   * Fetch an Equipment by its ID.
   * @param equipmentId The ID of the equipment.
   * @return Observable emitting the Equipment.
   */
  public loadEquipment(equipmentId: string): Observable<Equipment> {
    const QUERY = gql`
      query Equipment($id: String!) {
        equipment(id: $id) {
          ...EquipmentInfo
        }
      }
      ${this.equipmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: equipmentId
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'network-only')
      .pipe(
        map(response => plainToInstance(Equipment, response.data['equipment'] as Equipment))
      );
  }

  /**
   * Make an API request to create new Equipment with the provided data.
   * @param assetId ID of the Asset the new Equipment belongs to.
   * @param equipmentInput Equipment data.
   * @return Observable emitting the new Equipment that was created.
   */
  public createEquipment(assetId: string, equipmentInput: EquipmentInput): Observable<Equipment> {
    const MUTATION = gql`
      mutation CreateEquipment($organizationId: String!, $assetId: String!, $createEquipmentInput: CreateEquipmentInput!) {
        createEquipment(organizationId: $organizationId, assetId: $assetId, createEquipmentInput: $createEquipmentInput) {
          ...EquipmentInfo
        }
      }
      ${this.equipmentInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      assetId,
      createEquipmentInput: equipmentInput
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Equipment, response.data['createEquipment'])),
        switchMap(equipment => this.usersService.fetchUsersInfo(equipment)),
        tap(newEquipment => this.addEquipmentSubject.next(newEquipment))
      );
  }

  /**
   * Make an API request to copy the Equipments into another Asset.
   * @param equipments Equipments to duplicate.
   * @param assetId ID of Asset to copy the Equipment into.
   * @param spaceIds IDs of Spaces to link to new Equipment.
   * @return Observable emitting the Equipment that was created.
   */
  public duplicateEquipments(equipments: Equipment[], assetId: string, spaceIds: string[]): Observable<Equipment[]> {
    const MUTATION = gql`
      mutation DuplicateEquipment($equipmentIds: [String!]!, $assetId: String!, $spaceIds: [String!]!) {
        duplicateEquipments(equipmentIds: $equipmentIds, assetId: $assetId, spaceIds: $spaceIds) {
          ...EquipmentInfo
        }
      }${this.equipmentInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      equipmentIds: equipments.map(equipment => equipment.id),
      assetId,
      spaceIds
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Equipment, response.data['duplicateEquipments'] as Equipment[])),
        // Fetch Users who created and last updated the Equipment
        switchMap(equipments => from(equipments)),
        mergeMap(equipment => this.usersService.fetchUsersInfo(equipment)),
        toArray()
      );
  }

  /**
   * Call the API to update an Equipment.
   * @param equipment Equipment to update
   * @param equipmentInput Data to update the Equipment with.
   * @return Observable emitting the updated Equipment once the update is completed
   */
  public updateEquipment(equipment: Equipment, equipmentInput: EquipmentInput): Observable<Equipment> {
    const MUTATION = gql`
      mutation UpdateEquipment($equipmentId: String!, $updateEquipmentInput: UpdateEquipmentInput!) {
        updateEquipment(equipmentId: $equipmentId, updateEquipmentInput: $updateEquipmentInput) {
          ...EquipmentInfo
        }
      }
      ${this.equipmentInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      equipmentId: equipment.id,
      updateEquipmentInput: {
        name: equipment.name,
        spaceIds: equipment.spaceIds,
        assetId: equipment.assetId,
        ...equipmentInput
      }
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Equipment, response.data['updateEquipment'])),
        switchMap(equipment => this.usersService.fetchUsersInfo(equipment)),
        tap(updatedEquipment => this.updateEquipmentSubject.next(updatedEquipment))
      );
  }

  /**
   * Make an API request to set the provided picture Document to be the Equipment's main picture.
   * @param equipment Equipment which to set the main picture of.
   * @param mainPictureDocument Document to be used as main picture.
   * @return Updated Equipment with main picture set.
   */
  public setEquipmentMainPicture(equipment: Equipment, mainPictureDocument: Document): Observable<Equipment> {
    const MUTATION = gql`
      mutation SetEquipmentMainPicture($equipmentId: String!, $mainPictureDocumentId: String!) {
        setEquipmentMainPicture(equipmentId: $equipmentId, mainPictureDocumentId: $mainPictureDocumentId) {
          id
          properties
          lastChangeDate
          lastChangeUserId
        }
      }
    `;
    const MUTATION_VAR = {
      equipmentId: equipment.id,
      mainPictureDocumentId: mainPictureDocument.id
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToClassFromExist(equipment, response.data['setEquipmentMainPicture'])),
        switchMap(equipment => this.usersService.fetchUsersInfo(equipment))
      );
  }

  /**
   * Make an API request to delete Equipments.
   * @param equipments Equipments to delete.
   * @return True if all Equipments have been deleted successfully, false otherwise.
   */
  public deleteEquipments(equipments: Equipment[]): Observable<boolean> {
    const MUTATION = gql`
      mutation DeleteEquipment($equipmentIds: [String!]!) {
        deleteEquipments(equipmentIds: $equipmentIds)
      }
    `;
    const MUTATION_VAR = {equipmentIds: equipments.map(equipment => equipment.id)};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteEquipments'] as boolean),
        tap(success => success && this.deleteEquipmentsSubject.next(equipments))
      );
  }

  /**
   * Open the sidenav with information about the selected Equipment.
   * @param equipment Equipment to be displayed in the side panel.
   */
  public openEquipmentSidePanel(equipment: Equipment): void {
    this.sidePanelToggleSubject.next(equipment);
  }

  /**
   * Close the sidenav.
   */
  public closeEquipmentSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Navigate to an Equipment's sheet.
   * @param equipmentId ID of the Equipment to navigate to.
   * @return Router's promise
   */
  public async navigateToEquipmentSheet(equipmentId: string): Promise<void> {
    await this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'equipments',
      'equipment-sheet',
      equipmentId
    ]);
  }

  /**
   * Navigate to an Asset's sheet's equipments tab.
   * @param assetId ID of the Asset to navigate to.
   * @return Router's promise
   */
  public async navigateToAssetSheet(assetId: string): Promise<void> {
    await this.assetsService.navigateToAssetSheet(assetId, 'equipments');
  }
}
