<div class="fx-layout-col fx-justify-center fx-align-center" id="dialog-container" [cdkTrapFocus]="true">

  <mat-dialog-content class="fx-flex-auto fx-layout-col fx-justify-start fx-align-center">
    <status-message [type]="'warning'"></status-message>
    <h1 class="fx-justify-space-around fx-align-start" mat-dialog-title>{{'TITLE.DELETE_SPACE' | translate}}</h1>

    <form [formGroup]="deleteSpaceForm" novalidate>
      <mat-radio-group formControlName="deleteMode" class="fx-layout-col">
        <mat-radio-button value="deleteSpaceAndChildren">
          {{'LABEL.DELETE_SPACE_AND_CHILDREN' | translate}}
        </mat-radio-button>
        <mat-radio-button value="deleteSpaceOnly">{{'LABEL.DELETE_SPACE_ONLY' | translate}}</mat-radio-button>
      </mat-radio-group>

      <div class="text-danger">
        {{ 'WARNING.SPACE_ENTITIES_DELETION' | translate}}
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button id="'delete-space-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{'BUTTON.CANCEL' | translate}}
    </button>
    <button [disabled]="!deleteSpaceForm.valid"
            color="accent"
            id="delete-space-ok-button"
            mat-raised-button
            matDialogClose="yes">
      {{'BUTTON.DELETE' | translate}}
    </button>
  </mat-dialog-actions>
</div>
