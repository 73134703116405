<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ entity?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeSidePanel()" [disableRipple]="true"
            class="fx-layout-col fx-justify-center fx-align-center"
            id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button
        (click)="downloadDocument()"
        [class]="'mdi mdi-download sm'"
        [matTooltip]="'BUTTON.DOWNLOAD_DOCUMENT' | translate"
        [permissions]="[Permission.EXPORT_DOCUMENT]">
      </restricted-icon-button>

      <restricted-icon-button
        (click)="deleteDocument()"
        [class]="'mdi mdi-delete sm'"
        [matTooltip]="'BUTTON.DELETE' | translate"
        [preconditions]="accessManager.hasAllNeededPermissions(permissionsForDeletion)">
      </restricted-icon-button>

      @if (displayAssetSheetLink) {
        <span class="fx-flex"></span>
      }

      @if (displayAssetSheetLink && entity?.assets?.length === 1) {
        <div class="asset-sheet-link">
          <span (click)="navigateToAssetSheet()"> {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }} </span>
        </div>
      }
    </div>
  </div>
  <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
    <div class="page-sidebar">
      <div class="form-container">
        @if (entity) {
          <form-builder
            [formId]="formId"
            [displayHeader]="false"
            [eventsOrigin]="eventsOrigin"
            [permissionsForEdition]="permissionsForEdition">
          </form-builder>
        }
      </div>
    </div>
  </div>
</div>
