import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Work } from '@app/core/model/entities/works/work';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { WorksService } from '@app/features/main/views/works/works.service';
import { WorkActionReset } from '@app/shared/store/reducers/work-action.actions';
import { WorkStateReset } from '@app/shared/store/reducers/work-state.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { filter, Subject, takeUntil } from 'rxjs';
import { WorkModalService } from '@app/features/main/views/works/works-inventory/modals/work-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';

@Component({
  selector: 'work-header',
  templateUrl: './work-header.component.html',
  styleUrls: ['./work-header.component.scss'],
  providers: [WorkModalService, DocumentModalService]
})
export class WorkHeaderComponent implements OnInit, OnDestroy {

  public work: Work;
  public workAction: UntypedFormControl;
  public workState: UntypedFormControl;

  public Permission = PermissionEnum;
  public permissionsForEdition = [PermissionEnum.EDIT_WORK];

  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService,
              public accessManager: AccessManager,
              private activationEndService: ActivationEndService,
              private appManager: AppManager,
              public appConfig: AppConfig,
              private titleService: Title,
              private fb: UntypedFormBuilder,
              private worksService: WorksService,
              private store: Store<any>,
              private analyticsService: AnalyticsService,
              private snackbarManager: SnackbarManager,
              private router: Router,
              private workModalService: WorkModalService) {
  }

  /**
   * Get current Work entity, initialize form fields and set page title.
   */
  public ngOnInit(): void {
    this.work = this.appManager.currentEntity as Work;

    // Update Work as it is updated outside the header
    this.worksService.workUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(updatedWork => {
        this.work = updatedWork;
      });

    this.store.dispatch(new WorkActionReset({currentValue: this.work.action, originalValue: this.work.action}));
    this.store.dispatch(new WorkStateReset({currentValue: this.work.state, originalValue: this.work.state}));

    // Init form fields
    this.workAction = this.fb.control(this.work.action, Validators.compose([
      Validators.required,
      Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
    ]));
    this.workState = this.fb.control(this.work.state, Validators.required);

    this.activationEndService.pushOptionalParams({
      'TITLE.WORK_IDENTIFIER': {
        identifier: this.work.identifier.toString()
      }
    });
    this.titleService.setTitle(`${this.translate.instant('TITLE.WORK_IDENTIFIER', {identifier: this.work.identifier})} - myA`);
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open the 'delete Work' dialog then delete the current Work entity if the user confirms.
   */
  public deleteWork(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.WORK,
      this.work.id
    );
    this.workModalService.openDeleteWorksDialog([this.work])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.WORK_DELETED'));
        this.router.navigate(['organization', this.appManager.currentOrganization.id, 'works'], {replaceUrl: true})
          .catch(console.error);
      });
  }

  /**
   * Redirects the user to the Asset sheet attached to the current Work
   */
  public showAssetSheet(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.work.assetId
    );

    this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'assets',
      'asset-sheet',
      this.work.assetId,
      'works'
    ]).catch(console.error);
  }
}
