import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import { Exclude, Expose, Type } from 'class-transformer';

export class Space extends Entity {
  public entityType = EntityTypeEnum.SPACE;

  public identifier: string;
  public assetId: string;

  @Expose({name: 'parentPathList'}) public parentPath: string[];
  @Type(() => Document) public documents: Document[] = [];

  public dataDate: string;

  // Parent spaces' names
  @Exclude() public hierarchy: string[];

  // Whether the Space has children
  @Exclude() public hasChildren: boolean;

  public getDirectParentId(): string {
    return this.parentPath[this.parentPath.length - 1];
  }

  @Expose()
  public toString(): string {
    return this.computedProperties?.['displayName'] ?? `${this?.identifier} - ${this?.name}`;
  }

  /**
   * Transforms this Space to a RelatedSpace.
   */
  public toRelatedSpace(): RelatedSpace {
    return new RelatedSpace({
      id: this.id,
      name: this.name,
      identifier: this.identifier,
      parentPath: this.parentPath,
      displayName: this.computedProperties?.['displayName']
    });
  }
}

export type IRelatedSpace = IRelatedEntity & { parentPath: string | string[] };

export class RelatedSpace {
  public id: string;
  public parentPath: string[];
  public displayName: string;

  constructor(relatedSpace: IRelatedSpace) {
    this.id = relatedSpace.id;
    this.parentPath = typeof relatedSpace.parentPath === 'string' ? JSON.parse(relatedSpace.parentPath) : relatedSpace.parentPath;
    this.displayName = relatedSpace.displayName
      ?? `${relatedSpace.identifier ?? '?'} - ${relatedSpace.name ?? '?'}`;
  }

  @Expose()
  public toString(): string {
    return this.displayName;
  }
}

export interface SpaceInput {
  name?: string;
  dataDate?: string;
}
