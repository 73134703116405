<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <input #inputText [formControl]="formControl" matInput class="mat-input-element ag-input-field-input" type="text"
         (focusout)="onFocusOut()"
         (focusin)="fullRowEdit && inputText.select()"
         [matAutocomplete]="auto"/> <span *ngIf="suffix">{{suffix}}</span>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected()">
    <mat-option *ngFor="let fieldValue of filteredSuggestions | async"
                [value]="fieldValue">{{ fieldValue }}</mat-option>
  </mat-autocomplete>

  <div *ngIf="control.invalid">
    <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
  <div *ngIf="control.pending">
    <i class="mdi mdi-loading mdi-spin loading tooltip-icon"></i>
  </div>
  <div *ngIf="!fullRowEdit">
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>
