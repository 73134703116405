import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Project } from '@app/core/model/entities/project/project';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { ProjectModalService } from '@app/features/main/views/projects/projects-inventory/modals/project-modal-service';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
  providers: [ProjectModalService, DocumentModalService]
})
export class ProjectHeaderComponent implements OnInit, OnDestroy {

  public project: Project;

  public Permission = PermissionEnum;
  public permissionsForEdition = [PermissionEnum.EDIT_PROJECT];
  public preconditionForDeletion = false;

  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService,
              public accessManager: AccessManager,
              private activationEndService: ActivationEndService,
              private appManager: AppManager,
              public appConfig: AppConfig,
              private titleService: Title,
              private analyticsService: AnalyticsService,
              private snackbarManager: SnackbarManager,
              private router: Router,
              private projectsService: ProjectsService,
              private projectModalService: ProjectModalService) {
  }

  /**
   * Get current Project entity, initialize form fields and set page title.
   */
  public ngOnInit(): void {
    this.project = this.appManager.currentEntity as Project;

    // Update Project as it is updated outside the header
    this.projectsService.projectUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(updatedProject => this.project = updatedProject);

    this.activationEndService.pushOptionalParams({
      'TITLE.PROJECT_IDENTIFIER': {
        identifier: this.project.identifier.toString()
      }
    });
    this.titleService.setTitle(`${this.translate.instant(
      'TITLE.PROJECT_IDENTIFIER',
      {identifier: this.project.identifier}
    )} - myA`);

    // Set precondition to delete
    // Super admin can delete any project
    if (this.accessManager.isSuperAdmin()) {
      this.preconditionForDeletion = true;
      return;
    }

    const userGroup = this.accessManager.currentUser.groups?.firstOrNull(group => {
      return group.client.organizationId === this.appManager.currentOrganization.id;
    });

    // User in group with all asset and asset type access can delete any project
    if (userGroup?.allAssetAccess && userGroup?.allAssetTypeAccess) {
      this.preconditionForDeletion = true;
      return;
    }

    // User in group with access to at least one asset or asset type related to th project can delete it.
    const projectAssetIds = this.project.relatedAssets.map(projectAsset => projectAsset.id);
    const projectAssetTypeIds = this.project.relatedAssets.map(projectAsset => projectAsset.typeId);

    const userHasAccessToAtLeastOneAsset = userGroup.assetIds.some(
      assetId => projectAssetIds.includes(assetId)
    );
    const userHasAccessToAtLeastOneAssetType = userGroup.assetTypeIds.some(
      assetTypeId => projectAssetTypeIds.includes(assetTypeId)
    );

    this.preconditionForDeletion = (userGroup.allAssetAccess && userHasAccessToAtLeastOneAsset) ||
      (userGroup.allAssetTypeAccess && userHasAccessToAtLeastOneAssetType);
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open the 'delete Project' dialog then delete the current Project entity if the user confirms.
   */
  public deleteProject(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.PROJECT,
      this.project.id
    );
    this.projectModalService.openDeleteProjectsDialog([this.project])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.PROJECT_DELETED'));
        this.router.navigate(['organization', this.appManager.currentOrganization.id, 'projects'], {replaceUrl: true})
          .catch(console.error);
      });
  }

}
