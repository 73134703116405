import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';

/**
 * Clear current Organization, current entities, reset translation language.
 * @return true.
 */
export default function cleanupGuard(): boolean {
  const translate = inject(TranslateService)
  const appManager = inject(AppManager)
  const browserLang = translate.getBrowserLang();

  translate.use(browserLang.match(/en|fr/) ? browserLang : translate.defaultLang).subscribe();
  appManager.currentOrganization = undefined;
  appManager.emptyCurrentEntityStack();
  appManager.entityObservable$.next(false);

  return true;
}
