import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Space } from '@app/core/model/entities/asset/space';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AbstractFieldBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'computed-field-builder',
  templateUrl: './computed-field-builder.component.html',
  styleUrls: ['./computed-field-builder.component.scss']
})
export class ComputedFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;

  /**
   * Key is id of entity's parents and value is name of entity's parent
   */
  public parentNames = new Map<string, string>();

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  public ngOnInit(): void {
    this.formatIfDateValue();

    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        {value: this.fieldInitValue, disabled: true}
      )
    });
    this.getNextState();

    // Initialise parentNames used to display spaces' name
    this.entity['parentPath']?.forEach((parentId, i) => {
      this.parentNames.set(parentId, (this.entity as Space).hierarchy?.[i]);
    });
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    if (this.isSingleField) {
      this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
        if (newMode === FieldStateMode.EMPTY) {
          this.read();
        }
      });
    }

    this.formStateService.getPath(['computed']).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.entity = this.appManager.currentEntity;
      this.calcFieldInitValue();
      this.formatIfDateValue();
      this.form.get('field').setValue(this.fieldInitValue);
      this.getFieldGroupInfo(['isDisplayed']).next(!((this.fieldInitValue === null || this.fieldInitValue === void 0) && this.isSingleField));
    });
    if ((this.fieldInitValue === null || this.fieldInitValue === void 0) && this.isSingleField) {
      this.getFieldGroupInfo(['isDisplayed']).next(false);
    }
  }

  public getFieldValue(): any {
    const value = this.form.get('field').value;
    if (typeof value === 'boolean') {
      return this.translate.instant(value ? 'VALUE.TRUE' : 'VALUE.FALSE');
    } else {
      return value?.toString();
    }
  }

  public cancel(): void {
    // Do nothing
  }

  private formatIfDateValue(): void {
    if (this.fieldConfig.field.fieldType === FieldTypeEnum.DATE && this.fieldInitValue) {
      this.fieldInitValue = this.appConfig.getFormattedDate(this.fieldInitValue, this.fieldConfig.field.fieldType);
    }
  }
}
