<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span class="check-title">{{ 'VALUE.' + entity?.type | uppercase | translate }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeSidePanel()" [disableRipple]="true"
            class="fx-layout-col fx-justify-center fx-align-center"
            id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadCheckDocuments()"
        [class]="'mdi mdi-paperclip sm'"
        [id]="'add-document-button'"
        [matTooltip]="'BUTTON.ATTACH_FILE' | translate"
        [permissions]="[Permission.EDIT_CHECK, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>

      <restricted-icon-button (onClick)="renewCheck()"
        [class]="'mdi mdi-autorenew sm'"
        [id]="'renew-check-button'"
        [matTooltip]="'BUTTON.RENEW' | translate"
        [permissions]="[Permission.CREATE_CHECK]">
      </restricted-icon-button>

      <restricted-icon-button (onClick)="deleteCheck()"
        [class]="'mdi mdi-delete sm'"
        [id]="'delete-check-button'"
        [matTooltip]="'BUTTON.DELETE' | translate"
        [permissions]="[Permission.DELETE_CHECK]">
      </restricted-icon-button>

      @if (displayAssetSheetLink) {
        <span class="fx-flex"></span>
      }

      @if (displayAssetSheetLink) {
        <div class="asset-sheet-link">
          <span (click)="navigateToAssetSheet()"> {{ 'BUTTON.SHOW_ASSET_SHEET' | translate }} </span>
        </div>
      }
    </div>
  </div>
  <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
    <div class="page-sidebar">
      <div class="form-container">
        @if (entity) {
          <form-builder
            [formId]="formId"
            [displayHeader]="false"
            [eventsOrigin]="eventsOrigin"
            [permissionsForEdition]="permissionsForEdition"
            [fieldsToHide]="fieldsToHide">
          </form-builder>
        }
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{'LABEL.DOCUMENT' | translate}} </span>
        <restricted-text-button
          (onClick)="uploadCheckDocuments()"
          [id]="'check-upload-document-button'"
          [permissions]="[Permission.EDIT_CHECK, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{'BUTTON.ATTACH_FILE' | translate}}
        </restricted-text-button>
      </div>
      @for (document of entity?.documents; track document) {
        <div class="document-content">
          @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
            <a
              (click)="fileService.downloadFile(document.id)">
              <i class="mdi mdi-file-outline xs"></i>
              <span class="file-name-label">{{document.name}}</span>
            </a>
          } @else {
            <span class="file-name-label">{{document.name}}</span>
          }
          <span (click)="deleteCheckDocument(document)"
            [permission-lock]="[Permission.EDIT_CHECK, Permission.DELETE_DOCUMENT]"
            class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
        </div>
      }
    </div>
  </div>
</div>
