@if (isPending) {
  <i class="mdi mdi-loading mdi-spin loading tooltip-icon xs"></i>
} @else {
  @if (isEditing) {
    <div class="buttons-container fx-layout-row">
      <button #cancelButton (click)="onButtonCancelClick()"
        (keydown.tab)="onButtonCancelTab($event)"
        id="cancel-edit-row-button"
        class="fx-layout-row fx-justify-center fx-align-center"
        [matTooltip]="'BUTTON.CANCEL' | translate"
        type="button"
        mat-icon-button>
        <i aria-hidden="true" class="mdi mdi-close xs"></i>
      </button>
      <button #saveButton (click)="onButtonSaveClick()"
        (keydown.tab)="onButtonSaveTab($event)"
        id="save-edit-row-button"
        color="accent"
        type="button"
        class="fx-layout-row fx-justify-center fx-align-center"
        [matTooltip]="'BUTTON.SAVE' | translate"
        [disabled]="params.formGroup.invalid"
        mat-icon-button>
        <i aria-hidden="true" class="mdi mdi-check xs"></i>
      </button>
    </div>
  } @else {
    <div class="buttons-container">
      <button (click)="onButtonStartClick()"
        [permission-lock]="params.editPermissions"
        id="enter-edit-row-button"
        type="button"
        class="fx-layout-row fx-justify-center fx-align-center"
        [matTooltip]="'BUTTON.EDIT' | translate"
        mat-icon-button>
        <i aria-hidden="true" class="mdi mdi-pencil xs"></i>
      </button>
    </div>
  }
}

