import { AbstractBean } from '@app/core/model/abstract-bean';
import { Permission } from '@app/core/model/client/permission';
import { Expose, Type } from 'class-transformer';

export class UserRole extends AbstractBean {

  public code: string;
  public isTb: boolean;
  public order: number;

  @Type(() => Permission)
  public activePermissions: Permission[];

  @Expose()
  public toString(): string {
    return this.code;
  }
}
