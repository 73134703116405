<div id="dialog-container">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <mat-divider></mat-divider>
  <file-uploader [uploadData]="data.uploadData"
    (uploadInProgress)="uploadIsInProgress($event)"
    (fileUploaded)="createNewDocuments($event)"
    class="uploader-container">
  </file-uploader>
  @if (!preventUploadFiles) {
    <mat-dialog-actions>
      <button (click)="removeAllFiles()" mat-stroked-button matDialogClose="cancel">
        {{ data['cancelButtonText'] }}
      </button>
      <button [disabled]="isUploadInvalid()"
        color="accent"
        mat-raised-button
        matDialogClose="yes">
        {{ data['doneButtonText'] }}
      </button>
    </mat-dialog-actions>
  }
</div>
