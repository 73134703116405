<div class="fx-layout-col fx-justify-center fx-align-center" id="dialog-container" [cdkTrapFocus]="true">
  <status-message [type]="'warning'"></status-message>

  <h1 class="fx-justify-space-around fx-align-start" mat-dialog-title>{{ 'TITLE.MOVE_SPACE' | translate }}</h1>

  <mat-dialog-content class="fx-flex-auto fx-layout-col">
    <form [formGroup]="moveSpaceForm">
      <mat-radio-group formControlName="moveChildren" class="fx-layout-col">
        <mat-radio-button [value]="false">{{'LABEL.MOVE_SPACE_ONLY' | translate}}</mat-radio-button>
        <mat-radio-button [value]="true">{{'LABEL.MOVE_SPACE_AND_CHILDREN' | translate}}</mat-radio-button>
      </mat-radio-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button id="'move-space-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{'BUTTON.CANCEL' | translate}}
    </button>
    <button [disabled]="!moveSpaceForm.valid"
            color="accent"
            id="move-space-ok-button"
            mat-raised-button
            matDialogClose="yes">
      {{'BUTTON.MOVE' | translate}}
    </button>
  </mat-dialog-actions>
</div>
