import { MediaMatcher } from '@angular/cdk/layout';
import { Directive, Inject, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { ActionEnum, EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Entity } from '@app/core/model/entities/entity';

import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
  FieldGroup
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AppManager } from '@app/core/services/managers/app.manager';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { customCssRenderer, getValue, translateValueBuilder } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import structuredClone from '@ungap/structured-clone';
import { BehaviorSubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

export enum FieldStateMode {
  READ,
  EDIT,
  EMPTY,
  SAVING,
  AFTER_SAVE,
  ERROR
}

/**
 * Shared class between field groups and fields in which are defined
 * a few common properties and methods used to change the state of said fieldgroup or field
 */
@Directive()
abstract class SharedBuilder implements OnDestroy {
  public FieldMode = FieldStateMode;
  public currentMode: FieldStateMode;

  protected destroy$ = new Subject<void>();

  protected constructor(public entity: Entity,
                        public data: any,
                        protected eventsOrigin: EventOriginEnum,
                        protected formStateService: FormStateService) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public abstract edit(): void;

  public abstract read(): void;

  public abstract empty(): void;

  public abstract save(): void;

  public abstract cancel(): void;
}

/**
 * Shared class between all field groups
 * including all grouped, singles and the custom ones e.g. address
 */
export abstract class AbstractFieldGroupBuilder extends SharedBuilder {

  private touchScreenQuery: MediaQueryList;

  protected constructor(entity: Entity,
                        data: any,
                        eventsOrigin: EventOriginEnum,
                        formStateService: FormStateService,
                        public fieldGroup: FieldGroup,
                        public preconditionsForEdition: boolean,
                        public permissionsForEdition: string[],
                        public accessManager: AccessManager,
                        public appConfig: AppConfig,
                        public appManager: AppManager,
                        protected singleEditService: SingleEditService,
                        protected analyticsService: AnalyticsService,
                        protected media: MediaMatcher) {
    super(entity, data, eventsOrigin, formStateService);
    this.touchScreenQuery = media.matchMedia('(pointer: coarse)');
  }

  /**
   * On touch screen devices only, toggle the tooltip after pressing the icon.
   * @param tooltip MatTooltip to toggle.
   */
  public onClickTooltip(tooltip: MatTooltip): void {
    if (this.touchScreenQuery.matches) {
      tooltip.toggle();
    }
  }

  /**
   * React to any click on the add-field link
   * @param {MouseEvent} event
   */
  public onClickActivate(event: MouseEvent): void {
    this.activate();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Reacts to an Enter keypress on a focused element
   * @param {KeyboardEvent} event
   */
  public onEnterActivate(event: KeyboardEvent): void {
    this.activate();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Responds to an Escape keypress on a focused element
   * @param {KeyboardEvent} event
   */
  public onEscapeCancel(event: KeyboardEvent): void {
    this.cancel();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Sets the current state of the field group as saving
   * and copies the data from the current value to the initialvalue as to avoid simply copying references
   */
  public save(): void {
    this.getFieldGroupState().next(this.FieldMode.SAVING);
    this.formStateService.save(this.fieldGroup, this.data.propertiesPath);
  }

  /**
   * Adds indirection to the save logic if need be by separating the save event from the save logic
   */
  public onClickSave(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroup.code, ActionEnum.SAVE, this.eventsOrigin);
    this.save();
  }

  /**
   * Replaces the data of the current value with the data in the initialvalue
   * and retrieves the next state based on whether the current value is empty or not
   */
  public cancel(): void {
    this.setFieldGroupValue(JSON.parse(JSON.stringify(this.getFieldGroupInitialValue())));
    this.getNextState();
  }

  /**
   * Adds indirection to the cancel logic because otherwise all fields would send
   * a cancel analytics event when switching from one field to another
   */
  public onClickCancel(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroup.code, ActionEnum.CANCEL, this.eventsOrigin);
    this.cancel();
  }

  /**
   * Enables the field to be edited in whichever way it functions
   */
  public edit(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroup.code, ActionEnum.SELECT, this.eventsOrigin);
    this.getFieldGroupState().next(this.FieldMode.EDIT);
    this.singleEditService.singleEditSubject.next(this.fieldGroup.code);
  }

  /**
   * Sets the field in read mode which can be clicked to turn it into the edit mode
   */
  public read(): void {
    this.getFieldGroupState().next(this.FieldMode.READ);
  }

  /**
   * Displays a link which can be clicked to turn it into the edit mode
   */
  public empty(): void {
    this.getFieldGroupState().next(this.FieldMode.EMPTY);
  }

  /**
   * Set up the hooks to which the field must react in order to update themselves
   */
  protected setupHooks(): void {
    // Hook to a field becoming into an edit mode
    this.singleEditService.singleEditSubject.pipe(takeUntil(this.destroy$)).subscribe((groupCode?: string) => {
      if (this.fieldGroup.code !== groupCode) {
        this.cancel();
      }
    });
  }

  /**
   * Checks the current value of a field and determines which state it should be next
   * based on if every property of the object is undefined
   */
  protected getNextState(): void {
    const isEmpty = Object.values(this.getFieldGroupValue()).every(value => {
      return ((Array.isArray(value) && value.length === 0)
        || value === null
        || value === void 0
        || value === ''
        || Object.getPrototypeOf(value) === Object.prototype && Object.keys(value).length === 0
      );
    });
    if (isEmpty) {
      this.empty();
    } else {
      this.read();
    }
  }

  /**
   * Either activate the field into editing mode or save it if you were editing it
   */
  private activate(): void {
    if (this.accessManager.hasAllNeededPermissions(this.permissionsForEdition) && this.preconditionsForEdition) {
      this.edit();
    }
  }

  protected refreshEntity(): void {
    this.entity = this.data.propertiesPath
      ? this.appManager.currentEntity[this.data.propertiesPath]
      : this.appManager.currentEntity;
  }

  /**
   * Retrieves data about the field group, stored at the provided path in the FormStateService.
   * @param keys Path in the FormStateService.
   * @return Data at given path.
   */
  public getFieldGroupInfo(keys: string[]): any {
    return this.formStateService.getPath([this.fieldGroup.code, ...keys], {
      customPath: this.data.propertiesPath
    });
  }

  /**
   * Retrieves the field group's value from the FormStateService.
   * @return Field group's value.
   */
  public getFieldGroupValue(): any {
    return this.getFieldGroupInfo(['value']);
  }

  /**
   * Retrieves the field group's initial value from the FormStateService.
   * @return Field group's initial value.
   */
  public getFieldGroupInitialValue(): any {
    return this.getFieldGroupInfo(['initialValue']);
  }

  /**
   * Retrieves the field group's state from the FormStateService.
   * @return Field group's state.
   */
  public getFieldGroupState(): Subject<FieldStateMode> {
    return this.getFieldGroupInfo(['state']);
  }

  /**
   * Store the value at the given path in the FormStateService.
   * @param keys Path where to store the value.
   * @param value Data to be stored.
   */
  public setFieldGroupInfo(keys: string[], value: any): void {
    this.formStateService.setPath([this.fieldGroup.code, ...keys], value, {
      customPath: this.data.propertiesPath
    });
  }

  /**
   * Sets the field group's value in the FormStateService.
   * @param value Value to be stored.
   */
  public setFieldGroupValue(value: any): void {
    this.setFieldGroupInfo(['value'], value);
  }

  /**
   * Sets the field group's initial value in the FormStateService.
   * @param value Initial value to be stored.
   */
  public setFieldGroupInitialValue(value: any): void {
    this.setFieldGroupInfo(['initialValue'], value);
  }
}

/**
 * Shared class between all fields
 * of every type such as numeric, text and list
 */
export abstract class AbstractFieldBuilder extends SharedBuilder {
  public fieldGroupCode: string;
  public propertiesPath: string;
  public isSingleField: boolean;
  public toggled: boolean;
  public form: UntypedFormGroup;
  public focusable: boolean;
  protected fieldInitValue: any;
  protected requestFocus: boolean;

  private touchScreenQuery: MediaQueryList;

  protected constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
                        @Inject(FIELD_EXTRA_DATA) data: any,
                        @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
                        formStateService: FormStateService,
                        @Inject(FIELD_CONFIG_INJECTION) public fieldConfig: FieldConfig,
                        @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
                        @Inject(FIELD_PERMISSIONS_INJECTION) public permissionsForEdition: string[],
                        public appManager: AppManager,
                        public appConfig: AppConfig,
                        public accessManager: AccessManager,
                        public media: MediaMatcher,
                        public translate: TranslateService,
                        protected validationService: ValidationService,
                        protected singleEditService: SingleEditService,
                        protected analyticsService: AnalyticsService) {
    super(entity, data, eventsOrigin, formStateService);
    this.toggled = true;
    this.isSingleField = data.isSingleField;
    this.fieldGroupCode = data.fieldGroupCode;
    this.propertiesPath = data.propertiesPath;
    this.focusable = data.focusable;
    this.touchScreenQuery = media.matchMedia('(pointer: coarse)');

    this.calcFieldInitValue();
  }

  /**
   * On touch screen devices only, toggle the tooltip after pressing the icon.
   * @param tooltip MatTooltip to toggle.
   */
  public onClickTooltip(tooltip: MatTooltip): void {
    if (this.touchScreenQuery.matches) {
      tooltip.toggle();
    }
  }

  /**
   * React to any click on the add-field link
   * @param {MouseEvent} event
   */
  public onClickActivate(event: MouseEvent): void {
    this.activate();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Reacts to an Enter keypress on a focused element
   * @param {KeyboardEvent} event
   */
  public onEnterActivate(event: KeyboardEvent): void {
    this.activate();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Responds to an Escape keypress on a focused element
   * @param {KeyboardEvent} event
   */
  public onEscapeCancel(event: KeyboardEvent): void {
    this.cancel();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Sets the current state of the field as saving
   * and copies the data from the current value to the initialvalue
   */
  public save(): void {
    if (this.isSingleField) {
      this.getFieldGroupState().next(this.FieldMode.SAVING);
      this.form?.markAsPristine();
      this.formStateService.save(this.fieldConfig, this.propertiesPath);
    }
  }

  /**
   * Adds indirection to the save logic if need be by separating the save event from the save logic
   */
  public onClickSave(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroupCode, ActionEnum.SAVE, this.eventsOrigin);
    this.save();
  }

  /**
   * Replaces the data of the current value with the data in the initialvalue
   * and retrieves the next state based on whether the current value is empty or not
   */
  public cancel(): void {
    this.setFieldValue(this.getFieldInitialValue());
    this.getNextState();
  }

  /**
   * Adds indirection to the cancel logic because otherwise all fields would send
   * a cancel analytics event when switching from one field to another
   */
  public onClickCancel(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroupCode, ActionEnum.CANCEL, this.eventsOrigin);
    this.cancel();
  }

  /**
   * Enables the field to be edited in whichever way it functions
   */
  public edit(): void {
    this.analyticsService.trackInlineActionEvent(this.fieldGroupCode, ActionEnum.SELECT, this.eventsOrigin);
    this.singleEditService.singleEditSubject.next(this.fieldGroupCode);
    this.requestFocus = true;
    this.getFieldGroupState().next(this.FieldMode.EDIT);
  }

  /**
   * Sets the field in read mode which can be clicked to turn it into the edit mode
   */
  public read(): void {
    this.getFieldGroupState().next(this.FieldMode.READ);
  }

  /**
   * Displays a link which can be clicked to turn it into the edit mode
   */
  public empty(): void {
    this.getFieldGroupState().next(this.FieldMode.EMPTY);
  }

  /**
   * Translate the value if there is a key in the po files. Otherwise, return the value.
   * @param value
   */
  public translateValue(value: string): string {
    return translateValueBuilder(this.translate)(value, this.fieldConfig.fieldCode);
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for which an error has been raised by the corresponding validator function.
   */
  public get erroredValidators(): FieldValidator[] {
    return this.fieldConfig.field.validators.filter(validator => !!this.form.get('field').errors?.[validator.type]);
  }

  protected calcFieldInitValue(): void {
    this.fieldInitValue = this.fieldConfig ? getValue(this.entity, this.fieldConfig.fieldPath) : undefined;
  }

  /**
   * Set up the hooks to which the field must react in order to update themselves
   */
  protected setupHooks(): void {
    // Hook to a field becoming into an edit mode
    this.singleEditService.singleEditSubject.pipe(takeUntil(this.destroy$)).subscribe((groupCode?: string) => {
      if (this.fieldGroupCode !== groupCode) {
        this.cancel();
      }
    });

    // Hook to the changing of the state of the fieldgroup
    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      switch (newMode) {
        case FieldStateMode.AFTER_SAVE:
          this.refreshEntity();
          this.setFieldGroupInfo(['initialValue'], JSON.parse(JSON.stringify(this.getFieldGroupInfo(['value']))));
          this.getNextState();
          break;
        case FieldStateMode.ERROR:
          this.setFieldGroupInfo(['value'], structuredClone(this.getFieldGroupInfo(['initialValue'])));
          this.getNextState();
          break;
        default:
          this.currentMode = newMode;
      }
    });

    // Hook to the requiredTrue validator if it exists on this field
    const requiredTrueValidator = this.fieldConfig.field?.validators.find(validator => {
      return validator.type === ValidatorType.REQUIRED_TRUE;
    });
    if (requiredTrueValidator) {
      // Create a hook on the valuechange of the requiredTrue validator if it doesn't exist
      if (!this.getFieldGroupInfo(['requiredTrue', requiredTrueValidator.definition])) {
        const hookValue = getValue(this.entity, requiredTrueValidator.definition.split('.')) || false;
        this.setFieldGroupInfo([
          'requiredTrue',
          requiredTrueValidator.definition
        ], new BehaviorSubject<boolean>(hookValue));
      }
      // Toggle this field if the hook is activated
      this.getFieldGroupInfo(['requiredTrue', requiredTrueValidator.definition])
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (toggle) => {
            this.toggled = !!toggle;

            // If the hook is set to false, reset the hooked fields
            if (!toggle) {
              this.setFieldValue('');
              this.form.reset({field: ''});
            }
          });
    }

    // Value change hook
    if (this.form) {
      this.setFieldGroupInfo(
        [this.fieldConfig.fieldCode, 'valueChanges'],
        this.form.valueChanges.pipe(startWith(this.form.value as unknown))
      );
    }
  }

  /**
   * Get a validator function which combines all validators from the field's config.
   * @param data Additional data that can be passed to composing validators when required.
   * @returns Composed validator function.
   */
  protected computeValidators(data?: { [key: string]: any }): ValidatorFn {
    return Validators.compose(this.fieldConfig.field?.validators.map((validator) => {
      return this.validationService.getValidator(validator, this.entity, data);
    }));
  }

  /**
   * Uses Angular's composition of async validators on all async validators from the field configuration
   * @returns {ValidatorFn}
   */
  protected computeAsyncValidators(): AsyncValidatorFn {
    return Validators.composeAsync(this.fieldConfig.field?.validators.map((validator) => {
      return this.validationService.getAsyncValidator(validator, this.entity, this.fieldConfig.field.code);
    }));
  }

  /**
   * Checks the current value of a field and determines which state it should be next
   * based on if the value is an empty array or undefined
   */
  protected getNextState(): void {
    const isEmpty = Object.values(this.getFieldGroupInfo(['value'])).every(value => {
      return ((Array.isArray(value) && value.length === 0)
        || value == void 0
        || value === '');
    });
    if (isEmpty) {
      this.empty();
    } else {
      this.read();
    }
  }

  /**
   * Either activate the field into editing mode or save it if you were editing it
   */
  private activate(): void {
    if (this.currentMode === this.FieldMode.EDIT && this.form.valid) {
      this.save();
    } else {
      if (this.accessManager.hasAllNeededPermissions(this.permissionsForEdition) && this.preconditionsForEdition) {
        this.edit();
      }
    }
  }

  protected refreshEntity(): void {
    this.entity = this.propertiesPath
      ? this.appManager.currentEntity[this.propertiesPath]
      : this.appManager.currentEntity;
  }

  /**
   * Retrieves data about the field group, stored at the provided path in the FormStateService.
   * @param keys Path where the data is stored.
   * @return Data at given path.
   */
  public getFieldGroupInfo(keys: string[]): any {
    return this.formStateService.getPath([this.fieldGroupCode, ...keys], {
      customPath: this.propertiesPath
    });
  }

  /**
   * Retrieves the field's value from the FormStateService.
   * @return Field's value.
   */
  public getFieldValue(): any {
    return this.getFieldGroupInfo(['value', this.fieldConfig.fieldCode]);
  }

  /**
   * Retrieves the field's initial value from the FormStateService.
   * @return Field's initial value.
   */
  public getFieldInitialValue(): any {
    return this.getFieldGroupInfo(['initialValue', this.fieldConfig.fieldCode]);
  }

  /**
   * Retrieves the field group's state from the FormStateService.
   * @return Field group's state.
   */
  public getFieldGroupState(): Subject<FieldStateMode> {
    return this.getFieldGroupInfo(['state']);
  }

  /**
   * Stores value at the given path in the FormStateService.
   * @param keys Path where to store value.
   * @param value Data to be stored.
   */
  public setFieldGroupInfo(keys: string[], value: any): void {
    this.formStateService.setPath([this.fieldGroupCode, ...keys], value, {
      customPath: this.propertiesPath
    });
  }

  /**
   * Sets the field's value in the FormStateService.
   * @param value Value to be stored.
   */
  public setFieldValue(value: any): void {
    this.setFieldGroupInfo(['value', this.fieldConfig.fieldCode], value);
  }

  /**
   * Sets the field's initial value in the FormStateService.
   * @param value Initial value to be stored.
   */
  public setFieldInitialValue(value: any): any {
    this.setFieldGroupInfo(['initialValue', this.fieldConfig.fieldCode], value);
  }

  public focus(): void {
    //Do nothing
  }

  /**
   * Returns if the field has requested focus by user interaction
   */
  public hasRequestedFocus(): boolean {
    return this.requestFocus;
  }

  /**
   * Resets state of field focus
   */
  public resetRequestFocus(): void {
    this.requestFocus = false;
  }

  /**
   * Render fieldConfig's CSS properties
   * @return An object containing css styles to apply
   */
  public get customCss(): { [cssProperty: string]: string } {
    return customCssRenderer(this.fieldConfig.customCss, this.entity);
  }
}
