<div id="dialog-container" [formGroup]="visualisationForm">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_VISUALISATION' | translate }}</h1>
  <mat-dialog-content class="fx-flex-auto">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LABEL.VISUALISATION_NAME' | translate }}</mat-label>
      <input formControlName="name" [id]="'create_visualisation_name'" matInput
             required/>
      <mat-error *ngIf="visualisationForm.get('name').hasError('required')">
        {{ 'ERROR.FIELD_REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="visualisationForm.get('name').hasError('maxlength')">
        {{ 'ERROR.FIELD_MAX_LENGTH' | translate:{value: appConfig.NAME_MAX_LENGTH} }}
      </mat-error>
      <mat-error *ngIf="visualisationForm.get('name').hasError('isValueTaken')">
        {{ 'ERROR.VISUALISATION_NAME_ALREADY_IN_USE' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose>{{ 'BUTTON.CANCEL' | translate }}</button>
    <button [disabled]="!visualisationForm.valid" [id]="'visualisation-add-button'" color="accent" mat-raised-button
            matDialogClose="yes">{{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
