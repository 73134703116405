import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Asset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { AssetModalService } from '@app/features/main/views/assets/modals/asset-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { from, Subject, switchMap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './asset-header.component.html',
  styleUrls: ['./asset-header.component.scss'],
  providers: [AssetModalService]
})
export class AssetHeaderComponent implements OnInit, OnDestroy {

  public Permission = PermissionEnum;
  public permissionsForEdition = [PermissionEnum.EDIT_ASSET];

  public asset: Asset;

  private destroy$ = new Subject<void>();

  public selectAssetFormControl: FormControl<RelatedAsset>;

  constructor(public appConfig: AppConfig,
              public accessManager: AccessManager,
              private router: Router,
              private fb: UntypedFormBuilder,
              private assetsService: AssetsService,
              private assetModalService: AssetModalService,
              private appManager: AppManager,
              private snackbarManager: SnackbarManager,
              private activationEndService: ActivationEndService,
              private translate: TranslateService,
              private titleService: Title,
              private analyticsService: AnalyticsService) {
  }

  public ngOnInit(): void {
    this.asset = this.appManager.currentEntity as Asset;

    // Update Asset as it is updated outside the header
    this.assetsService.assetUpdated$.pipe(takeUntil(this.destroy$))
      .subscribe(updatedAsset => {
        this.asset = updatedAsset;
      });

    this.selectAssetFormControl = this.fb.control(void 0);

    this.activationEndService.pushOptionalParams({
      'TITLE.ASSET_TYPE_IDENTIFIER': {
        identifier: this.asset.identifier.toString(),
        type: this.asset.computedProperties.typeName
      }
    });
    this.titleService.setTitle(this.appManager.currentEntity['name'] + ' - myA');

    // Navigate to the selected asset sheet
    this.selectAssetFormControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(asset => !!asset),
      switchMap((relatedAsset: RelatedAsset) => {
        const currentTabName = this.router.url.split('/').pop();
        return from(this.assetsService.navigateToAssetSheet(relatedAsset.id, currentTabName));
      })
    ).subscribe();
  }

  /**
   * Show confirmation dialog then delete the Asset.
   */
  public deleteAsset(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.HEADER,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.ASSET,
      this.asset.id
    );
    this.assetModalService.openDeleteAssetsDialog([this.asset])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result),
        switchMap(() => {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.ASSET_DELETED'));
          return this.router.navigate([
            'organization',
            this.appManager.currentOrganization.id,
            'assets'
          ], {replaceUrl: true});
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
