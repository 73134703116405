<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_EQUIPMENT' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="equipmentForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.EQUIPMENT_NAME' | translate }}</mat-label>
        <input matInput id="input_equipment_name" formControlName="name" required/>
        @if (equipmentForm.get('name').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
        @if (equipmentForm.get('name').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET' | translate }}</mat-label>
        <mat-select-asset-autocomplete [inputId]="'input_equipment_asset'"
          [entityType]="EntityTypeEnum.EQUIPMENT"
          formControlName="asset"
          required>
        </mat-select-asset-autocomplete>
        @if (equipmentForm.get('asset').hasError('required')) {
          <mat-error>
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        }
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.EQUIPMENT_SPACES' | translate}}</p>
        @if (equipmentForm.get('asset').value) {
          <ag-grid-angular
            (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions"
            class="ag-theme-material"
            id="equipment_spaces_grid"
            style="width: 100%; height: 300px">
          </ag-grid-angular>
        } @else {
          <div id="no-asset-selected-message">
            {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
          </div>
        }
        @if (equipmentForm.get('spaces').hasError('incoherentSpaces')) {
          <mat-error>
            {{'ERROR.SPACES_INCOHERENT' | translate}}
          </mat-error>
        }
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [id]="'cancel-button'" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [id]="'add-button'" [disabled]="!formIsValid()" color="accent"
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
