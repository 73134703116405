import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Client } from '@app/core/model/client/client';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { ClientsListService } from '@app/features/main/views/management/client/clients-inventory/clients-list.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './client-header.component.html'
})
export class ClientHeaderComponent implements OnInit, OnDestroy {

  public client: Client;
  public Permission = PermissionEnum;

  public validators: FieldValidator[] = [];
  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private generalService: GeneralService,
              private snackbarManager: SnackbarManager,
              private popupManager: PopupManager,
              private clientListService: ClientsListService,
              private appManager: AppManager,
              public accessManager: AccessManager,
              public appConfig: AppConfig,
              public translate: TranslateService,
              private analyticsService: AnalyticsService) {
  }

  public ngOnInit(): void {
    this.client = this.appManager.currentEntity as Client;
    this.validators = [
      new FieldValidator(ValidatorType.MAX_LENGTH, this.appConfig.NAME_MAX_LENGTH),
      new FieldValidator(ValidatorType.REQUIRED),
      new FieldValidator(ValidatorType.UNIQUE, this.client.name)
    ];

    this.clientListService.getDeleteClientsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['organization', this.appManager.currentOrganization.id, 'clients']).catch(console.error);
      });
  }

  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Updates the client name given the event sent by the inline widget
   * @param event
   */
  public saveClientName(event: { value: string }): void {
    const COMBINED_MUTATION = gql`
      mutation UpdateClientName($clientId: Int!, $clientInput: UpdateClientInput!) {
        updateClient(id: $clientId, client: $clientInput) {
          id
          name
        }
      }
    `;
    const MUTATION_VAR = {
      clientId: this.client.id,
      clientInput: {
        name: event.value
      }
    };
    this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
        }
      });
  }

  /**
   * Deletes the client if all conditions are met
   * and redirects to the management list if successful
   */
  public deleteClient(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.BUTTON,
      NavigateToEnum.EDIT_INLINE,
      EntityTypeEnum.CLIENT,
      this.client.id
    );
    if (this.client.isTb) {
      this.popupManager.showOkPopup({
        dialogTitle: this.translate.instant('TITLE.UNAUTHORIZED_DELETE'),
        dialogMessage: this.translate.instant('MESSAGE.UNAUTHORIZED_DELETE_TB_CLIENT'),
        type: 'error'
      });
      return;
    }
    this.clientListService.deleteClients([this.client]);
  }
}
