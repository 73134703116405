import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { ContractInput } from '@app/core/model/entities/asset/contract';
import { Space } from '@app/core/model/entities/asset/space';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EntityTypeEnum } from "@app/core/enums/entity-type.enum";

@Component({
  templateUrl: './organization-contract-create-modal.component.html'
})
export class OrganizationContractCreateModalComponent implements OnInit, OnDestroy {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  public contractForm: FormGroup<{
    provider: FormControl<string>,
    asset: FormControl<RelatedAsset>,
    spaces: FormControl<Space[]>
  }>;

  public gridApi: GridApi;
  public gridOptions: GridOptions;

  public spaces = new BehaviorSubject<Space[]>([]);

  protected destroy$ = new Subject<void>();

  private invalidRows: string[] = [];

  // Injection
  public appConfig = inject(AppConfig);
  protected fb = inject(UntypedFormBuilder);
  protected assetsService = inject(AssetsService);
  protected spacesService = inject(SpacesService);
  protected columnBuilder = inject(ColumnBuilder);
  protected translate: TranslateService = inject(TranslateService);
  protected gridOptionsService = inject(GridOptionsService);

  constructor() {
    this.gridOptions = {
      ...this.gridOptionsService.staticSelectSpacesGridOptions,
      localeText: this.columnBuilder.localeTextCommon(),
      rowClassRules: {
        'invalid-row': ({data}: { data: Space }): boolean => this.invalidRows.includes(data.id)
      },
      onRowClicked: this.onRowSelected.bind(this),
      onRowSelected: this.onRowSelected.bind(this)
    };

    this.contractForm = this.fb.group({
      provider: this.fb.control([], Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      asset: this.fb.control(null, Validators.required),
      spaces: this.fb.control([], Validators.compose([
        Validators.required,
        ExtraValidators.checkSpaces
      ]))
    });
  }

  /**
   * Fetch accessible Assets and Spaces.
   */
  public ngOnInit(): void {
    // Listen for Spaces to be displayed in the grid
    this.contractForm.controls.asset.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap((relatedAsset?: RelatedAsset) => {
          return relatedAsset ? this.spacesService.loadSpaces(relatedAsset.id) : of([]);
        })
      )
      .subscribe(this.spaces);
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.spaces.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load the grid's column definitions and fill with the selected Asset's Spaces.
   * @param params Ag-grid params.
   */
  public onGridReady(params): void {
    this.gridApi = params.api;
    this.gridOptions.columnApi.applyColumnState({
      state: [
        {colId: 'ag-Grid-AutoColumn', sort: 'asc'}
      ]
    });

    // Resize columns when receiving new Spaces
    this.spaces.subscribe(spaces => {
      this.gridApi.setRowData(spaces);
      this.gridApi.sizeColumnsToFit();
      this.contractForm.controls.spaces.reset([]);
    });
  }

  /**
   * Get the form's data for creating a new Contract.
   * @return New Contract's data.
   */
  public getGeneratedObject(): { contractInput: ContractInput, assetId: string } {
    const {provider, asset, spaces} = this.contractForm.getRawValue();
    return {
      contractInput: {
        provider,
        spaceIds: spaces.map(space => space.id)
      },
      assetId: asset.id
    };
  }

  /**
   * Update value in the form and refresh invalid rows.
   * @param event Event thrown by the grid.
   */
  private onRowSelected(event: RowSelectedEvent): void {
    const spaces = this.gridApi.getSelectedRows() as Space[];
    this.contractForm.controls.spaces.setValue(spaces);
    this.contractForm.controls.spaces.markAsTouched();

    const nodesToRefresh = [event.node];

    // Check if row is invalid and update invalid rows
    if (event.node.isSelected() && (this.hasParentSelected(event.data) || this.hasChildrenSelected(event.data))) {
      this.invalidRows.push(event.data.id);
    } else if (!event.node.isSelected()) {
      this.invalidRows = this.invalidRows.filter(row => {
        const rowNode = this.gridApi.getRowNode(row);
        nodesToRefresh.push(rowNode);
        return row !== event.data.id
          && (this.hasParentSelected(rowNode.data) || this.hasChildrenSelected(rowNode.data));
      });
    }

    // Refresh rows
    this.gridApi.redrawRows({
      rowNodes: nodesToRefresh
    });
  }

  /**
   * Checks if a Space has ancestors that are selected in the grid.
   * @param space Space to check.
   * @return true if at least one ancestor is selected, false otherwise.
   * @private
   */
  private hasParentSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows().some(selectedRow =>
      space.parentPath.includes(selectedRow.id)
    );
  }

  /**
   * Checks if a Space has children that are selected in the grid.
   * @param space Space to check.
   * @return true is at least one child is selected, false otherwise.
   * @private
   */
  private hasChildrenSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows().some(selectedRow =>
      selectedRow.parentPath.includes(space.id)
    );
  }
}
