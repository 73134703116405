<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <input [formControl]="formControl" matInput class="ag-input-field-input" type="text" autocomplete="off"
    (focus)="datePicker.open()" (dateChange)="dateChange()" [id]="'date'" [matDatepicker]="datePicker" />
  <mat-datepicker-toggle [for]="datePicker" matIconSuffix></mat-datepicker-toggle>
  <mat-datepicker #datePicker color="accent" disabled="false"></mat-datepicker>

  @if (control.invalid) {
    <div>
      <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
    </div>
  }

  <div>
    <i class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>


