import { Directive, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { percent } from './validator';

const PERCENT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PercentValidator),
  multi: true
};

@Directive({
  selector: '[percent][formControlName],[percent][formControl],[percent][ngModel]',
  providers: [PERCENT_VALIDATOR]
})
export class PercentValidator implements Validator, OnInit, OnChanges {
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = percent;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'PERCENT') {
        this.validator = percent;
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
