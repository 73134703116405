import { Injectable } from '@angular/core';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import dayjs from 'dayjs';

@Injectable()
export class AppConfig {
  public DISPLAY_DATE_FORMAT: string;
  public DISPLAY_DATETIME_FORMAT: string;
  public DATETIME_FORMAT: string;
  public DATE_FORMAT: string;
  public GIGA_TO_BYTE: number;
  public MEGA_TO_BYTE: number;
  public MAX_FILE_SIZE: number;
  public MAX_IMAGE_SIZE: number;
  public MAX_UPLOADS_DOCUMENTS: number;
  public MAX_UPLOADS_FILES: number;
  public MAX_SUBSPACE_COUNT: number;
  public PASSWORD_FORMAT: string;
  public USER_PASSWORD_MIN_LENGTH: number;
  public USER_NAME_MAX_LENGTH: number;
  public ORG_ACRONYM_MAX_LENGTH: number;
  public CONTRACT_ID_MAX_LENGTH: number;
  public NAME_MAX_LENGTH: number;
  public FIELD_MAX_LENGTH: number;
  public TOOLTIP_MAX_LENGTH: number;
  public DEFAULT_MAX_LENGTH: number;
  public UNDO_REDO_LIMIT: number;
  public ORG_STORAGE_SPACE_VALUES: number[];
  public EMPTY_FIELD_VALUE: string;
  public GRID_EMPTY_VALUE: string;
  public GRID_MULTI_SORT_KEY: 'ctrl';
  public POSITIVE_VALUE_REGEX: string;
  public DEFAULT_ADDRESS_FORMAT: any;
  public PLACEHOLDER_MAP: string;
  public PLACEHOLDER_CAROUSEL: string;
  public PLACEHOLDER_EMPTY_FOLDER: string;
  public PLACEHOLDER_ORGANIZATION: string;
  public PLACEHOLDER_PICTURE: string;
  public PLACEHOLDER_PROFILE_PICTURE: string;
  public PICTOGRAM_FOLDER_PATH: string;
  public DPE_LIST: string[];
  public PURPOSE_MAX_LENGTH: number;
  public UNIT_CRV_GTE: number;
  public EXPORT_CELLS_FORBIDDEN_VALUES: string[];
  public DEFAULT_WORKSPACE: string;
  public DEFAULT_DATAFLOW: string;
  public UUID_REGEX: string;

  constructor() {
    this.DISPLAY_DATE_FORMAT = 'L';
    this.DISPLAY_DATETIME_FORMAT = 'L LT';
    this.DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
    this.DATE_FORMAT = 'YYYY-MM-DD';
    this.GIGA_TO_BYTE = 1073741824; // 1 billion bytes per giga byte
    this.MEGA_TO_BYTE = 1048576; // 1 million bytes per mega bytes
    this.MAX_FILE_SIZE = 262144000; // max file size for upload widget 250MiB
    this.MAX_IMAGE_SIZE = 5242880; // Max file size for uploaded pictures 5MiB
    this.MAX_UPLOADS_DOCUMENTS = 100; // max simultaneous documents uploads
    this.MAX_UPLOADS_FILES = 1000; // max simultaneous files uploads
    this.MAX_SUBSPACE_COUNT = 5; // TODO TTT-3575 Remove after property in Org is implemented
    this.ORG_STORAGE_SPACE_VALUES = [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000];
    this.EMPTY_FIELD_VALUE = '-';
    this.GRID_EMPTY_VALUE = '-';
    this.GRID_MULTI_SORT_KEY = 'ctrl';
    this.DEFAULT_ADDRESS_FORMAT = 'CA';

    this.PASSWORD_FORMAT = '^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])([a-zA-Z0-9!?%&*_\$]+)';
    this.POSITIVE_VALUE_REGEX = '^[0-9]+([.,][0-9]+)?$';
    this.USER_PASSWORD_MIN_LENGTH = 8;
    this.USER_NAME_MAX_LENGTH = 100;
    this.ORG_ACRONYM_MAX_LENGTH = 15;
    this.CONTRACT_ID_MAX_LENGTH = 100;

    this.NAME_MAX_LENGTH = 50;
    this.FIELD_MAX_LENGTH = 500;
    this.TOOLTIP_MAX_LENGTH = 250;

    this.DEFAULT_MAX_LENGTH = 5000;

    this.UNDO_REDO_LIMIT = 25;

    this.PLACEHOLDER_EMPTY_FOLDER = 'assets/images/placeholder/placeholder_folder_no_data.png';
    this.PLACEHOLDER_CAROUSEL = 'assets/images/placeholder/image_carousel_empty.png';
    this.PLACEHOLDER_MAP = 'assets/images/placeholder/image_map_empty.png';
    this.PLACEHOLDER_ORGANIZATION = 'assets/images/placeholder/placeholder_organization.png';
    this.PLACEHOLDER_PICTURE = 'assets/images/placeholder/placeholder_picture.png';
    this.PICTOGRAM_FOLDER_PATH = '/assets/images/pictogram/';
    this.PLACEHOLDER_PROFILE_PICTURE = '/assets/images/placeholder/placeholder_profile_picture.png';

    this.DPE_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

    this.PURPOSE_MAX_LENGTH = 200;
    this.UNIT_CRV_GTE = 0;

    this.EXPORT_CELLS_FORBIDDEN_VALUES = ['+', '-', '=', '@', '\n', '\t'];

    this.DEFAULT_WORKSPACE = '76c8cef6-6ec4-453b-9834-5116dd90241c';
    this.DEFAULT_DATAFLOW = '03942477-7413-4955-a6c1-aa29743a94dd';
    this.UUID_REGEX = '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$';
  }

  /**
   * Display a date with a localize format.
   * @param date the date to display
   * @param fieldType the type of the field (date or datetime)
   * @return formatted date or null if date is null or empty string.
   */
  public getFormattedDate(date: string, fieldType: FieldTypeEnum = FieldTypeEnum.DATE): string | null {
    if (!date) return null;

    switch (fieldType) {
      case FieldTypeEnum.DATE:
        return dayjs(date).format(this.DISPLAY_DATE_FORMAT);
      case FieldTypeEnum.DATETIME:
        return dayjs(date).format(this.DISPLAY_DATETIME_FORMAT);
      default:
        return null;
    }
  }

  /**
   * Transform the string to lower case, removing accent characters and other diacritics.
   * @param input Original string.
   * @return Transformed string.
   */
  public simplifyStringForSearch(input: string): string {
    return input.toLowerCase()
      .normalize('NFD')
      .replaceAll(/['"]/g, '')
      .replace(/\p{Diacritic}/gu, '');
  }
}
