import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldGroup
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { format } from '@app/shared/extra/decimal-format';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import dayjs, { Dayjs } from 'dayjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'replacement-value-field-builder',
  templateUrl: './simplified-replacement-value-field-builder.component.html',
  styleUrls: ['./simplified-replacement-value-field-builder.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class SimplifiedReplacementValueFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;
  public form: FormGroup<{
    replacementValue: FormGroup<{
      simplifiedUnitReplacementValue: FormControl<string>,
      simplifiedReplacementValueDate: FormControl<Dayjs>,
      simplifiedReplacementValue: FormControl<string>
    }>,
    floorArea: FormControl<string>
  }>;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );
  }

  public ngOnInit(): void {
    this.formStateService.setPath(['simplifiedReplacementValue'], {
      state: new ReplaySubject<FieldStateMode>(1),
      isSingleField: true,
      value: {},
      initialValue: {}
    });
    this.initForm();
  }

  /**
   * Checks the current value of a field and determines which state it should be next
   * based on if the computed value can be calculated.
   * @protected
   */
  protected getNextState(): void {
    const isFieldEmpty = (field): boolean => field === void 0 || field === '' || field === null;

    const floorArea = this.form.get('floorArea').value;
    const simplifiedUnitReplacementValue = this.getFieldGroupValue()['simplifiedUnitReplacementValue'];
    const isEmpty = isFieldEmpty(floorArea) || isFieldEmpty(simplifiedUnitReplacementValue);

    if (isEmpty) {
      this.empty();
    } else {
      this.read();
    }
  }

  /**
   * Initialize form values and validators.
   */
  public initForm(): void {
    const fieldsConfig = this.fieldGroup.fieldConfigs;
    const unitReplacementValueFieldConfig = fieldsConfig.find(fieldConfig => {
      return fieldConfig.fieldCode === 'simplifiedUnitReplacementValue';
    });
    const replacementValueDateFieldConfig = fieldsConfig.find(fieldConfig => {
      return fieldConfig.fieldCode === 'simplifiedReplacementValueDate';
    });
    const replacementValueFieldConfig = fieldsConfig.find(fieldConfig => {
      return fieldConfig.fieldCode === 'simplifiedReplacementValue';
    });
    const floorAreaFieldConfig = fieldsConfig.find(fieldConfig => {
      return fieldConfig.fieldCode === 'floorArea';
    });
    const date = dayjs(this.entity.properties['simplifiedReplacementValueDate'] ?? '');
    this.form = new FormGroup({
      replacementValue: new FormGroup({
        simplifiedUnitReplacementValue: new FormControl(
          this.entity.properties['simplifiedUnitReplacementValue'] ?? '',
          unitReplacementValueFieldConfig.field?.validators.map((validator) => {
            return this.validationService.getValidator(validator, this.entity);
          }),
        ),
        simplifiedReplacementValueDate: new FormControl(
          date.isValid() ? date : void 0,
          replacementValueDateFieldConfig.field?.validators.map((validator) => {
            return this.validationService.getValidator(validator, this.entity);
          }),
        ),
        simplifiedReplacementValue: new FormControl(
          this.entity.computedProperties['simplifiedReplacementValue'] ?? '',
          replacementValueFieldConfig.field?.validators.map((validator) => {
            return this.validationService.getValidator(validator, this.entity);
          }),
        )
      }),
      floorArea: new FormControl({
        value: this.entity.properties['floorArea'] ?? '',
        disabled: true}
      )
    });
    const completeReplacementValue = {
      simplifiedUnitReplacementValue: this.entity.properties['simplifiedUnitReplacementValue'],
      simplifiedReplacementValueDate: this.entity.properties['simplifiedReplacementValueDate'],
      simplifiedReplacementValue: this.entity.computedProperties['simplifiedReplacementValue'],
      floorArea: this.entity.properties['floorArea']
    };

    // JSON parse and stringify are used to perform a deep copy of the address
    this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(completeReplacementValue)));
    this.setFieldGroupValue(JSON.parse(JSON.stringify(completeReplacementValue)));
    this.getNextState();
  }

  /**
   * Set up hooks and listen for form state changes.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();

    this.getFieldGroupState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newMode) => {
        if (newMode === FieldStateMode.AFTER_SAVE) {
          this.refreshEntity();
          // update computed value in form
          this.form.get('replacementValue.simplifiedReplacementValue')
            .setValue(this.entity.computedProperties['simplifiedReplacementValue']?.replaceCommaDecimalSeparator());
          this.setFieldGroupInitialValue(JSON.parse(JSON.stringify(this.getFieldGroupValue())));
          this.getNextState();
        } else {
          this.currentMode = newMode;
        }
      });
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  public save(): void {
    const dateValue = this.form.get('replacementValue.simplifiedReplacementValueDate').value;
    const simplifiedUnitReplacementValue = this.form.get('replacementValue.simplifiedUnitReplacementValue').value;
    this.setFieldGroupValue({
      simplifiedUnitReplacementValue: simplifiedUnitReplacementValue ? format(simplifiedUnitReplacementValue) : simplifiedUnitReplacementValue,
      simplifiedReplacementValueDate: (dateValue) ? dayjs(dateValue).format(this.appConfig.DATE_FORMAT) : ''
    });
    super.save();
  }

  public cancel(): void {
    this.initForm();
  }

  public formatDate(date): string {
    return dayjs(date).format('ll');
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for a certain field for which an error has been raised by the corresponding ValidatorFn.
   * @param field Field's code.
   * @return List of FieldValidators.
   */
  public getErroredValidators(field: string): FieldValidator[] {
    const fieldConfig = this.fieldGroup.fieldConfigs.find(fieldConfig => fieldConfig.code === field);
    return fieldConfig?.field?.validators?.filter(validator => !!this.form.get(field).errors?.[validator.code])
      ?? [];
  }

  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }
}
