import { Pipe, PipeTransform } from '@angular/core';
import { CheckType } from '@app/core/model/entities/asset/check-type';

@Pipe({
  name: 'checkResultColour'
})
export class CheckResultColourPipe implements PipeTransform {

  public transform(input: string, args: { checkType: CheckType }): any {
    if (args.checkType && input) {
      if (args.checkType.values[input] === 'none') {
        return 'result-type-none';
      } else if (args.checkType.values[input] === 'info' || args.checkType.observation[input] === 'info') {
        return 'result-type-info';
      } else if (args.checkType.values[input] === 'warn') {
        return 'result-type-warning';
      } else if (args.checkType.values[input] === 'error' || args.checkType.observation[input] === 'error') {
        return 'result-type-danger';
      }
    }
  }

}
