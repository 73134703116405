<div class="mat-select-autocomplete-container"
     #container
     [attr.aria-labelledby]="formField?.getLabelId()"
     (focusin)="onFocusIn()"
     (focusout)="onFocusOut()">
  <input [id]="inputId"
         matInput
         appVisibilityObserver
         matAutocompleteOrigin
         #input
         #trigger="matAutocompleteTrigger"
         #origin="matAutocompleteOrigin"
         [formControl]="control"
         [matAutocomplete]="autocomplete"
         [matAutocompleteConnectedTo]="autocompleteOrigin || origin"
         (input)="onInput()"
         (visibilityChange)="onVisibilityChange($event)"
         [placeholder]="placeholder"
         [required]="required"
         [matAutocompleteDisabled]="control.disabled"/>
  <mat-autocomplete #autocomplete="matAutocomplete"
                    [classList]="['mat-mdc-select-autocomplete-panel']"
                    [displayWith]="displayWith"
                    (optionSelected)="optionSelected($event.option.value)">
    <mat-option *ngIf="!required && hasData" [value]="null">
      {{ valueFormatter(null) }}
    </mat-option>
    <mat-option *ngFor="let option of dataSourceInput$ | async" [value]="option">
      {{ valueFormatter(option) }}
    </mat-option>
  </mat-autocomplete>
</div>