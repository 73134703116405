import { AbstractBean } from '@app/core/model/abstract-bean';
import { User } from '@app/core/model/client/user';
import { Expose, Type } from 'class-transformer';

export class UserPreferences extends AbstractBean {

  public organizationId: string;
  public preferences: JSON;

  @Type(() => User)
  public user: User;

  @Expose()
  public toString(): string {
    return this.id;
  }
}
