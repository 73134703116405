export enum Dimensions {
  CB = 42, // checkbox
  XXS = 100,
  XS = 125,
  SM = 135,
  MD = 150,
  ML = 180,
  LG = 200,
  XL = 300,
  XXL = 500
}
