<div [hidden]="!(getFieldGroupInfo(['isDisplayed']) | async)" class="field-builder-container">

  @if (currentMode === FieldMode.SAVING) {
    <div class="field-single-container">
      <div class="saving-field-container">
        <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
          </div>
        </div>
      </div>
    </div>
  }

  @if (currentMode === FieldMode.EMPTY) {
    <div class="field-single-container">
      <div class="empty-field-container">
        <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
          class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-label fx-flex-100-33-percent">
            {{ fieldGroup.label }}
            @if (fieldGroup.tooltip) {
              <span [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
                <i class="mdi mdi-help-circle tooltip-icon"></i>
              </span>
            }
          </div>
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <div class="empty-value">
              @if (!fieldGroup.customOptions?.disabled) {
                @if (accessManager.hasAllNeededPermissions(permissionsForEdition)  && preconditionsForEdition) {
                  <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
                } @else {
                  {{ appConfig.EMPTY_FIELD_VALUE }}
                }
              } @else {
                <span>{{ fieldGroup.emptyLabel }}</span>
              }
              <ng-template #disabled>
                <span>{{ fieldGroup.emptyLabel }}</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <div [ngClass]="{'field-single-container': currentMode === FieldMode.EDIT || currentMode === FieldMode.READ}">
    <div [ngClass]="{'edit-field-container': currentMode === FieldMode.EDIT}">
      <ng-template fieldAnchor>
      </ng-template>
    </div>
  </div>
</div>
