import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ok-modal-dialog',
  template: `
    <div id="dialog-container" class="fx-layout-col" [cdkTrapFocus]="true">
      <mat-dialog-content class="fx-flex-auto fx-layout-col fx-justify-space-around fx-align-center">
        <i class="mdi lg" [ngClass]="iconName" [style.color]="colour"></i>
        <div class="fx-layout-col fx-justify-start fx-align-center">
          <h3 id="title" class="modal-title">{{ title }}</h3>
          <div id="message" class="modal-message">{{ message }}</div>
        </div>
        <div></div>
      </mat-dialog-content>
      <mat-dialog-actions class="center">
        <button mat-raised-button cdkFocusInitial matDialogClose="yes" color="accent" id="dialog-ok-button">{{ okText }}</button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: [
    'ok.modal-dialog.css',
  ]
})
export class OkModalDialogComponent extends HasStatusType {

  // Injected
  public title: string;
  public message: string;
  public okText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public translate: TranslateService) {
    super(data.type);
    this.title = data.dialogTitle;
    this.message = data.dialogMessage;

    this.okText = data.okText || this.translate.instant('BUTTON.OK');
  }


}
