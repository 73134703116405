import { Directive, OnInit } from '@angular/core';

@Directive()
export abstract class HasStatusType implements OnInit {

  // Icon type
  public type: string;
  public iconName: string;
  public colour: string;

  protected constructor(private statusType: string) {
    this.type = statusType;
  }

  public ngOnInit(): void {
    switch (this.type) {
      case 'success':
        this.iconName = 'mdi-check-circle';
        this.colour = '#00C853';
        break;
      case 'warning':
        this.iconName = 'mdi-alert-circle';
        this.colour = '#FFD600';
        break;
      case 'error':
        this.iconName = 'mdi-close-circle';
        this.colour = '#D32F2F';
        break;
      case 'info':
        this.iconName = 'mdi-information';
        this.colour = '#3F51B5';
        break;
      case 'mobile':
        this.iconName = 'mdi-cellphone-off';
        break;
      default:
        this.iconName = '';
        this.colour = 'black';
        break;
    }
  }
}
