import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum WorkActionActionTypes {
  FieldValueChange = 'workAction_change',
  FieldValueReset = 'workAction_reset',
  FieldValueRevert = 'workAction_revert'
}

export class WorkActionChange extends PayloadAction {
  public readonly type = WorkActionActionTypes.FieldValueChange;
}

export class WorkActionReset extends PayloadAction {
  public readonly type = WorkActionActionTypes.FieldValueReset;
}

export class WorkActionRevert implements Action {
  public readonly type = WorkActionActionTypes.FieldValueRevert;
}
