import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';

@Component({
  templateUrl: './organization-space-move-modal.component.html'
})
export class OrganizationSpaceMoveModalComponent extends HasStatusType {

  public moveSpaceForm: FormGroup<{ moveChildren: FormControl<boolean> }>;

  constructor(@Inject(MAT_DIALOG_DATA) data: { type: string },
              private fb: UntypedFormBuilder) {
    super(data.type);

    this.moveSpaceForm = this.fb.group({
      moveChildren: this.fb.control(null, Validators.required)
    });
  }

  /**
   * Get form data to use for moving a Space.
   * @return Move Space form data.
   */
  public getGeneratedObject(): { moveChildren: boolean } {
    return {
      moveChildren: this.moveSpaceForm.get('moveChildren').value
    };
  }
}
