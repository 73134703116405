<div class="dashboard-tool-panel-container">
  <div class="form-container fx-layout-col fx-justify-space-evenly fx-align-center">
    <form *ngIf="dashboards" class="fx-layout-col fx-justify-space-evenly fx-align-center" novalidate
          autocomplete="off">
      <div class="fx-layout-row fx-justify-space-around fx-align-center">
        <div class="field-label fx-flex-100-percent">
          {{ 'LABEL.APPLIED_DASHBOARD' | translate }}
        </div>
        <div class="btn-container">
          <button (click)="closeDashboardToolPanel()" [disableRipple]="true"
                  class="fx-layout-col fx-justify-stretch fx-align-center"
                  id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <mat-form-field appearance="outline" [formGroup]="dashboardForm">
          <mat-select formControlName="currentDashboard"
                      [id]="'input-current-dashboard'"
                      required>
            <mat-option *ngFor="let dashboard of dashboards"
                        [value]="dashboard">
              {{ dashboard.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="dashboardForm.get('currentDashboard').hasError('required')">
            {{ 'ERROR.FIELD_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div *ngIf="!dashboardForm.get('currentDashboard').value?.isDefault && hasPermissionForEdition()"
         class="fx-layout-col fx-justify-space-evenly fx-align-center fx-gap-10">
      <ng-container *ngIf="this.dashboardService.currentDashboard?.isModified">
        <div [id]="'dashboard_changed_label'">{{ 'LABEL.DASHBOARD_CHANGED' | translate }}</div>
        <button (click)="updateDashboard()" [id]="'dashboard-update-button'" color="accent"
                mat-raised-button>{{ 'BUTTON.UPDATE_DASHBOARD' | translate }}
        </button>
      </ng-container>
      <button (click)="deleteDashboard()" [id]="'dashboard-delete-button'"
              mat-stroked-button>{{ 'BUTTON.DELETE_DASHBOARD' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="hasPermissionForEdition()"
       class="button-container fx-layout-col fx-justify-space-evenly fx-align-center fx-gap-10">
    <button (click)="createDashboard()" [id]="'dashboard-save-button'"
            mat-stroked-button>{{ 'BUTTON.SAVE_DASHBOARD' | translate }}
    </button>
  </div>
</div>
