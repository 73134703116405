import { Directive, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { decimal } from './validator';

const DECIMAL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DecimalValidator),
  multi: true
};

@Directive({
  selector: '[decimal][formControlName],[decimal][formControl],[decimal][ngModel]',
  providers: [DECIMAL_VALIDATOR]
})
export class DecimalValidator implements Validator, OnInit, OnChanges {
  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = decimal;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'DECIMAL') {
        this.validator = decimal;
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
