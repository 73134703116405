import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';


@NgModule({
    declarations: [
        SpinnerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SpinnerComponent
    ]
})
export class SpinnerModule {
}
