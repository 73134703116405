import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Work } from '@app/core/model/entities/works/work';
import {
  WorkCreateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-create-modal/work-create-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './work-duplicate-modal.component.html'
})
export class WorkDuplicateModalComponent extends WorkCreateModalComponent {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  protected modalTitle: string;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { works: Work[] }) {
    super();
    this.modalTitle = data.works.length > 1 ? 'TITLE.DUPLICATE_WORKS' : 'TITLE.DUPLICATE_WORK';
    this.workForm.get('action').setValue(data.works[0].action);
    this.workForm.get('state').setValue(data.works[0].state);
  }
}
