import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AcceptInvitationComponent } from '@app/features/fullscreens/accept-invitation/accept-invitation.component';
import { RedirectComponent } from '@app/features/fullscreens/redirect/redirect.component';
import {
  GlobalBiReportsHeaderComponent
} from '@app/features/main/banner/global-bi-reports-header/global-bi-reports-header.component';
import { MainComponent } from '@app/features/main/main.component';
import { MyProfileComponent } from '@app/features/main/views/my-pages/my-profile/my-profile.component';
import {
  OrganizationsGlobalBIReportsComponent
} from '@app/features/main/views/organizations/global-bi-reports/organizations-global-bi-reports.component';
import { OrganizationsComponent } from '@app/features/main/views/organizations/organizations.component';
import authenticationGuard from '@services/guards/authentication.guard';
import authorizationGuard from '@services/guards/authorization.guard';
import cleanupGuard from '@services/guards/cleanup.guard';
import isTbGuard from '@services/guards/is-tb.guard';
import organizationGuard from '@services/guards/organization.guard';

export interface RouteData {
  headerComponent?: Type<any>;
  title?: string;
  // Close the sidenav, user can't interact with it
  hideSidenav?: boolean;
  hideHeader?: boolean;
  // Hide breadcrumb
  hideBreadcrumb?: boolean;
  // Close the sidenav on the page, but it is still openable
  closeSidenav?: boolean;
  permissions?: PermissionEnum[];
}

export const mainRoutes: Routes = [
  {
    path: '',
    canActivate: [authenticationGuard],
    component: RedirectComponent,
    pathMatch: 'full'
  },
  {
    path: 'join',
    component: AcceptInvitationComponent
  },
  {
    path: '',
    canActivate: [authenticationGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        canActivate: [isTbGuard, cleanupGuard],
        loadChildren: (): Promise<any> => import('./features/main/views/_tb/tb.module').then(module => module.TbModule)
      },
      {
        path: 'profile',
        component: MyProfileComponent,
        data: <RouteData>{
          title: 'TITLE.MY_PROFILE',
          hideSidenav: true
        }
      },
      {
        path: 'organizations',
        canActivate: [cleanupGuard],
        component: OrganizationsComponent,
        data: <RouteData>{
          title: 'TITLE.ORGANIZATIONS',
          hideHeader: true,
          hideSidenav: true
        }
      },
      {
        path: 'report/:name',
        canActivate: [authorizationGuard, cleanupGuard],
        component: OrganizationsGlobalBIReportsComponent,
        data: <RouteData>{
          permissions: [PermissionEnum.VIEW_BI_REPORT],
          headerComponent: GlobalBiReportsHeaderComponent,
          hideSidenav: true
        }
      },
      {
        path: 'organization/:oid',
        data: <RouteData>{
          breadCrumb: 'TITLE.HOME'
        },
        canActivate: [organizationGuard],
        loadChildren: (): Promise<any> => import('./features/main/views/management/management.module').then(module => module.ManagementModule)
      }
    ]
  }
];

export const errorRoutes: Routes = [
  {
    path: 'error',
    loadChildren: (): Promise<any> => import('./features/errors/error.module').then(module => module.ErrorRoutingModule)
  },
  {
    path: '**', redirectTo: '/error/missing', pathMatch: 'full'
  }
];

export const routes: Routes = [
  ...mainRoutes,
  ...errorRoutes
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
