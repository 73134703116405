import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Document } from '@app/core/model/entities/document/document';
import { Work } from '@app/core/model/entities/works/work';
import {
  AssetWorkCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/works/modals/asset-work-create-modal/asset-work-create-modal.component';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import {
  WorkCreateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-create-modal/work-create-modal.component';
import {
  WorkDuplicateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-duplicate-modal/work-duplicate-modal.component';
import {
  WorkUpdateProjectsModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-update-modal/work-update-projects-modal.component';
import { WorksService } from '@app/features/main/views/works/works.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class WorkModalService {

  constructor(private popupManager: PopupManager,
              private appConfig: AppConfig,
              private worksService: WorksService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Display a dialog for the user to fill data then create a new Work with the data.
   * @param asset If specified, Asset to link the new Work to.
   * @return Created Work.
   */
  public openCreateWorkDialog(asset?: Asset): Observable<Work> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? AssetWorkCreateModalComponent : WorkCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.WORK);

          if (dialogResponse === 'yes') {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.worksService.createWork(dialogRef.componentInstance.getGeneratedObject())
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog for the user to select an Asset and Spaces then copy a list of existing Works to the selected Asset.
   * @param works Works to duplicate.
   * @return Boolean of true if all works has been successfully created, false otherwise.
   */
  public openDuplicateWorksDialog(works: Work[]): Observable<Work[]> {
    const dialogRef = this.popupManager.showGenericPopup(
      WorkDuplicateModalComponent,
      PopupSize.MEDIUM,
      {works}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.DUPLICATE, EntityTypeEnum.WORK);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: works.map(work => work.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            const {assetId, spaceIds} = dialogRef.componentInstance.getGeneratedObject();
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.worksService.duplicateWorks(works, assetId, spaceIds)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog for the user to select a Project to add as a related project of the selected Works.
   * @param works Works to update.
   * @return An array of the updated works
   */
  public openAddProjectsToWorksDialog(works: Work[]): Observable<Work[]> {
    const dialogRef = this.popupManager.showGenericPopup(
      WorkUpdateProjectsModalComponent,
      PopupSize.MEDIUM,
      {works}
    );

    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.WORK);
          const workIds = works.map(work => work.id);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: workIds.toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.UPDATE
            });
            const projectId = dialogRef.componentInstance.getGeneratedObject();
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.worksService.addProjectToWorks(workIds, [projectId])
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Display a dialog asking the user to confirm Works deletion, then delete the Works if confirmed.
   * @param works Works to delete.
   * @return True if the Works have been successfully deleted, false if the user cancelled the operation.
   * @throws Any encountered error.
   */
  public openDeleteWorksDialog(works: Work[]): Observable<boolean> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(works.length === 1 ? 'TITLE.DELETE_WORK' : 'TITLE.DELETE_WORKS'),
      dialogMessage: this.translate.instant(works.length === 1 ?
        'LABEL.DELETE_WORK_AND_DOCUMENTS'
        : 'LABEL.DELETE_WORKS_AND_DOCUMENTS'),
      type: 'warning',
      okText: this.translate.instant('LABEL.DELETE')
    });
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const event = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.WORK);

          if (dialogResponse === 'yes') {
            event.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: works.map(work => work.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.DELETE
            });
            this.analyticsService.trackEvent(event);
            this.spinnerService.showSpinner();
            return this.worksService.deleteWorks(works)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            event.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(event);
            return of(false);
          }
        })
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to a Work.
   * @param workId ID of the Work to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadWorkDocumentsDialog(workId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      workId,
      EntityTypeEnum.WORK,
      DocumentTypeEnum.WORK_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm a Work's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteWorkDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }


  /**
   * Display a dialog for the User to upload pictures of a Work.
   * @param workId ID of the Work to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadWorkPicturesDialog(workId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      workId,
      EntityTypeEnum.WORK,
      DocumentTypeEnum.WORK_PICTURE,
      {
        allowedContentTypes: ['image/jpeg', 'image/png'],
        maxSize: this.appConfig.MAX_IMAGE_SIZE,
        title: 'TITLE.ADD_PICTURE'
      }
    );
  }

  /**
   * Display a dialog asking the User to confirm a Work's picture's deletion.
   * @param imageDocument Document representing the image to delete.
   * @return True if the image's Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteWorkPictureDialog(imageDocument: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(imageDocument, {
      dialogTitle: this.translate.instant('TITLE.DELETE_PICTURE'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_PICTURE'),
      okText: this.translate.instant('BUTTON.DELETE'),
      cancelText: this.translate.instant('BUTTON.CANCEL'),
      type: 'warning'
    });
  }
}
