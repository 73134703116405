import { AbstractBean } from '@app/core/model/abstract-bean';
import { FormNode } from '@app/core/model/other/field-config';
import { ColumnGroup } from '@app/core/model/other/grid-config';
import { Expose, Type } from 'class-transformer';

export class ImportTemplate extends AbstractBean implements FormNode {
  public code: string;

  @Expose({name: 'columnGroupsList'})
  @Type(() => ColumnGroup)
  public columnGroups: ColumnGroup[];

  @Expose({name: '__typename'})
  public graphqlTypename: string;

  public get label(): string {
    return `MENU.${this.entityType}_IMPORT_TEMPLATE`;
  }
}
