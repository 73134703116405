import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Document } from '@app/core/model/entities/document/document';
import {
  DocumentCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/documents/modals/document-create-modal/document-create-modal.component';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import {
  OrganizationDocumentCreateModalComponent
} from '@app/features/main/views/organization-documents/modals/organization-document-create-modal/organization-document-create-modal.component';
import {
  ProjectDocumentsCreateModalComponent
} from '@app/features/main/views/projects/project-sheet/documents/modals/document-create-modal/project-documents-create-modal.component';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import {
  DeleteArchiveDocumentModalComponent
} from '@app/shared/popup/suppress-document-modal/delete-archive-document-modal.component';
import { UploadFileModalComponent } from '@app/shared/popup/upload-file-modal/upload-file-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DocumentModalService {

  constructor(private appConfig: AppConfig,
              private popupManager: PopupManager,
              private appManager: AppManager,
              private documentsService: DocumentsService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Open a modal for the User to upload files and create Documents.
   * If the params are not specified, the document is related to the current organization by default.
   * @param entityId Id of the entity the new documents will be related to.
   * @param entityType Type of the entity the new documents will be related to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openAddDocumentsDialog(entityId?: string, entityType?: EntityTypeEnum): Observable<Document[]> {
    const dialogInput = this.getAddDocumentsDialogInput(entityType, entityId);

    const dialogRef = this.popupManager.showGenericPopup(
      dialogInput.component,
      PopupSize.LARGE,
      {
        uploadData: {
          entityId: dialogInput.entityId,
          entityType: dialogInput.entityType,
          organizationId: this.appManager.currentOrganization.id,
          documentType: dialogInput.documentType
        }
      }
    );
    return dialogRef.afterClosed()
      .pipe(
        tap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.DOCUMENT)
            .addProperties({
              [AnalyticsKeyEnum.DOCUMENT_TYPE]: dialogInput.documentType,
              [AnalyticsKeyEnum.DIALOG_ACTION]: dialogResponse === 'yes' ? ActionEnum.SAVE : ActionEnum.CANCEL
            });
          this.analyticsService.trackEvent(analyticsEvent);
        }),
        filter(dialogResponse => dialogResponse === 'yes'),
        switchMap(() => {
          const createDocumentsInput = dialogRef.componentInstance.getCreateDocumentsInput();
          this.spinnerService.showSpinner();
          return this.documentsService.createDocuments(
            createDocumentsInput,
            dialogInput.entityId,
            dialogInput.entityType
          )
            .pipe(finalize(() => this.spinnerService.hideSpinner()));
        })
      );
  }

  /**
   * Set the dialogInput corresponding to the document's entity type.
   * If the params are not specified, the document is related to the current organization by default.
   * @param entityType Type of the entity the new documents will be related to.
   * @param entityId Id of the entity the new documents will be related to.
   * @return Input with the id and type of entity related to the document, the type of document and the related DocumentsCreateModalComponent.
   * @protected
   */
  protected getAddDocumentsDialogInput(entityType: EntityTypeEnum, entityId?: string): AddDocumentsDialogInput {
    switch (entityType) {
      case EntityTypeEnum.ASSET:
        return <AddDocumentsDialogInput>{
          entityId: entityId,
          entityType: entityType,
          documentType: DocumentTypeEnum.ASSET_DOCUMENT,
          component: DocumentCreateModalComponent
        };
      case EntityTypeEnum.PROJECT:
        return <AddDocumentsDialogInput>{
          entityId: entityId,
          entityType: entityType,
          documentType: DocumentTypeEnum.PROJECT_DOCUMENT,
          component: ProjectDocumentsCreateModalComponent
        };
      default:
        // If no entityType is specified, document is considered as an ORGANIZATION_DOCUMENT by default
        return <AddDocumentsDialogInput>{
          entityId: this.appManager.currentOrganization.id,
          entityType: EntityTypeEnum.ORGANIZATION,
          documentType: DocumentTypeEnum.ORGANIZATION_DOCUMENT,
          component: OrganizationDocumentCreateModalComponent
        };
    }
  }

  /**
   * Open a dialog for the User to delete or archive the Documents. If the list of Documents contains at least one
   * active Document, the User is prompted with a choice to either delete or archive these Documents. Documents that are
   * already archived can only be deleted. After the User confirms the operation, the Documents are deleted or updated accordingly.
   * @param documents Documents to delete.
   * @param dialogData Dialog optional configuration.
   * @return An object with the following fields:
   *          - result True if the Documents were deleted or archived successfully, false if the operation was canceled by the User.
   *          - dialogResponse Action selected by the User.
   * @throws Any encountered errors.
   */
  public openDeleteDocumentsDialog(
    documents: Document[],
    dialogData = {}
  ): Observable<{ result: boolean, dialogResponse: string }> {
    const isOneDocument = documents.length === 1;
    const activeDocuments = documents.filter(document => document.documentState === DocumentStateEnum.ACTIVE);
    let dialogRef: MatDialogRef<any>;

    if (activeDocuments.length > 0) {
      dialogRef = this.popupManager.showGenericPopup(DeleteArchiveDocumentModalComponent, PopupSize.MEDIUM_SMALL, {
        dialogTitle: this.translate.instant(isOneDocument ? 'TITLE.ARCHIVE_DELETE_DOCUMENT' : 'TITLE.ARCHIVE_DELETE_DOCUMENTS'),
        dialogMessage: this.translate.instant(isOneDocument ? 'MESSAGE.ARCHIVE_DELETE_DOCUMENT' : 'MESSAGE.ARCHIVE_DELETE_DOCUMENTS'),
        okText: this.translate.instant('BUTTON.VALIDATE'),
        deleteText: this.translate.instant(isOneDocument ? 'MESSAGE.DELETE_DOCUMENT' : 'MESSAGE.DELETE_DOCUMENTS'),
        archiveText: this.translate.instant(isOneDocument ? 'MESSAGE.ARCHIVE_DOCUMENT' : 'MESSAGE.ARCHIVE_DOCUMENTS'),
        type: 'warning',
        ...dialogData
      });
    } else {
      dialogRef = this.popupManager.showOkCancelPopup({
        dialogTitle: this.translate.instant(isOneDocument ? 'TITLE.DELETE_DOCUMENT' : 'TITLE.DELETE_DOCUMENTS'),
        dialogMessage: this.translate.instant(isOneDocument ? 'MESSAGE.DELETE_DOCUMENT' : 'MESSAGE.DELETE_DOCUMENTS'),
        okText: this.translate.instant(isOneDocument ? 'BUTTON.DELETE_DOCUMENT' : 'BUTTON.DELETE_DOCUMENTS'),
        cancelText: this.translate.instant('BUTTON.CANCEL'),
        type: 'warning',
        ...dialogData
      });
    }
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.DOCUMENT);

          if (['yes', 'delete'].includes(dialogResponse)) {
            analyticsEvent.addProperties(
              {
                [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
                [AnalyticsKeyEnum.ENTITY_ID]: documents.map(selectedDocument => selectedDocument.id).toString()
              });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.documentsService.deleteOrganizationDocuments(documents)
              .pipe(
                map(result => {
                  return {result, dialogResponse: 'delete'};
                }),
                finalize(() => this.spinnerService.hideSpinner())
              );
          } else if (dialogResponse === 'archive') {
            analyticsEvent.addProperties(
              {
                [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.UPDATE,
                [AnalyticsKeyEnum.ENTITY_ID]: documents.map(selectedDocument => selectedDocument.id).toString()
              });
            this.analyticsService.trackEvent(analyticsEvent);
            return this.documentsService.archiveOrganizationDocuments(documents)
              .pipe(
                map(() => {
                  return {result: true, dialogResponse: 'archive'};
                }),
                finalize(() => this.spinnerService.hideSpinner())
              );
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of({result: false, dialogResponse});
          }
        })
      );
  }

  /**
   * Open a dialog for the User to upload files and create Documents related to an entity.
   * @param entityId ID of the entity the created Documents are related to.
   * @param entityType Type of the entity the Documents are related to.
   * @param documentType Type of Documents to create.
   * @param options Upload options.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadEntityDocumentsDialog(
    entityId: string,
    entityType: EntityTypeEnum,
    documentType: DocumentTypeEnum,
    options?: {
      allowedContentTypes?: string[],
      maxSize?: number,
      uploadSuccessfulText?: string,
      title?: string
    }
  ): Observable<Document[]> {
    const dialogRef = this.popupManager.showGenericPopup(UploadFileModalComponent, PopupSize.MEDIUM, {
      title: this.translate.instant(options?.title || 'TITLE.ADD_DOCUMENT'),
      doneButtonText: this.translate.instant('BUTTON.DONE'),
      cancelButtonText: this.translate.instant('BUTTON.CANCEL'),
      uploadData: {
        maxUploads: this.appConfig.MAX_UPLOADS_DOCUMENTS,
        entityId: entityId,
        entityType: entityType,
        organizationId: this.appManager.currentOrganization.id,
        allowedContentTypes: options?.allowedContentTypes,
        maxSize: options?.maxSize,
        documentType: documentType,
        error: 'ERROR.UPLOAD_FILE_NO_REPONSE'
      }
    });
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.DOCUMENT);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.DOCUMENT_TYPE]: documentType,
              [AnalyticsKeyEnum.ENTITY_ID]: entityId,
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            const createDocumentsInputs = dialogRef.componentInstance.getCreateDocumentsInput();
            this.spinnerService.showSpinner();
            return this.documentsService.createDocuments(createDocumentsInputs, entityId, entityType)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open a confirmation dialog for the User to delete a Document.
   * @param document Document to be deleted.
   * @param dialogData Optional dialog configuration.
   * @return True of the Document was successfully deleted or false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteEntityDocumentDialog(document: Document, dialogData = {}): Observable<boolean> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      type: 'warning',
      dialogTitle: this.translate.instant('TITLE.DELETE_DOCUMENT'),
      dialogMessage: this.translate.instant('LABEL.DELETE_DOCUMENT'),
      okText: this.translate.instant('BUTTON.DELETE'),
      ...dialogData
    });
    return dialogRef.afterClosed()
      .pipe(
        mergeMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.DOCUMENT)
            .addProperties({[AnalyticsKeyEnum.DOCUMENT_TYPE]: document.documentType});

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: document.id,
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.documentsService.deleteEntityDocument(document)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of(false);
          }
        }));
  }
}

interface AddDocumentsDialogInput {
  entityId: string;
  entityType: EntityTypeEnum;
  documentType: DocumentTypeEnum;
  component: ComponentType<any>;
}
