<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_CHECK' | translate}}</h1>
  <mat-divider></mat-divider>
  <mat-stepper orientation="horizontal" [linear]="true">
    <ng-template matStepperIcon="edit">
      <i class="mdi mdi-check"></i>
    </ng-template>

    <mat-step [label]="'LABEL.CHECK_TYPE' | translate" [stepControl]="checkTypeForm">
      <mat-dialog-content class="fx-flex-auto fx-flex-offset-5">
        <mat-grid-list [cols]="getColCount()" [rowHeight]="'85px'">
          @for (checkType of checkTypes | async; track checkType; let i = $index) {
            <mat-grid-tile (click)="selectType(checkType)"
              (keyup.enter)="selectType(checkType)"
              [id]="i"
              [ngClass]="checkType.code === checkTypeForm.get('checkType').value ? 'selected-type' : ''"
              class="grid-tile"
              tabindex="0">
              <div class="check-type-tile fx-justify-center fx-align-center">
                <img [alt]="fileIconPath" [src]="fileIconPath"/>
                <span class="check-type-label">{{ 'VALUE.' + checkType.code | uppercase | translate }}</span>
              </div>
            </mat-grid-tile>
          }
        </mat-grid-list>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button [id]="'check-type-cancel-button'" mat-stroked-button matDialogClose="cancel">
          {{ 'BUTTON.CANCEL' | translate }}
        </button>
        <button [disabled]="!checkTypeForm.valid"
          [id]="'check-type-next-button'"
          color="accent"
          mat-raised-button
          matStepperNext>
          {{ 'BUTTON.NEXT' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>

    <mat-step [label]="'LABEL.SPACES' | translate">
      <mat-dialog-content class="fx-flex-auto">
        <div class="fx-fill fx-layout-col">
          <ag-grid-angular (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions"
            [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
            class="ag-theme-material"
            id="space-grid"
            rowSelection="multiple"
            style="width: 100%; height: 300px">
          </ag-grid-angular>
          @if (checkSpaceForm.get('spaces').touched && checkSpaceForm.get('spaces').hasError('required')) {
            <mat-error
              >
              {{ 'ERROR.FIELD_REQUIRED'| translate}}
            </mat-error>
          }
          @if (checkSpaceForm.get('spaces').hasError('incoherentSpaces')) {
            <mat-error>
              {{'ERROR.SPACES_INCOHERENT' | translate}}
            </mat-error>
          }
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button [id]="'cancel-button'" mat-stroked-button matDialogClose="cancel">
          {{ 'BUTTON.CANCEL' | translate }}
        </button>
        <button [id]="'check-previous-button'" mat-stroked-button matStepperPrevious>
          {{ 'BUTTON.PREVIOUS' | translate }}
        </button>
        <button [disabled]="!checkSpaceForm.valid"
          [id]="'check-save-button'"
          color="accent"
          mat-raised-button
          matDialogClose="yes">
          {{ 'BUTTON.ADD' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
  </mat-stepper>
</div>
