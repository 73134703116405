import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface FooterItem {
  count: number;
  total: number;
  name: string;
  entityIds: number[];
}

@Injectable()
export class ModuleGridFooterService {
  public footerMapSubject: Subject<Map<string, FooterItem>> = new BehaviorSubject<Map<string, FooterItem>>(new Map<string, FooterItem>());
  public footerMap: Map<string, FooterItem> = new Map<string, FooterItem>();

  /**
   * Update the number of checked entities identified by the module code
   * and broadcast the change
   * @param {string} module code key for the map
   * @param {number} diff increase or decrease the checked count
   */
  public setFooter(module: string, diff: number): void {
    this.footerMap.get(module).count += diff;
    this.broadcastChange();
  }

  /**
   * Initialises the map of modules with the current count and selections of entities with the module
   * @param {FooterItem[]} items
   */
  public initFootersMap(items: FooterItem[]): void {
    items.forEach((item) => {
      this.footerMap.set(item.name, item);
    });
    this.broadcastChange();
  }

  /**
   * Emits the value change
   */
  public broadcastChange(): void {
    this.footerMapSubject.next(this.footerMap);
  }

  /**
   * Adds the entity id to the list of entities who will have the module
   * @param {string} module code to which we add the entity id
   * @param {number} entityId to add to the list of ids
   */
  public addEntity(module: string, entityId: number): void {
    const index = this.footerMap.get(module).entityIds.indexOf(entityId);
    if (index === -1) {
      this.footerMap.get(module).entityIds.push(entityId);
    }
  }

  /**
   * Removes the entity id from the list of entities who will have the module
   * @param {string} module code from which we remove the entity id
   * @param {number} entityId to remove from the list of ids
   */
  public removeEntity(module: string, entityId: number): void {
    const index = this.footerMap.get(module).entityIds.indexOf(entityId);
    if (index > -1) {
      this.footerMap.get(module).entityIds.splice(index, 1);
    }
  }
}
