import { Component } from '@angular/core';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { WorkModalService } from '@app/features/main/views/works/works-inventory/modals/work-modal.service';
import {
  WorksSidebarComponent
} from '@app/features/main/views/works/works-inventory/works-sidebar/works-sidebar.component';

@Component({
  selector: 'equipment-works-sidebar',
  templateUrl: './equipment-works-sidebar.component.html',
  styleUrls: ['./equipment-works-sidebar.component.scss'],
  providers: [WorkModalService, DocumentModalService]
})
export class EquipmentWorksSidebarComponent extends WorksSidebarComponent {
}
