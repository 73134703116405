@if (currentMode === FieldMode.READ) {
  <div class="read-field-container">
    <div [hidden]="!toggled"
      [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
      class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" (click)="onClickTooltip(tooltip)"
            [matTooltip]="fieldConfig.tooltip" matTooltipPosition="below">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
           [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition,
                        'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}" tabindex="0">
          <a (click)="navigateToAssetDashboard()">
            {{ getFieldValue() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
          </a>
          @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
            <span
              class="overlay-icon">
              @if (preconditionsForEdition) {
                <i
                class="mdi mdi-pencil selectable"></i>
              } @else {
                <i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i>
              }
            </span>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (currentMode === FieldMode.EDIT) {
  <div>
    <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
      <div class="field-label fx-flex-100-33-percent">
        {{ fieldConfig.label }}
        @if (fieldConfig.tooltip) {
          <span #tooltip="matTooltip" (click)="onClickTooltip(tooltip)"
            [matTooltip]="fieldConfig.tooltip">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        }
      </div>
      @if (preconditionsForEdition) {
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'LABEL.ASSET' | translate }}</mat-label>
            <mat-select-asset-autocomplete #inputField [disabled]="(asyncPreconditionsForEdition$ | async) === false"
              [entityType]="entity.entityType"
              [formControl]="$any(form.get('asset'))">
            </mat-select-asset-autocomplete>
            @if ((asyncPreconditionsForEdition$ | async) === false) {
              <mat-hint>
                {{ 'VALIDATION.WORK_HAS_RELATED_EQUIPMENT' | translate }}
              </mat-hint>
            }
          </mat-form-field>
          @if (isSingleField) {
            <div class="save-options">
              <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
                <i class="mdi mdi-close"></i>
              </button>
              <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                class="button save">
                <i class="mdi mdi-check"></i>
              </button>
            </div>
          }
        </div>
      } @else {
        <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
          <div
            [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition}"
            tabindex="0">
            <a (click)="navigateToAssetDashboard()">
              {{ getFieldValue() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
            </a>
            @if (accessManager.hasAllNeededPermissions(permissionsForEdition)) {
              <span class="overlay-icon">
                <i class="mdi mdi-pencil-off tooltip-icon"></i>
              </span>
            }
          </div>
        </div>
      }
    </div>
  </div>
}
