import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { UserGlobalBIReport } from '@app/core/model/client/user';
import { AccessManager } from '@services/managers/access.manager';

@Component({
  templateUrl: './organizations-global-bi-reports.component.html'
})
export class OrganizationsGlobalBIReportsComponent implements OnInit {

  public globalBIReport: UserGlobalBIReport;
  public EntityType = EntityTypeEnum;
  // TODO TTT-4022 Configure workspace by user for global report
  public workspaceId = inject(AppConfig).DEFAULT_WORKSPACE;

  constructor(private route: ActivatedRoute,
              private accessManager: AccessManager) {

  }

  /**
   * Set the global Power BI report according to its name (variable passed in url parameter).
   */
  public ngOnInit(): void {
    this.globalBIReport = this.accessManager.currentUser.globalBIReports?.find(globalReport =>
      globalReport.name === this.route.snapshot.paramMap.get('name')
    );
  }
}
