<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row fx-justify-start fx-align-baseline">
      <div class="sheet-title fx-flex-85-percent">
        <span>{{ project?.toString() }}</span>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeProjectSidePanel()" [disableRipple]="true"
            id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadProjectDocuments()"
        [class]="'mdi mdi-paperclip sm'"
        [id]="'upload-project-document-button'"
        [matTooltip]="'BUTTON.ADD_DOCUMENT' | translate"
        [permissions]="[Permission.EDIT_PROJECT, Permission.CREATE_DOCUMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="deleteProject()"
        [class]="'mdi mdi-delete sm'"
        [id]="'delete-project-button'"
        [matTooltip]="'BUTTON.DELETE' | translate"
        [permissions]="[Permission.DELETE_PROJECT]"
        [preconditions]="preconditionForDeletion">
      </restricted-icon-button>

      <span class="fx-flex"></span>

      <div class="project-sheet-link">
        <span (click)="navigateToProjectSheet()">{{ 'BUTTON.SHOW_PROJECT_SHEET' | translate }}</span>
      </div>
    </div>
  </div>
  <div cdk-scrollable class="sidebar-sheet fx-flex-grow">
    <div class="page-sidebar">
      <div class="form-container">
        @if (project) {
          <form-builder
            formId="project_sidebar"
            [displayHeader]="false"
            [eventsOrigin]="eventsOrigin"
            [permissionsForEdition]="permissionsForEdition">
          </form-builder>
        }
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{ 'LABEL.DOCUMENT' | translate }} </span>
        <restricted-text-button
          (onClick)="uploadProjectDocuments()"
          [id]="'projects-upload-document-button'"
          [permissions]="[Permission.EDIT_PROJECT, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{ 'BUTTON.ADD_DOCUMENT' | translate }}
        </restricted-text-button>
      </div>
      @for (document of project?.documents; track document) {
        <div class="document-content">
          @if (accessManager.hasAccess(Permission.EXPORT_DOCUMENT)) {
            <a (click)="fileService.downloadFile(document.id)"
              >
              <i class="mdi mdi-file-outline xs"></i>
              <span class="file-name-label">{{ document.name }}</span>
            </a>
          } @else {
            <span class="file-name-label">{{ document.name }}</span>
          }
          <span (click)="deleteProjectDocument(document)"
            [permission-lock]="[Permission.EDIT_PROJECT, Permission.DELETE_DOCUMENT]" class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
        </div>
      }
    </div>
  </div>
</div>
