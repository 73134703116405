import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Entity } from '@app/core/model/entities/entity';
import { Expose } from 'class-transformer';

export class VirtualTour extends Entity {
  public assetId: string;
  public url: string;
  public entity: EntityTypeEnum;

  @Expose()
  public toString(): string {
    return this.name;
  }

  /**
   * Whether the VirtualTour use AppSheet
   */
  public get useAppSheet(): boolean {
    return this.appSheetURL !== null;
  }

  /**
   * AppSheet URL
   */
  public get appSheetURL(): URL {
    return this.properties['appUrl'] ? new URL(this.properties['appUrl']) : null;
  }

  /**
   * The appSheet table name
   */
  public get tableName(): string {
    switch (this.entity) {
      case EntityTypeEnum.WORK:
        return 'BMA'; // FIXME If appsheet table names change, these values must change as well
      case EntityTypeEnum.EQUIPMENT:
        return 'Inventaire'; // See comment above
      default:
        console.error('Entity not supported');
        return '';
    }
  }

  /**
   * The appSheet entity ID code
   */
  public get entityIdCode(): string {
    switch (this.entity) {
      case EntityTypeEnum.WORK:
        return 'bma_id'; // FIXME If appsheet entity ids change, these values must change as well
      case EntityTypeEnum.EQUIPMENT:
        return 'inventaire_id';
      default:
        console.error('Entity not supported');
        return '';
    }
  }

  /**
   * The appSheet entity name
   */
  public get entityName(): string {
    switch (this.entity) {
      case EntityTypeEnum.WORK:
        return 'action'; // FIXME If appsheet entity names change, these values must change as well
      case EntityTypeEnum.EQUIPMENT:
        return 'nom_equipement';
      default:
        console.error('Entity not supported');
        return '';
    }
  }

  /**
   * The appSheet API URL
   */
  public get appAPI(): string {
    const appId = this.appSheetURL.pathname.split('/')[2];
    return 'https://api.appsheet.com/api/v2/apps/' + appId + '/tables/';
  }
}

export class AppSheetInput {
  public appUrl: string;
  public appKey: string;
  public buildingId: string;
}

export interface VirtualTourInput {
  name: string;
  url: string;
  entity: EntityTypeEnum | '';
  properties?: Record<string, any>;
}
