import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent {

  public message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackBarRef: MatSnackBarRef<NotificationPopupComponent>,
              private analyticsService: AnalyticsService,
              public translate: TranslateService) {
    if (data.newFeatureMessage) {
      this.message = data.newFeatureMessage;
    }
  }

  public dismiss(): void {
    this.snackBarRef.dismiss();
    this.analyticsService.trackNavigationEvent(EventOriginEnum.NOTIFICATION_POPUP, NavigateToEnum.CLOSE_NOTIFICATION);
  }

  public reloadPage(): void {
    window.location.reload();
  }

}
