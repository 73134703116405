<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_PROJECT_TO_WORKS' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="workForm">
      <div class="project-form-field">

        @if (assetIds.length > 0) {
          @if (projects.length > 0) {
            <mat-form-field appearance="outline">
              <input formControlName="project"
                [matAutocomplete]="projectAutocomplete"
                [id]="'input-works-project'"
                matInput
                required>
              <mat-autocomplete #projectAutocomplete="matAutocomplete">
                @for (project of projects; track project) {
                  <mat-option [value]="project">
                    {{ project }}
                  </mat-option>
                }
              </mat-autocomplete>
              @if (workForm.controls.project.hasError('required')) {
                <mat-error
                  class="small-font text-danger support-text">
                  {{ 'ERROR.FIELD_REQUIRED' | translate }}
                </mat-error>
              }
              @if (workForm.controls.project.hasError('notInArray')) {
                <mat-error
                  class="small-font text-danger support-text">
                  {{ 'ERROR.ENTITY_NOT_FOUND' | translate }}
                </mat-error>
              }
            </mat-form-field>
          } @else {
            <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
              <p id="no-related_project-message">
                {{ 'LABEL.NO_RELATED_PROJECTS' | translate }}
              </p>
            </div>
          }
        } @else {
          <div class="field-container fx-flex-100-66-percent-min-500">
            <p id="no-asset-selected-message">
              {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
            </p>
          </div>
        }

      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [id]="'update-work-cancel-button'" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [id]="'update-work-add-button'" [disabled]="workForm.invalid" color="accent"
      matDialogClose="yes">
      {{ 'BUTTON.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
