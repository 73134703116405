import { Component, inject } from '@angular/core';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Check, CheckInput } from '@app/core/model/entities/asset/check';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { Document } from '@app/core/model/entities/document/document';
import { ChecksService } from '@app/features/main/views/organization-checks/checks.service';
import { CheckModalService } from '@app/features/main/views/organization-checks/modals/check-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { AbstractSidebar } from '@app/shared/components/side-panel/abstract-sidebar';
import { CheckTypeService } from '@app/shared/services/check-type.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'check-sidebar',
  templateUrl: './check-sidebar.component.html',
  providers: [CheckModalService, DocumentModalService]
})
export class CheckSidebarComponent extends AbstractSidebar<Check> {

  public checkType: CheckType;
  public permissionsForEdition: string[] = [PermissionEnum.EDIT_CHECK];

  // Injection
  protected translate: TranslateService = inject(TranslateService);
  private checksService = inject(ChecksService);
  private checkTypeService = inject(CheckTypeService);
  private checkModalService = inject(CheckModalService);

  constructor() {
    super();
    this.entitiesUpdated$ = this.checksService.checkUpdated$;
    this.sidePanelToggle$ = this.checksService.sidePanelToggle$;
    this.afterLoadRequests.push(this.loadCheckType.bind(this), this.loadCheckDocuments.bind(this));
  }

  /**
   * Loads the Check CheckTypes.
   * @param check Check to load the CheckTypes for.
   * @return Observable<Document[]>.
   */
  private loadCheckType(check: Check): Observable<CheckType> {
    return this.checkTypeService.getCheckTypeByCode(check.type)
      .pipe(
        tap(checkType => this.checkType = checkType)
      );
  }

  /**
   * Loads the Check Documents.
   * @param check Check to load the Documents for.
   * @return Observable<Document[]>.
   */
  private loadCheckDocuments(check: Check): Observable<Document[]> {
    return this.checksService.loadCheckDocuments(check.id)
      .pipe(tap(documents => this.entity.documents = documents));
  }

  /**
   * API call to update the Check.
   * @param data The update input.
   * @returns The updated Check.
   */
  protected override updateEntity(data: Record<string, any>): Observable<Check> {
    Check.transformPropertiesForInput(data);
    return this.checksService.updateCheck(this.entity, data as CheckInput)
      .pipe(
        tap(updatedCheck => {
          updatedCheck.documents = this.entity.documents;
          this.entity = updatedCheck;
        })
      );
  }

  public closeSidePanel(): void {
    this.checksService.closeCheckSidePanel();
  }

  /**
   * Open a dialog the User to upload files to create Documents linked to the Check.
   */
  public uploadCheckDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.CHECK,
      this.entity.id
    );
    this.checkModalService.openUploadCheckDocumentsDialog(this.entity.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.entity.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Open a dialog for the User to fill information to renew the Check.
   */
  public renewCheck(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.RENEW_DIALOG,
      EntityTypeEnum.CHECK,
      this.entity.id
    );
    this.checkModalService.openRenewCheckDialog(this.entity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CHECK_RENEWED')));
  }

  /**
   * Open a dialog for the User to confirm the deletion of the Check.
   */
  public deleteCheck(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.CHECK,
      this.entity.id
    );
    this.checkModalService.openDeleteChecksDialog([this.entity])
      .pipe(
        takeUntil(this.destroy$),
        filter(success => success)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.CHECK_DELETED')));
  }

  /**
   * Display a confirmation dialog before deleting a Check's Document.
   * @param document Document to be deleted.
   */
  public deleteCheckDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.checkModalService.openDeleteCheckDocumentDialog(document)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documentIsDeleted => {
        if (documentIsDeleted) {
          this.entity.documents = this.entity.documents.filter(doc => doc.id !== document.id);
        }
      });
  }

  /**
   * Navigate to the Check's related Asset's sheet and open the checks tab.
   */
  public async navigateToAssetSheet(): Promise<void> {
    this.closeSidePanel();
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity.assetId
    );
    await this.checksService.navigateToAssetSheet(this.entity.assetId);
  }
}
