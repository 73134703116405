import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Contract } from '@app/core/model/entities/asset/contract';
import {
  OrganizationContractCreateModalComponent
} from '@app/features/main/views/organization-contracts/modal/organization-contract-create-modal/organization-contract-create-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';

@Component({
  templateUrl: './organization-contract-renew-modal.component.html'
})
export class OrganizationContractRenewModalComponent extends OrganizationContractCreateModalComponent {

  private contractToRenew: Contract;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { contractToRenew: Contract }) {
    super();
    this.contractToRenew = data.contractToRenew;
  }

  /**
   * Set the form's initial values, disable fields that can not be edited when renewing a Contract.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Set form value for Contract's provider and disable input
    this.contractForm.get('provider').setValue(this.contractToRenew.provider);
    this.contractForm.get('provider').disable();

    // Find Asset linked to the Contract and set form value
    this.contractForm.get('asset').setValue(
      new RelatedAsset(this.contractToRenew.computedProperties.asset.firstItem())
    );
  }

  /**
   * Return empty Observable. This override method does not fetch accessible Assets from the
   * API as it is not necessary in that case.
   * @return Empty Observable.
   * @protected
   */
  protected fetchAssets(): Observable<RelatedAsset[]> {
    return EMPTY;
  }
}
