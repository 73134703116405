import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class UserGridFooterService {
  public footerSubject: Subject<number> = new BehaviorSubject<number>(0);
  public footerValue: number;

  constructor() {
  }

  /**
   * Update the number of selected users in the grid and broadcasts the change
   * @param {number} value
   */
  public setFooter(value: number): void {
    this.footerValue = value;
    this.broadcastChange();
  }

  /**
   * Emits the value change
   */
  public broadcastChange(): void {
    this.footerSubject.next(this.footerValue);
  }
}
