export class ModuleCard {
  public static ASSET = new ModuleCard(
    'MODULE.ASSET',
    'ASSET',
    {
      namespace: 'mdi',
      source: 'mdi-city'
    },
    ['assets']
  );
  public static GRAPH = new ModuleCard(
    'MODULE.GRAPH',
    'GRAPH',
    {
      namespace: 'mdi',
      source: 'mdi-finance'
    },
    ['graph', 'view']
  );
  public static PROJECT = new ModuleCard(
    'MODULE.PROJECT',
    'PROJECT',
    {
      namespace: 'mdi',
      source: 'mdi-office-building-cog-outline'
    },
    ['projects', 'inventory']
  );
  public static DOCUMENT = new ModuleCard(
    'MODULE.DOCUMENT',
    'DOCUMENT',
    {
      namespace: 'mdi',
      source: 'mdi-file-document-outline'
    },
    ['documents', 'inventory']
  );
  public static CONFIG_ORG = new ModuleCard(
    'MODULE.CONFIGURATION',
    'ORGANIZATION',
    {
      namespace: 'mdi',
      source: 'mdi-cog'
    },
    ['configuration']
  );
  public static RIGHT = new ModuleCard(
    'MODULE.MANAGE_RIGHTS',
    'RIGHT',
    {
      namespace: 'mdi',
      source: 'mdi-shield-account'
    },
    ['clients']
  );
  public static CHECK = new ModuleCard(
    'MODULE.CHECK',
    'CHECK',
    {
      namespace: 'mdi',
      source: 'mdi-clipboard-check-outline'
    },
    ['checks']
  );
  public static CONTRACT = new ModuleCard(
    'MODULE.CONTRACT',
    'CONTRACT',
    {
      namespace: 'mdi',
      source: 'mdi-clipboard-text-outline'
    },
    ['contracts']
  );
  public static OCCUPANT = new ModuleCard(
    'MODULE.OCCUPANT',
    'OCCUPANT',
    {
      namespace: 'mdi',
      source: 'mdi-clipboard-account-outline'
    },
    ['occupants']
  );
  public static SPACE = new ModuleCard(
    'MODULE.SPACE',
    'SPACE',
    {
      namespace: 'mdi',
      source: 'mdi-domain'
    },
    ['spaces']
  );
  public static EQUIPMENT = new ModuleCard(
    'MODULE.EQUIPMENT',
    'EQUIPMENT',
    {
      namespace: 'mdi',
      source: 'mdi-wrench-cog-outline'
    }
  );
  public static WORK = new ModuleCard(
    'MODULE.WORK',
    'WORK',
    {
      namespace: 'mdi',
      source: 'mdi-hammer-wrench'
    },
    ['works']
  );
  public static VIRTUAL_TOUR = new ModuleCard(
    'MODULE.VIRTUAL_TOUR',
    'VIRTUAL_TOUR',
    {
      namespace: 'mdi',
      source: 'mdi-home-map-marker'
    },
    ['virtual-tours']
  );
  public static JMAP = new ModuleCard(
    'MODULE.JMAP',
    'JMAP',
    {
      namespace: 'mdi',
      source: 'mdi-map-legend'
    },
    ['jmap']
  );
  public static CRV = new ModuleCard(
    'MODULE.CRV',
    'CRV',
    {
      namespace: 'mdi',
      source: 'mdi-home-analytics'
    }
  );
  public static RISK = new ModuleCard(
    'MODULE.RISK',
    'RISK',
    {
      namespace: 'mdi',
      source: 'mdi-alert'
    }
  );
  public static BI_REPORT = new ModuleCard(
    'MODULE.BI_REPORT',
    'BI_REPORT',
    {
      namespace: 'mdi',
      source: 'mdi-file-chart'
    }
  );
  public static ASSET_AUDIT = new ModuleCard(
    'MODULE.ASSET_AUDIT',
    'ASSET_AUDIT',
    {
      namespace: 'mdi',
      source: 'mdi-tools'
    }
  );
  public static FILE_REPOSITORY = new ModuleCard(
    'MODULE.FILE_REPOSITORY',
    'FILE_REPOSITORY',
    {
      namespace: 'mdi',
      source: 'mdi-file-cloud-outline'
    },
    ['files']
  );
  public static SNAPSHOT = new ModuleCard(
    'MODULE.SNAPSHOT',
    'SNAPSHOT',
    {
      namespace: 'mdi',
      source: 'mdi-folder-multiple'
    }
  );
  public static RETOOL_APP = new ModuleCard(
    'MODULE.RETOOL_APP',
    'RETOOL_APP',
    {
      namespace: 'mdi',
      source: 'mdi-application-outline'
    }
  );

  private constructor(public name: string,
                      public code: string,
                      public icon: { namespace: string, source: string },
                      public link?: string[]) {
  }
}
