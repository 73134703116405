import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Project } from '@app/core/model/entities/project/project';
import { Work } from '@app/core/model/entities/works/work';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './work-update-projects-modal.component.html'
})
export class WorkUpdateProjectsModalComponent implements OnInit, OnDestroy, GeneratesObject {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  public workForm: FormGroup<{
    project: FormControl<Project>
  }>;

  // Data
  public assetIds: string[] = [];
  public projects: Project[] = [];
  protected destroy$ = new Subject<void>();

  // Injection
  protected fb = inject(FormBuilder);
  protected projectsService = inject(ProjectsService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { works: Work[] }) {
    this.assetIds = [...new Set(
      data.works
        .map(work => work.assetId)
        .filter(assetId => !!assetId)
    )];
  }

  /**
   * Fetch possible values for projects.
   */
  public ngOnInit(): void {
    this.workForm = this.fb.group({
      project: this.fb.control(undefined, Validators.required)
    });

    if (this.assetIds.length > 0) {
      this.projectsService.loadProjectsByAssets(this.assetIds)
        .subscribe(projects => {
          this.projects = projects;
          this.workForm.controls.project.addValidators(ExtraValidators.notInArray(projects));
        });
    }
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Data from the filled form.
   * @return Work data.
   */
  public getGeneratedObject(): string {
    return this.workForm.controls.project.value.id;
  }
}
