import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationModule, NgModule } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { stompServiceFactory } from '@app/core/stomp.config';
import { AuthService } from '@auth0/auth0-angular';
import { ChangeService } from '@services/change.service';
import { FileService } from '@services/file.service';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { ErrorManager } from '@services/managers/error.manager';
import { NotificationManager } from '@services/managers/notification.manager';
import { PopupManager } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { PowerBIService } from '@services/powerBI.service';
import { RetoolAppService } from '@services/retool-app.service';
import { ScriptService } from '@services/script.service';
import { SectionService } from '@services/section.service';
import { RxStompService } from '@stomp/ng2-stompjs';

@NgModule({
  declarations: [],
  imports: [
    ApplicationModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    // Managers
    AppManager,
    AccessManager,
    ErrorManager,
    PopupManager,
    SnackbarManager,
    NotificationManager,
    // Services
    GeneralService,
    ChangeService,
    AnalyticsService,
    FileService,
    SectionService,
    ScriptService,
    PowerBIService,
    RetoolAppService,
    {
      provide: AppConfig,
      useValue: new AppConfig()
    },
    {
      provide: RxStompService,
      useFactory: stompServiceFactory,
      deps: [AuthService]
    }
  ]
})
export class CoreModule {
}
