import { Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Lease, LeaseInput } from '@app/core/model/entities/asset/lease';
import { Occupant, OccupantInput } from '@app/core/model/entities/asset/occupant';
import { Document } from '@app/core/model/entities/document/document';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { LeaseModalService } from '@app/features/main/views/organization-occupants/modals/lease-modal.service';
import { OccupantsService } from '@app/features/main/views/organization-occupants/occupants.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { AbstractSidebar } from '@app/shared/components/side-panel/abstract-sidebar';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'lease-sidebar',
  templateUrl: './lease-sidebar.component.html',
  styleUrls: ['./lease-sidebar.component.scss'],
  providers: [LeaseModalService, DocumentModalService]
})
export class LeaseSidebarComponent extends AbstractSidebar<Lease> implements OnInit, OnDestroy {

  public occupant: Occupant;

  /**
   * The name of the occupant form (section template).
   */
  @Input() public occupantFormId: string;

  public permissionsForEdition: string[] = [PermissionEnum.EDIT_OCCUPANT];
  public readOnly: Subject<boolean> = new Subject<boolean>();
  @ViewChild('occupantPanel', {static: false}) public occupantPanel: MatExpansionPanel;
  @ViewChild('leasePanel', {static: false}) public leasePanel: MatExpansionPanel;

  // Injection
  protected translate: TranslateService = inject(TranslateService);
  private occupantsService = inject(OccupantsService);
  private leaseModalService = inject(LeaseModalService);

  constructor() {
    super();

    this.entitiesUpdated$ = this.occupantsService.leaseUpdated$;
    this.sidePanelToggle$ = this.occupantsService.sidePanelToggle$
      .pipe(switchMap(leaseData => {
        return this.fieldService.getField('name', EntityTypeEnum.OCCUPANT)
          .pipe(
            map(field => {
              this.validators = field.validators;
              return leaseData;
            })
          );
      }));
    this.afterLoadRequests.push(this.loadLeaseDocuments.bind(this));
    this.afterLoadActions.push(this.expandPanel.bind(this));
  }

  private loadLeaseDocuments(lease: Lease): Observable<Document[]> {
    return this.occupantsService.loadLeaseDocuments(lease.id)
      .pipe(
        tap(documents => {
          this.entity.documents = documents;
        }));
  }

  /**
   * Expand both Lease and Occupant side panel and refresh the occupant
   * reference with the selected Lease.
   * @param lease The selected Lease.
   */
  private expandPanel(lease: Lease): void {
    this.leasePanel.open();
    this.occupantPanel.open();
    this.occupant = lease.occupant;
  }

  /**
   * API call to update the Lease.
   * @param data The update input.
   * @returns The updated Lease.
   */
  protected override updateEntity(data: Record<string, any>): Observable<Lease> {
    Lease.transformPropertiesForInput(data);
    return this.occupantsService.updateLease(this.entity, data as LeaseInput)
      .pipe(
        tap(updatedLease => {
          updatedLease.documents = this.entity.documents;
          this.entity = updatedLease;
          this.occupant = updatedLease.occupant;
        }),
      );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // Update Occupant whenever a new value is set for a property
    this.formStateService.saved$
      .pipe(
        takeUntil(this.destroy$),
        filter(({root}) => root === 'occupant'),
        switchMap(({code, data}) => {
          Occupant.transformPropertiesForInput(data);
          return this.occupantsService.updateOccupant(this.occupant, data as OccupantInput)
            .pipe(
              tap(updatedOccupant => {
                this.occupant = updatedOccupant;

                // force computed fields to recalculate
                this.formStateService.getPath(['computed']).next();
                // release the SAVING state to the next state
                this.formStateService.getPath(['occupant', code, 'state']).next(FieldStateMode.AFTER_SAVE);
              }),
              // Resubscribe to the saved observable if an error occurred.
              catchError(() => {
                this.formStateService.getPath(['occupant', code, 'state']).next(FieldStateMode.ERROR);
                return this.formStateService.saved$;
              })
            );
        })
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.readOnly.complete();
  }

  /**
   * Close the side panel.
   * @param event UIEvent that caused the side panel to close.
   */
  public override closeSidePanel(event?: UIEvent): void {
    this.occupantsService.closeLeaseSidePanel();
    event?.stopImmediatePropagation();
  }

  /**
   * Open a dialog fot the User to renew the Lease.
   */
  public renewLease(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.RENEW_DIALOG,
      EntityTypeEnum.LEASE,
      this.entity.id
    );
    this.leaseModalService.openRenewLeaseDialog(this.entity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.LEASE_RENEWED')));
  }

  /**
   * Open a confirmation dialog before deleting the Lease.
   */
  public deleteLease(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.LEASE,
      this.entity.id
    );
    this.leaseModalService.openDeleteLeasesDialog([this.entity])
      .pipe(takeUntil(this.destroy$))
      .subscribe(success => {
        if (success) {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.LEASE_DELETED'));
        }
      });
  }

  /**
   * Open a dialog for the User to upload Documents related to a Lease.
   */
  public uploadLeaseDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.LEASE,
      this.entity.id
    );
    this.leaseModalService.openUploadLeaseDocumentsDialog(this.entity.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.entity.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Open a confirmation dialog before deleting a Lease's Document.
   * @param document Document to delete.
   */
  public deleteLeaseDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.leaseModalService.openDeleteLeaseDocumentDialog(document)
      .subscribe(documentIsDeleted => {
        if (documentIsDeleted) {
          this.entity.documents = this.entity.documents.filter(doc => doc.id !== document.id);
        }
      });
  }

  /**
   * Prevents the panel from collapsing when clicking on the Occupant's name for editing.
   * @param event MouseEvent that was raised.
   */
  public preventToggle(event: UIEvent): void {
    if (this.occupantPanel.expanded) {
      event.stopImmediatePropagation();
    }
  }

  /**
   * Make the form read only or not
   * @param bool
   */
  public makeReadOnly(bool: boolean): void {
    this.readOnly.next(bool);
  }

  /**
   * Navigate to the Lease's related Asset sheet's occupants tab.
   */
  public async navigateToAssetSheet(): Promise<void> {
    this.closeSidePanel();
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity.assetId
    );
    await this.occupantsService.navigateToAssetSheet(this.entity.assetId);
  }
}
