import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloCache } from '@apollo/client';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { environment } from '@env/environment';
import { ErrorManager } from '@services/managers/error.manager';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, takeWhile } from 'rxjs/operators';

@Injectable()
export class GeneralService {

  constructor(private apollo: Apollo,
              private websocketService: RxStompService,
              private errorManager: ErrorManager,
              private httpClient: HttpClient) {
  }

  /**
   * GraphQL executor for combined queries
   * @param QUERY - GraphQL query to execute
   * @param VARIABLES - GraphQL query variables
   * @param fetchPolicy - GraphQL query fetch policy, default is 'no-cache'. Make sure the entity is listed in the
   * apollo config when using cache, otherwise null will be returned.
   * @returns {Observable<any>}
   */
  public get(QUERY: DocumentNode,
             VARIABLES = {},
             fetchPolicy?: WatchQueryFetchPolicy): Observable<ApolloQueryResult<any>> {
    // FIXME remove of(null) if it's useless
    return of(null).pipe(
      switchMap(() => {
        return this.apollo.watchQuery({
          query: QUERY,
          variables: VARIABLES,
          fetchPolicy
        }).valueChanges.pipe(first());
      })
    );
  }

  /**
   * GraphQL executor for combined mutations
   * @param MUTATION - GraphQL mutation to execute
   * @param VARIABLES - GraphQL mutation variables
   * @param update - Function used to modify the Apollo cache after a successful mutation.
   * @returns {Observable<any>}
   */
  public set(MUTATION: DocumentNode,
             VARIABLES = {},
             update?: { (cache: ApolloCache<any>): void }): Observable<FetchResult<any>> {
    // FIXME remove of(null) if it's useless
    return of(null).pipe(
      switchMap(() => {
        return this.apollo.mutate({
          mutation: MUTATION,
          variables: VARIABLES,
          update
        }).pipe(first());
      }));
  }

  /**
   * GraphQL executor for combined subscriptions
   * @param SUBSCRIPTION
   * @param VARIABLES
   * @param context
   * @param headers
   * @returns {Observable<any>}
   */
  public subscribeTo(SUBSCRIPTION: string, VARIABLES = {}, context: string, headers = {}): Observable<any> {
    this.websocketService.publish({
      destination: environment.backend.websocket.requestChannel + '/' + context,
      body: JSON.stringify({
        query: SUBSCRIPTION,
        variables: VARIABLES
      }),
      headers: headers
    });

    return this.websocketService.watch(environment.backend.websocket.responseChannel + '/' + context)
      .pipe(
        map((val: Message) => {
          if (val.headers['flag']) {
            // If we get an error, send it to the errorManager
            if (val.headers['flag'] === 'ERROR_FLAG') {
              const error: { message: string } = JSON.parse(val.body);
              this.errorManager.handleQueryError(error, 'Error in ' + context);
              throw Error('API error: ' + error.message);
            }
            // Return flag as-is
            return val.headers['flag'];
          } else {
            // Parse the message received
            return JSON.parse(val.body);
          }
        }),
        takeWhile((data) => data !== 'COMPLETED_FLAG' && data !== 'ERROR_FLAG')
      );
  }

  /**
   * Send a GET request and get body as JSON
   * @param url endpoint
   * @param headers optional http headers
   * @return JSON body of response
   */
  public httpGetAsJson(url: string, headers?: HttpHeaders): Observable<JSON> {
    const options = {
      headers: headers,
      // Use as const to let TypeScript know that we really do mean to use a constant string type
      responseType: 'json' as const
    };
    return this.httpClient.get(url, options).pipe(map(body => {
      return JSON.parse(JSON.stringify(body));
    }), first());
  }

  /**
   * Send a POST request and get body as JSON
   * @param url endpoint
   * @param body
   * @param headers optional http headers
   * @return JSON body of response
   */
  public httpPostAsJson(url: string, body: Record<string, unknown>, headers?: HttpHeaders): Observable<JSON> {
    const options = {
      headers: headers,
      // Use as const to let TypeScript know that we really do mean to use a constant string type
      responseType: 'json' as const
    };
    return this.httpClient.post(url, JSON.stringify(body), options).pipe(map(res => {
      return JSON.parse(JSON.stringify(res));
    }), first());
  }

  /**
   * Send a GET request and get body as ArrayBuffer
   * @param url endpoint
   * @param headers optional http headers
   * @return ArrayBuffer body of response
   */
  public httpGetAsArrayBuffer(url: string, headers?: HttpHeaders): Observable<ArrayBuffer> {
    const options = {
      headers: headers,
      // ResponseType is a union of string.
      // Use as const to let TypeScript know that we really do mean to use a constant string type.
      responseType: 'arraybuffer' as const
    };
    return this.httpClient.get(url, options).pipe(first());
  }

  public httpGetAsBlob(url: string, headers?: HttpHeaders): Observable<HttpResponse<Blob>> {
    const options = {
      headers: headers,
      observe: 'response' as const,
      responseType: 'blob' as const
    };
    return this.httpClient.get(url, options).pipe(first());
  }
}
