import { Component, Input } from '@angular/core';
import { CharacteristicItem } from '@app/core/model/entities/asset/characteristic-item';

@Component({
  selector: 'characteristics-widget',
  templateUrl: './characteristics-widget.component.html',
  styleUrls: ['./characteristics-widget.component.scss']
})
export class CharacteristicsWidgetComponent {

  // Characteristics widget configuration
  @Input() public characteristicList: CharacteristicItem[];

  constructor() {
    this.characteristicList = [];
  }
}
