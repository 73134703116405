import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';
import dayjs from 'dayjs';

@Component({
  selector: 'date-time-cell-edit',
  templateUrl: './date-time-cell-edit.component.html',
  styleUrls: ['./date-time-cell-edit.component.scss']
})
export class DateTimeCellEditComponent extends AbstractCellEditor implements OnDestroy {

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * format date, if value is invalid,
   * @param value : string or number value represent a date
   * @param invalidValue : invalic value if date value is invalid
   * @return date value formatted
   */
  private formateDate(value: string | number, invalidValue: string | number): string | number {
    return dayjs(value || '').isValid() ? dayjs(value).format(this.appConfig.DATETIME_FORMAT) : invalidValue;
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
    // Input must be a dayjs compatible value
    this.control.setValue(this.formateDate(this.params.value, '') );
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   * @return String representation of a date
   */
  public getValue(): string {
    return this.formateDate(this.control.value, '').toString();
  }

  /**
   * End subscriptions
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update tooltip message in case of validation error
   * @param type Type of the validator in error
   * @param definition Definition of the validator in error
   */
  protected setTooltip(type: string, definition: string | number): void {
    this.errorTooltip = this.translateService.instant(
      `ERROR.FIELD_${type}`.toUpperCase(),
      {value: this.formateDate(definition, definition)}
    );
  }
}
