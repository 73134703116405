<div class="indicator fx-layout-col fx-justify-center fx-align-start" [ngClass]="icon">
  <div ref="eLabel" class="indicator-title">{{ 'LABEL.' + label | translate }}</div>
  <div ref="eValue" class="indicator-value">
    {{ totalValue | fieldFormatType: formatType : currency }}@if (suffix) {
    <span>{{ 'SUFFIX.' + suffix | uppercase | translate }}</span>
  }
</div>
@if (isAnyFilterPresent) {
  <div ref="eValue" class="indicator-value indicator-value-filtered">
    {{ filteredValue | fieldFormatType: formatType : currency }}@if (suffix) {
    <span>{{ 'SUFFIX.' + suffix | uppercase | translate }}</span>
  }
  ({{filteredValue - totalValue | fieldFormatType: formatType : currency }}@if (suffix) {
  <span>{{ 'SUFFIX.' + suffix | uppercase | translate }}</span>
  })
</div>
}
</div>
