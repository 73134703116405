import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { RelatedAsset } from '@app/core/model/entities/asset/asset';
import { IRelatedSpace, RelatedSpace, Space } from '@app/core/model/entities/asset/space';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue } from '@app/shared/extra/utils';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { GridStateService } from '@app/shared/services/grid-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { GridApi, GridOptions, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'spaces-field-builder',
  templateUrl: './spaces-field-builder.component.html',
  styleUrls: ['./spaces-field-builder.component.scss']
})
export class SpacesFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {
  public declare form: FormGroup<{ field: FormControl<(RelatedSpace | Space)[]> }>;
  public selectedSpaces: (RelatedSpace | Space)[] = [];
  public initialSpaces: RelatedSpace[];
  public widthAuto = true;
  public noAsset = true;
  public gridApi: GridApi<Space>;
  public gridOptions: GridOptions<Space>;
  public quickFilter: string;
  protected invalidRows: string[] = [];
  protected spaces = new ReplaySubject<Space[]>(1);

  /**
   * Key is space's id and value is space's display name
   * If there is no space, key is null and string is this.appConfig.EMPTY_FIELD_VALUE
   */
  public spacesMap = new Map<string, string>();

  @ViewChild('gridContainer') public gridContainer: ElementRef;

  protected cancel$ = new Subject<void>();

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              private deviceDetectorService: DeviceDetectorService,
              private spacesService: SpacesService,
              private columnBuilder: ColumnBuilder,
              private gridStateService: GridStateService,
              private gridOptionsService: GridOptionsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );

    this.selectedSpaces = this.fieldInitValue;

    // Add additional options to the gridOption
    const extraSpaceGridOptions = <GridOptions>{
      ...this.gridOptionsService.staticSelectSpacesGridOptions,
      suppressContextMenu: false,
      multiSortKey: this.appConfig.GRID_MULTI_SORT_KEY,
      domLayout: 'autoHeight',
      getRowId: (params) => params.data.id,
      animateRows: true,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">' + this.translate.instant('LABEL.LOADING') + '</span>',
      localeText: this.columnBuilder.localeTextCommon(),
      rowClassRules: {
        'invalid-row': (params): boolean => this.invalidRows.includes(params.data.id)
      },
      getMainMenuItems: (params) => params.defaultItems,
      getRowHeight: () => this.gridOptionsService.staticGridOptions.rowHeight,
      onRowClicked: this.onRowSelected.bind(this),
      onRowSelected: this.onRowSelected.bind(this),
      onGridSizeChanged: () => this.gridApi.sizeColumnsToFit()
    };
    this.gridOptions = {
      ...this.gridOptionsService.staticGridOptions,
      ...extraSpaceGridOptions
    };
  }

  public ngOnInit(): void {
    this.initialSpaces = this.fieldInitValue?.map((space: IRelatedSpace) => new RelatedSpace(space));
    this.form = new FormGroup<{ field: FormControl<(RelatedSpace | Space)[]> }>({
      field: new FormControl<RelatedSpace[]>(
        this.initialSpaces,
        this.computeValidators(),
        this.computeAsyncValidators()
      )
    });

    // Load spaces
    if (this.getFieldGroupInfo(['asset', 'valueChanges'])) {
      // If the field group contains an Asset field whose value can change, load Spaces whenever a new Asset is selected
      this.getFieldGroupInfo(['asset', 'valueChanges'])
        .pipe(
          takeUntil(this.destroy$),
          switchMap(({ asset }: { asset: RelatedAsset }) => {
            if (asset) {
              this.noAsset = false;
              return this.spacesService.loadSpaces(asset.id);
            }
            this.noAsset = true;
            return of([]);
          })
        )
        .subscribe(this.spaces);
    } else {
      // Otherwise, load the entity's related Asset's Spaces only once, if applicable.
      const assetId = getValue(this.entity, ['assetId']);
      if (assetId) {
        this.noAsset = false;
        this.spacesService.loadSpaces(assetId).subscribe(this.spaces);
      } else {
        // FIXME when building the field for the first time, the entity is the Asset or Work instead of the expected related entity (Lease, Check...)
        this.noAsset = true;
        this.spaces.next([]);
      }
    }
    this.setSelectedEntities();
  }

  /**
   * This method will set selectable entities in the grid.
   * @param entities A list of Entities
   */
  protected setGridData(entities: Entity[]): void {
    this.gridApi.setRowData(entities as Space[]);
  }

  /**
   * This method will set current selected entities.
   */
  protected setSelectedEntities(): void {
    this.selectedSpaces = this.initialSpaces;
    this.setFieldValue(this.selectedSpaces);
    this.setFieldInitialValue(this.selectedSpaces);
    this.getNextState();
    this.selectedSpaces?.forEach(space => this.spacesMap.set(space.id, space.toString()));
    if (this.spacesMap.size === 0) {
      this.spacesMap.set(null, this.appConfig.EMPTY_FIELD_VALUE);
    }
  }

  public ngAfterViewInit(): void {
    this.setupHooks();
    this.form.get('field').valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(50),
        takeUntil(this.destroy$)
      )
      .subscribe((spaces) => this.setFieldValue(spaces?.map(space => space.id)));
  }

  /**
   * Event thrown by the grid once it is loaded
   * @param params
   */
  public onGridReady(params: GridReadyEvent): void {
    this.invalidRows = [];
    this.gridApi = params.api;
    this.gridOptions.columnApi.applyColumnState({ state: [{ colId: 'ag-Grid-AutoColumn', sort: 'asc' }] });
    if (this.deviceDetectorService.isMobile()) {
      this.gridOptions.columnApi.applyColumnState({
        state: [
          { colId: 'ag-Grid-AutoColumn' },
          { colId: 'identifier' }
        ]
      });
    }
    this.spaces.pipe(takeUntil(this.cancel$))
      .subscribe(spaces => {
        this.form.get('field').setValue([]);
        this.setGridData(spaces);
        this.gridApi.sizeColumnsToFit();
        this.selectedSpaces.forEach(space => {
          const rowNode = this.gridApi.getRowNode(space.id);
          if (rowNode?.data?.parentPath) {
            rowNode.data.parentPath.forEach((parentId: string) => {
              this.gridApi.getRowNode(parentId).setExpanded(true);
            });
          }
          rowNode?.setSelected(true);
        });
      });

    // Focus and scroll to field
    this.gridContainer.nativeElement.focus();
    this.gridContainer.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center' });
  }

  public onClickSave(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      super.onClickSave();
    } else {
      this.cancel();
    }
  }

  /**
   * Event thrown by the grid when a row is selected or deselected.
   * @param event RowSelectedEvent
   */
  public onRowSelected(event: RowSelectedEvent): void {
    this.selectedSpaces = this.gridApi.getSelectedRows().map(space => space.toRelatedSpace());
    this.form.controls.field.setValue(this.selectedSpaces);
    this.form.controls.field.markAsDirty();

    const nodesToRefresh = [event.node];

    // Check if row is invalid and update invalid rows
    if (event.node.isSelected() && (this.hasParentSelected(event.data) || this.hasChildrenSelected(event.data))) {
      this.invalidRows.push(event.data.id);
    } else if (!event.node.isSelected()) {
      this.invalidRows = this.invalidRows.filter(row => {
        const rowNode = this.gridApi.getRowNode(row);
        nodesToRefresh.push(rowNode);
        return row !== event.data.id
          && (this.hasParentSelected(rowNode.data) || this.hasChildrenSelected(rowNode.data));
      });
    }

    // Refresh rows
    this.gridApi.redrawRows({ rowNodes: nodesToRefresh });
  }

  /**
   * Restore field's value to original Spaces.
   */
  public cancel(): void {
    super.cancel();
    this.selectedSpaces = this.initialSpaces;
    this.cancel$.next();
  }

  /**
   * Checks if a Space has ancestors that are selected in the grid.
   * @param space Space to check.
   * @return true if at least one ancestor is selected, false otherwise.
   * @private
   */
  private hasParentSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => space.parentPath.includes(selectedRow.id));
  }

  /**
   * Checks if a Space has children that are selected in the grid.
   * @param space Space to check.
   * @return true is at least one child is selected, false otherwise.
   * @private
   */
  private hasChildrenSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => selectedRow.parentPath.includes(space.id));
  }

  /**
   * Navigate to the Space sheet of the related Asset
   */
  public async navigateToAssetSpaceSheet(): Promise<void> {
    if (this.noAsset) return;

    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      this.entity['assetId']
    );
    await this.spacesService.navigateToAssetSheet(this.entity['assetId']);
  }

  /**
   * Open Space sheet of the related Asset and specify which row to select in datagrid
   * @param spaceId
   */
  public onSpaceFieldClick(spaceId: string): void {
    if (spaceId == null) return;

    this.navigateToAssetSpaceSheet().then(() => {
      this.gridStateService.setRowToSelect(spaceId);
    });
  }
}
