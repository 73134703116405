import { inject, Injectable } from '@angular/core';
import { FormNode } from '@app/core/model/other/field-config';
import { ColumnGroupInput } from '@app/core/model/other/grid-config';
import { ImportTemplate } from '@app/core/model/other/import-template';
import {
  DropLocation
} from '@app/features/main/views/management/organization/field-configuration/form-config-datagrid/form-config-datagrid.component';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { IRowNode } from 'ag-grid-community';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ImportTemplateService {

  private readonly importTemplateGraphQlFragment: DocumentNode;

  private appManager: AppManager = inject(AppManager);
  private generalService: GeneralService = inject(GeneralService);

  constructor() {
    this.importTemplateGraphQlFragment = gql`
      fragment ImportTemplateInfo on ImportTemplate {
        id
        entityType
        columnGroupsList {
          code
          order
          label
          columnsList {
            fieldCode
            order
            customOptions
            field {
              code
              label
              entityType
              fieldType
              checkType
              computed
              parentPathList
              fieldValuesList
              validatorsList {
                conditionsList {
                  field
                  operator
                  value
                }
                definition
                type
              }
            }
            conditionsToViewList {
              field
              operator
              value
            }
            conditionsToEditList {
              field
              operator
              value
            }
          }
        }
      }
    `;
  }

  /**
   * Creates a column group given an input containing information about the column group itself
   * such as the importTemplate it belongs to, its label and the order in which it needs to appear in the grid
   * @param importTemplateId Id of the importTemplate to add the column group to
   * @param columnGroupInput Input containing information about the column group
   * @return Observable containing the newly updated importTemplate with its new column group
   */
  public createColumnGroup(importTemplateId: string, columnGroupInput: ColumnGroupInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation CreateColumnGroup($createColumnGroupInput: ColumnGroupInput!, $importTemplateId: String!, $organizationId: String!) {
        createImportTemplateColumnGroup(importTemplateId: $importTemplateId, createColumnGroupInput: $createColumnGroupInput, organizationId: $organizationId){
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;
    const MUTATION_VAR = {
      importTemplateId: importTemplateId,
      createColumnGroupInput: columnGroupInput,
      organizationId: this.appManager.currentOrganization.id
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(ImportTemplate, response.data['createImportTemplateColumnGroup'] as ImportTemplate)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Updates a importTemplate given an input of a few properties
   * @param importTemplateId Id of the importTemplate to update
   * @param columnGroupInput Input containing a new label or new order
   * @return Observable containing the newly updated importTemplate with its updated column group
   */
  public updateColumnGroup(importTemplateId: string, columnGroupInput: ColumnGroupInput): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation UpdateColumnGroup($importTemplateId: String!, $updateColumnGroupInput: ColumnGroupInput!, $organizationId: String!) {
        updateImportTemplateColumnGroup(importTemplateId: $importTemplateId, updateColumnGroupInput: $updateColumnGroupInput, organizationId: $organizationId) {
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;
    const MUTATION_VAR = {
      importTemplateId: importTemplateId,
      updateColumnGroupInput: columnGroupInput,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(ImportTemplate, response.data['updateImportTemplateColumnGroup'] as ImportTemplate)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Adds an existing field of the organization to the given grid config
   * @param importTemplateId The ImportTemplate in which to add a field
   * @param columnGroupCode The column group in which to specifically add the field
   * @param fieldCode The code of the field to add
   * @param order The order in which the field should appear in the Template
   * @return Observable containing the updated importTemplate
   */
  public addFieldToImportTemplate(importTemplateId: string, columnGroupCode: string, fieldCode: string, order: number): Observable<FormNode[]> {
    const COMBINED_MUTATION = gql`
      mutation AddExistingFieldToImportTemplate($organizationId: String!, $importTemplateId: String!, $columnGroupCode: String!, $fieldCode: String!, $order: Int!) {
        addExistingFieldToImportTemplate(importTemplateId: $importTemplateId, organizationId: $organizationId, columnGroupCode: $columnGroupCode, fieldCode: $fieldCode, order: $order) {
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      importTemplateId,
      columnGroupCode,
      fieldCode,
      order
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map((response) => plainToInstance(ImportTemplate, response.data['addExistingFieldToImportTemplate'] as ImportTemplate)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Get all ImportTemplates of an organization.
   * The importTemplates are then grouped by their form codes and flattened to calculate the data path for Ag-Grid tree
   * @return Observable that emits a list of FormNodes.
   */
  public getAllFlattenedFormNodes(): Observable<FormNode[]> {
    const COMBINED_QUERY = gql`
      query AllImportTemplates($orgId: String!) {
        importTemplates(organizationId: $orgId) {
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;
    const QUERY_VAR = {
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(ImportTemplate, response.data['importTemplates'] as ImportTemplate[])),
        map(importTemplates => this.flattenChildrenRecursively(importTemplates))
      );
  }

  /**
   * Deletes a form node from the grid config datagrid
   * @param nodeToDelete the Ag-Grid row node to remove along with its children
   * @return Observable containing response if the operation was successful
   */
  public deleteGridFormNodes(nodeToDelete: IRowNode): Observable<FormNode[]> {

    const COMBINED_MUTATION = gql`
      mutation DeleteFormNodes($nodePath: [String]!, $organizationId: String!) {
        deleteImportTemplateFormNodes(organizationId: $organizationId, nodePath: $nodePath) {
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;

    const MUTATION_VAR = {
      nodePath: nodeToDelete.data.hierarchy,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(ImportTemplate, response.data['deleteImportTemplateFormNodes'] as ImportTemplate)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Moves a certain tree node from a source path to a target path
   * @param source the source node to be moved
   * @param target the target node to which the source node is moved
   * @param placement indication of the placement of the node after it is reordered (defaults to after)
   * @return Observable containing the ImportTemplate after its columns were reordered
   */
  public reorderGridFormNodes(source: IRowNode,
                              target: IRowNode,
                              placement: DropLocation = 'after'): Observable<FormNode[]> {
    const COMBINED_QUERY = gql`
      mutation ReorderFormNodes($orgId: String!, $source: [String]!, $target: [String]!, $placement: Int!) {
        reorderImportTemplateFormNodes(organizationId: $orgId, sourceNodePath: $source, targetNodePath: $target, placement: $placement) {
          ...ImportTemplateInfo
        }
      }${this.importTemplateGraphQlFragment}
    `;
    const QUERY_VAR = {
      orgId: this.appManager.currentOrganization.id,
      placement: placement == 'before' ? -1 : +1,
      source: source.data.hierarchy,
      target: target.data.hierarchy
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(ImportTemplate, response.data['reorderImportTemplateFormNodes'] as ImportTemplate)),
        map(forms => this.flattenChildrenRecursively([forms]))
      );
  }

  /**
   * Flattens the children of a form node recursively and creates a list of FormNodes
   * containing the data path from the root FormNode to all children
   * @param data the form nodes to flatten
   * @param parent the parent node, if present
   * @param childHierarchy the hierarchy data structure to build
   * @return a list of FormNodes (importTemplates, columnGroups, columns) containing the hierarchy path from the root FormNode to all children
   */
  private flattenChildrenRecursively(data?: FormNode[], parent = null, childHierarchy = null): FormNode[] {
    let newData = [];
    if (!data) return newData; // Abort early if data is undefined

    data.forEach((initialRow) => {
      let parentHierarchy = [];
      initialRow.hierarchy = parentHierarchy;

      if (parent) {
        initialRow['parent'] = parent;
        parentHierarchy = [...childHierarchy];
        initialRow.hierarchy = parentHierarchy;
      }

      newData.push(initialRow);
      switch (initialRow['graphqlTypename']) {
        case 'ImportTemplate':
          parentHierarchy.push(initialRow['entityType']);
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['columnGroups'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        case 'ColumnGroup':
          parentHierarchy.push(initialRow['code']);
          newData = [
            ...newData,
            ...this.flattenChildrenRecursively(
              initialRow['columns'],
              initialRow,
              parentHierarchy
            ),
          ];
          break;
        case 'FieldConfig':
          parentHierarchy.push(initialRow['fieldCode']);
          break;
        default:
          break;
      }
    });
    return newData;
  }

}
