import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { AppConfig } from '@app/core/app.config';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { format } from '@app/shared/extra/decimal-format';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';

@Component({
  selector: 'text-area-cell-edit',
  templateUrl: './number-cell-edit.component.html',
  styleUrls: ['./number-cell-edit.component.scss']
})
export class NumberCellEditComponent extends AbstractCellEditor {

  public suffix?: string;

  @ViewChild(MatInput, {static: false}) public input: MatInput;

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {
    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  /**
   * Validates a string against a given pattern
   *
   * @param input The string to validate
   * @param pattern The regular expression pattern for validation
   * @return True if the string is valid, false otherwise
   */
  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);
    this.suffix = this.params.suffix;
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    this.input.focus();
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): any {
    return this.control.value ? Number(format(this.control.value)) : this.control.value;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
