import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset } from '@app/core/model/entities/asset/asset';
import {
  WorkCreateModalComponent
} from '@app/features/main/views/works/works-inventory/modals/work-create-modal/work-create-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  templateUrl: './asset-work-create-modal.component.html'
})
export class AssetWorkCreateModalComponent extends WorkCreateModalComponent {

  private asset: Asset;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { asset: Asset }) {
    super();
    this.asset = data.asset;
  }

  /**
   * Set initial Asset value so Spaces can be loaded.
   */
  public ngOnInit(): void {
    super.ngOnInit();
    this.workForm.get('asset').setValue(this.asset.toRelatedAsset());
  }

  /**
   * Single Asset that the new Work will be linked to. This override method does not fetch accessible Assets from the
   * API as it is not necessary in that case.
   * @return Observable of a single Asset.
   * @protected
   */
  protected fetchAssets(): Observable<Asset[]> {
    return of([this.asset]);
  }
}
