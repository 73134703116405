import { formatNumber, formatPercent } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EngineeringNotationEnum } from '@app/core/enums/engineering-notation.enum';
import { FormatType } from '@app/core/model/other/field-config';
import CustomDuration from '@app/shared/extra/custom-duration';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

@Pipe({name: 'fieldFormatType'})
export class FieldFormatTypePipe implements PipeTransform {
  public transform(input: any, formatType?: FormatType): any {
    const locale = navigator.language.match(/en|fr/) ? navigator.language : 'fr';
    if (input !== void 0 && input !== '') {
      switch (formatType) {
        case 'integer':
          return formatNumber(input, locale, '1.0-0');
        case 'percent':
          return formatPercent(input, locale, '1.1-1');
        case 'numeric':
          return formatNumber(input, locale, '1.0-1');
        case 'precise_number':
          return formatNumber(input, locale, '1.0-6');
        case 'duration':
          dayjs.extend(duration);
          return CustomDuration.humanize(dayjs.duration(input, "d").locale(locale));
        case 'scientific':
          //Return scientific notation using E notation, no official local pattern
          return input.toExponential(1);
        case 'engineering':
          return this.formatEngineering(input, locale);
        default:
          return input;
      }
    } else {
      return '';
    }
  }

  /**
   * Format the value into engineering notation, with a maximum of 2 decimals digits.
   * Fallback to exponential notation if the value is out of range [0;10¹⁶[.
   * See https://en.wikipedia.org/wiki/Engineering_notation for details.
   * @param value
   * @param locale
   */
  private formatEngineering(value: any, locale: string): string {
    const exponential = value.toExponential(4);
    if (exponential.includes('e-')) return exponential;
    return exponential.split('e+').reduce((base, exponent) => {
      const quotient = Math.floor(Number(exponent) / 3);
      const remainder = Number(exponent) % 3;
      return formatNumber(base * (10 ** remainder), locale, '1.0-2') + ' ' + FieldFormatTypePipe.exponentLabel(quotient);
    });
  }

  /**
   * Get the label corresponding to the exponent value.
   * Fallback to exponential if not found.
   * @param exponent
   */
  private static exponentLabel(exponent: number): string {
    switch (exponent) {
      case 0:
        return EngineeringNotationEnum.DEFAULT;
      case 1:
        return EngineeringNotationEnum.KILO;
      case 2:
        return EngineeringNotationEnum.MEGA;
      case 3:
        return EngineeringNotationEnum.GIGA;
      case 4:
        return EngineeringNotationEnum.TERA;
      default:
        return  (exponent < 0 ? 'e-' : 'e+') + Math.abs(exponent*3);
    }
  }
}
