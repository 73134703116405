@if (previewUrl && this.mimeType) {
  <div class="document-viewer-container">
    @if (mimeType === 'application/pdf') {
      <div>
        <object [data]="previewUrl"
                [type]="mimeType"
                [ngClass]="cssCLass">
          {{ 'LABEL.UNVAILABLE_PREVIEW' | translate }}
        </object>
      </div>
    } @else {
      <div>
        <img [src]="previewUrl"
             [ngClass]="cssCLass"
             alt="documentPreview"/>
        @if (!mimeType.includes('image') || unsupportedImageType.includes(this.mimeType)) {
          <span> {{ 'LABEL.UNVAILABLE_PREVIEW' | translate }} </span>
        }
      </div>
    }
  </div>
} @else {
  <spinner></spinner>
}
