import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { AppConfig } from '@app/core/app.config';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { AbstractCellEditor, CustomCellEditorParams } from '@app/shared/components/cell-edit/abstract.cell-editor';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorParams } from 'ag-grid-enterprise';

@Component({
  selector: 'select-cell-edit',
  templateUrl: './select-cell-edit.component.html',
  styleUrls: ['./select-cell-edit.component.scss']
})
export class SelectCellEditComponent extends AbstractCellEditor {

  public entityList: EntityItem[];
  //Display undefined option if the field is not required
  public optional: boolean;

  @ViewChild(MatSelect, {static: false}) public input: MatSelect;

  constructor(public fb: UntypedFormBuilder,
              public formStateService: FormStateService,
              public validationService: ValidationService,
              public translateService: TranslateService,
              public appConfig: AppConfig) {

    super(fb, formStateService, validationService, translateService, appConfig);
  }

  /**
   * Initialisation method for the render component
   * @param params A merge of default cell editor params and custom params
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    super.agInit(params);

    this.optional = !this.params.fieldConfig?.field.validators.find(validator => {
      return validator.type == ValidatorType.REQUIRED;
    });
    this.entityList = this.params.entityList;
  }

  /**
   *  A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
   *  This method is called each time the edit component is activated.
   */
  public afterGuiAttached(): void {
    this.input.focus();
    this.input.open();
  }

  /**
   * Gets called when focus should be put into the editor.
   */
  public focusIn(): void {
    this.input.focus();
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): string {
    return this.control.value.entityId ?? '';
  }
}

interface EntityItem {
  entityName: string,
  entityId: string
}
