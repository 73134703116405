<div class="card-container-container fx-layout-col-lt-md">
  <div class="small card-container fx-flex-none-lt-md fx-flex-custom-1-gt-sm">
    <mat-card>
      <mat-card-content>
        @if (authService.user$ | async; as user) {
          <div
            class="m-t-30 fx-layout-col fx-justify-center fx-align-center"
            id="avatar-container">
            <img class="profile-picture"
                 [ngSrc]="user.picture"
                 width="150"
                 height="150"
                 alt="{{'TOOLTIP.PROFILE' | translate}}">
            <h4 class="card-title m-t-10">{{ user.name }}</h4>
            <h6 class="card-subtitle">{{ 'ROLE.' + accessManager.currentUser.userRole.toString() | translate }}</h6>

            <!-- Information about the user's authentication provider -->
            <button mat-stroked-button (click)="openProfilePage()">
              <span class="mat-icon mdi mdi-open-in-new"></span>
              <span>{{ 'BUTTON.MANAGE_PROFILE' | translate }}</span>
            </button>
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>

  <div class="card-container padding-left fx-flex-grow-lt-md fx-flex-custom-2-gt-sm">
    <mat-card>
      <mat-card-content>
        <mat-tab-group color="accent">
          <mat-tab [label]="'LABEL.REPORTS' | translate">
            <my-report></my-report>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
