import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import {
  ListFieldBuilderComponent
} from '@app/shared/components/fields/list-field-builder/list-field-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';

@Component({
  selector: 'list-field-builder',
  templateUrl: './list-field-builder.component.html',
  styleUrls: ['./list-field-builder.component.scss']
})
export class DocumentTypeFieldBuilderComponent extends ListFieldBuilderComponent {

  constructor(@Inject(FIELD_ENTITY_INJECTION) public entity: Document,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(entity, data, eventsOrigin, formStateService, fieldConfig, preconditionsForEdition, permissionsForEdition, appManager, appConfig, accessManager, media, translate, validationService, singleEditService, analyticsService);
    this.options = this.getOptions();
  }

  /**
   * Get a list of alternative document types based on the current document type.
   * @return List of possible document types for the current Document entity.
   * @private
   */
  private getOptions(): string[] {
    switch (this.entity.documentType) {
      case DocumentTypeEnum.ASSET_DOCUMENT:
      case DocumentTypeEnum.ASSET_PICTURE:
        return [DocumentTypeEnum.ASSET_DOCUMENT, DocumentTypeEnum.ASSET_PICTURE];
      case DocumentTypeEnum.WORK_DOCUMENT:
      case DocumentTypeEnum.WORK_PICTURE:
        return [DocumentTypeEnum.WORK_DOCUMENT, DocumentTypeEnum.WORK_PICTURE];
      case DocumentTypeEnum.EQUIPMENT_DOCUMENT:
      case DocumentTypeEnum.EQUIPMENT_PICTURE:
        return [DocumentTypeEnum.EQUIPMENT_DOCUMENT, DocumentTypeEnum.EQUIPMENT_PICTURE];
      default:
        return [];
    }
  }
}
