import { AbstractBean } from '@app/core/model/abstract-bean';

export class RetoolApp extends AbstractBean {
  public organizationId: string;
  public color: string;
  public retoolId: string;
  public groupId: number;
}

export interface RetoolAppInput {
  name: string;
  color: string;
  retoolId: string;
  groupId: number;
}
