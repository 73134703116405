<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center">
  <div>
    {{ params.valueFormatted }}
  </div>
  @if (formControl.invalid) {
    <div>
      <i class="mdi mdi-help-circle tooltip-icon" [matTooltip]="params.customErrorMessage"></i>
    </div>
  }
</div>
