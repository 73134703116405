import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * Apply required validator on the control if the predicate is verified.
 * @param predicate whether to apply required validator.
 */
export const requiredConditional = (predicate: () => boolean): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) return null;
    return predicate() ? Validators.required(control) : null;
  };
};
