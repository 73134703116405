import { inject, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { GeneralService } from '@services/general.service';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService implements OnDestroy {

  // Used to start/stop the refresh token interval
  private refreshSubscription: Subscription;
  // Used to emit the token refreshed
  private tokenRefreshSubject = new Subject<string>;

  private generalService = inject(GeneralService);
  private authService = inject(AuthService);

  /**
   * Execute a schema retrieval query to simulate activity on the site,
   * and keep the authentication token up to date.
   */
  public startTokenRefresh(): void {
    this.refreshSubscription = timer(180000, 180000).pipe(
      switchMap(() => this.refreshTokenRequest()),
      switchMap(() => this.authService.getAccessTokenSilently()),
      tap((token) => this.tokenRefreshSubject.next(token))
    )
      .subscribe({
        next: () => console.debug('Token refreshed'),
        error: (err) => console.error('Error refreshing token:', err)
      });
  }

  /**
   * Emit a string value once token changed.
   */
  public get tokenRefreshed$(): Observable<string> {
    return this.tokenRefreshSubject.asObservable()
      .pipe(distinctUntilChanged());
  }

  /**
   * Unsubscribe from refreshSubcription
   */
  public stopTokenRefresh(): void {
    // Clean up the subscription
    this.refreshSubscription && this.refreshSubscription.unsubscribe();
  }

  /**
   * Execute a graphql schema request.
   * @return An an empty observable.
   * @private
   */
  private refreshTokenRequest(): Observable<void> {
    const COMBINED_QUERY = gql`
      query Schema {
        __schema {
          __typename
        }
      }
    `;
    const QUERY_VAR = {};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(map(() => void 0));
  }

  /**
   * Prevent memory leak
   */
  public ngOnDestroy(): void {
    this.stopTokenRefresh();
  }
}
