import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { HasStatusType } from '@app/shared/popup/has-status-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './delete-archive-document-modal.component.html',
  styleUrls: ['./delete-archive-document-modal.component.scss']
})
export class DeleteArchiveDocumentModalComponent extends HasStatusType {

  // Injected
  public title: string;
  public message: string;
  public okText: string;
  public deleteText: string;
  public archiveText: string;
  public cancelText: string;
  public selectedOption: 'delete' | 'archive';

  constructor(public translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    super(data.type);
    this.title = data.dialogTitle;
    this.message = data.dialogMessage;
    this.selectedOption = 'delete';

    this.okText = data.okText || this.translate.instant('BUTTON.OK');
    this.deleteText = data.deleteText || this.translate.instant('BUTTON.DELETE');
    this.archiveText = data.archiveText || this.translate.instant('BUTTON.ARCHIVE');
    this.cancelText = data.cancelText || this.translate.instant('BUTTON.CANCEL');
  }

  setSelectedOption(event: MatRadioChange): void {
    this.selectedOption = event.value;
  }
}

