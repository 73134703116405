<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center fx-gap-5">
  <input #inputText [formControl]="displayControl" matInput class="mat-input-element ag-input-field-input" type="text"
         (focusin)="fullRowEdit && inputText.select()"/>
  <span *ngIf="suffix">{{suffix}}</span>

  <div *ngIf="control.invalid">
    <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="errorTooltip"></i>
  </div>
  <div *ngIf="!fullRowEdit">
    <i aria-hidden="true" class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>