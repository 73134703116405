import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { UserPreferences } from '@app/core/model/entities/preferences/user-preferences';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToClassFromExist } from 'class-transformer';
import { gql } from 'apollo-angular';
import { first } from 'rxjs/operators';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';

@Injectable()
export class UserPreferencesService {

  public currentUserPreferences: UserPreferences;

  constructor(private appManager: AppManager,
              private generalService: GeneralService,
              private analyticsService: AnalyticsService) {
  }

  public updateUserPreferences(): void {
    const COMBINED_MUTATION = gql`
      mutation UpdateUserPreferences($id: Int!, $preferences: UserPreferencesInput!) {
        updateUserPreferences(id: $id, preferences: $preferences) {
          id
          preferences
        }
      }
    `;
    const MUTATION_VAR = {
      id: this.currentUserPreferences.id,
      preferences: this.currentUserPreferences.preferences
    };
    this.generalService.set(COMBINED_MUTATION, MUTATION_VAR).pipe(first()).subscribe(response => {
      this.analyticsService.trackEvent(
        new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.USER_PREFERENCES)
          .addProperties({ [AnalyticsKeyEnum.ENTITY_ID]: this.currentUserPreferences.id })
      );
      this.currentUserPreferences = plainToClassFromExist(this.currentUserPreferences, response.data['updateUserPreferences']);
    });
  }

}
