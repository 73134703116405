import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum OrganizationNameActionTypes {
  FieldValueChange = 'organizationName_change',
  FieldValueReset = 'organizationName_reset',
  FieldValueRevert = 'organizationName_revert'
}

export class OrganizationNameChange extends PayloadAction {
  readonly type = OrganizationNameActionTypes.FieldValueChange;
}

export class OrganizationNameReset extends PayloadAction {
  readonly type = OrganizationNameActionTypes.FieldValueReset;
}

export class OrganizationNameRevert implements Action {
  readonly type = OrganizationNameActionTypes.FieldValueRevert;
}

export type OrganizationNameActions = OrganizationNameChange | OrganizationNameReset | OrganizationNameRevert;
