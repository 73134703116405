<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  @if (project) {
    <div class="project-name-block page-title">
      <div>{{ project?.toString() }}</div>
    </div>
    @if (project?.properties?.state) {
      <div class="project-state-block">
        <div>{{ project.properties?.state.toString() }}</div>
      </div>
    }
  }

  <div class="fx-flex"></div>
  <div id="project-menu" class="fx-flex-48">
    <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'project-sheet-menu-button'"
      [matMenuTriggerFor]="menu"
    [preconditions]="accessManager.hasAccess(Permission.DELETE_PROJECT) && preconditionForDeletion"></restricted-icon-button>
    <mat-menu #menu="matMenu">
      <restricted-menu-item (onClick)="deleteProject()" [id]="'delete-project-menu'"
        [permissions]="[Permission.DELETE_PROJECT]"
        [preconditions]="preconditionForDeletion">
        {{'BUTTON.DELETE_PROJECT' | translate}}
      </restricted-menu-item>
    </mat-menu>
  </div>
</div>
