<div id="dialog-container" [formGroup]="dashboardForm">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_DASHBOARD' | translate }}</h1>
  <mat-dialog-content class="fx-flex-auto">
    <mat-form-field appearance="outline">
      <mat-label>{{'LABEL.DASHBOARD_NAME' | translate}}</mat-label>
      <input formControlName="name" [id]="'create_dashboard_name'" matInput
        required/>
      @if (dashboardForm.get('name').hasError('required')) {
        <mat-error>
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
      }
      @if (dashboardForm.get('name').hasError('maxlength')) {
        <mat-error>
          {{ 'ERROR.FIELD_MAX_LENGTH' | translate:{value: appConfig.NAME_MAX_LENGTH} }}
        </mat-error>
      }
      @if (dashboardForm.get('name').hasError('isValueTaken')) {
        <mat-error>
          {{ 'ERROR.DASHBOARD_NAME_ALREADY_IN_USE' | translate }}
        </mat-error>
      }
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose>{{ 'BUTTON.CANCEL' | translate }}</button>
    <button [disabled]="!dashboardForm.valid" [id]="'create-dashboard-button'" color="accent" mat-raised-button
    matDialogClose="yes">{{ 'BUTTON.ADD' | translate }}</button>
  </mat-dialog-actions>
</div>
