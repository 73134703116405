import { Component } from '@angular/core';
import {
  TextFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';
import * as path from 'path-browserify';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'file-name-header-field-builder',
  templateUrl: './text-header-field-builder.component.html'
})
export class FileNameHeaderFieldBuilderComponent extends TextFieldBuilderComponent {

  private getNameWithoutExtension(name: string): string {
    return path.basename(name, this.getExtension(name));
  }

  private getExtension(name: string): string {
    return path.extname(name);
  }

  public edit(): void {
    super.edit();
    this.form.get('field').setValue(this.getNameWithoutExtension(this.getFieldValue()));
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(distinctUntilChanged(), debounceTime(50), takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.getFieldInitialValue()) {
        this.setFieldValue(value + this.getExtension(this.getFieldInitialValue()));
      }
    });
  }
}

