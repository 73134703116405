import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { Asset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { LeaseInput } from '@app/core/model/entities/asset/lease';
import { OccupantInput } from '@app/core/model/entities/asset/occupant';
import { Space } from '@app/core/model/entities/asset/space';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { OccupantsService } from '@app/features/main/views/organization-occupants/occupants.service';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { simplifyStringForSearch } from '@app/shared/extra/utils';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { EntityTypeEnum } from "@app/core/enums/entity-type.enum";

@Component({
  templateUrl: './organization-lease-create-modal.component.html'
})
export class OrganizationLeaseCreateModalComponent implements OnInit, OnDestroy, GeneratesObject {

  protected readonly EntityTypeEnum = EntityTypeEnum;

  public leaseForm: FormGroup<{
    occupantName: FormControl<string>,
    asset: FormControl<RelatedAsset>,
    spaces: FormControl<Space[]>
  }>;

  // Ag-grid
  public gridApi: GridApi;
  public gridOptions: GridOptions;

  // Data
  public filteredOccupantNames: Observable<string[]>;

  public Asset = Asset;
  public spaces = new BehaviorSubject<Space[]>([]);

  protected destroy$ = new Subject<void>();

  private invalidRows: string[] = [];

  // Injection
  protected optionsGridService = inject(GridOptionsService);
  public appConfig = inject(AppConfig);
  protected fb = inject(UntypedFormBuilder);
  protected assetsService = inject(AssetsService);
  protected spacesService = inject(SpacesService);
  protected columnBuilder = inject(ColumnBuilder);
  protected translate: TranslateService = inject(TranslateService);
  protected occupantsService = inject(OccupantsService);

  constructor() {
    this.gridOptions = {
      ...this.optionsGridService.staticSelectSpacesGridOptions,
      localeText: this.columnBuilder.localeTextCommon(),
      rowClassRules: {
        'invalid-row': ({data}: { data: Space }): boolean => this.invalidRows.includes(data.id)
      },
      onRowClicked: this.onRowSelected.bind(this),
      onRowSelected: this.onRowSelected.bind(this)
    };

    // Init leaseForm
    this.leaseForm = this.fb.group({
      occupantName: this.fb.control(null, Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.FIELD_MAX_LENGTH)
      ])),
      asset: this.fb.control(null, Validators.required),
      spaces: this.fb.control([], Validators.compose([
        Validators.required,
        ExtraValidators.checkSpaces
      ]))
    });
  }

  /**
   * Fetch possible values for Occupant, accessible Assets and Spaces.
   */
  public ngOnInit(): void {
    // Fetch available Occupant names
    this.filteredOccupantNames = this.occupantsService.availableOccupantNames$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(names => {
          return this.leaseForm.controls.occupantName.valueChanges
            .pipe(
              startWith(''),
              takeUntil(this.destroy$),
              map((value): string[] => names.filter(name => {
                  return simplifyStringForSearch(name).includes(simplifyStringForSearch(value));
                })
              )
            );
        })
      );

    // Listen for Spaces to be displayed in the grid
    this.leaseForm.controls.asset.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap(asset => {
          return asset ? this.spacesService.loadSpaces(asset.id) : of([]);
        })
      )
      .subscribe(this.spaces);
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.spaces.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load the grid's column definitions and fill with the selected Asset's Spaces.
   * @param params Ag-grid params.
   */
  public onGridReady(params): void {
    this.gridApi = params.api;
    this.gridOptions.columnApi.applyColumnState({
      state: [
        {colId: 'ag-Grid-AutoColumn', sort: 'asc'}
      ]
    });

    // Resize columns when receiving new Spaces
    this.spaces.subscribe(spaces => {
      this.gridApi.setRowData(spaces);
      this.gridApi.sizeColumnsToFit();
      this.leaseForm.controls.spaces.reset([]);
    });
  }

  /**
   * Return an object containing data from the filled form.
   * @return Data for creating Occupant and Lease.
   */
  public getGeneratedObject(): { occupantInput: OccupantInput, leaseInput: LeaseInput, assetId: string } {
    return {
      occupantInput: {
        name: this.leaseForm.controls.occupantName.value
      },
      leaseInput: {
        spaceIds: this.leaseForm.controls.spaces.value.map(space => space.id)
      },
      assetId: this.leaseForm.controls.asset.value?.id
    };
  }

  /**
   * Update value in the form and refresh invalid rows.
   * @param event Event thrown by the grid.
   * @private
   */
  private onRowSelected(event: RowSelectedEvent): void {
    const spaces = this.gridApi.getSelectedRows() as Space[];
    this.leaseForm.controls.spaces.setValue(spaces);
    this.leaseForm.controls.spaces.markAsTouched();

    const nodesToRefresh = [event.node];

    // Check if row is invalid and update invalid rows
    if (event.node.isSelected() && (this.hasParentSelected(event.data) || this.hasChildrenSelected(event.data))) {
      this.invalidRows.push(event.data.id);
    } else if (!event.node.isSelected()) {
      this.invalidRows = this.invalidRows.filter(row => {
        const rowNode = this.gridApi.getRowNode(row);
        nodesToRefresh.push(rowNode);
        return row !== event.data.id
          && (this.hasParentSelected(rowNode.data) || this.hasChildrenSelected(rowNode.data));
      });
    }

    // Refresh rows
    this.gridApi.redrawRows({
      rowNodes: nodesToRefresh
    });
  }

  /**
   * Checks if a Space has ancestors that are selected in the grid.
   * @param space Space to check.
   * @return True if at least one ancestor is selected, false otherwise.
   * @private
   */
  private hasParentSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => space.parentPath.includes(selectedRow.id));
  }

  /**
   * Checks if a Space has children that are selected in the grid.
   * @param space Space to check.
   * @return True is at least one child is selected, false otherwise.
   * @private
   */
  private hasChildrenSelected(space: Space): boolean {
    return this.gridApi.getSelectedRows()
      .some(selectedRow => selectedRow.parentPath.includes(space.id));
  }
}
