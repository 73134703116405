import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Asset } from '@app/core/model/entities/asset/asset';
import { Lease } from '@app/core/model/entities/asset/lease';
import {
  LeaseCreateModalComponent
} from '@app/features/main/views/assets/asset-sheet/occupants/modals/lease-create-modal/lease-create-modal.component';
import {
  OrganizationLeaseCreateModalComponent
} from '@app/features/main/views/organization-occupants/modals/organization-lease-create-modal/organization-lease-create-modal.component';
import {
  OrganizationLeaseRenewModalComponent
} from '@app/features/main/views/organization-occupants/modals/organization-lease-renew-modal/organization-lease-renew-modal.component';
import { OccupantsService } from '@app/features/main/views/organization-occupants/occupants.service';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Document } from '@app/core/model/entities/document/document';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { SpinnerService } from '@app/shared/popup/spinner.service';

@Injectable()
export class LeaseModalService {

  constructor(private popupManager: PopupManager,
              private appManager: AppManager,
              private occupantsService: OccupantsService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private documentModalService: DocumentModalService,
              private spinnerService: SpinnerService) {
  }

  /**
   * Open dialog with a form for the User to fill information to create a new Lease.
   * @param asset If specified, Asset the new Lease is related to.
   * @return Created Lease.
   * @throws Any encountered error.
   */
  public openAddLeaseDialog(asset?: Asset): Observable<Lease> {
    const dialogRef = this.popupManager.showGenericPopup(
      asset ? LeaseCreateModalComponent : OrganizationLeaseCreateModalComponent,
      PopupSize.MEDIUM,
      {asset}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.LEASE);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            this.analyticsService.trackEvent(analyticsEvent);

            const {occupantInput, leaseInput, assetId} = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.occupantsService.createLease(
              this.appManager.currentOrganization.id,
              assetId,
              occupantInput,
              leaseInput
            )
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open dialog with a form for the User to fill information to renew the Lease of an Occupant.
   * @param leaseToRenew Lease to renew.
   * @return Created Lease.
   * @throws Any encountered error.
   */
  public openRenewLeaseDialog(leaseToRenew: Lease): Observable<Lease> {
    const dialogRef = this.popupManager.showGenericPopup(
      OrganizationLeaseRenewModalComponent,
      PopupSize.MEDIUM,
      {leaseToRenew}
    );
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.RENEW, EntityTypeEnum.LEASE);
          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: leaseToRenew.id,
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            const {leaseInput} = dialogRef.componentInstance.getGeneratedObject();
            this.spinnerService.showSpinner();
            return this.occupantsService.renewLease(leaseToRenew, leaseInput.spaceIds)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        })
      );
  }

  /**
   * Open dialog then delete Leases upon confirmation by the User.
   * @param leases Leases to be deleted after confirmation
   * @return True if Leases have been successfully deleted, false if the action was canceled by the User.
   * @throws Any encountered error.
   */
  public openDeleteLeasesDialog(leases: Lease[]): Observable<boolean> {
    return this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant(leases.length === 1 ? 'TITLE.DELETE_LEASE' : 'TITLE.DELETE_LEASES'),
      dialogMessage: this.translate.instant(leases.length === 1
        ? 'LABEL.DELETE_LEASE_AND_DOCUMENTS'
        : 'LABEL.DELETE_LEASES_AND_DOCUMENTS'),
      type: 'warning',
      okText: this.translate.instant('LABEL.DELETE')
    })
      .afterClosed()
      .pipe(
        switchMap(dialogResponse => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.LEASE);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({
              [AnalyticsKeyEnum.ENTITY_ID]: leases.map(lease => lease.id).toString(),
              [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE
            });
            this.analyticsService.trackEvent(analyticsEvent);
            this.spinnerService.showSpinner();
            return this.occupantsService.deleteLeases(leases)
              .pipe(finalize(() => this.spinnerService.hideSpinner()));
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return of(false);
          }

        })
      );
  }

  /**
   * Display a dialog for the User to upload files and create Documents related to a Lease.
   * @param leaseId ID of the Lease to link Documents to.
   * @return Created Documents.
   * @throws Any encountered errors.
   */
  public openUploadLeaseDocumentsDialog(leaseId: string): Observable<Document[]> {
    return this.documentModalService.openUploadEntityDocumentsDialog(
      leaseId,
      EntityTypeEnum.LEASE,
      DocumentTypeEnum.LEASE_DOCUMENT
    );
  }

  /**
   * Display a dialog asking the User to confirm a Lease's Document's deletion.
   * @param document Document to delete.
   * @return True if the Document was successfully deleted of false if the operation was canceled by the User.
   * @throws Any encountered errors.
   */
  public openDeleteLeaseDocumentDialog(document: Document): Observable<boolean> {
    return this.documentModalService.openDeleteEntityDocumentDialog(document);
  }
}
