import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { UserGlobalBIReport } from '@app/core/model/client/user';
import { OrganizationWithLogo } from '@app/core/model/entities/organization/organization';
import { getPowerBIReportInputs } from '@app/core/model/other/powerBI';
import { balanceItemsInArrays } from '@app/shared/extra/utils';
import { SpinnerService } from '@app/shared/popup/spinner.service';
import { OrganizationsService } from '@app/shared/services/organizations.service';
import { AccessManager } from '@services/managers/access.manager';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit, OnDestroy {

  public globalBIReports: { report: UserGlobalBIReport, label: string }[] = [];

  /**
   * An Observable list of organization row with their associated logos.
   */
  public organizationsRowWithLogo$: Observable<OrganizationWithLogo[][]>;

  /**
   * A Subject that emits a void event to signal the destruction of the component.
   * @private
   */
  private destroy$ = new Subject<void>();

  /**
   * Constructor for the Organizations Component.
   * Import services.
   */
  constructor(private analyticsService: AnalyticsService,
              private accessManager: AccessManager,
              private organizationsService: OrganizationsService,
              private router: Router,
              private spinnerService: SpinnerService) {
  }

  /**
   * Fetch all accessible Organizations with their associated logos from service.
   */
  public ngOnInit(): void {
    this.organizationsRowWithLogo$ = this.organizationsService.loadOrganizationsWithLogo().pipe(
      takeUntil(this.destroy$),
      map(organizations => balanceItemsInArrays(organizations, 5))
    );

    if (this.accessManager.hasAccess(PermissionEnum.VIEW_BI_REPORT, true)) {
      this.globalBIReports = this.accessManager.currentUser.globalBIReports.map(userReport => {
        return {
          report: userReport,
          label: getPowerBIReportInputs().find(input => input.reportFormControlName === userReport.name)?.label
        };
      });
    }
  }

  /**
   * Navigate to the selected Organization dashboard page.
   * @param organizationId Id of the organization.
   */
  public async goToOrganization(organizationId: string): Promise<void> {
    this.spinnerService.showSpinner();
    // load all data related to the organization.
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.ORGANIZATION_LIST,
      NavigateToEnum.DASHBOARD,
      EntityTypeEnum.ORGANIZATION,
      organizationId
    );
    // Navigate to the organization's dashboard.
    await this.router.navigate(['organization', organizationId])
      .then(() => this.spinnerService.hideSpinner());
  }

  /**
   * Clear subscriptions before the destruction of the component.
   */
  public ngOnDestroy(): void {
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Navigate to the global Power BI report.
   * @param reportName
   */
  public async navigateToGlobalBIReport(reportName: string): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.BUTTON,
      NavigateToEnum.DASHBOARD,
      EntityTypeEnum.REPORT,
      reportName
    );

    await this.router.navigate(['report', reportName]);
  }
}
