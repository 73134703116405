<div class="ag-cell-editor fx-layout-row fx-justify-space-between fx-align-center fx-gap-10">
  <input #inputText [formControl]="params.textControl" matInput class="mat-input-element ag-input-field-input"
         type="text"
         (keydown.tab)="onTextInputTab($event)"
         (focusin)="fullRowEdit && inputText.select()"/>
  <div *ngIf="params.textControl.invalid">
    <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="textErrorTooltip"></i>
  </div>
  <span>{{ valueInBetween }}</span>
  <mat-select [formControl]="params.suffixControl"
              class="suffix-input mat-input-element ag-input-field-input"
              panelWidth="50px"
              hideSingleSelectionIndicator="true"
              [placeholder]="params.suffixControl.value">
    <mat-option *ngFor="let suffixValue of suffixValues"
                [value]="suffixValue">{{suffixValue}}</mat-option>
  </mat-select>
  <div *ngIf="params.suffixControl.invalid">
    <i aria-hidden="true" class="mdi mdi-help-circle tooltip-icon" [matTooltip]="suffixErrorTooltip"></i>
  </div>
  <div *ngIf="!fullRowEdit">
    <i aria-hidden="true" class="mdi mdi-check" (click)="stopEditing()"></i>
  </div>
</div>