import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { CreateDocumentInput, Document, DocumentToCreateFromFile } from '@app/core/model/entities/document/document';
import { CreateDocumentData } from '@app/shared/components/file-uploader/file-uploader.component';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { TranslateService } from '@ngx-translate/core';
import { SectionService } from '@services/section.service';
import dayjs from 'dayjs';
import {
  OrganizationDocumentCreateModalComponent
} from '@app/features/main/views/organization-documents/modals/organization-document-create-modal/organization-document-create-modal.component';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { Observable } from 'rxjs';
import { Asset } from '@app/core/model/entities/asset/asset';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './document-create-modal.component.html'
})
export class DocumentCreateModalComponent extends OrganizationDocumentCreateModalComponent {

  constructor(appConfig: AppConfig,
              documentsService: DocumentsService,
              sectionService: SectionService,
              columnBuilder: ColumnBuilder,
              translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    super(appConfig, documentsService, sectionService, columnBuilder, translate, data);

    // Add columns that are not relevant for an Asset's Document
    this.propertiesGridColumnState = [
      {colId: 'defaultSortCol', sort: 'asc'},
      {colId: 'assets', hide: true},
      {colId: 'assetDocumentSection', hide: true},
      {colId: 'documentState', hide: true}
    ];
  }

  /**
   * Create a Document input for each row of the properties grid.
   * @return Document inputs.
   */
  public getCreateDocumentsInput(): CreateDocumentInput[] {
    const documentsToUpdate: CreateDocumentInput[] = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        const document: CreateDocumentInput = {
          fileName: node.data.fileName,
          documentType: DocumentTypeEnum.ASSET_DOCUMENT,
          documentState: DocumentStateEnum.ACTIVE,
          documentName: (node.data.document.documentName)
            ? node.data.document.documentName + (node.data.document as Document).getExtension()
            : node.data.document.name,
          properties: {
            // Use spread to add optional field if condition is fulfilled
            ...(node.data.document.documentCategory && {documentCategory: node.data.document.documentCategory}),
            ...(node.data.document.date && {
              documentDate: dayjs(node.data.document.date).format(this.appConfig.DATE_FORMAT)
            }),
            ...(node.data.document.description && {description: node.data.document.description})
          }
        };
        documentsToUpdate.push(document);
      });
    }
    return documentsToUpdate;
  }

  /**
   * Fetch available categories for autocompletion in the Document properties grid. Assets are not fetched since they
   * are not used in this case.
   * @return Observable of the upload configuration.
   * @protected
   */
  protected fetchUploadConfiguration(): Observable<{ assets: Asset[], categories: string[] }> {
    return this.documentsService.availableDocumentCategories$
      .pipe(
        takeUntil(this.destroy$),
        map(categories => {
          return {
            categories,
            assets: [] // Assets are not used in this modal and do not need to be fetched
          };
        })
      );
  }

  /**
   * Build an Asset's Document from an uploaded file's data.
   * @param documentData Uploaded file information to use for the Document's properties.
   * @return Data to use for creating a new Document.
   * @protected
   */
  protected buildDocumentFromFile(documentData: CreateDocumentData): DocumentToCreateFromFile {
    return new DocumentToCreateFromFile(documentData.fileName, documentData.documentName);
  }
}
