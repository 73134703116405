import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import {
  CreateDatagridVisualisationNameReset
} from '@app/shared/store/reducers/create-datagrid-visualisation-name.actions';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { Store } from '@ngrx/store';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';

@Component({
  templateUrl: './create-datagrid-visualisation-modal.component.html',
  styleUrls: ['./create-datagrid-visualisation-modal.component.scss']
})
export class CreateDatagridVisualisationModalComponent implements OnInit, OnDestroy {

  public visualisationForm: FormGroup<{
    name: FormControl<string>
  }>;

  constructor(private generalService: GeneralService,
              private fb: UntypedFormBuilder,
              private store: Store<any>,
              private appManager: AppManager,
              public appConfig: AppConfig,
              private accessManager: AccessManager,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.visualisationForm = this.fb.group({
      name: this.fb.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.NAME_MAX_LENGTH)
      ]))
    });
    this.visualisationForm.get('name').setAsyncValidators(ExtraValidators.isValueTaken(this.generalService,
      EntityTypeEnum.DATAGRID_VISUALISATION,
      'name',
      '',
      {
        datagrid: this.data.datagrid,
        organizationId: this.appManager.currentOrganization.id,
        user: this.accessManager.currentUser.id
      },
      {store: this.store, fieldName: 'createDatagridVisualisationName'}
    ));
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new CreateDatagridVisualisationNameReset({currentValue: '', originalValue: ''}));
  }

  public getGeneratedObject(): any {
    return {
      visualisationName: this.visualisationForm.get('name').value
    };
  }

}
