<div class="card-container-container">
  <div class="card-container">
    <mat-card>
      <mat-card-content cdk-scrollable>
        <div class="fx-fill fx-layout-col">
          <ng-template #tabList
            [ngTemplateOutletContext]="{ showSubscriptionTabVar: true }"
            [ngTemplateOutlet]="tabList">

            <nav color="accent" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
              @for (item of menuItems; track item) {
                <a #rla="routerLinkActive"
                  [active]="rla.isActive"
                  [id]="item.id" [permission-lock]="item.permissions" [routerLink]="item.link" mat-tab-link
                  routerLinkActive="isActive">
                  {{ item.key | translate }}
                </a>
              }
              <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
            </nav>
            <div class="sheet-container">
              <router-outlet></router-outlet>
            </div>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
