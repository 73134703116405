@if (isSpinnerVisible) {
  <div class="preloader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
} @else {
  <ng-content></ng-content>
}
