export enum DatagridVisualisationEnum {
  ASSET = 'ASSET',
  DOCUMENT = 'DOCUMENT',
  SPACE = 'SPACE',
  CHECK = 'CHECK',
  CONTRACT = 'CONTRACT',
  OCCUPANT = 'OCCUPANT',
  ASSET_CHECK = 'ASSET_CHECK',
  ASSET_OCCUPANT = 'ASSET_OCCUPANT',
  ASSET_CONTRACT = 'ASSET_CONTRACT',
  ASSET_DOCUMENT = 'ASSET_DOCUMENT',
  ASSET_SPACE = 'ASSET_SPACE',
  WORK = 'WORK',
  ASSET_WORK = 'ASSET_WORK',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_WORK = 'EQUIPMENT_WORK',
  ASSET_EQUIPMENT = 'ASSET_EQUIPMENT',
  PROJECT = 'PROJECT',
  ASSET_PROJECT = 'ASSET_PROJECT',
  PROJECT_DOCUMENT = 'PROJECT_DOCUMENT',
  PROJECT_WORK = 'PROJECT_WORK',
  ASSET_AUDIT = 'ASSET_AUDIT',
  FILE_REPOSITORY = 'FILE_REPOSITORY',
  SNAPSHOT = 'SNAPSHOT',
  RETOOL_APP = 'RETOOL_APP',
  ANALYSIS_DOCUMENT = 'ANALYSIS_DOCUMENT'
}
