<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  @if (work) {
    <div class="work-action-block page-title">
      <div>{{ work?.toString() }}</div>
    </div>
    <div class="work-state-block">
      <div>{{ work.state.toString() }}</div>
    </div>
  }

  <div class="fx-flex"></div>
  <div id="work-menu" class="fx-flex-48">
    <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'work-sheet-menu-button'" [matMenuTriggerFor]="menu"
    [preconditions]="(!!this?.work?.asset && accessManager.hasAccess(Permission.VIEW_ASSET)) || accessManager.hasAccess(Permission.DELETE_WORK)"></restricted-icon-button>
    <mat-menu #menu="matMenu">
      <restricted-menu-item (onClick)="deleteWork()" [id]="'delete-work-menu'" [permissions]="[Permission.DELETE_WORK]"
        [preconditions]="true">
        {{'BUTTON.DELETE_WORK' | translate}}
      </restricted-menu-item>

      @if (!!this?.work?.asset) {
        <restricted-menu-item (onClick)="showAssetSheet()" [id]="'show_asset_sheet_menu'"
          [permissions]="[Permission.VIEW_ASSET]" [preconditions]="true">
          {{'BUTTON.SHOW_ASSET_SHEET' | translate}}
        </restricted-menu-item>
      }
    </mat-menu>
  </div>
</div>
