import { Injectable } from '@angular/core';
import { FieldConfig, FieldGroup } from '@app/core/model/other/field-config';
import { getValue, setValue } from '@app/shared/extra/utils';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormStateService {

  private saveSubject = new Subject<{ code: string, data: Record<string, any>, root?: string }>();

  private loadSubject = new Subject<void>();

  public endLoad(): void {
    this.loadSubject.next();
  }

  /**
   * Generate update data associated with the FieldConfig or FieldGroupConfig that was updated and emit them through
   * {@see saved$} so that subscribers can make the necessary update request.
   * @param config FieldConfig or FieldGroupConfig associated with the form field that was changed.
   * @param root Root path to the entity's data, in case of multiple entities.
   */
  public save(config: FieldConfig | FieldGroup, root?: string): void {
    const data = {};
    this.saveInObject(data, config, {customPath: root});
    this.saveSubject.next({code: config.code, data, root});
  }

  /**
   * Emits data associated with a form field that was changed.
   */
  public get saved$(): Observable<{ code: string, data: Record<string, any>, root?: string }> {
    return this.saveSubject.asObservable();
  }

  /**
   * Completes once the form has finished loading.
   */
  public get loaded$(): Observable<void> {
    return this.loadSubject.asObservable();
  }

  /**
   * Returns the stored value at the given path. Options can include a custom path to use as root.
   * @param path List of keys that compose the path.
   * @param options Optional parameters.
   */
  public getPath(path: string[], options: any = {}): any {
    return getValue(this, path, options);
  }

  /**
   * Stores the provided value at the given path. Options can include a custom path to use as root.
   * @param path List of keys that compose the path.
   * @param value Value to be stored.
   * @param options Optional parameters.
   */
  public setPath(path: string[], value: any, options: any = {}): void {
    const root = options.customPath ? this[options.customPath] : this;
    setValue(root, path, value);
  }

  /**
   * Save all the updated fields in the entity or in the properties object if it's belong to properties
   * @param propertiesObject The object where to save the fields belonging to properties
   * @param config The Field or the FieldGroup config
   * @param option Optional parameters
   */
  public saveInObject(
    propertiesObject: Record<string, unknown>,
    config: FieldConfig | FieldGroup,
    option?: any
  ): void {
    if (config instanceof FieldGroup) this.saveFieldGroupInObject(propertiesObject, config, option);
    if (config instanceof FieldConfig) this.saveFieldInObject(propertiesObject, config, option);
  }

  /**
   * Save the field value in the entity or in the properties object if it's belong to properties
   * @param propertiesObject The object where to save the fields belonging to properties
   * @param fieldConfig The field config
   * @param option Optional parameters
   * @param fieldGroupCode The fieldGroup code if the fieldGroup is not single
   */
  private saveFieldInObject(
    propertiesObject: Record<string, unknown>,
    fieldConfig: FieldConfig,
    option?: any,
    fieldGroupCode?: string
  ): void {
    const path = fieldConfig.fieldPath;
    // Retrieval of the value ignores the properties object in the form state service,
    // TODO: maybe add the properties object in form state?
    if (path.firstItem() === 'properties') path.shift();

    const value = this.getPath([fieldGroupCode || fieldConfig.code, 'value', fieldConfig.code], option);
    setValue(propertiesObject, fieldConfig.fieldPath, value);
  }

  /**
   * Save the values inside the field group in the entity or in the properties object if it's belong to properties
   * @param propertiesObject The object where to save the fields belonging to properties
   * @param fieldGroup The fieldGroup config
   * @param option Optional parameters
   */
  private saveFieldGroupInObject(
    propertiesObject: Record<string, unknown>,
    fieldGroup: FieldGroup,
    option?: any
  ): void {
    fieldGroup.fieldConfigs.forEach(fieldConfig => {
      if (!fieldConfig.computed || fieldConfig.conditionsToEdit.length > 0) {
        this.saveFieldInObject(propertiesObject, fieldConfig, option, fieldGroup.code);
      }
    });
  }

  public cleanUp(): void {
    // remove properties added by fieldBuilders (except saveSubject and loadSubject)
    Object.keys(this).forEach((key) => {
      if (!['loadSubject', 'saveSubject'].includes(key)) {
        delete this[key];
      }
    });
  }
}
