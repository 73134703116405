import { Injectable } from '@angular/core';

@Injectable()
export class ExportUtils {

  private excelStyles = [
    {
      id: 'header',
      interior: {
        color: '#aaaaaa',
        pattern: 'Solid',
      },
    },
    {
      id: 'body',
      interior: {
        color: '#dddddd',
        pattern: 'Solid',
      },
    },
    ...Array.from(Array(7), (x, i) => {
      return {
        id: 'indent-'+i,
        alignment: {
          indent: i * 3
        },
        dataType: 'string'
      }
    })
  ];

  public createCell(text, styleId) {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(text) ? 'Number' : 'String',
        value: (text) ? String(text) : '',
      },
    };
  }

  public getExcelStyles() {
    return this.excelStyles;
  }
}
