import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform a number of bytes into a human-readable format.
 *
 * @remarks
 * This pipe converts a number of bytes into a more readable format such as KB, MB, GB, etc.
 * It uses a dictionary to determine the appropriate unit based on the value.
 *
 * @see https://github.com/danrevah/ngx-pipes
 */
@Pipe({name: 'bytes'})
export class BytesPipe implements PipeTransform {
  private dictionary: Array<{ max: number; type: string }> = [
    {max: 1024, type: 'B'},
    {max: 1048576, type: 'KB'},
    {max: 1073741824, type: 'MB'},
    {max: 1.0995116e12, type: 'GB'},
  ];

  /**
   * Checks if the given value is a number.
   *
   * @param value - The value to check.
   * @returns {boolean} - True if the value is a number, false otherwise.
   */
  private isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  /**
   * Check if a number is finite.
   *
   * @param {any} value - The number to be checked.
   * @returns {boolean} - True if the number is finite, false otherwise.
   */
  private isNumberFinite(value: any): boolean {
    return this.isNumber(value) && isFinite(value);
  }

  /**
   * Applies precision to a given number.
   *
   * @param {number} num - The number to apply precision to.
   * @param {number} precision - The precision value.
   * @returns {number} The number with applied precision.
   */
  private applyPrecision(num: number, precision: number): number {
    if (precision <= 0) {
      return Math.round(num);
    }

    const tho = 10 ** precision;

    return Math.round(num * tho) / tho;
  }

  /**
   * Transforms a given number into a string representation with an optional precision.
   *
   * @param {number} value - The number to transform.
   * @param {number} [precision] - The optional precision to apply to the transformed number. Default precision is 0.
   * @returns {string | number} - The transformed number as a string or number value.
   */
  public transform(value: number, precision: number = 0): string | number {
    if (!this.isNumberFinite(value)) {
      return NaN;
    }

    const format = this.dictionary.find(d => value < d.max) || this.dictionary[this.dictionary.length - 1];
    const calc = value / (format.max / 1024);
    const num = this.applyPrecision(calc, precision);

    return `${num} ${format.type}`;
  }
}
