<div id="dialog-container" class="fx-layout-col fx-justify-center fx-align-center fx-fill" [cdkTrapFocus]="true">
  <div class="fx-flex-auto fx-layout-col fx-justify-space-around fx-align-center">
    <i class="mdi lg" [ngClass]="iconName" [style.color]="colour"></i>
    <div class="fx-layout-col fx-justify-start fx-align-center">
      <h3 id="title" class="modal-title">{{ title }}</h3>
      <div id="message" class="modal-message">{{ message }}</div>
    </div>
  </div>

  <mat-dialog-content id="list-container" class="fx-flex-auto">
    <mat-radio-group class="fx-layout-col">
      <mat-radio-button [value]="'delete'"
                        [checked]="true"
                        (change)="setSelectedOption($event)"
                        labelPosition="after">
        {{ deleteText | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="'archive'" (change)="setSelectedOption($event)" labelPosition="after">
        {{ archiveText | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel" id="dialog-cancel-button">
      {{ cancelText }}
    </button>
    <button mat-raised-button [mat-dialog-close]="selectedOption" color="accent" id="dialog-ok-button">
      {{ okText }}
    </button>
  </mat-dialog-actions>
</div>
