import { DatagridVisualisationEnum } from '@app/core/enums/datagrid-visualisation.enum';
import { AbstractBean } from '@app/core/model/abstract-bean';
import { User } from '@app/core/model/client/user';
import { ColumnState } from 'ag-grid-community';
import { Expose, Type } from 'class-transformer';

export class DatagridVisualisation extends AbstractBean {

  public datagrid: DatagridVisualisationEnum;
  public organizationId: string;
  public config: DatagridVisualisationConfig;
  public isDefault: boolean;
  public assetTypeId: string;

  @Type(() => User)
  public user: User;

  @Expose()
  public toString(): string {
    return this.name;
  }

  /**
   * Return a deep copy of the visualisation.
   * @return {DatagridVisualisation}
   */
  public copy(): DatagridVisualisation {
    return JSON.parse(JSON.stringify(this));
  }
}

export interface DatagridVisualisationConfig {
  columnState?: ColumnState[];
  filterModel?: { [key: string]: any };
}
