<mat-dialog-content>
  <div class="fx-fill fx-layout-col">
    @if (!preventUploadFiles) {
      <div (click)="fileInput.click()"
        (uploadOutput)="onUploadOutput($event)"
        [options]="options"
        [uploadInput]="uploadInput"
        ngFileDrop
        class="drop-container">
        <form class="fx-layout-col fx-justify-center fx-align-center">
          <svg class="img-upload" height="60" viewBox="0 0 640 640" width="60" xmlns="http://www.w3.org/2000/svg">
            <path class="st-1"
            d="M411.312 372.688l-64-64c-6.247-6.248-16.379-6.248-22.627 0l-64 64c-6.248 6.247-6.248 16.379 0 22.627s16.379 6.248 22.628 0l36.687-36.687v137.372c0 8.837 7.163 16 16 16s16-7.163 16-16v-137.372l36.688 36.687c3.123 3.123 7.217 4.685 11.312 4.685s8.189-1.562 11.312-4.688c6.25-6.247 6.25-16.377 0-22.624z"></path>
            <path class="st-2"
            d="M512.2 512h-112.2c-8.837 0-16-7.163-16-16s7.163-16 16-16h112.2c52.824 0 95.8-42.976 95.8-95.8s-42.976-95.8-95.8-95.8c-11.33 0-22.371 1.938-32.817 5.764-6.979 2.555-14.798-0.031-18.876-6.243s-3.343-14.415 1.776-19.803c11.425-12.022 17.717-27.692 17.717-44.118 0-35.29-28.71-64-64-64-20.536 0-39.32 9.492-51.535 26.042-7.374 9.991-11.668 21.692-12.417 33.836-0.419 6.781-5.073 12.558-11.609 14.409-6.539 1.854-13.53-0.629-17.441-6.182-3.52-4.998-7.331-9.814-11.329-14.314-30.367-34.185-73.984-53.791-119.669-53.791-88.224 0-160 71.775-160 160s71.776 160 160 160h80c8.836 0 16 7.163 16 16s-7.164 16-16 16h-80c-105.869 0-192-86.131-192-192s86.131-192 192-192c51.271 0 100.368 20.573 136.315 56.791 2.802-6.217 6.28-12.168 10.402-17.752 18.044-24.445 46.933-39.039 77.283-39.039 52.935 0 96 43.065 96 96 0 11.223-1.949 22.202-5.685 32.532 1.954-0.089 3.917-0.132 5.885-0.132 70.469 0 127.8 57.331 127.8 127.8s-57.331 127.8-127.8 127.8z"></path>
          </svg>
          <label>
            {{ isMultiple ? ("LABEL.DRAG_N_DROP_DOCUMENTS" | translate: {'max': this.options.maxUploads}) : ("LABEL.DRAG_N_DROP_DOCUMENT" | translate) }}
          </label>
          <button class="btn-select-file" mat-stroked-button>{{ 'LABEL.SELECT_FILE' | translate }}</button>
          <input #fileInput
            (uploadOutput)="onUploadOutput($event)"
            [accept]="options.allowedContentTypes"
            [options]="options"
            [uploadInput]="uploadInput"
            hidden
            [multiple]="isMultiple"
            ngFileSelect
            type="file">
        </form>
      </div>
      @if (files.length > 0) {
        <div class="fx-layout-col"
          [ngClass]="{ 'single-file': files.length === 1, 'files-list': files.length > 1 }">
          @for (file of files; track file; let i = $index) {
            <div style="margin-top: 20px">
              <div class="fx-layout-row fx-justify-left fx-align-center">
                <span class="filename-label">{{ file.nativeFile.name }}</span>
                @if (file.progress?.status === uploadStatus.Done) {
                  <div class="upload-success">
                    @if (!getError(file?.id)) {
                      <i class="mdi sm mdi-check"></i>
                    }
                  </div>
                }
              </div>
              @if (file?.progress?.status === uploadStatus.Uploading) {
                <div
                  class="fx-layout-row fx-justify-left fx-align-center">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  <button (click)="cancelUpload(file.id)" [disableRipple]="true" class="upload-in-progress"
                    mat-icon-button>
                    <i class="mdi mdi-close sm"></i>
                  </button>
                </div>
              }
              @if (getError(file?.id)) {
                <div class="error-message">{{getError(file?.id).error}}</div>
              }
            </div>
          }
        </div>
      }
    } @else {
      <div class="fx-fill fx-justify-center fx-align-center">
        <div class="fx-layout-row fx-justify-center fx-align-baseline">
          <p>{{ "LABEL.FILES_ARE_BEING_DELETED" | translate }}</p>
          <div class="stage">
            <div class="dot-flashing"></div>
          </div>
        </div>
      </div>
    }
  </div>
</mat-dialog-content>
