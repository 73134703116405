import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
} from '@app/core/model/other/field-config';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { AbstractFieldBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue, simplifyStringForSearch } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class EntityChipsFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public noEntity = true;
  public Permission = PermissionEnum;
  public filteredEntities: Observable<any[]>;
  public selectedEntities: any[] = [];
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public inputField: ElementRef<HTMLInputElement>;
  public suggestions: MatAutocomplete;

  protected constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
                        @Inject(FIELD_EXTRA_DATA) data: any,
                        @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
                        formStateService: FormStateService,
                        @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
                        @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
                        @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
                        appManager: AppManager,
                        appConfig: AppConfig,
                        accessManager: AccessManager,
                        media: MediaMatcher,
                        translate: TranslateService,
                        validationService: ValidationService,
                        singleEditService: SingleEditService,
                        analyticsService: AnalyticsService,
                        protected fb: FormBuilder) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService
    );
  }

  /**
   * Create the form, and add the chips.
   */
  public ngOnInit(): void {
    this.selectedEntities.push(...this.getFieldValue());
    this.form = this.fb.group({
      entityName: this.fb.control('')
    });

    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();

    // Set filter on entities
    this.filteredEntities = this.form.get('entityName').valueChanges.pipe(
      startWith(''),
      debounceTime(50),
      filter(value => value !== void 0),
      map(value => this.filter(value))
    );
  }

  /**
   * Set up hooks
   */
  public ngAfterViewInit(): void {
    this.setupHooks();
    // Reset on error
    this.getFieldGroupState().pipe(
      takeUntil(this.destroy$),
      filter(state => state === FieldStateMode.ERROR)
    ).subscribe(() => {
      this.cancel();
    });
  }

  /**
   * Get the field value to display in read mode.
   * @return any
   */
  public getFieldValue(): any[] {
    return getValue(this.entity, this.fieldConfig.fieldPath) ?? [];
  }

  /**
   * Recreate the chips when switching to edit mode.
   */
  public edit(): void {
    super.edit();
    if (this.selectedEntities.length === 0) {
      this.selectedEntities.push(...this.getFieldValue() ?? []);
    }
  }

  // TODO TTT-4135 Remove, iterate over errors instead of validators
  /**
   * List of validators for which an error has been raised by the corresponding validator function.
   */
  public override get erroredValidators(): FieldValidator[] {
    return this.fieldConfig.field.validators.filter(validator => !!this.form.get('entityName').errors?.[validator.code]);
  }

  /**
   * Compute the field initial value.
   * @protected
   */
  protected calcFieldInitValue(): void {
    this.fieldInitValue = this.fieldConfig ? (getValue(
      this.entity,
      this.fieldConfig.fieldPath
    ) ?? []).map(entity => entity.id) : [];
  }

  /**
   * Filter use by the autocomplete input.
   * @param value the input value
   * @return Entity[]
   * @protected
   */
  protected filter(value: Entity | string): Entity[] {
    return this.fieldConfig.field?.fieldValues
      .filter((entity: Entity) =>
        // Check that the suggested entities match the input
        simplifyStringForSearch(entity.toString()).includes(simplifyStringForSearch(value.toString()))
        &&
        // Check that the suggested entities are not already selected
        !this.selectedEntities.map(selectedEntity => selectedEntity.id).includes(entity.id)
      );
  }

  /**
   * Remove a chip from the list.
   * @param entity the chip to remove.
   * @protected
   */
  protected remove(entity: any): void {
    const index = this.selectedEntities.indexOf(entity);

    if (index >= 0) {
      this.selectedEntities.splice(index, 1);
    }

    this.setFieldValue(this.selectedEntities.slice().map(entity => entity.id));
    this.form.get('entityName').setValue('');
  }

  /**
   * Reset the form and the chips to they initial state, then switch to read mode.
   */
  public cancel(): void {
    this.selectedEntities = (this.getFieldValue() || []).slice();
    this.form.get('entityName').setValue('');
    this.setFieldValue((this.getFieldInitialValue() || []).slice());
    this.getNextState();
  }

  /**
   * Add a chip when the user select an entry in the suggestion panel.
   * @param entity Option selected in filtered list
   * @protected
   */
  protected select(entity: Entity): void {
    this.inputField.nativeElement.value = '';

    // Add the new chip
    this.selectedEntities.push(entity);
    this.form.get('entityName').setValue('');
    this.setFieldValue(this.selectedEntities.slice().map(entity => entity.id));
  }
}
