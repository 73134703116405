<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled"
       [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span #tooltip="matTooltip" *ngIf="fieldConfig.tooltip" [matTooltip]="fieldConfig.tooltip"
            matTooltipPosition="below" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <ng-container *ngIf="document.documentType !== DocumentTypeEnum.PROJECT_DOCUMENT; else projectDocument">
      <div class="field-container clickable-field fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
             [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition,
                        'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
          <a (click)="navigateToAssetDashboard()">
            {{ getFieldValue().firstItem()?.toString() | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
          </a>
          <span *ngIf="fieldConfig.computed"
                [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
                #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
          <i class="mdi mdi-calculator tooltip-icon"></i>
        </span>
          <span
            *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
            class="overlay-icon">
          <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
        </div>
      </div>
    </ng-container>
    <ng-template #projectDocument>
      <div class="field-container fx-flex-100-66-percent fx-layout-col">
        <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
             [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition}">
          <mat-chip-listbox *ngIf="getFieldValue()?.length > 0 else empty" tabIndex="-1">
            <mat-chip-option class="clickable-field" (click)="onClickActivate($event)"
                      (keyup.enter)="onEnterActivate($event)"
                      *ngFor="let chip of (getFieldValue()| slice:0:5)">
              <a (click)="navigateToAssetDashboardByAssetId(chip.id)">
                {{ chip?.toString() }}
              </a>
            </mat-chip-option>
            <ng-container *ngIf="getFieldValue()?.length>5">
              {{ 'TOOLTIP.AND_MORE' | translate: {quantity: (getFieldValue().length - 5)} }}
            </ng-container>
            <span
              *ngIf="fieldConfig.computed"
              [matTooltipPosition]="'below'"
              [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
              #tooltip="matTooltip"
              (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-calculator tooltip-icon"></i>
          </span>
          </mat-chip-listbox>
          <ng-template #empty>
            <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
          </ng-template>
          <span
            *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
            class="overlay-icon">
            <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
            <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span #tooltip="matTooltip" *ngIf="fieldConfig.tooltip" [matTooltip]="fieldConfig.tooltip"
            (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.ASSET' | translate}}</mat-label>
        <mat-select-asset-autocomplete #inputField [formControl]="$any(form.get('asset'))"
                                       [disabled]="(asyncPreconditionsForEdition$ | async) === false">
        </mat-select-asset-autocomplete>
      </mat-form-field>
      <div *ngIf="isSingleField" class="save-options">
        <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
          <i class="mdi mdi-close"></i>
        </button>
        <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                class="button save">
          <i class="mdi mdi-check"></i>
        </button>
      </div>
    </div>
  </div>
</div>

