import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HasStatusType } from '@app/shared/popup/has-status-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'yes-no-cancel-modal-dialog',
  templateUrl: './yes-no-cancel.modal-dialog.html'
})
export class YesNoCancelModalDialogComponent extends HasStatusType {

  // Injected
  public title: string;
  public message: string;
  public yesText: string;
  public noText: string;
  public cancelText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public translate: TranslateService) {
    super(data.type);
    this.title = data.dialogTitle;
    this.message = data.dialogMessage;

    this.yesText = data.yesText || this.translate.instant('BUTTON.YES');
    this.noText = data.noText || this.translate.instant('BUTTON.NO');
    this.cancelText = data.cancelText || this.translate.instant('BUTTON.CANCEL');
  }
}
