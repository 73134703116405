import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum AssetStateActionTypes {
  FieldValueChange = 'assetState_change',
  FieldValueReset = 'assetState_reset',
  FieldValueRevert = 'assetState_revert'
}

export class AssetStateChange extends PayloadAction {
  public readonly type = AssetStateActionTypes.FieldValueChange;
}

export class AssetStateReset extends PayloadAction {
  public readonly type = AssetStateActionTypes.FieldValueReset;
}

export class AssetStateRevert implements Action {
  public readonly type = AssetStateActionTypes.FieldValueRevert;
}
