import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';

export interface CustomCellRendererParams {
  validators: ValidatorFn;
  customErrorMessage: string;
  formControl: FormControl;
}

@Component({
  selector: 'computed-cell-renderer',
  templateUrl: './computed-cell-renderer.component.html',
  styleUrls: ['./computed-cell-renderer.component.scss']
})
export class ComputedCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public ColumnType = ColumnType;
  public params: Partial<ICellRendererParams & CustomCellRendererParams>;
  public formControl: FormControl;

  private touchScreenQuery: MediaQueryList;

  protected destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.formControl = this.fb.control({disabled: true});
  }

  /**
   * Check if the column is numerical.
   * @returns True if the column is numerical, false otherwise.
   */
  public get isNumericalColumn(): boolean {
    return this.params.colDef.type === ColumnType.NUMERICAL;
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters.
   * @param params parameters of the renderer cell.
   */
  public agInit(params: Partial<ICellRendererParams & CustomCellRendererParams>): void {
    this.params = params;
    if (!!params.formControl) {
      this.formControl = params.formControl;
    }
    // Set the validators of the control.
    this.formControl.setValidators(this.params.validators);
    // Set value to the control.
    // The control will check if it's valid or not.
    this.formControl.setValue(this.params.value);
    // If the control is invalid, show it on the GUI.
    if (this.formControl.invalid) {
      this.params.eGridCell.classList.add('error-cell');
    }
  }

  /**
   * On touch screen devices only, toggle the tooltip after pressing the icon.
   * @param tooltip MatTooltip to toggle.
   */
  public onClickTooltip(tooltip: MatTooltip): void {
    if (this.touchScreenQuery.matches) {
      tooltip.toggle();
    }
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param params
   * @returns {boolean}
   */
  public refresh(_: ICellRendererParams): boolean {
    return false;
  }

  /**
   *  Remove the error class on the cell GUI and stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.params.eGridCell.classList.remove('error-cell');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
