import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const integer = (control: AbstractControl): ValidationErrors | null => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  return /^-?\d+$/.test(control.value) ? null : { integer: true };
};
