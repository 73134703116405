import { AfterViewChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { format } from '@app/shared/extra/decimal-format';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import * as L from 'leaflet';
import 'leaflet.fullscreen';
import 'leaflet.markercluster';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';

@Component({
  template: '',
  styleUrls: ['./map.component.scss']
})
export abstract class AbstractAssetMapComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() public width: string;
  @Input() public height: string;

  @Input() public allowPanning: boolean;
  @Input() public allowZooming: boolean;
  @Input() public allowClicking: boolean;

  @Input() public permissionsForEdition: string[];

  public requestCompleted = false;
  public hasAddress = false;
  public map: L.Map;

  public options: L.MapOptions;
  public zoomOptions: L.Control.ZoomOptions;
  public fullscreenOptions: L.Control.FullscreenOptions;
  public sidebarOptions: L.SidebarOptions;
  protected markerClusterOptions: L.MarkerClusterGroupOptions;

  protected defaultMarkerGroup: L.FeatureGroup;

  protected defaultIconMarkerUrl = 'assets/images/map/pin_map_cinnabar.png';
  protected defaultIconMarkerSelectedUrl = 'assets/images/map/pin_map_cinnabar_selected.png';

  protected destroy$ = new Subject<void>();

  private mdiIconsLoaded: boolean = false;

  protected constructor(protected translate: TranslateService,
                        protected deviceDetector: DeviceDetectorService,
                        protected accessManager: AccessManager) {

    // Initialise the permissions array
    this.permissionsForEdition = [];

    // Configure some options for the marker cluster
    this.markerClusterOptions = {
      maxClusterRadius: 40,
      iconCreateFunction: (cluster): L.DivIcon => {
        return new L.DivIcon({
          html: '<div><span>' + cluster.getChildCount() + '</span></div>',
          className: 'map-marker-cluster',
          iconSize: new L.Point(40, 40)
        });
      },
      pane: L.Marker.prototype.options.pane,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      singleMarkerMode: false,
      disableClusteringAtZoom: 19,
      removeOutsideVisibleBounds: true,
      animate: true,
      animateAddingMarkers: false,
      spiderfyDistanceMultiplier: 1,
      spiderLegPolylineOptions: {weight: 1.5, color: '#222', opacity: 0.5},
      chunkedLoading: false,
      chunkInterval: 200,
      chunkDelay: 50,
      chunkProgress: null,
      polygonOptions: {}
    };

    // Configure some options for the sidebar
    this.sidebarOptions = {
      autopan: false,
      closeButton: true,
      container: 'sidebar',
      position: 'right'
    };
  }

  public ngOnInit(): void {
    // Define the map options
    this.options = {
      dragging: this.allowPanning,
      zoomControl: false,
      scrollWheelZoom: this.allowZooming,
      doubleClickZoom: false
    };
    this.zoomOptions = {
      zoomInTitle: this.translate.instant('LABEL.MAP_ZOOMIN'),
      zoomOutTitle: this.translate.instant('LABEL.MAP_ZOOMOUT')
    };
    this.fullscreenOptions = {
      position: 'topleft',
      title: this.translate.instant('LABEL.FULLSCREEN'),
      titleCancel: this.translate.instant('LABEL.CLOSE')
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewChecked(): void {
    if (this.map) {
      this.map.invalidateSize();

      if (!this.mdiIconsLoaded) {
        // Remove the default leaflet fullscreen icon of the control and replace it to a material design icon
        const fullscreenControlHTMLElement = document.getElementsByClassName('leaflet-control-zoom-fullscreen')[0] as HTMLElement;
        if (fullscreenControlHTMLElement) {
          fullscreenControlHTMLElement.classList.remove('fullscreen-icon');
          fullscreenControlHTMLElement.classList.add('mdi', 'mdi-fullscreen', 'sm');
          // Set the fullscreen control layer icon when the map enter on fullscreen mode
          this.map.on('enterFullscreen', () => {
            fullscreenControlHTMLElement.classList.remove('mdi-fullscreen');
            fullscreenControlHTMLElement.classList.add('mdi-fullscreen-exit');
          });
          // Set the fullscreen control layer icon when the map exit on fullscreen mode
          this.map.on('exitFullscreen', () => {
            fullscreenControlHTMLElement.classList.remove('mdi-fullscreen-exit');
            fullscreenControlHTMLElement.classList.add('mdi-fullscreen');
          });
          this.mdiIconsLoaded = true;
        }
        // Remove the default leaflet zoom in icon of the control and replace it to a material design icon
        const zoomInControlHTMLElement = document.getElementsByClassName('leaflet-control-zoom-in')[0] as HTMLElement;
        if (zoomInControlHTMLElement) {
          zoomInControlHTMLElement.innerText = '';
          zoomInControlHTMLElement?.classList.add('mdi', 'mdi-plus', 'sm');
        }

        // Remove the default leaflet zoom out icon of the control and replace it to a material design icon
        const zoomOutControlHTMLElement = document.getElementsByClassName('leaflet-control-zoom-out')[0] as HTMLElement;
        if (zoomOutControlHTMLElement) {
          zoomOutControlHTMLElement.innerText = '';
          zoomOutControlHTMLElement.classList.add('mdi', 'mdi-minus', 'sm');
        }
      }
    }
  }

  /**
   * Set the dimension of the map with the input parameters
   * @returns {any}
   */
  public getDimensionOfMap(): any {
    return {
      'width': this.width ? this.width : '100%',
      'height': this.height ? this.height : '100%'
    };
  }

  /**
   * Returns the correct cursor style based on user permissions
   * @returns {string}
   */
  public getCursorStyle(): string {
    if (this.permissionsForEdition.length === 0 || this.accessManager.hasAllNeededPermissions(this.permissionsForEdition)) {
      return 'cursor-pointer';
    } else {
      return 'read-map';
    }
  }

  /**
   * Configure initial zoom based on marker group distribution.
   * If there is no label, leaflet default zoom is used.
   * @protected
   */
  protected setInitialZoom(): void {
    if (this.defaultMarkerGroup.getLayers().length > 0) {
      setTimeout(() => this.map.fitBounds(this.defaultMarkerGroup.getBounds().pad(0.5), {maxZoom: 18}));
    }
  }

  public onMapReady(map: L.Map): void {
    this.map = map;
    this.map.addLayer(this.defaultMarkerGroup);
    if (this.allowZooming) {
      L.control.zoom(this.zoomOptions).addTo(this.map);
    }
    L.control.scale().addTo(this.map);
    L.control.fullscreen(this.fullscreenOptions).addTo(this.map);

    this.setBaseLayers();
    this.setInitialZoom();
  }

  /**
   * Takes the coordinate input and converts it if necessary to make it understandable for the map component
   * @param {string | number} coordinate
   * @returns {number}
   */
  protected formatCoordinateToNumber(coordinate: string | number): number {
    return (typeof coordinate === 'string') ? Number.parseFloat(format(coordinate)) : coordinate;
  }

  /**
   * Adds the necessary layers in either the minimap or directly into the map
   */
  protected abstract setBaseLayers(): void;

  /**
   * Draws the whole map by creating and adding the marker group
   * Centers the map on the bounds of the markers if the map is present
   */
  protected abstract setMapMarkers(): void;
}
