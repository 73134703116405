import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { FormGroup } from '@angular/forms';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { MatButton } from '@angular/material/button';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface CustomFullRowEditCellRendererParams {
  formGroup: FormGroup;
  editPermissions: PermissionEnum[];
  onSave: (rowNode: IRowNode) => Observable<void>;
}

@Component({
  selector: 'full-row-edit-cell-renderer',
  templateUrl: './full-row-edit-cell-renderer.component.html',
  styleUrls: ['./full-row-edit-cell-renderer.component.scss']
})
export class FullRowEditCellRendererComponent implements ICellRendererAngularComp {

  @ViewChild('cancelButton') public cancelButton: MatButton;
  @ViewChild('saveButton') public saveButton: MatButton;

  public params: Partial<ICellRendererParams & CustomFullRowEditCellRendererParams>;
  public isEditing: boolean;
  public isPending: boolean = false;

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters.
   * @param params parameters of the renderer cell.
   */
  public agInit(params: Partial<ICellRendererParams & CustomFullRowEditCellRendererParams>): void {
    this.init(params);
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell.
   * @param params of the renderer cell.
   * @returns {boolean}
   */
  public refresh(params: Partial<ICellRendererParams & CustomFullRowEditCellRendererParams>): boolean {
    this.init(params);
    return true;
  }

  /**
   * Event thrown when the user click to the start edit row button.
   */
  public onButtonStartClick(): void {
    // Start the row edit on the current row.
    this.params.api.startEditingCell({
      rowIndex: this.params.node.rowIndex,
      colKey: this.params.colDef.colId
    });
  }

  /**
   * Event thrown when the user click to the cancel edit row button.
   */
  public onButtonCancelClick(): void {
    this.params.api.stopEditing(true);
  }

  /**
   * Event thrown when the cancel button is focused
   * and the user is pressing the tab key.
   * @param event Keyboard event.
   */
  public onButtonCancelTab(event: KeyboardEvent): void {
    event.preventDefault();
    // Focus on the save button if the form's group is valid
    // otherwise, focus on the first editable cell of the row.
    if (this.params.formGroup.valid) {
      this.saveButton.focus();
    } else {
      this.focusToFirstEditableCell();
    }
  }

  /**
   * Event thrown when the user click to the save edit row button.
   */
  public onButtonSaveClick(): void {
    this.isPending = true;
    this.params.onSave(this.params.node)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          this.isPending = false;
        }
      });
    this.params.api.stopEditing(false);
  }

  /**
   * Event thrown when the save button is focused
   * and the user is pressing the tab key.
   * @param event Keyboard event.
   */
  public onButtonSaveTab(event: KeyboardEvent): void {
    event.preventDefault();
    // Focus on the first editable cell of the row.
    this.focusToFirstEditableCell();
  }

  /**
   * Focus on cancel button.
   */
  public focusIn(): void {
    this.params.api.ensureColumnVisible(this.params.column);
    this.cancelButton.focus();
  }

  /**
   * Initiation method.
   * @param params parameters of the renderer cell.
   */
  public init(params: Partial<ICellRendererParams & CustomFullRowEditCellRendererParams>): void {
    this.params = params;
    // Check if the current row is on edit mode.
    this.isEditing = ((params.api.getPinnedTopRowCount() > 0 && params.node.isRowPinned()) || params.api.getPinnedTopRowCount() === 0)
      && params.api.getEditingCells()?.first()?.rowIndex === params.node.rowIndex;
  }

  /**
   * Focus on the first editable cell of the row.
   * @private
   */
  private focusToFirstEditableCell(): void {
    const firstEditableCellColumn = this.params.columnApi.getColumns().firstOrNull((column) => {
      return column.isCellEditable(this.params.node);
    });
    if (!!firstEditableCellColumn) {
      this.params.api.ensureColumnVisible(firstEditableCellColumn);
      this.params.api.getCellEditorInstances({
        columns: [firstEditableCellColumn],
        rowNodes: [this.params.node]
      }).first().focusIn();
    }
  }

}
