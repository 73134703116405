<ng-template #content>
  <ng-content></ng-content>
</ng-template>

@if (isRaisedButton) {
  <button (click)="internalButtonClick($event)"
    [color]="colour"
    [id]="id"
    [ngClass]="{'with-margins': withMargins}"
    [permission-lock]="permissions"
    [disabled]="!preconditions"
    type="button"
    mat-raised-button>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
} @else {
  <button (click)="internalButtonClick($event)"
    [color]="colour"
    [id]="id"
    [ngClass]="{'with-margins': withMargins}"
    [permission-lock]="permissions"
    [disabled]="!preconditions"
    type="button"
    mat-stroked-button>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}

