import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { gql } from '@apollo/client/core';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import {
  Dataflow,
  PageBIReport,
  PowerBIReport,
  PowerBIReportConfig,
  RefreshState
} from '@app/core/model/other/powerBI';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service to load and interact with the powerBI report.
 */
@Injectable()
export class PowerBIService {

  private readonly powerBIReportConfigInfoFragment = gql`
    fragment PowerBIReportConfigInfo on PowerBIReportConfig {
      id
      name
      embedUrl
      accessToken
    }
  `;

  constructor(private generalService: GeneralService,
              private appConfig: AppConfig,
              private appManager: AppManager,
              private accessManager: AccessManager) {
  }

  public resolve(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<PowerBIReportConfig> {
    const reportId = this.appManager.currentOrganization.properties['assetReportId'];
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    return reportId && this.accessManager.hasAccess(PermissionEnum.VIEW_BI_REPORT) ?
      this.loadPowerBIReportConfig(workspaceId, reportId) : null;
  }

  /**
   * Retrieve the configuration to display the powerBI report.
   * @param workspaceId The workspace ID.
   * @param reportId The report ID.
   * @return The powerBI embed configuration.
   */
  public loadPowerBIReportConfig(workspaceId: string, reportId: string): Observable<PowerBIReportConfig> {
    const organizationId = this.appManager?.currentOrganization?.id;
    const QUERY = gql`
      query ReportConfig($organizationId: String, $workspaceId: String!, $reportId: String!) {
        powerBIReportConfig(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId) {
          ...PowerBIReportConfigInfo
        }
      }
      ${this.powerBIReportConfigInfoFragment}
    `;
    const QUERY_VAR = {organizationId, workspaceId, reportId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => {
        const reportConfig = plainToInstance(PowerBIReportConfig, response['data']['powerBIReportConfig']);
        if (!!this.appManager?.currentOrganization) {
          reportConfig.filters.push(
            PowerBIReportConfig.buildFilter('organizations', '_id', this.appManager.currentOrganization.id)
          );
        }
        return reportConfig;
      }),
    );
  }

  /**
   * Exports powerBI reports into a single pdf.
   * The resulting report is sent by email to the user when it's ready.
   * @param workspaceId The workspace id.
   * @param reportId The report id.
   * @param filters List of filters, export one page per filter.
   * @return True.
   */
  public exportPowerBIReports(workspaceId: string, reportId: string, filters: Record<string, any>[]): Observable<true> {
    const organizationId = this.appManager?.currentOrganization?.id;
    const QUERY = gql`
      query ExportReport($organizationId: String, $workspaceId: String!, $reportId: String!, $filters: [Object]!, $locale: String!) {
        exportPowerBIReports(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, filters: $filters, locale: $locale)
      }
    `;
    const QUERY_VAR = {organizationId, workspaceId, reportId, filters, locale: navigator.language};

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => response['data']['exportPowerBIReports'] as true)
      );
  }

  /**
   * Exports powerBI paginated report into a pdf file.
   * The resulting report is sent by email to the user when it's ready.
   * @param workspaceId The workspace id.
   * @param reportId The report id.
   * @param entityType The entity type.
   * @param entityIds Optional. List of selected entities. All entities will be displayed if the list is empty or null, unless an assetId is provided.
   * @param assetId Optional. If no entityIds are provided, only entities belonging to the asset will be displayed.
   * @return True.
   */
  public exportPaginatedPowerBIReport(
    workspaceId: string,
    reportId: string,
    entityType: EntityTypeEnum,
    entityIds: string[] = null,
    assetId: string = null
  ): Observable<true> {
    const organizationId = this.appManager.currentOrganization.id;
    const QUERY = gql`
      query ExportPaginatedReport($organizationId: String!, $workspaceId: String!, $reportId: String!, $entityType: EntityType!, $entityIds: [String!], $assetId: String) {
        exportPaginatedPowerBIReport(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, entityType: $entityType, entityIds: $entityIds, assetId: $assetId)
      }
    `;
    const QUERY_VAR = {organizationId, workspaceId, reportId, entityType, entityIds, assetId};

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => response['data']['exportPaginatedPowerBIReport'] as true),
      );
  }

  /**
   * Fetch all powerBI reports.
   * @param workspaceId Workspace ID.
   * @return An array of powerBI reports with id, name and type.
   */
  public getAllPowerBIReports(workspaceId: string): Observable<PowerBIReport[]> {
    const QUERY = gql`
      query PowerBIReports($workspaceId: String!) {
        powerBIReports(workspaceId: $workspaceId) {
          id
          name
          type
        }
      }
    `;
    const QUERY_VAR = {workspaceId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => response['data']['powerBIReports'] as PowerBIReport[])
    );
  }

  /**
   * Get all dataflows in the workspace.
   * @param workspaceId Workspace ID.
   * @return An array of dataflows with id and name.
   */
  public getAllDataflows(workspaceId: string): Observable<Dataflow[]> {
    const QUERY = gql`
      query Dataflows($workspaceId: String!) {
        dataflows(workspaceId: $workspaceId) {
          id
          name
        }
      }
    `;
    const QUERY_VAR = {workspaceId};
    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => response['data']['dataflows'])
    );
  }

  /**
   * Fetch all powerBI report pages.
   * @return An array of pages with id and name.
   * @param workspaceId Workspace ID
   * @param reportId Report ID
   */
  public getAllPowerBIReportPages(workspaceId: string, reportId: string): Observable<PageBIReport[]> {
    const QUERY = gql`
      query PowerBIReportPages($reportId: String!, $workspaceId: String!) {
        powerBIReportPages(reportId: $reportId, workspaceId: $workspaceId) {
          id
          name
        }
      }
    `;
    const QUERY_VAR = {workspaceId, reportId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => response['data']['powerBIReportPages'] as PageBIReport[])
    );
  }

  /**
   * Get the last refresh time and status for the dataflow and each dataset for the organization.
   * @return Observable of RefreshState
   */
  public getRefreshStates(): Observable<RefreshState[]> {
    const organizationId = this.appManager.currentOrganization.id;
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    const dataflowId = this.appManager?.currentOrganization?.properties?.['dataflowId'];
    if (!workspaceId || !dataflowId) return of([]);

    const QUERY = gql`
      query RefreshStates($organizationId: String!, $workspaceId: String!, $dataflowId: String!) {
        refreshStates(organizationId: $organizationId, workspaceId: $workspaceId, dataflowId: $dataflowId) {
          name
          lastRefreshTime
          status
        }
      }
    `;
    const QUERY_VAR = {organizationId, workspaceId, dataflowId};

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          const refreshStates = response['data']['refreshStates'] as RefreshState[];
          refreshStates.forEach(state => {
            if (!!state.lastRefreshTime) {
              state.lastRefreshTime = dayjs.utc(
                state.lastRefreshTime,
                this.appConfig.DATETIME_FORMAT
              )
                .local()
                .format(this.appConfig.DISPLAY_DATETIME_FORMAT);
            }
          });
          return refreshStates;
        })
      );
  }

  /**
   * Refresh dataflow and datasets for the organization.
   * The refresh is done asynchronously.
   * @return true
   */
  public refresh(): Observable<true> {
    const organizationId = this.appManager.currentOrganization.id;
    const workspaceId = this.appManager?.currentOrganization?.properties?.['workspaceId'];
    const dataflowId = this.appManager?.currentOrganization?.properties?.['dataflowId'];
    const MUTATION = gql`
      mutation RefreshAll($organizationId: String!, $workspaceId: String!, $dataflowId: String!) {
        refreshAll(organizationId: $organizationId, workspaceId: $workspaceId, dataflowId: $dataflowId)
      }
    `;
    const MUTATION_VAR = {organizationId, workspaceId, dataflowId};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response['data']['refreshAll'] as true),
      );
  }
}
