import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app.routing';
import { ApolloConfigModule } from '@app/core/apollo.config';
import { CoreModule } from '@app/core/core.module';
import { FullscreenRoutingModule } from '@app/features/fullscreens/fullscreen.module';
import { MainRoutingModule } from '@app/features/main/main.module';
import { MyPagesModule } from '@app/features/main/views/my-pages/my-pages.module';
import { TranslatePoHttpLoader } from '@app/shared/extra/translate-po-http-loader';
import { getLocale } from '@app/shared/extra/utils';
import { SharedModule } from '@app/shared/shared.module';
import { SpinnerModule } from '@app/spinner.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { Apollo } from 'apollo-angular';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslatePoHttpLoader {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HammerModule,

    CoreModule,
    SpinnerModule,
    MainRoutingModule,
    FullscreenRoutingModule,
    MyPagesModule,

    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    LeafletModule,
    Angulartics2Module.forRoot(),
    SharedModule.forRoot(),

    ApolloConfigModule,

    // i18n
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      errorPath: '/error/forbidden',

      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          {uri: environment.backend.baseUrl + '/*'}
        ]
      }
    })
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: getLocale()
    },
    // Apollo service
    Apollo,
    Title,
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
