<div class="inline-field-container">

  <div *ngIf="!editMode" class="field-container fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
    <div (click)="makeEditable()" (keyup.enter)="makeEditable()" [ngClass]="{'disabled': disabled}" class="read-value"
         tabindex="0">
        <span (click)="makeEditable()" [class.selectable]="full === true" class="inline-edit-value">
          {{ control?.value }}
        </span>
      <div (click)="makeEditable()" *ngIf="!disabled" class="inline-edit-content">
          <span class="overlay-icon">
            <i class="mdi mdi-pencil selectable"></i>
          </span>
      </div>
    </div>
  </div>

  <div *ngIf="editMode && !disabled" class="field-container fx-flex-100-percent fx-layout-col">
    <form autocomplete="off">
      <mat-form-field appearance="outline" >
        <mat-label>{{ label }}</mat-label>
        <input #inputField (keyup.enter)="onKey($event)" (keyup.escape)="onKey($event)"
               [formControl]="control" class="edit-value" matInput type="text">
        <mat-error *ngFor="let validator of validators">
          <span *ngIf="control.hasError(validator.type)">
            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
          </span>
        </mat-error>
      </mat-form-field>
    </form>
    <div *ngIf="editMode" class="save-options">
      <button (click)="cancelEditable()" (keyup.escape)="onKey($event)" class="button">
        <i class="mdi mdi-close"></i>
      </button>
      <button (click)="callSave()" (keyup.escape)="onKey($event)" [disabled]="preventSave()"
              class="button save">
        <i class="mdi mdi-check"></i>
      </button>
    </div>
  </div>
</div>
