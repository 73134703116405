@if (hasAddress) {
  <div (leafletClick)="mapClick($event)"
    (leafletMapReady)="onMapReady($event)"
    [leafletOptions]="options"
    [ngClass]="getCursorStyle()"
    [ngStyle]="getDimensionOfMap()"
    class="map-widget"
    leaflet>
  </div>
} @else {
  <img [src]="appConfig.PLACEHOLDER_MAP" alt="{{ 'LABEL.NO_ASSET_ADDRESS' | translate }}" class="default-map-img"/>
}
