import { Component, Input } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AbstractInlineEditComponent } from '@app/shared/components/inline-edit/inline-edit.component';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';

@Component({
  selector: 'inline-edit-unit-crv',
  templateUrl: './inline-edit-unit-crv.component.html',
  styleUrls: ['./inline-edit-unit-crv.component.scss']
})
export class InlineEditUnitCrvComponent extends AbstractInlineEditComponent {

  @Input() public suffix: string = '';

  constructor(protected validationService: ValidationService,
              protected singleEditService: SingleEditService,
              protected analyticsService: AnalyticsService,
              public accessManager: AccessManager,
              public appConfig: AppConfig) {
    super(validationService, singleEditService, analyticsService);
  }

  public onActivate(event: Event): void {
    this.makeEditable();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * Prevents typing unwanted characters in a numeric field
   * @param {InputEvent} event
   * @returns {boolean}
   */
  public validateKeypressEvent(event: InputEvent): boolean {
    const input = event.data;
    const pattern = /[0-9\-.,]/;
    return event.inputType != 'insertText' || this.validateInput(input, pattern);
  }

  /**
   * Prevents pasting unwanted characters in a numeric field
   * @param {ClipboardEvent} event
   * @returns {boolean}
   */
  public validatePasteEvent(event: ClipboardEvent): boolean {
    const input = event.clipboardData.getData('text');
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/;
    return this.validateInput(input, pattern);
  }

  private validateInput(input: string, pattern: RegExp): boolean {
    return pattern.test(input);
  }
}
