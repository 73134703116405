import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  Section
} from '@app/core/model/other/field-config';
import { AbstractFieldGroupBuilder } from '@app/shared/components/fields/abstract.field';
import { getFieldGroupBuilder } from '@app/shared/components/fields/field-builder-selector';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SectionAnchorDirective } from '@app/shared/components/form-builder/sections/section-anchor.directive';
import { SectionBuilderComponent } from '@app/shared/components/form-builder/sections/section-builder.component';
import { ValidationService } from '@app/shared/services/validation.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SectionService } from '@services/section.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EMPTY, merge, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public formId: string;
  @Input() public displayHeader: boolean = true;
  @Input() public permissionsForEdition: string[];
  @Input() public readOnly: Subject<boolean>;
  @Input() public eventsOrigin: EventOriginEnum = EventOriginEnum.SIDEPANEL;
  /**
   * @deprecated Use another form template instead. TTT-3204
   */
  @Input() public fieldsToHide: string[] = [];

  @ViewChild(SectionAnchorDirective, { static: true }) public sectionHost: SectionAnchorDirective;

  private destroy$ = new Subject<void>();
  private sections: Section[] = [];

  constructor(public appManager: AppManager,
              public accessManager: AccessManager,
              private validationService: ValidationService,
              private formStateService: FormStateService,
              public deviceDetectorService: DeviceDetectorService,
              public sectionService: SectionService) {
  }

  public ngOnInit(): void {
    this.sectionHost.viewContainerRef.clear();
  }

  public ngOnDestroy(): void {
    this.formStateService.cleanUp();

    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.loadSections();
  }

  private loadSections(): void {
    // Get sections by form id
    this.sectionService.getSectionsByFormCode(this.formId).pipe(
      switchMap(sections => {
        this.sections = sections;
        return merge(
          this.appManager.entityObservable$,
          this.readOnly ? this.readOnly.asObservable().pipe(map((bool) => !bool)) : EMPTY
        );
      }),
      filter(() => !!this.appManager.currentEntity),
      takeUntil(this.destroy$)
    ).subscribe(preconditionsForEdition => {
      // Initialize the hook objects
      ['GTE_OTHER_FIELD_VALUE', 'LTE_OTHER_FIELD_VALUE', 'AFTER_OTHER_DATE', 'BEFORE_OTHER_DATE'].forEach(hook => {
        if (!this.formStateService.getPath([hook])) {
          this.formStateService.setPath([hook], {});
        }
      });
      if (!this.formStateService.getPath(['computed'])) {
        this.formStateService.setPath(['computed'], new ReplaySubject<void>());
      }

      // For each section, create a section component and inject
      this.sectionHost.viewContainerRef.clear();
      const sectionEntity: Entity = this.appManager.currentEntity;
      this.sections.filter(section => {
        return this.validationService.validateConditions(sectionEntity, section.conditionsToView);
      })
        .forEach((section) => {

          const filteredFieldGroups = section.fieldGroups
            // Filter fields that have been passed in fieldsToHide
            .filter(fieldGroup => !this.fieldsToHide.includes(fieldGroup.code)) // FIXME Replace list of fields to hide with a list of conditions TTT-3204
            // Filter fields that have conditions to view
            .filter(fieldGroup => this.validationService.validateConditions(sectionEntity, fieldGroup.conditionsToView));
          // If there are no fields to show, return
          if (filteredFieldGroups.length === 0) {
            return;
          }

          const sectionRef = this.sectionHost.viewContainerRef.createComponent(SectionBuilderComponent, {
            injector: Injector.create({
              providers: [{
                provide: FIELD_EXTRA_DATA,
                useValue: {
                  sectionLabel: section.label,
                  displayHeader: this.displayHeader,
                  sectionEmptyLabel: section.emptyLabel
                }
              }]
            })
          });

          const fieldGroupContainerRef = sectionRef.instance.fieldGroupHost.viewContainerRef;
          fieldGroupContainerRef.clear();

          // Calculate section's conditions to edit
          const sectionEditable = this.validationService.validateConditions(sectionEntity, section.conditionsToEdit);
          filteredFieldGroups
            .forEach((fieldGroup, index) => {
              // Make sure there are actual fields in the group
              if (fieldGroup.fieldConfigs.length > 0) {
                // Get the field type to create
                const fieldGroupTypeComponent: Type<AbstractFieldGroupBuilder> = getFieldGroupBuilder(fieldGroup);

                if (fieldGroupTypeComponent) {
                  // Init path for sub-entity
                  if (fieldGroup.propertiesPath && !this.formStateService.getPath([fieldGroup.propertiesPath])) {
                    this.formStateService.setPath([fieldGroup.propertiesPath], {});
                  }

                  // FIXME: Get propertiesPath from section at line 93 TTT-3666
                  const fieldGroupEntity = fieldGroup.propertiesPath && this.appManager.currentEntity[fieldGroup.propertiesPath]
                    ? this.appManager.currentEntity[fieldGroup.propertiesPath]
                    : this.appManager.currentEntity;

                  const customEditPermissions = this.accessManager.getCustomEditPermissionByEntityType(fieldGroupEntity);
                  // Create the fieldgroup component and inject the entity and fieldgroup configuration
                  const fieldGroupRef = fieldGroupContainerRef.createComponent(fieldGroupTypeComponent, {
                      index: index,
                      injector: Injector.create({
                        parent: fieldGroupContainerRef.injector, providers: [
                          { provide: FIELD_GROUP_CONFIG_INJECTION, useValue: fieldGroup },
                          { provide: FIELD_ENTITY_INJECTION, useValue: fieldGroupEntity },
                          {
                            provide: FIELD_PRECONDITIONS_INJECTION,
                            useValue: preconditionsForEdition && sectionEditable && this.validationService.validateConditions(
                              fieldGroupEntity,
                              fieldGroup.conditionsToEdit
                            )
                          },
                          {
                            provide: FIELD_PERMISSIONS_INJECTION,
                            useValue: customEditPermissions || this.permissionsForEdition
                          },
                          {
                            provide: FIELD_EXTRA_DATA,
                            useValue: {
                              propertiesPath: fieldGroup.propertiesPath
                            }
                          },
                          { provide: FIELD_EVENTS_ORIGIN, useValue: this.eventsOrigin }
                        ]
                      })
                    }
                  );
                  fieldGroupRef.changeDetectorRef.detectChanges();
                  sectionRef.instance.fieldGroupRefs.push(fieldGroupRef);
                }
              }
            });
        });
      this.formStateService.endLoad();
    });
  }
}
