import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import {
  OrganizationLeaseCreateModalComponent
} from '@app/features/main/views/organization-occupants/modals/organization-lease-create-modal/organization-lease-create-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  templateUrl: './lease-create-modal.component.html'
})
export class LeaseCreateModalComponent extends OrganizationLeaseCreateModalComponent {

  private readonly relatedAsset: RelatedAsset;

  protected translate: TranslateService = inject(TranslateService);

  constructor(@Inject(MAT_DIALOG_DATA) data: { asset: Asset }) {
    super();
    this.relatedAsset = new RelatedAsset({
      id: data.asset.id,
      name: data.asset.name,
      identifier: data.asset.identifier,
      typeId: data.asset.typeId
    });
  }

  /**
   * Set initial Asset value so Spaces can be loaded.
   */
  public ngOnInit(): void {
    super.ngOnInit();
    this.leaseForm.get('asset').setValue(this.relatedAsset);
  }

  /**
   * Single Related Asset object that the new Lease will be linked to. This override method does not fetch accessible Assets from the
   * API as it is not necessary in that case.
   * @return Observable of a single Related Asset object.
   * @protected
   */
  protected fetchAssets(): Observable<RelatedAsset[]> {
    return of([this.relatedAsset]);
  }
}
