import { Component, OnDestroy, OnInit, } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Document } from '@app/core/model/entities/document/document';
import { Project, ProjectInput } from '@app/core/model/entities/project/project';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { ProjectModalService } from '@app/features/main/views/projects/projects-inventory/modals/project-modal-service';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  template: ''
})
export class BaseProjectSheet implements OnInit, OnDestroy {

  public project?: Project;
  public hasDocuments: boolean = false;
  public Permission = PermissionEnum;
  public permissionsForEdition = [PermissionEnum.EDIT_PROJECT];

  protected destroy$ = new Subject<void>();

  constructor(public accessManager: AccessManager,
              public appConfig: AppConfig,
              public fileService: FileService,
              public projectsService: ProjectsService,
              protected appManager: AppManager,
              protected analyticsService: AnalyticsService,
              protected formStateService: FormStateService,
              protected translate: TranslateService,
              protected snackbarManager: SnackbarManager,
              protected documentsService: DocumentsService,
              protected projectModalService: ProjectModalService) {
  }

  /**
   * Listen for inputs and update Project entity when necessary.
   */
  public ngOnInit(): void {
    // Listen for properties updates
    this.formStateService.saved$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(({code, data}) => {
          Project.transformPropertiesForInput(data);
          return this.projectsService.updateProject(this.appManager.currentEntity as Project, data as ProjectInput)
            .pipe(
              tap(updatedProject => {
                this.project = updatedProject;

                // force computed fields to recalculate
                this.formStateService.getPath(['computed']).next();
                // release the SAVING state to the next state
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.AFTER_SAVE);
              }),
              // Resubscribe to the saved observable if an error occurred.
              catchError(() => {
                this.formStateService.getPath([code, 'state']).next(FieldStateMode.ERROR);
                return this.formStateService.saved$;
              })
            );
        })
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      });
  }

  /**
   * Load the current Project entity's documents.
   * @protected
   */
  protected loadProjectData(): void {
    if (this.project) {
      // Load Project's documents
      this.documentsService.loadEntityDocuments(
        this.project.id,
        EntityTypeEnum.PROJECT,
        DocumentTypeEnum.PROJECT_DOCUMENT
      )
        .pipe(
          takeUntil(this.destroy$),
          tap(documents => {
            this.project.documents = documents;
            this.hasDocuments = documents.length > 0;
          })
        )
        .subscribe();
    }
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Project entity.
   */
  public uploadProjectDocuments(): void {
    this.projectModalService.openUploadProjectDocumentsDialog(this.project.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(documents => {
        this.project.documents.push(...documents);
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_UPLOADED'));
      });
  }

  /**
   * Open a dialog asking for confirmation to delete a document linked to the Project entity.
   * @param document Document to delete.
   */
  public deleteProjectDocument(document: Document): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SHEET,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.DOCUMENT,
      document.id
    );
    this.projectModalService.openDeleteProjectDocumentDialog(document)
      .pipe(
        takeUntil(this.destroy$),
        filter(result => result)
      )
      .subscribe(() => {
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DOCUMENT_DELETED'));
        this.project.documents = this.project.documents.filter(doc => doc.id != document.id);
      });
  }

  /**
   * Stop listening to Observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
