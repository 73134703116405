import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { OrganizationNameReset } from '@app/shared/store/reducers/organization-name.actions';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { Store } from '@ngrx/store';
import { GeneralService } from '@services/general.service';

@Component({
  templateUrl: './duplicate-organization-modal.component.html'
})
export class DuplicateOrganizationModalComponent implements OnInit, OnDestroy {

  public organizationForm: FormGroup<{ name: FormControl<string> }>;

  constructor(private generalService: GeneralService,
              private fb: UntypedFormBuilder,
              private store: Store<any>,
              public appConfig: AppConfig,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit(): void {
    this.organizationForm = this.fb.group({
      name: this.fb.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(this.appConfig.NAME_MAX_LENGTH)
      ]))
    });
    this.organizationForm.get('name').setAsyncValidators(ExtraValidators.isValueTaken(this.generalService,
      EntityTypeEnum.ORGANIZATION, 'name', '', {}, {store: this.store, fieldName: 'organizationName'}
    ));
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new OrganizationNameReset({currentValue: '', originalValue: ''}));
  }

  public getGeneratedObject(): any {
    return {
      organizationName: this.organizationForm.get('name').value
    };
  }
}
