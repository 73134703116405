<div id="dialog-container">
  <h1 mat-dialog-title>{{ title | translate }}</h1>
  <mat-divider></mat-divider>
  <ng-container *ngIf="hideSpinner(); else spinner">
    <mat-stepper orientation="horizontal" [linear]="true">
      <ng-template matStepperIcon="edit">
        <i class="mdi mdi-check"></i>
      </ng-template>

      <mat-step [label]="'LABEL.CLIENT_NAME' | translate" [stepControl]="clientNameForm">
        <mat-dialog-content class="fx-flex-auto">
          <form [formGroup]="clientNameForm" novalidate>
            <mat-form-field *ngIf="!appManager.currentOrganization" appearance="outline">
              <mat-label>{{ 'LABEL.ORGANIZATION' | translate }}</mat-label>
              <mat-select formControlName="organization" [id]="'client_organization_select'" [required]="true">
                <mat-option *ngFor="let organization of organizationsList" [value]="organization.id">
                  {{ organization.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="clientNameForm.get('organization').hasError('required')">
                {{ 'ERROR.FIELD_REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'LABEL.CLIENT_NAME' | translate }}</mat-label>
              <input formControlName="clientName" [id]="'input_client_name'" [required]="true"
                     matInput/>
              <mat-error *ngIf="clientNameForm.get('clientName').hasError('required')">
                {{ 'ERROR.FIELD_REQUIRED' | translate }}
              </mat-error>
              <mat-error *ngIf="clientNameForm.get('clientName').hasError('maxlength')">
                {{ 'ERROR.FIELD_MAX_LENGTH' | translate:{value: appConfig.NAME_MAX_LENGTH} }}
              </mat-error>
              <mat-error *ngIf="clientNameForm.get('clientName').hasError('isValueTaken')">
                {{ 'ERROR.CLIENT_NAME_ALREADY_IN_USE' | translate }}
              </mat-error>
            </mat-form-field>
          </form>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button [id]="'client-cancel-button'" mat-stroked-button
                  matDialogClose>{{ 'BUTTON.CANCEL' | translate }}</button>
          <button [disabled]="!clientNameForm.valid" [id]="'client-next-button'" color="accent" mat-raised-button
                  matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </mat-dialog-actions>
      </mat-step>

      <mat-step [label]="'LABEL.SUBSCRIPTION_CLIENT' | translate" [stepControl]="clientSubscriptionsForm">
        <mat-dialog-content class="fx-flex-auto">
          <form [formGroup]="clientSubscriptionsForm" novalidate>
            <div class="fx-layout-col">
              <div class="fx-layout-row">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'LABEL.CRM_CONTRACT_ID' | translate }}</mat-label>
                  <input formControlName="subscriptionContractId" [id]="'input_subscription_contract_id'" matInput/>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionContractId').hasError('maxlength')">
                    {{ 'ERROR.FIELD_MAX_LENGTH' | translate:{ value: appConfig.CONTRACT_ID_MAX_LENGTH } }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="fx-layout-row fx-gap-10">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'LABEL.SUBSCRIPTION_START_DATE' | translate }}</mat-label>
                  <input (focus)="startDate.open()" formControlName="subscriptionStart"
                         [id]="'input_subscription_start_date'" [matDatepickerFilter]="datepickerFilter" [matDatepicker]="startDate"
                         [required]="true" matInput/>
                  <mat-datepicker-toggle [for]="startDate" matIconSuffix></mat-datepicker-toggle>
                  <mat-datepicker #startDate color="accent" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionStart').hasError('required')">
                    {{ 'ERROR.FIELD_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'LABEL.SUBSCRIPTION_DURATION' | translate }}</mat-label>
                  <input formControlName="subscriptionDuration" [id]="'input_subscription_duration'" [required]="true"
                         matInput
                         min="0" type="number"/>
                  <div class="text-suffix" matTextSuffix>{{ 'SUFFIX.MONTH' | translate }}</div>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionDuration').hasError('required')">
                    {{ 'ERROR.FIELD_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionDuration').hasError('gte')">
                    {{ 'ERROR.MIN_VALUE' | translate: {value: 1} }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="fx-layout-row">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'LABEL.ACCOUNTS_AUTHORIZED' | translate }}</mat-label>
                  <input formControlName="subscriptionNbAuthorizedAccounts" [id]="'input_subscription_nb_authorized_accounts'" [required]="true"
                         matInput
                         min="0" type="number"/>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').hasError('required')">
                    {{ 'ERROR.FIELD_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error *ngIf="clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').hasError('gt')">
                    {{ 'ERROR.MIN_VALUE' | translate:{value: 1} }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-list>
                  <h2 class="fx-layout-row fx-justify-start" mat-subheader>
                    <span class="fx-flex-30-percent">{{ 'LABEL.MODULES_SUBSCRIBED' | translate }}</span>
                    <span class="fx-flex-40-percent">{{ 'LABEL.NB_LICENCES_AUTHORIZED' | translate }}</span>
                    <span class="fx-flex-30-percent">{{ 'LABEL.ACCESS_LIMITED_DURATION' | translate }}</span>
                  </h2>
                  <mat-list-item *ngFor="let moduleInfo of clientSubscriptionsForm.get('subscriptionModules')['controls']; let i = index"
                                 [id]="i" [formGroup]="moduleInfo">
                    <div class="subscription-row fx-layout-row fx-justify-start fx-align-center" matLine>
                      <div class="fx-flex-30-percent">
                        <mat-checkbox formControlName="active"
                                      [id]="'cb_module_' + moduleInfo.get('id').value "
                                      labelPosition="after">
                          {{ 'MODULE.' + moduleInfo.get('name').value | translate }}
                        </mat-checkbox>
                      </div>
                      <div class="fx-flex-40-percent">
                          <div *ngIf="moduleInfo.get('isDefault').value; else notDefaultModule"
                               class="default-module fx-layout-row fx-justify-start fx-align-center">
                              {{clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').value}}
                          </div>
                          <ng-template #notDefaultModule>
                        <mat-form-field appearance="fill">
                          <input formControlName="nbLicences" class="input_module_nb_licence"
                                 [max]="clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').value" [min]="1"
                                 [placeholder]="'LABEL.NB_LICENCES' | translate" [readonly]="!moduleInfo.get('active').value"
                                 [required]="true" [step]="1" matInput min="0" type="number"/>
                          <mat-error *ngIf="moduleInfo.get('nbLicences').hasError('required')">
                            {{ 'ERROR.FIELD_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error *ngIf="moduleInfo.get('nbLicences').hasError('max')">
                            {{ 'ERROR.FIELD_MAX_VALUE' | translate: {value: clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').value} }}
                          </mat-error>
                          <mat-error *ngIf="moduleInfo.get('nbLicences').hasError('min')">
                            {{ 'ERROR.MIN_VALUE' | translate: {value: 1} }}
                          </mat-error>
                        </mat-form-field>
                          </ng-template>
                      </div>
                      <div class="fx-flex-offset-5-percent">
                        <mat-slide-toggle [disabled]="!moduleInfo.get('active').value || moduleInfo.get('isDefault').value"
                                          formControlName="limitedDuration">
                          <span *ngIf="moduleInfo.get('limitedDuration').value"> {{ 'LABEL.YES' | translate }}</span>
                          <span *ngIf="!moduleInfo.get('limitedDuration').value">{{ 'LABEL.NO' | translate }}</span>
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </form>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button [id]="'client-subscription-cancel-button'" mat-stroked-button
                  matDialogClose>{{ 'BUTTON.CANCEL' | translate }}</button>
          <button [id]="'client-subscription-previous-button'" mat-stroked-button
                  matStepperPrevious>{{ 'BUTTON.PREVIOUS' | translate }}</button>
          <button [disabled]="!clientSubscriptionsForm.valid" [id]="'save-button'" color="accent" mat-raised-button
                  matDialogClose="yes">{{ 'BUTTON.CREATE' | translate }}</button>
        </mat-dialog-actions>
      </mat-step>
    </mat-stepper>
  </ng-container>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>
