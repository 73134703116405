export enum ValidatorType {
  MAX_LENGTH = 'MAX_LENGTH',
  MIN_VALUE = 'MIN_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  INTEGER = 'INTEGER',
  MAX_NOW = 'MAX_NOW',
  REQUIRED_TRUE = 'REQUIRED_TRUE',
  REQUIRED_FALSE = 'REQUIRED_FALSE',
  BEFORE_OTHER_DATE = 'BEFORE_OTHER_DATE',
  AFTER_OTHER_DATE = 'AFTER_OTHER_DATE',
  READ_ONLY = 'READ_ONLY',
  IS_CLASSIFICATION = 'IS_CLASSIFICATION',
  REQUIRED = 'REQUIRED',
  REALISATION_DATE_IS_BEFORE_TODAY = 'REALISATION_DATE_IS_BEFORE_TODAY',
  REALISATION_DATE_IS_AFTER_TODAY = 'REALISATION_DATE_IS_AFTER_TODAY',
  RENEW_DATE_IS_BEFORE_TODAY = 'RENEW_DATE_IS_BEFORE_TODAY',
  RENEW_DATE_IS_AFTER_TODAY = 'RENEW_DATE_IS_AFTER_TODAY',
  RENEW_DATE_IS_UNDEFINED = 'RENEW_DATE_IS_UNDEFINED',
  UNIQUE = 'UNIQUE',
  END_DATE_IS_BEFORE_TODAY = 'END_DATE_IS_BEFORE_TODAY',
  END_DATE_IS_AFTER_TODAY = 'END_DATE_IS_AFTER_TODAY',
  END_DATE_IS_UNDEFINED = 'END_DATE_IS_UNDEFINED',
  REGEX = 'REGEX',
  DISTINCT = 'DISTINCT',
  GTE_SPACE_CHILDREN_PROPERTY = 'GTE_SPACE_CHILDREN_PROPERTY',
  LTE_SPACE_PARENT_PROPERTY = 'LTE_SPACE_PARENT_PROPERTY',
  ASSET_VALUE_GTE_SPACE_CHILDREN_PROPERTY = 'ASSET_VALUE_GTE_SPACE_CHILDREN_PROPERTY',
  GTE_TOTAL_EXPENSES = 'GTE_TOTAL_EXPENSES',
  LTE_TOTAL_COSTS = 'LTE_TOTAL_COSTS',
  DOCUMENT_EXISTS = 'DOCUMENT_EXISTS',
  EQUIPMENT_HAS_RELATED_WORK = 'EQUIPMENT_HAS_RELATED_WORK',
  ORGANIZATION_CRV_EXISTS = 'ORGANIZATION_CRV_EXISTS',
  IS_SPACE_MOVE_ALLOWED = 'IS_SPACE_MOVE_ALLOWED',
  NOT_FOR_ASSET_TYPES = 'NOT_FOR_ASSET_TYPES',
  FOR_ASSET_TYPES = 'FOR_ASSET_TYPES',
  MAX_LENGTH_FILE_NAME = 'MAX_LENGTH_FILE_NAME',
  PROJECT_HAS_INACCESSIBLE_ASSETS = 'PROJECT_HAS_INACCESSIBLE_ASSETS',
  INCOHERENT_SPACES = 'INCOHERENT_SPACES',
  SPACE_HAS_INACCESSIBLE_ASSETS = 'SPACE_HAS_INACCESSIBLE_ASSETS',
  PERCENT = 'PERCENT',
  YEAR = 'YEAR',
  DATE = 'DATE',
  DECIMAL = 'DECIMAL',
  NOT_IN_ARRAY = 'NOT_IN_ARRAY',
  IN_ARRAY = 'IN_ARRAY',
  BEFORE_DATE = 'BEFORE_DATE',
  AFTER_DATE = 'AFTER_DATE',
  EMAIL = 'EMAIL'
}
