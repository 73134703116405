export enum EntityTypeEnum {
  USER = 'USER',
  CLIENT = 'CLIENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  MODULE = 'MODULE',
  ORGANIZATION = 'ORGANIZATION',
  SUBSCRIPTION_MODULE = 'SUBSCRIPTION_MODULE',
  REPORT = 'REPORT',
  PAGINATED_REPORT = 'PAGINATED_REPORT',
  ASSET = 'ASSET',
  CHECK = 'CHECK',
  CONTRACT = 'CONTRACT',
  OCCUPANT = 'OCCUPANT',
  LEASE = 'LEASE',
  DOCUMENT = 'DOCUMENT',
  GROUP = 'GROUP',
  CRV = 'CRV',
  ASSET_CRV = 'ASSET_CRV',
  UNIT_CRV_DETAIL = 'UNIT_CRV_DETAIL',
  TECHNICAL_CATEGORY_CRV = 'TECHNICAL_CATEGORY_CRV',
  ORGANIZATION_CRV = 'ORGANIZATION_CRV',
  SPACE = 'SPACE',
  USER_PREFERENCES = 'USER_PREFERENCES',
  DATAGRID_VISUALISATION = 'DATAGRID_VISUALISATION',
  USER_GROUP = 'USER_GROUP',
  DASHBOARD = 'DASHBOARD',
  USER_REPORT = 'USER_REPORT',
  WORK = 'WORK',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
  EQUIPMENT = 'EQUIPMENT',
  RISK = 'RISK',
  PROJECT = 'PROJECT',
  ASSET_AUDIT = 'ASSET_AUDIT',
  FILE = 'FILE',
  JMAP = 'JMAP',
  SNAPSHOT = 'SNAPSHOT',
  RETOOL_APP = 'RETOOL_APP',
  ASSET_TYPE = 'ASSET_TYPE',
  FIELD = 'FIELD',
  GRID_CONFIG = 'GRID_CONFIG',
  IMPORT_TEMPLATE = 'IMPORT_TEMPLATE',
  FORM = 'FORM',
}
