import { Type } from '@angular/core';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { FieldConfig, FieldGroup } from '@app/core/model/other/field-config';
import {
  AddressFieldBuilderComponent
} from '@app/shared/components/field-groups/address-field-builder/address-field-builder.component';
import {
  GroupFieldBuilderComponent
} from '@app/shared/components/field-groups/group-field-builder/group-field-builder.component';
import {
  MarketValueFieldBuilderComponent
} from '@app/shared/components/field-groups/marketValue-field-builder/market-value-field-builder.component';
import {
  SimplifiedReplacementValueFieldBuilderComponent
} from '@app/shared/components/field-groups/simplified-replacementValue-field-builder/simplified-replacement-value-field-builder.component';
import {
  SingleFieldBuilderComponent
} from '@app/shared/components/field-groups/single-field-builder/single-field-builder.component';
import {
  TechnicalCategoryFieldBuilderComponent
} from '@app/shared/components/field-groups/technical-category-field-builder/technical-category-field-builder.component';
import { AbstractFieldBuilder, AbstractFieldGroupBuilder } from '@app/shared/components/fields/abstract.field';
import {
  AssetFieldBuilderComponent
} from '@app/shared/components/fields/asset-field-builder/asset-field-builder.component';
import {
  AutocompleteFieldBuilderComponent
} from '@app/shared/components/fields/autocomplete-field-builder/autocomplete-field-builder.component';
import {
  BudgetFieldBuilderComponent
} from '@app/shared/components/fields/budget-field-builder/budget-field-builder.component';
import {
  ChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/chips-field-builder.component';
import {
  AssetsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/assets-chips-field-builder.component';
import {
  EquipmentsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/equipments-chips-field-builder.component';
import {
  ProjectsChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/projects-chips-field-builder.component';
import {
  ComputedFieldBuilderComponent
} from '@app/shared/components/fields/computed-field-builder/computed-field-builder.component';
import {
  DateFieldBuilderComponent
} from '@app/shared/components/fields/date-field-builder/date-field-builder.component';
import {
  DocumentAssetsFieldBuilderComponent
} from '@app/shared/components/fields/document-assets-builder/document-assets-field-builder/document-assets-field-builder.component';
import { DpeFieldBuilderComponent } from '@app/shared/components/fields/dpe-field-builder/dpe-field-builder.component';
import {
  EmailFieldBuilderComponent
} from '@app/shared/components/fields/email-field-builder/email-field-builder.component';
import {
  LinkFieldBuilderComponent
} from '@app/shared/components/fields/link-field-builder/link-field-builder.component';
import {
  ListCheckResultFieldBuilderComponent
} from '@app/shared/components/fields/list-check-result-field-builder/list-check-result-field-builder.component';
import {
  DocumentTypeFieldBuilderComponent
} from '@app/shared/components/fields/list-field-builder/document-type-field-builder.component';
import {
  ListFieldBuilderComponent
} from '@app/shared/components/fields/list-field-builder/list-field-builder.component';
import {
  MultivalueFieldBuilderComponent
} from '@app/shared/components/fields/multivalue-field-builder/multivalue-field-builder.component';
import {
  NumberFieldBuilderComponent
} from '@app/shared/components/fields/number-field-builder/number-field-builder.component';
import {
  PercentFieldBuilderComponent
} from '@app/shared/components/fields/number-field-builder/percent-field-builder/percent-field-builder.component';
import {
  RadiobuttonFieldBuilderComponent
} from '@app/shared/components/fields/radiobutton-field-builder/radiobutton-field-builder.component';
import {
  FileNameHeaderFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/header/file-name-header-field-builder.component';
import {
  TextHeaderFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/header/text-header-field-builder.component';
import {
  TextFieldBuilderComponent
} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';
import {
  TextAreaFieldBuilderComponent
} from '@app/shared/components/fields/textarea-field-builder/textarea-field-builder.component';
import {
  SpacePathFieldBuilderComponent
} from '@app/shared/components/fields/tree-field-builder/path/space-path-field-builder.component';
import {
  SpacesFieldBuilderComponent
} from '@app/shared/components/fields/tree-field-builder/spaces-field-builder.component';

export function getFieldGroupBuilder(groupConfig: FieldGroup): Type<AbstractFieldGroupBuilder> {
  switch (groupConfig.fieldGroupType) {
    case 'address':
      return AddressFieldBuilderComponent;
    case 'marketValue':
      return MarketValueFieldBuilderComponent;
    case 'simplifiedReplacementValue':
      return SimplifiedReplacementValueFieldBuilderComponent;
    case 'grouped':
      return GroupFieldBuilderComponent;
    case 'single':
      return SingleFieldBuilderComponent;
    case 'technical_category':
      return TechnicalCategoryFieldBuilderComponent;
    default:
      return null;
  }
}

/**
 * Get the field builder based on the field configuration.
 * @param fieldConfig The configuration of the field.
 * @returns An object containing the field builder and a boolean indicating if the field is focusable.
 */
export function getFieldBuilder(fieldConfig: FieldConfig): { fieldBuilder: Type<AbstractFieldBuilder>, focusable: boolean } {
  // Field builders that can handle computed and not computed field.
  switch (fieldConfig.field?.fieldType) {
    case FieldTypeEnum.ASSET:
      return {fieldBuilder: AssetFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.ASSETS:
      return {fieldBuilder: AssetsChipsFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.PROJECTS:
      return {fieldBuilder: ProjectsChipsFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.SPACES:
      return {fieldBuilder: SpacesFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.EQUIPMENTS:
      return {fieldBuilder: EquipmentsChipsFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.TEXT_HEADER:
      return {fieldBuilder: TextHeaderFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.FILE_NAME_HEADER:
      return {fieldBuilder: FileNameHeaderFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.DOCUMENT_TYPE:
      return {fieldBuilder: DocumentTypeFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.DOCUMENT_RELATED_ASSETS:
      return {fieldBuilder: DocumentAssetsFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.DPE_LIST:
      return {fieldBuilder: DpeFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.LIST_CHECK_RESULT:
      return {fieldBuilder: ListCheckResultFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.EMAIL:
      return {fieldBuilder: EmailFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.BUDGET:
      return {fieldBuilder: BudgetFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.LINK:
      return {fieldBuilder: LinkFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.SPACE_PATH:
      return {fieldBuilder: SpacePathFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.SUGGESTBOX:
    case FieldTypeEnum.TEXT_AUTOCOMPLETE:
      return {fieldBuilder: AutocompleteFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.CHIPS:
    case FieldTypeEnum.CHIPS_AUTOCOMPLETE:
      return {fieldBuilder: ChipsFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.PERCENT:
      return {fieldBuilder: PercentFieldBuilderComponent, focusable: true};
  }

  if (fieldConfig.computed && !!fieldConfig.field?.fieldType) {
    if (fieldConfig.field?.fieldType === FieldTypeEnum.TEXT && fieldConfig?.customOptions?.['header']) {
      // FIXME TTT-2145 To remove after header field builders / sections are deleted.
      return {fieldBuilder: TextHeaderFieldBuilderComponent, focusable: true};
    }
    return {fieldBuilder: ComputedFieldBuilderComponent, focusable: false};
  }

  // Field builders that can only handle not computed field.
  switch (fieldConfig.field?.fieldType) {
    case FieldTypeEnum.TEXT:
      return {fieldBuilder: TextFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.YEAR:
    case FieldTypeEnum.NUMERIC:
      return {fieldBuilder: NumberFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.LIST:
    case FieldTypeEnum.NUMERIC_LIST:
      return {fieldBuilder: ListFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.TEXTAREA:
      return {fieldBuilder: TextAreaFieldBuilderComponent, focusable: true};
    case FieldTypeEnum.RADIOBUTTON:
      return {fieldBuilder: RadiobuttonFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.DATETIME:
    case FieldTypeEnum.DATE:
      return {fieldBuilder: DateFieldBuilderComponent, focusable: false};
    case FieldTypeEnum.LIST_MULTIVALUE:
      return {fieldBuilder: MultivalueFieldBuilderComponent, focusable: false};
    default:
      return null;
  }
}
