import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sidenav$ = new BehaviorSubject(true);

  /**
   * Emits whether the sidenav is open every time its stage changes.
   */
  public get isSidenavOpen(): Observable<boolean> {
    return this.sidenav$.asObservable();
  }

  /**
   * Toggle the sidenav's state.
   */
  public toggleSidenav(): void {
    this.sidenav$.next(!this.sidenav$.value);
  }

  public setSidenavState(state: boolean): void {
    this.sidenav$.next(state);
  }
}
