<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_SPACE' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="spaceForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.PARENT_SPACE_NAME' | translate}}</mat-label>
        <input formControlName="parentSpaceName" [id]="'input-parent-space-name'" matInput/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.SPACE_NAME' | translate}}</mat-label>
        <input formControlName="spaceName" [id]="'input-space-name'" matInput/>
        @if (spaceForm.get('spaceName').hasError('required')) {
          <mat-error>
            {{'ERROR.FIELD_REQUIRED' | translate}}
          </mat-error>
        }
        @if (spaceForm.get('spaceName').hasError('maxlength')) {
          <mat-error>
            {{ 'ERROR.FIELD_MAX_LENGTH' | translate: { value: appConfig.FIELD_MAX_LENGTH } }}
          </mat-error>
        }
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [id]="'create-space-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{'BUTTON.CANCEL' | translate}}
    </button>
    <button [disabled]="!spaceForm.valid"
      [id]="'create-space-add-button'"
      color="accent"
      mat-raised-button
      matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate}}
    </button>
  </mat-dialog-actions>
</div>
