import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Module } from '@app/core/model/client/module';
import { Organization } from '@app/core/model/entities/organization/organization';
import {
  CUSTOM_FORMATS,
  CustomDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from '@app/shared/extra/custom-date-adapter';
import { AbstractModalDialog } from '@app/shared/interfaces/abstract-modal-dialog';
import { GeneratesObject } from '@app/shared/interfaces/generates-object';
import { OrganizationsService } from '@app/shared/services/organizations.service';
import { ClientNameReset } from '@app/shared/store/reducers/client-name.actions';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { Store } from '@ngrx/store';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './client-create-modal.component.html',
  styleUrls: ['./client-create-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_FORMATS
    }
  ]
})
export class ClientCreateModalComponent extends AbstractModalDialog implements OnInit, OnDestroy, GeneratesObject {

  public static LIMITED_DURATION = 1;

  public clientNameForm: UntypedFormGroup;

  public clientSubscriptionsForm: UntypedFormGroup;

  public organizationsList: Organization[];
  public title = 'TITLE.CREATE_CLIENT';
  private createQuery: boolean;

  constructor(public appConfig: AppConfig,
              public appManager: AppManager,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private generalService: GeneralService,
              private organizationsService: OrganizationsService,
              private store: Store<any>,
              private fb: UntypedFormBuilder) {
    super();

    dayjs.extend(utc);
    dayjs.extend(isToday);

    this.createQuery = false;
    this.hideSpinner = (): boolean => this.createQuery;

    // Load organizations list
    this.organizationsService.loadData().subscribe(
      (response) => {
        // set organization list
        this.organizationsList = response.sort(
          (organizationA, organizationB) => organizationA.name.localeCompare(organizationB.name)
        );
      }
    );
  }

  public ngOnInit(): void {
    /*
    Form to edit the client name
     */
    this.clientNameForm = this.fb.group({
      clientName: this.fb.control('',
        Validators.compose([Validators.required, Validators.maxLength(this.appConfig.NAME_MAX_LENGTH)]),
        ExtraValidators.isValueTaken(this.generalService, EntityTypeEnum.CLIENT, 'name', '', {}, {
          store: this.store,
          fieldName: 'clientName'
        }))
    });
    if (this.appManager.currentOrganization == null) {
      this.clientNameForm.addControl('organization', this.fb.control('', Validators.compose([Validators.required])));
    }

    /*
    Form to edit the client subscription
     */
    this.clientSubscriptionsForm = this.fb.group({
      subscriptionStart: this.fb.control('', Validators.required),
      subscriptionDuration: this.fb.control('', Validators.compose([Validators.required, ExtraValidators.number, ExtraValidators.gte(1)])),
      subscriptionNbAuthorizedAccounts: this.fb.control('', Validators.compose([Validators.required, ExtraValidators.number, ExtraValidators.gte(1)])),
      subscriptionContractId: this.fb.control(null, Validators.maxLength(this.appConfig.CONTRACT_ID_MAX_LENGTH)),
      subscriptionModules: this.fb.array([])
    });

    /*
    Request to get all modules
     */
    const COMBINED_QUERY = gql`
      query ModuleListQuery {
        modules(onlySubscribable: true) {
          id
          code
          order
          isDefault
        }
      }
    `;
    const QUERY_VAR = {};
    this.generalService.get(COMBINED_QUERY, QUERY_VAR).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.createQuery = true;
      const modules = plainToInstance(Module, response.data['modules'] as Module[]);

      this.initialiseForm(modules);
    });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new ClientNameReset({currentValue: '', originalValue: ''}));

    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Date picker filter to block past days
   * @param {Date} d
   * @returns {boolean}
   */
  public datepickerFilter(d: any): boolean {
    // Compares the 'filtered' date to the start of today (12:00 am)
    return dayjs(d).isSame(dayjs().startOf('day')) || dayjs(d).isAfter(dayjs().startOf('day'));
  }

  private initialiseForm(modules: Module[]): void {
    const moduleFormArray = this.fb.array(modules.sort((moduleA, moduleB) =>
      moduleA.order.compareTo(moduleB.order)
    ).map((module: Module) => {
      const moduleItem = this.fb.group({
        active: this.fb.control({value: module.isDefault, disabled: module.isDefault}),
        id: this.fb.control(module.id),
        name: this.fb.control(module.code),
        nbLicences: this.fb.control({value: '', disabled: true}, Validators.compose([Validators.required, ExtraValidators.number])),
        limitedDuration: this.fb.control({value: false, disabled: true}),
        isDefault: this.fb.control(module.isDefault)
      });

      // Change detection for the module checkboxes
      moduleItem.get('active').valueChanges.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          // Enable validation on the number of licences field
          moduleItem.get('nbLicences').enable();
          moduleItem.get('nbLicences').markAsTouched();

          // If number of licences was not previously set, default to 1
          if (moduleItem.get('nbLicences').value === '') {
            moduleItem.get('nbLicences').setValue(1);
          }

        } else {
          // Disable validation
          moduleItem.get('nbLicences').disable();

          // Reset fields
          moduleItem.get('nbLicences').reset('');
          moduleItem.get('limitedDuration').reset(false);
        }
      });

      return moduleItem;
    }));

    this.clientSubscriptionsForm.setControl('subscriptionModules', moduleFormArray);
  }

  /**
   * Generates an object corresponding to a GraphQL Client Input
   * @returns {any}
   */
  public getGeneratedObject(): any {
    const subscriptionStartDate = dayjs(this.clientSubscriptionsForm.get('subscriptionStart').value);
    const now = dayjs();
    const startDate = (subscriptionStartDate.isToday()) ? subscriptionStartDate.set('h', now.get('h')).set('m', now.get('m')).set('s', now.get('s')) : subscriptionStartDate;

    return {
      organizationId: (this.appManager.currentOrganization) ? this.appManager.currentOrganization.id : this.clientNameForm.get('organization').value,
      client: {
        name: this.clientNameForm.get('clientName').value,
        subscription: {
          startDate: startDate.utc().format(this.appConfig.DATETIME_FORMAT),
          endDate: startDate.clone().add(this.clientSubscriptionsForm.get('subscriptionDuration').value, 'month').utc().format(this.appConfig.DATETIME_FORMAT),
          nbUser: this.clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').value,
          contractId: this.clientSubscriptionsForm.get('subscriptionContractId').value,
          subscriptionModules: (this.clientSubscriptionsForm.get('subscriptionModules') as UntypedFormArray).controls.filter((module) => {
            return module.get('active').value;
          }).map((module) => {
            return {
              startDate: startDate.utc().format(this.appConfig.DATETIME_FORMAT),
              endDate: startDate.clone().add(
                (module.get('limitedDuration').value) ? ClientCreateModalComponent.LIMITED_DURATION : this.clientSubscriptionsForm.get('subscriptionDuration').value,
                'month'
              ).utc().format(this.appConfig.DATETIME_FORMAT),
              limitedDuration: module.get('limitedDuration').value,
              nbLicence: module.get('isDefault').value ? this.clientSubscriptionsForm.get('subscriptionNbAuthorizedAccounts').value : module.get('nbLicences').value,
              moduleId: +module.get('id').value,
              userIds: []
            };
          })
        }
      }
    };
  }

}
