import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const checkBudgetBalance = (formStateService: FormStateService): ValidatorFn => {
  return (_: AbstractControl): {[key: string]: boolean} => {
    const workCosts = formStateService.getPath(['costs', 'value', 'costs']);
    const workExpenses = formStateService.getPath(['expenses', 'value', 'expenses']);

    if (!isPresent(workCosts) || !isPresent(workExpenses)) {
      return null;
    }

    // Do reduce unitPrice * quantity on each line here
    const totalCost = workCosts.reduce((total, entry) => {
      total += entry.quantity * entry.unitPrice;
      return total;
    }, 0);
    const totalExpenses = workExpenses.reduce((total, entry) => {
      total += entry.quantity * entry.unitPrice;
      return total;
    }, 0);

    if (totalCost < totalExpenses) {
      return {
        lteTotalCosts: true,
        gteTotalExpenses: true
      };
    }
    return null;
  };
};
