import { Component } from '@angular/core';
import { AppConfig } from '@app/core/app.config';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

/**
 * Renderer used to replace text cell values by pictograms.
 */
@Component({
  selector: 'pictogram-cell-renderer',
  templateUrl: './pictogram-cell-renderer.component.html',
  styleUrls: ['./pictogram-cell-renderer.component.scss'],
})
export class PictogramCellRendererComponent implements ICellRendererAngularComp {

  /**
   * Value to render
   * @type {string}
   */
  public cellValue: string;

  /**
   * List of pictograms
   * @type {object}
   */
  public pictograms: object;

  /**
   * True indicates that the value should be displayed in addition of pictograms,
   * otherwise the text is hidden
   * @type {boolean}
   */
  public withText: boolean;

  /**
   * True indicates that the column rendered is a group,
   * otherwise it's not a group
   * @type {boolean}
   */
  public isGroup: boolean;

  constructor(protected appConfig: AppConfig) {
  }

  /**
   * Called by AG-Grid once for initialization.
   * @param {ICellRendererParams} params Params from AG-Grid.
   */
  public agInit(params: ICellRendererParams): void {
    this.pictograms = params?.['values'] ?? {};
    this.withText = params?.['withText'] ?? false;
    this.cellValue = this.getValueToDisplay(params);
    this.isGroup = params?.['group'] ?? false;
  }

  /**
   * Retrieves the value to render.
   * @param {ICellRendererParams} params Params from ag-grid.
   * @returns {string} The value to render.
   */
  public getValueToDisplay(params: ICellRendererParams): string {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  /**
   * Used to refresh the value rendered.
   * @param {ICellRendererParams} params Params from ag-grid.
   * @returns {boolean} The refreshed value to render.
   */
  public refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }
}
